import Button from 'react-bootstrap/Button';
import { Modal, Form } from 'react-bootstrap';

//lib
import { capitalize } from "../../lib/stringCase"

const ClaimModal = (props) => {
    //props
    const { visible, record, onHide, formValue, submitHandler, handleChange, loader, errors } = props;

    return (
        <>
            <Modal show={visible} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Strategy - {record.strategy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Redem Status</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={record.redemStatus}
                                value={`${capitalize(record.redemStatus)}`}
                                autoFocus
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Investment Amount</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={record.coin}
                                value={`${record.stakedAmount} ${record.coin}`}
                                autoFocus
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter redem amount"
                                value={formValue.amount}
                                name="amount"
                                autoFocus
                                onChange={handleChange}
                            />
                            <span className="text-danger">{errors && errors?.amount}</span>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='modal-footer-flex-button'>
                    <button variant="secondary" className='secondary_btn' onClick={onHide}>Close</button>
                    <button variant="primary"  className='secondary_btn' onClick={submitHandler} disabled={loader}>Redeem</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ClaimModal;