import { combineReducers } from "redux";
import authReducer from "./authReducer";
import account from "./account";
import wallet from "./wallet";
import currencies from "./currencies";
import notice from "./notice";
import referralLevel from "./referralLevel";
import referralSettings from "./referralSettings";
import priceConversion from "./priceConversion";
import investment from "./investment";
import stackingData from "./stacking";
import userSettings from "./userSettings";
import language from "./language";

export default combineReducers({
  auth: authReducer,
  priceConversion,
  language,
  account,
  wallet,
  currencies,
  referralLevel,
  referralSettings,
  notice,
  stackingData,
  userSettings,
  investment,
});
