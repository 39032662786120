import React from "react";
import ReactApexChart from "react-apexcharts";

export default function PortfolioBChart(props) {
  const series = [
    {
      name: "Weights(%)",
      data: [20, 20, 30, 30],
    },
  ];
  const colors = ["#775DD0", "#00E396", "#FEB019", "#FF4560"];
  const options = {
    chart: {
      foreColor: "#58585f",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -30,
      style: {
        fontSize: "14px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: ["BTC", "ETH", "BTC Mining Pool", "Mantis Strategies"],
      labels: {
        style: {
          colors: "#000",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      title: {
        show: false,
        text: "",
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
        margin: 10,
      },
    },
    yaxis: {
      max: 80,
      show: false,
      labels: {
        style: {
          colors: "#000",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
      title: {
        text: "Weights",
        offsetX: -8,
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      borderColor: "rgba(31, 158, 252, 0)",
    },
    toolbar: {
      show: false,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="area_chart"
      />
    </>
  );
}
