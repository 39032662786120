import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

// import lib
import isLogin from '../../lib/isLogin';

const ConditionRoute = ({ type, children, ...rest }) => {
   
     if(type == 'auth' && isLogin() === true) {
        return <Navigate to='/' />
     } else if(type == 'private' && isLogin() !== true) {
        return <Navigate to='/login' />
     }

     return children;
};

export default ConditionRoute;