import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const NewsComponent = (props) => {
  const { data: getData } = props;
  let oneData = getData.data && getData.data[0];
  const { t, i18n } = useTranslation();

  return (
    <div>
      <section className="section">
        <div className="container">
          <div
            className="inner_bg_sec news_section"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <div className="row">
              <div className="col-lg-4">
                <h2
                  className="main_title"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {t("NEWS_COMPONENT_MAIN_TITLE")}
                </h2>
                {/* <h2
                  className="para_tag"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {oneData && oneData.title}
                </h2>
                <p
                  className="para_tag"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {oneData && oneData.description}
                </p> */}
                <Link
                  className="primary_btn"
                  to="news-events"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {t("NEWS_COMPONENT_VIEW_MORE_BUTTON")}
                  <span className="icon-right"></span>
                  <span className="icon-right after"></span>
                </Link>
              </div>
              <div
                className="col-lg-8 mt-lg-0 mt-5"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="news_wrapper">
                  <Link to="">
                    <img
                      src={require("../../assets/images/news_banner_01.png")}
                      alt="Banner"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="">
                    <img
                      src={require("../../assets/images/news_banner_02.png")}
                      alt="Banner"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="" className="wide">
                    <img
                      src={require("../../assets/images/news_banner_03.png")}
                      alt="Banner"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewsComponent;
