import { getCountryCallingCode } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";

// export const CountrySelect = ({ value, onChange, labels, ...rest }) => (
//  <div className="mb-4">
//     <select
//       className="form-group primary_inp"
//       {...rest}
//       value={value}
//       onChange={(event) => onChange(event.target.value || undefined)}
//     >
//       {getCountries().map((country) => (
//         <option  key={country} value={country}>
//           {labels[country]} +{getCountryCallingCode(country)}
//         </option>
//       ))}
//     </select>
//     </div>
// );

export const CountrySelect = ({ value, onChange, labels, ...rest }) => {

  let countryOptions = getCountries().map((country) => ({
    value: country,
    label: `${labels[country]} +${getCountryCallingCode(country)}`,
  }));

  countryOptions.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="mb-4">
      <select
        className="form-group primary_inp"
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        {countryOptions.map((country, key) => (
          <option key={key} value={country.value}>{country.label}</option>
        ))}
      </select>
    </div>
  );
};

