// import package
import io from "socket.io-client";

// import lib
import config from "./index";

const socket = io(config.SOCKET_URL);

const createSocketUser = (userId) => {
  try {
    socket.emit("CREATEROOM", userId);
    return true;
  } catch (error) {
    console.log("ERROR CREATE SOCKET:", error);
    return false;
  }
};

export { socket, createSocketUser };
