import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const StaticResponse = (props) => {
    //props
    const { show, record, onHide } = props;
    console.log("record",record)
    let messageTag = <p>There is not enough funds in your wallet balance to proceed with this investment. Please deposit further funds in your wallet by clicking below
        <Link to="/wallet" className="secondary_btn my-1">
            Wallet
        </Link></p>
    let msg = record?.message == "There is not enough asset in your balance." ? messageTag : record?.message;
    return (
        <>
            <Modal show={show} onHide={onHide}>
                {/* <Modal.Dialog> */}
                <Modal.Header closeButton>
                    <Modal.Title>{record && record?.type}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{msg}</Modal.Body>

                <Modal.Footer>
                    <button variant="danger" className='secondary_btn' onClick={onHide}>Close</button>
                </Modal.Footer>
                {/* </Modal.Dialog> */}
            </Modal>
        </>
    );
}

export default StaticResponse;