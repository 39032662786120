import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { useParams } from "react-router-dom";
// import ROIPercentChart from "../components/Dashboard/ROIPercentChart.js";
// import CapitalGrowthChart from "../components/Dashboard/CapitalGrowthChart.js";
// import StrategyChart from "../components/Dashboard/StrategyChart.js";
import { decryptString, encryptObject } from "../lib/cryptoJS.js";
import {
  AllInvestments,
  OrderPlaceLocked,
  getInvestDetails,
  getInvestIdChart,
  getLatestInvestChart,
  getStackingData,
} from "../api/invest.js";
import { getServerTime } from "../api/users.js";
import { daysToMonths } from "../lib/calculation.js";
import { toFixed } from "../lib/roundOf.js";
import { useDispatch, useSelector } from "react-redux";

import InvestDetail from "../components/Investment/InvestmentList.js";
import SettleHistory from "../components/Investment/investSettleHistory.js";
import ClaimHistory from "../components/Investment/ClaimHistory.js";
import OrderModal from "../components/Investment/orderModal.js";
import { getAssetData } from "../api/walletAction.js";

import CapitalGrowth from "../components/Investment/capitalGrowth.js";
import isEmpty from "../lib/isEmpty.js";
import { capitalize } from "../lib/stringCase.js";

import ResponseModal from "../components/ResponseModal.js";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

//Month function get
function getPreviousMonth(currentMonth, orderMonth, monthsArray) {
  // Ensure the currentMonth is a valid number (0 to 11)
  if (
    typeof currentMonth !== "number" ||
    currentMonth < 0 ||
    currentMonth > 11
  ) {
    throw new Error(
      "Invalid currentMonth value. Please provide a number between 0 and 11."
    );
  }

  // Get the index of the current month in the array
  const currentMonthIndex = monthsArray.findIndex(
    (month) => month.month === currentMonth
  );

  // If the current month is not found in the array, return 0
  if (currentMonthIndex === -1) {
    return 0;
  }

  // If the current month is not January, return the previous month
  if (currentMonthIndex !== 0) {
    return monthsArray[currentMonthIndex - 1].capGrowth;
  }

  // If the current month is January, return the last month (December)
  return monthsArray[11].capGrowth;
}

const intialFormValue = { amount: "", type: "fixed" };
let totalValue = 0;
export default function InvestmentDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();
  //useselect
  const walletDoc = useSelector((state) => state.wallet);
  const userSettings = useSelector((state) => state.userSettings);
  const { priceConversion } = useSelector((state) => state);

  const { t } = useTranslation();
  let findwallet =
    walletDoc &&
    walletDoc.length > 0 &&
    walletDoc.find((el) => el.coin == "USD");

  const [activeChart, setActiveChart] = useState([]);
  const [investData, setInvestData] = useState([]);
  const [CurDate, setCurDate] = useState();
  const [statkeData, setStatkeData] = useState([]);
  const [RoiDetail, setRoiDetail] = useState({});

  const [capDetail, setCapDetail] = useState({
    amount: 0,
    capgrowth: 0,
    percentage: 0,
  });

  const [formValue, setFormValue] = useState(intialFormValue);

  const { amount, type } = formValue;

  //Modal
  const [visible, setVisible] = useState(false);
  const [StakingData, setStakingData] = useState({});
  const [errs, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  //response modal
  const [response, setResponse] = useState(false);
  const [responseData, setResponseData] = useState({});

  const handlecloseAssetModal = () => {
    setFormValue(intialFormValue);
    setStakingData({});
    setVisible(false);
  };

  const SetRecord = () => {
    setVisible(true);
    setStakingData(investData);
  };

  const handlecloseResponseModal = () => {
    setResponse(false);
    setResponseData({});
  };

  const fetchData = async () => {
    let { status, result } = await getServerTime();
    if (status == "success") {
      setCurDate(result);
    }
  };

  //Get investment list
  const getDetails = async () => {
    try {
      let decrypt = decryptString(id, true);
      const { status, result, data } = await getInvestDetails(decrypt);
      if (status == "success") {
        setInvestData(result);
        let stakeData = data && data.length > 0 && data[0];
        let obj = {
          ...capDetail,
          ...{
            amount: stakeData ? stakeData.amount : 0,
            capgrowth:
              stakeData && stakeData.capitalGrowth > 0
                ? stakeData.capitalGrowth
                : 0,
            percentage: 0,
          },
        };
        setCapDetail(obj);

        // let firstData = result.monthList;
        // let usrdata = userSettings && userSettings?.stakeSetting;
        // if (!isEmpty(usrdata)) {
        //   let findData = usrdata && usrdata.length > 0 && usrdata.find((el) => el.stakeId.toString() == result._id.toString())
        //   let isData = findData.monthlist.every(el => el.capGrowth == null);
        //   console.log(isData, "------isData123")
        //   if (!isData) {
        //     console.log(findData.monthlist, "---findData.monthlist123")
        //     firstData = findData.monthlist
        //   }
        // }

        // setActiveChart(firstData)
        // let findMonth = CurrentDate && firstData && firstData.length > 0 && findMonths(firstData, CurrentDate);
        // setRoiDetail(findMonth)

        // //Date Section
        // let month = new Date().getMonth();
        // let orderMonth = stakeData && stakeData.date && new Date(stakeData.date).getMonth();
        // console.log(month, orderMonth, firstData, "-----------888888888888888888", month != orderMonth)
        // let Check = month != orderMonth ? getPreviousMonth(month, orderMonth, firstData) : 0;
        // let obj = {
        //   ...capDetail,
        //   ...{
        //     amount: stakeData ? stakeData.amount : 0,
        //     capgrowth: stakeData && stakeData.amount > 0 && Check > 0 ? stakeData.amount + (Check * parseFloat(stakeData.amount)) / 100 : 0,
        //     percentage: Check > 0 ? Check : 0
        //   }
        // };
        // setCapDetail(obj)
      }
    } catch (err) {
      console.log("Err on getDetails", err);
    }
  };

  //Get Total investment list
  const getAllInvests = async () => {
    try {
      let decrypt = decryptString(id, true);
      const { status, result } = await AllInvestments({ stakeId: decrypt });
      if (status == "success") {
        setStatkeData(result);
        let length = result && result.length;
        if (length) {
          totalValue = result.reduce((acc, cur) => acc + cur.stakedAmount, 0);
        }
      }
    } catch (err) {
      console.log("Err on getAllInvests", err);
    }
  };
  const handleActiveStrategy = async (Data, id) => {
    try {
      console.log("VALUE_RESULT", Data?.result);
      console.log(
        "VALUE_FILTER",
        Data?.result.filter((el) => el.stakeId?.toString() == id?.toString())
      );
      let chartdatum = {
        capital: { label: [], data: [], investData: [] },
        interest: { label: [], data: [] },
      };

      if (id) {
        let findData =
          Data?.result &&
          Data?.result.length > 0 &&
          Data?.result.filter((el) => el.stakeId?.toString() == id?.toString());
        if (findData) {
          //capital
          let month = [];
          let amount = [];
          let investAmount = [];
          console.log(findData, "----findDatafindDatafindData");
          for (let i in findData) {
            let d = moment(
              findData[i]._id.year + "/" + findData[i]._id.month
            ).format("MMM YYYY");
            month.push(d);
            amount.push(findData[i].capitalGrowth);
            investAmount.push(findData[i].amount);
          }
          chartdatum.capital = {
            label: month,
            data: amount,
            investData: investAmount,
          };
        }
      }
      console.log("CHARTUM DATA", chartdatum);
      setActiveChart(chartdatum);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const getAllInvestChart = async () => {
    try {
      const decrypt = decryptString(id, true);
      const { status, result } = await getLatestInvestChart();

      if (status == "success") {
        await handleActiveStrategy(result, decrypt);
      }
    } catch (err) {
      console.log("Err on getAllInvestChart", err);
    }
  };

  const findMonths = (monthlist, CurDate) => {
    let nowmonth = new Date(CurDate).getMonth();
    let month = monthlist.find((el) => el.month == nowmonth);
    return month;
  };

  //function
  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (value) {
      setErrors({});
    }
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      if (Number(amount) === 0) {
        return setErrors({ amount: "Amount cannot be zero" });
      } else if (amount < 0) {
        return setErrors({ amount: "Amount cannot be negative" });
      } else if (StakingData.minimumAmount > amount) {
        return setErrors({
          amount: `Amount must be greater than or equal to ${StakingData.minimumAmount}`,
        });
      } /* else if (StakingData.maximumAmount < amount) {
        return setErrors({ amount: `Amount must be less than or equal to ${StakingData.maximumAmount}` })
      }
 */
      setLoader(true);
      const reqData = {
        stakeId: StakingData._id,
        price: amount,
        duration_days: StakingData.period,
        currencyId: StakingData.currencyId,
        type,
      };
      console.log("req body is ", reqData);
      let encryptObj = encryptObject(reqData);
      const { status, message } = await OrderPlaceLocked({ token: encryptObj });
      const msg = t(message);
      if (status == "success") {
        setLoader(false);
        handlecloseAssetModal();
        setFormValue(intialFormValue);
        refetch();
        setResponseData({ message: msg, type: "Investment Order" });
        setResponse(true);
      } else {
        setLoader(false);
        setResponseData({ message: msg, type: "Investment Order" });
        setResponse(true);
      }
    } catch (error) {
      console.log(error, "-------------error");
    }
  };

  const refetch = async () => {
    await getAssetData(dispatch);
    await getStackingData(dispatch);
    getAllInvests();
    getAllInvestChart();
  };
  useEffect(() => {
    if (id) {
      fetchData();
      getDetails();
      getAllInvests();
      getAllInvestChart();
    }
  }, []);
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />

      <OrderModal
        visible={visible}
        record={StakingData}
        onHide={handlecloseAssetModal}
        handleChange={handleChange}
        formValue={formValue}
        submitHandler={submitHandler}
        loader={loader}
        error={errs}
      />

      <ResponseModal
        show={response}
        record={responseData}
        onHide={handlecloseResponseModal}
      />

      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="inner_head_title">
                {t("INVEST_INVESTMENT_DETAILS")} (
                {capitalize(investData?.strategy) === "Balanced"
                  ? t("BALANCED_TITLE")
                  : capitalize(investData?.strategy) === "High Yield"
                  ? t("HIGH_YIELD_TITLE")
                  : capitalize(investData?.strategy) === "Custom Solution"
                  ? t("CUSTOM_SOLUTION")
                  : t("GROWTH_TITLE")}
                )
              </h2>
            </div>
          </div>
          <div className="row dash_box_row">
            <div className="col-xl-12">
              <div className="dash_box_shadow">
                <div className="dahboard_overall invest_details_panel">
                  <div className="dahboard_overall_single">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          opacity="0.15"
                          d="M19.832 9.04036C19.7154 9.04036 19.6104 9.01703 19.4937 8.97037C19.2837 8.87703 19.1087 8.7137 19.0154 8.49203C18.9687 8.38703 18.9454 8.27036 18.9454 8.1537V3.48703C18.9454 3.47536 18.957 3.46369 18.957 3.44036C17.452 2.74036 15.772 2.33203 13.9987 2.33203C7.5587 2.33203 2.33203 7.5587 2.33203 13.9987C2.33203 20.4387 7.5587 25.6654 13.9987 25.6654C20.4387 25.6654 25.6654 20.4387 25.6654 13.9987C25.6654 12.2254 25.257 10.5454 24.5454 9.0287C24.5337 9.0287 24.522 9.04036 24.4987 9.04036H19.832Z"
                          fill="white"
                        />
                        <path
                          d="M20.6618 7.24659C20.7409 7.44986 20.8795 7.61924 21.0675 7.70958C21.1565 7.75475 21.2555 7.77734 21.3544 7.77734L25.3122 7.77734C25.7178 7.77734 26.0543 7.3934 26.0543 6.9304C26.0543 6.4674 25.7178 6.08345 25.3122 6.08345L23.1453 6.08345L26.832 2.40207C26.832 0.777344 25.6654 1.55512 25.6654 1.55512L22.0965 4.88643L22.0965 2.40207C22.0965 1.93907 21.7601 1.55512 21.3544 1.55512C20.9487 1.55512 20.6123 1.93907 20.6123 2.40207L20.6123 6.9191C20.6024 7.03203 20.6222 7.13366 20.6618 7.24659Z"
                          fill="white"
                        />
                        <path
                          d="M16.0423 13.79L14.8757 13.3817V10.7917H14.969C15.564 10.7917 16.0423 11.3167 16.0423 11.9583C16.0423 12.4367 16.439 12.8333 16.9173 12.8333C17.3957 12.8333 17.7923 12.4367 17.7923 11.9583C17.7923 10.3483 16.5323 9.04167 14.969 9.04167H14.8757V8.75C14.8757 8.27167 14.479 7.875 14.0007 7.875C13.5223 7.875 13.1257 8.27167 13.1257 8.75V9.04167H12.7757C11.364 9.04167 10.209 10.2317 10.209 11.7017C10.209 13.405 11.2007 13.9533 11.959 14.2217L13.1257 14.63V17.22H13.0323C12.4373 17.22 11.959 16.695 11.959 16.0533C11.959 15.575 11.5623 15.1783 11.084 15.1783C10.6057 15.1783 10.209 15.575 10.209 16.0533C10.209 17.6633 11.469 18.97 13.0323 18.97H13.1257V19.2617C13.1257 19.74 13.5223 20.1367 14.0007 20.1367C14.479 20.1367 14.8757 19.74 14.8757 19.2617V18.97H15.2256C16.6373 18.97 17.7923 17.78 17.7923 16.31C17.7923 14.595 16.8007 14.0467 16.0423 13.79ZM12.5306 12.5533C12.134 12.4133 11.959 12.3317 11.959 11.69C11.959 11.1883 12.3323 10.78 12.7757 10.78H13.1257V12.7517L12.5306 12.5533ZM15.2256 17.2083H14.8757V15.2367L15.4707 15.4467C15.8673 15.5867 16.0423 15.6683 16.0423 16.31C16.0423 16.8 15.669 17.2083 15.2256 17.2083Z"
                          fill="white"
                        />
                      </svg>
                      <h4>
                        {t("INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL")}
                      </h4>
                    </div>
                    <h3>$ {toFixed(totalValue, 0)}</h3>
                  </div>

                  <div className="dahboard_overall_single">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.15"
                          d="M2.91543 15.0924L1.14211 13.3191C0.418776 12.5958 0.418776 11.4057 1.14211 10.6824L2.91543 8.90906C3.21876 8.60572 3.46376 8.01072 3.46376 7.59072V5.08243C3.46376 4.05576 4.30377 3.21572 5.33044 3.21572H7.83876C8.25876 3.21572 8.85377 2.97075 9.1571 2.66742L10.9304 0.894063C11.6538 0.170729 12.8438 0.170729 13.5671 0.894063L15.3405 2.66742C15.6438 2.97075 16.2388 3.21572 16.6588 3.21572H19.1671C20.1938 3.21572 21.0338 4.05576 21.0338 5.08243V7.59072C21.0338 8.01072 21.2788 8.60572 21.5821 8.90906L23.3555 10.6824C24.0788 11.4057 24.0788 12.5958 23.3555 13.3191L21.5821 15.0924C21.2788 15.3957 21.0338 15.9907 21.0338 16.4107V18.9191C21.0338 19.9457 20.1938 20.7857 19.1671 20.7857H16.6588C16.2388 20.7857 15.6438 21.0308 15.3405 21.3341L13.5671 23.1074C12.8438 23.8308 11.6538 23.8308 10.9304 23.1074L9.1571 21.3341C8.85377 21.0308 8.25876 20.7857 7.83876 20.7857H5.33044C4.30377 20.7857 3.46376 19.9457 3.46376 18.9191V16.4107C3.46376 15.979 3.21876 15.384 2.91543 15.0924Z"
                          fill="white"
                        />
                        <path
                          d="M15.7584 16.6556C15.1051 16.6556 14.5801 16.1306 14.5801 15.4889C14.5801 14.8473 15.1051 14.3223 15.7467 14.3223C16.3884 14.3223 16.9134 14.8473 16.9134 15.4889C16.9134 16.1306 16.4001 16.6556 15.7584 16.6556Z"
                          fill="white"
                        />
                        <path
                          d="M8.77014 9.65365C8.11681 9.65365 7.5918 9.12865 7.5918 8.48698C7.5918 7.84531 8.1168 7.32031 8.75846 7.32031C9.40013 7.32031 9.92513 7.84531 9.92513 8.48698C9.92513 9.12865 9.41181 9.65365 8.77014 9.65365Z"
                          fill="white"
                        />
                        <path
                          d="M8.7588 16.3624C8.53713 16.3624 8.31547 16.2807 8.14047 16.1057C7.80214 15.7674 7.80214 15.2073 8.14047 14.869L15.1405 7.86898C15.4788 7.53065 16.0388 7.53065 16.3771 7.86898C16.7155 8.20732 16.7155 8.76739 16.3771 9.10572L9.37713 16.1057C9.20213 16.2807 8.98047 16.3624 8.7588 16.3624Z"
                          fill="white"
                        />
                      </svg>
                      <h4>{t("INVEST_PROJECTED_ROI")}</h4>
                    </div>
                    <h3>{investData && investData?.staticroi} %</h3>
                  </div>

                  <div className="dahboard_overall_single">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M14.0007 25.5501C20.444 25.5501 25.6673 20.3268 25.6673 13.8835C25.6673 7.44014 20.444 2.2168 14.0007 2.2168C7.55733 2.2168 2.33398 7.44014 2.33398 13.8835C2.33398 20.3268 7.55733 25.5501 14.0007 25.5501Z"
                          fill="white"
                        />
                        <path
                          d="M16.6368 14L14.8752 13.3817V9.42667H15.2952C16.2402 9.42667 17.0102 10.255 17.0102 11.27C17.0102 11.7483 17.4068 12.145 17.8852 12.145C18.3635 12.145 18.7602 11.7483 18.7602 11.27C18.7602 9.28667 17.2085 7.67667 15.2952 7.67667H14.8752V7C14.8752 6.52167 14.4785 6.125 14.0002 6.125C13.5218 6.125 13.1252 6.52167 13.1252 7V7.67667H12.3668C10.6402 7.67667 9.22852 9.135 9.22852 10.92C9.22852 13.0083 10.4418 13.6733 11.3635 14L13.1252 14.6183V18.5617H12.7052C11.7602 18.5617 10.9902 17.7333 10.9902 16.7183C10.9902 16.24 10.5935 15.8433 10.1152 15.8433C9.63685 15.8433 9.24018 16.24 9.24018 16.7183C9.24018 18.7017 10.7918 20.3117 12.7052 20.3117H13.1252V21C13.1252 21.4783 13.5218 21.875 14.0002 21.875C14.4785 21.875 14.8752 21.4783 14.8752 21V20.3233H15.6335C17.3602 20.3233 18.7718 18.865 18.7718 17.08C18.7602 14.98 17.5469 14.315 16.6368 14ZM11.9468 12.355C11.3518 12.145 10.9902 11.9467 10.9902 10.9317C10.9902 10.1033 11.6085 9.43833 12.3785 9.43833H13.1368V12.775L11.9468 12.355ZM15.6335 18.5733H14.8752V15.2367L16.0535 15.645C16.6485 15.855 17.0102 16.0533 17.0102 17.0683C17.0102 17.8967 16.3918 18.5733 15.6335 18.5733Z"
                          fill="white"
                        />
                      </svg>
                      <h4>{t("INVEST_DASHBOARD_CAPITAL_GROWTH")}</h4>
                    </div>
                    <h3>{capDetail && parseInt(capDetail?.capgrowth)} USD</h3>
                  </div>

                  <div className="dahboard_overall_single">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="26"
                        viewBox="0 0 24 26"
                        fill="none"
                      >
                        <path
                          d="M16.2176 3.02123V1.20383C16.2176 0.726178 15.8215 0.330078 15.3439 0.330078C14.8662 0.330078 14.4701 0.726178 14.4701 1.20383V2.95133H6.89765V1.20383C6.89765 0.726178 6.50155 0.330078 6.0239 0.330078C5.54625 0.330078 5.15015 0.726178 5.15015 1.20383V3.02123C2.00465 3.31248 0.4785 5.18813 0.2455 7.97248C0.2222 8.31033 0.5018 8.58992 0.828 8.58992H20.5398C20.8776 8.58992 21.1572 8.29868 21.1223 7.97248C20.8893 5.18813 19.3631 3.31248 16.2176 3.02123Z"
                          fill="white"
                        />
                        <path
                          opacity="0.15"
                          d="M21.1692 11.5028V13.5299C21.1692 14.2406 20.5401 14.7881 19.8411 14.6716C19.5149 14.625 19.1771 14.5901 18.8392 14.5901C15.3093 14.5901 12.4317 17.4676 12.4317 20.9976C12.4317 21.5335 12.6414 22.2791 12.8628 22.9548C13.1191 23.7121 12.5599 24.4926 11.756 24.4926H6.02422C1.94672 24.4926 0.199219 22.1626 0.199219 18.6676V11.4912C0.199219 10.8504 0.723469 10.3262 1.36422 10.3262H20.0042C20.645 10.3378 21.1692 10.8621 21.1692 11.5028Z"
                          fill="white"
                        />
                        <path
                          d="M18.8397 16.3496C16.265 16.3496 14.1797 18.435 14.1797 21.0096C14.1797 21.8834 14.4243 22.7105 14.8554 23.4095C15.6592 24.7609 17.1388 25.6696 18.8397 25.6696C20.5406 25.6696 22.0201 24.7609 22.824 23.4095C23.255 22.7105 23.4997 21.8834 23.4997 21.0096C23.4997 18.435 21.4143 16.3496 18.8397 16.3496ZM21.2512 20.5087L18.7698 22.8037C18.6067 22.9552 18.3853 23.0367 18.1756 23.0367C17.9543 23.0367 17.7329 22.9551 17.5582 22.7804L16.4048 21.6271C16.067 21.2892 16.067 20.73 16.4048 20.3922C16.7427 20.0543 17.3019 20.0543 17.6397 20.3922L18.1989 20.9514L20.0629 19.2272C20.4124 18.901 20.9716 18.9243 21.2978 19.2738C21.624 19.6233 21.6007 20.1708 21.2512 20.5087Z"
                          fill="white"
                        />
                        <path
                          d="M6.60641 16.3506C6.30351 16.3506 6.00061 16.2224 5.77926 16.0127C5.56956 15.7914 5.44141 15.4885 5.44141 15.1856C5.44141 14.8827 5.56956 14.5798 5.77926 14.3584C6.04721 14.0905 6.45495 13.9623 6.8394 14.0439C6.9093 14.0555 6.9792 14.0788 7.0491 14.1138C7.119 14.1371 7.1889 14.172 7.2588 14.2186C7.31705 14.2652 7.37531 14.3118 7.43356 14.3584C7.64326 14.5798 7.77141 14.8827 7.77141 15.1856C7.77141 15.4885 7.64326 15.7914 7.43356 16.0127C7.37531 16.0593 7.31705 16.1059 7.2588 16.1525C7.1889 16.1991 7.119 16.2341 7.0491 16.2574C6.9792 16.2923 6.9093 16.3156 6.8394 16.3273C6.75785 16.3389 6.67631 16.3506 6.60641 16.3506Z"
                          fill="white"
                        />
                        <path
                          d="M10.6845 16.3486C10.3816 16.3486 10.0787 16.2205 9.85738 16.0108C9.64768 15.7894 9.51953 15.4865 9.51953 15.1836C9.51953 14.8807 9.64768 14.5778 9.85738 14.3565C10.3001 13.9254 11.0806 13.9254 11.5117 14.3565C11.7214 14.5778 11.8495 14.8807 11.8495 15.1836C11.8495 15.4865 11.7214 15.7894 11.5117 16.0108C11.2903 16.2205 10.9874 16.3486 10.6845 16.3486Z"
                          fill="white"
                        />
                        <path
                          d="M6.60641 20.4264C6.30351 20.4264 6.00061 20.2982 5.77926 20.0885C5.56956 19.8672 5.44141 19.5643 5.44141 19.2614C5.44141 18.9585 5.56956 18.6556 5.77926 18.4342C5.89576 18.3294 6.01226 18.2478 6.16371 18.1896C6.59476 18.0032 7.10736 18.108 7.43356 18.4342C7.64326 18.6556 7.77141 18.9585 7.77141 19.2614C7.77141 19.5643 7.64326 19.8672 7.43356 20.0885C7.21221 20.2982 6.90931 20.4264 6.60641 20.4264Z"
                          fill="white"
                        />
                      </svg>
                      <h4>lock period</h4>
                    </div>
                    <h3>
                      {investData &&
                        toFixed(daysToMonths(investData.period), 0)}{" "}
                      {t("INVEST_MONTHS")}
                    </h3>
                  </div>

                  <div className="dahboard_overall_single">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="24"
                        viewBox="0 0 16 24"
                        fill="none"
                      >
                        <path
                          d="M15.9674 12.7598V17.0764C15.9674 20.7164 12.5724 23.6681 8.38412 23.6681C4.19578 23.6681 0.800781 20.7164 0.800781 17.0764V12.7598C0.800781 16.3998 4.19578 19.0014 8.38412 19.0014C12.5724 19.0014 15.9674 16.3998 15.9674 12.7598Z"
                          fill="white"
                        />
                        <path
                          opacity="0.3"
                          d="M15.9674 6.92578V12.7591C15.9674 16.3991 12.5724 19.0008 8.38412 19.0008C4.19578 19.0008 0.800781 16.3991 0.800781 12.7591V6.92578C0.800781 7.98745 1.09245 8.96745 1.60578 9.80745C2.85412 11.8608 5.42078 13.1674 8.38412 13.1674C11.3474 13.1674 13.9141 11.8608 15.1624 9.80745C15.6758 8.96745 15.9674 7.98745 15.9674 6.92578Z"
                          fill="white"
                        />
                        <path
                          d="M15.9674 6.92565C15.9674 7.98732 15.6758 8.96732 15.1624 9.80732C13.9141 11.8607 11.3474 13.1673 8.38412 13.1673C5.42078 13.1673 2.85412 11.8607 1.60578 9.80732C1.09245 8.96732 0.800781 7.98732 0.800781 6.92565C0.800781 3.28565 4.19578 0.333984 8.38412 0.333984C10.4841 0.333984 12.3741 1.06898 13.7508 2.25898C15.1158 3.46065 15.9674 5.10565 15.9674 6.92565Z"
                          fill="white"
                        />
                      </svg>
                      <h4>{t("INVEST_DASHBOARD_AVAILABLE_BALANCE")}</h4>
                    </div>
                    <h3>{findwallet && parseInt(findwallet.depositBal)} USD</h3>
                  </div>

                  <button className="secondary_btn w-auto" onClick={SetRecord}>
                    {t("INVEST_DASHBOARD_INVEST_LINK")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {CurDate && (
            <CapitalGrowth
              /* investData={investData} statkeData={statkeData} */ activeData={
                activeChart
              }
              CurDate={CurDate}
            />
          )}

          <InvestDetail
            statkeData={statkeData}
            CurDate={CurDate}
            refetch={refetch}
            priceConversion={priceConversion}
          />

          <SettleHistory Id={id} />

          <ClaimHistory Id={id} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
