import React from "react";
import ReactApexChart from "react-apexcharts";
import { substring } from "../../lib/stringCase";

export default function ROIPercentChart(props) {

  const { roiData/* , monthArray, activeData, CurDate  */ } = props;
console.log("ROI_DATA:",roiData)
  /*   let isData = activeData && activeData.length > 0;
    let CurMonth = new Date(CurDate).getMonth();
    let min = Math.min(...monthArray) === Infinity ? null : Math.min(...monthArray); */

  // let data = roiData && roiData.length > 0 && roiData.map((item) => isData && min != null && item.month != CurMonth && min < item.month ? item.APY : 0)
  // let data = roiData && roiData.length > 0 && roiData.map((item) => item.APY)
  let original = roiData && roiData.interest
  const series = [
    {
      name: "ROI(USD)",
      data: original.data
    },
  ];

  const options = {
    chart: {
      foreColor: "#58585f",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    xaxis: {
      categories: original.label,
      // categories: [
      //   "Jul 2023",
      //   "Aug 2023",
      //   "Sep 2023",
      //   "Oct 2023",
      //   "Nov 2023",
      //   "Dec 2023",
      // ],
      labels: {
        style: {
          colors: "#fff",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      title: {
        text: "Monthly",
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
        margin: 10,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
      title: {
        text: "ROI (USD)",
        offsetX: -8,
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    grid: {
      borderColor: "rgba(217, 217, 217, 0.18)",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 3,
    },
    toolbar: {
      show: false,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="area_chart"
      />
    </>
  );
}
