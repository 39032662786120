import React, { useEffect } from "react";
// socket
import { socket } from "./config/socketConnectivity";
import SocketContext from "./context/SocketContext";
// Redux
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
// api
import { decodeJwt } from './api/jsonWebToken';
// router-dom
import { BrowserRouter, useRoutes } from "react-router-dom";
// routes
import HelperRoute from "./components/Route/HelperRoute";
import routes from "./routes";
// lib
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";
import { ToastContainer } from "react-toastify";
import { I18nextProvider } from 'react-i18next';
import i18n from "./components/i18next/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const AppRoutes = () => {
  return useRoutes(routes);
};
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
});
const App = () => {
  //   const routes = useRoutes([
  //     { path: "/", element: <Home /> },
  //     { path: "/*", element: <Navigate to="/" /> },
  //     { path: "register", element: <Register /> },
  //     { path: "login", element: <Login /> },
  //     { path: "forgot-password", element: <ForgotPassword /> },
  //     { path: "dashboard", element: <Dashboard /> },
  //     { path: "referral", element: <Referral /> },
  //     { path: "wallet", element: <Wallet /> },
  //     { path: "invest", element: <Invest /> },
  //     { path: "investment-details", element: <InvestmentDetails /> },
  //   ]);
  //   return routes;
  // };
  const { isAuth } = store.getState().auth;

  useEffect(() => {
    if (isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }
  }, []);
  // const AppWrapper = () => {
  //   return (
  //     <Router basename="/ui-templates/MantisNetwork">
  //       <App />
  //     </Router>
  //   );
  // };
  return (
    <Provider store={store}>
      {/* <QueryClientProvider client={queryClient}> */}
        <I18nextProvider i18n={i18n}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename="/">
              <SocketContext.Provider value={{ socket }}>
                <ToastContainer />
                <HelperRoute />
                <AppRoutes />
              </SocketContext.Provider>
            </BrowserRouter>
          </PersistGate>
        </I18nextProvider>
      {/* </QueryClientProvider> */}
    </Provider>
  );
}
export default App
