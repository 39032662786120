import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";

import EarnHistory from "../components/History/earnHistory.js"

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function History() {

    return (
        <div>
            <ScrollToTopOnMount />
            <NavbarInner />
            <div className="inner_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="inner_head_title">History</h2>
                        </div>
                    </div>
                    <EarnHistory />
                </div>
            </div>
            <Footer />
        </div>
    );
}
