// import constant
import { SET_USER_SETTINGS, RESET_ALL } from "../constant";

const initialValue = [];

const userSettings = (state = initialValue, action) => {
  switch (action.type) {
    case SET_USER_SETTINGS:
      return {
        ...state,
        ...action.data
      };  
    case RESET_ALL: {
      return initialValue
    }
    default:
      return state;
  }
};

export default userSettings;
