// import constant
import { SET_INVESTMENT_LIST, RESET_ALL } from '../constant';

const initialValue = [];

const investmentList = (state = initialValue, action) => {
    switch (action.type) {
        case SET_INVESTMENT_LIST:
            return action.data;
        case RESET_ALL: {
            return initialValue
        }
        default:
            return state;
    }
}

export default investmentList;