import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { Line } from "react-chartjs-2";
import { getLatestInvestChart } from "../../api/invest";
import { getUserProfitDetails } from "../../api/dashboard";

import moment from "moment";
import { useSelector } from "react-redux";
import { t } from "i18next";

const data = [
  {
    investmentPlan: "",
    coin: "Ethereum",
    symbol: "ETH",
    price: 3359.58,
    change1h: 0.3,
    change24h: -2.8,
    change7d: -0.8,
    marketCap: 404126747203,
    holdings: 10891.58,
    amount: 3.241,
    pnl: 4892.49,
    pnlPercent: 81.6,
    chartData: [10, 20, 30, 40, 50, 60, 70],
  },
  {
    investmentPlan: "",
    coin: "Solana",
    symbol: "SOL",
    price: 148.66,
    change1h: -0.5,
    change24h: 0.1,
    change7d: 8.6,
    marketCap: 68780144052,
    holdings: 10879.88,
    amount: 73.17,
    pnl: 7879.91,
    pnlPercent: 262.7,
    chartData: [70, 60, 50, 40, 30, 20, 10],
  },
  {
    coin: "Bitcoin",
    symbol: "BTC",
    price: 60908.73,
    change1h: 0.2,
    change24h: -3.3,
    change7d: -1.3,
    marketCap: 1201223897084,
    holdings: 10562.82,
    amount: 0.1734,
    pnl: 4562.82,
    pnlPercent: 76.1,
    chartData: [20, 30, 40, 50, 60, 70, 80],
  },
];

const CryptoTable = ({ profitData }) => {
  const { stackingData } = useSelector((state) => state);
  console.log("stackingData", stackingData);
  const columns = useMemo(
    () => [
      // { Header: "Plan", accessor: "investmentPlan" },
      {
        Header: "Plan",
        accessor: "stakeId",
        Cell: ({ value }) => {
          const plan = stackingData.find((item) => item._id == value);
          return <>{plan?.strategy}</>;
        },
      },
      { Header: "Price", accessor: "totalInvest" },
      {
        Header: "Profit (USD)",
        accessor: "profit",
        Cell: ({ value }) => {
          const profit = value ?? 0;
          return <>{profit.toFixed(2)}</>;
        },
      },
      {
        Header: "Profit (%)",
        accessor: "profitInPercent",
        Cell: ({ value }) => {
          const profit = value ?? 0;
          return <>{profit} %</>;
        },
      },
      { Header: "Current Value", accessor: "totalMarketPrice" },
      {
        Header: "Invest Fluctuations",
        accessor: "invest",
        Cell: ({ cell }) => {
          return <LineChart data={cell.value} />;
        },
      },
    ],
    []
  );
  console.log("profitDataprofitData", profitData);
  const tableInstance = useTable({ columns, data: profitData });

  const [Data, setData] = useState({});

  const [roiData, setRoiData] = useState({
    capital: { label: [], data: [], investData: [] },
    interest: { label: [], data: [] },
    stakeId: "",
    planeName: "",
  });
  console.log("roiDataroiData", roiData);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const NewSetChartData = async (chartdata) => {
    const result = chartdata.result ?? [];
    const result2 = chartdata?.result2 ?? [];
    let chartdatum = { ...roiData };
    for (let i = 0; i < result.length; i++) {
      let curData = result[i];

      if (curData) {
        let amount = [];
        let investAmount = [];
        for (let l in curData) {
          amount.push(curData[l].capitalGrowth);
          investAmount.push(curData[l].amount);
        }
        chartdatum = {
          ...roiData,
          capital: {
            data: amount,
            investData: investAmount,
          },
          planeName: stackingData.find((item) => item._id == curData.stakeId),
        };
        console.log("chartdatum", chartdatum);
      }
      for (let j = 0; j < result2.length; j++) {
        let foundData = result2[j];
        console.log("foundData", foundData);
        if (foundData) {
          let amount = [];
          for (let k in foundData) {
            amount.push(foundData[k].amount);
          }
          chartdatum = {
            ...roiData,
            ...{ interest: { data: amount } },
            planeName: stackingData.find(
              (item) => item._id == foundData.stakeId
            ),
          };
        }
      }
    }

    setRoiData(chartdatum);
  };
  const fetchLatestChartData = async () => {
    let { status, result } = await getLatestInvestChart();
    if (status == "success") {
      setData(result);
      NewSetChartData(result);
    }
  };

  useEffect(() => {
    fetchLatestChartData();
  }, []);
  return (
    <div className="row dash_box_row">
      <div className="col-lg-12">
        <div className="chart_box">
          <div className="inner_subtitle">
            <h2> {t("INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE")}</h2>
          </div>{" "}
          <table
            {...getTableProps()}
            style={{
              width: "100%",
              borderCollapse: "collapse",
              // backgroundColor: "#0044ff",
              backgroundColor: "rgba(0, 0, 0, 0.58)",
              color: "white",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        borderBottom: "2px solid black",
                        padding: "10px",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{ borderBottom: "1px solid gray" }}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{ padding: "10px" }}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const LineChart = ({ data }) => {
  const chartData = {
    labels: Array.from({ length: data.length + 1 }, (_, i) => i + 1),
    datasets: [
      {
        label: "Price",
        data: [0, ...data],
        fill: false,
        // backgroundColor: "white",
        borderColor: "yellow",
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        labels: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        display: false,
      },
      y: {
        beginAtZero: false,
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <div
      style={{
        height: "50px",
        width: "150px",
        // backgroundColor: "rgba(95, 131, 255, 0.5)",
        // backgroundColor: "#ffff ",
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

export default CryptoTable;
