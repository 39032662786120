import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// import api
import { forceLogoutByAdminURL, loginWithurl } from "../api/users";

// import lib
import { toastAlert } from "../lib/toastAlert";
import { useDispatch } from "react-redux";

const URL_LOGIN = () => {
  const params = useParams();
  const authToken = params.id;
  const one = useRef(false);
  // const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const getloginToken = async () => {
    try {
      let token = localStorage.getItem("user_token");
      // if (token) forceLogout(navigate, dispatch);
      if (token) {
        await forceLogoutByAdminURL(navigate, dispatch);
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));
      const { status, message } = await loginWithurl(
        { userId: params.authToken },
        dispatch
      );
      toastAlert(
        status === "success" ? status : "error",
        message,
        "emailVerify"
      );
      navigate("/dashboard");
    } catch (err) {
      console.log("getloginToken_err", err);
    }
  };
  useEffect(() => {
    let pathname = location.pathname;
    if (pathname == "/url-login/" + params.authToken && !one.current) {
      let bear = `Bearer ${authToken}`;
      // setAuthorization(bear)
      // setAuthToken(bear)
      // navigate('/')
      
      !one.current && getloginToken();
      one.current = true
      // oneTime = true;
    }
  }, [params]);

  return <h3>Loading...</h3>;
};

export default URL_LOGIN;
