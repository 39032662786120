// import lib
import isEmpty from './isEmpty';

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const firstLetterCase = (value) => {
    if (!isEmpty(value)) {
        return value.charAt(0).toUpperCase();
    }
    return ''
}

export const substring = (str, length) => {
    try {
        str = str.substring(0, length)
        return str
    } catch (err) {
        return str
    }
}

export const upperCase = (s) => {
    if (isEmpty(s)) {
        return ''
    }
    return s.toUpperCase()
}

export const emailFormat = (email) => {
    try {
        if (!isEmpty(email)) {
            let domain = email.substring(email.indexOf('@'), email.indexOf('.'))
            domain = domain.substring(domain.length - 2, domain.length)
            return email.substring(0, 3) + "....@..." + domain + email.substring(email.indexOf('.'), email.length)
        }
        return ''
    } catch (err) {
        return ''
    }
}

export const cnvtBoolean = value => {
    if (typeof value === 'boolean' && value == true) return true;
    if (typeof value === 'boolean' && value == false) return false;
    if (typeof value === 'string' && value == 'true') return true;
    if (typeof value === 'string' && value == 'false') return false;
}

// Function to filter duplicates based on the 'id' property
export const filterDuplicates = (arr, key) => {
    const seen = new Set();
    return arr.filter(obj => {
        const value = obj[key];
        if (seen.has(value)) {
            return false; // Duplicate, filter it out
        }
        seen.add(value);
        return true; // Unique, keep it
    });
};

export const removeSpecial = (inputString) => {
    const pattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;
    const result = inputString.replace(pattern, ' ');
    return capitalize(result);
}

export const toStaringCase = (inputString) => {
    let words = inputString.replace(/[^\w\s]/g, '').split(/[\s_]+/);

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    let staringCaseString = words.join(' ');

    return staringCaseString;
}