import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import SupportedCrypto from "../components/SupportedCrypto.js";
import { Link } from "react-router-dom";
// AOS
import AOS from "aos";
import "aos/dist/aos.css";
// Slider
import "rc-slider/assets/index.css";
import store from "../store";
import { getFaq, getLanguage, getNews, getSiteSetting } from "../api/users.js";

//import component
import { Calculator } from "../components/Home/Calculator.js";
import FAQ from "../components/Home/faq.js";
import News from "../components/Home/News.js";
import ReactApexChart from "react-apexcharts";
import PortfolioAChart from "../components/PortfolioChart/PortfolioAChart.js";
import PortfolioBChart from "../components/PortfolioChart/PortfolioBChart.js";
import PortfolioCChart from "../components/PortfolioChart/PortfolioCChart.js";
import { useDispatch, useSelector } from "react-redux";
import { encryptString } from "../lib/cryptoJS.js";
import { dynamicFind, formatNumber } from "../lib/roundOf.js";
import { useTranslation } from "react-i18next";
import isEmpty from "../lib/isEmpty.js";
import NavbarInner from "../components/Navbar-Inner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const { isAuth } = store.getState().auth;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const investmentDoc = useSelector((state) => state.stackingData);
  const { priceConversion, language } = useSelector((state) => state);

  const [FaqData, setFaqData] = useState([]);
  const [NewsData, setNewsData] = useState([]);
  const [SiteData, setSiteData] = useState({});

  useEffect(() => {
    loadScript();
    AOS.init();
    if (isEmpty(language)) {
      getLanguage(dispatch);
    }
  }, []);

  function loadScript() {
    // Accordian Add class
    const accordionItems = document.querySelectorAll(
      ".custom_accordian .accordion-item"
    );
    accordionItems.forEach((item, index) => {
      item.addEventListener("click", () => {
        accordionItems.forEach((otherItem, otherIndex) => {
          if (index !== otherIndex) {
            otherItem.classList.remove("active-accordion");
          }
        });
        item.classList.add("active-accordion");
      });
    });
  }

  const fetchData = async () => {
    let { status, result } = await getFaq();
    if (status == "success") {
      setFaqData(result);
    }
  };

  const fetchNews = async () => {
    let { status, result } = await getNews();
    if (status == "success") {
      let obj = {
        data: result && result?.data,
        imageUrl: result && result?.imageUrl,
      };
      setNewsData(obj);
    }
  };

  useEffect(() => {
    fetchData();
    fetchNews();
  }, []);

  const getSiteSettingFunc = async () => {
    try {
      const { status, result } = await getSiteSetting();
      if (status == "success") setSiteData(result && result.usrdashboard);
    } catch (err) {
      console.log("Err on GetAllOrderhistory", err);
    }
  };

  useEffect(() => {
    getSiteSettingFunc();
  }, []);

  const btcData = dynamicFind(priceConversion, "BTC");
  const ethData = dynamicFind(priceConversion, "ETH");

  return (
    <div>
      {/* <ScrollToTopOnMount /> */}
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-5"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <h1>
                <span>{t("EMPOWER_FINANCIAL_FUTURE")}</span>
              </h1>
              <h5>{t("HOME_DESCRIPTION")}</h5>
              {!isAuth ? (
                <Link className="primary_btn" to="/register">
                  {t("GET_STARTED")}
                  <span className="icon-right"></span>
                  <span className="icon-right after"></span>
                </Link>
              ) : (
                <Link className="primary_btn" to="/dashboard">
                  {t("DASHBOARD")}
                  <span className="icon-right"></span>
                  <span className="icon-right after"></span>
                </Link>
              )}
            </div>
            <div
              className="col-lg-7"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <div className="banner_img">
                <img
                  src={require("../assets/images/banner_img1.png")}
                  alt="Banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="section pt-0">
          <div className="container">
            <div className="col-lg-10 m-auto">
              <div
                className="participant_panel"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div
                  className="participant_panel_left"
                  data-aos="zoom-in"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                    >
                      <path
                        d="M25.4743 30.3001V30.7351C23.2543 32.1601 20.5993 33.0001 17.7493 33.0001C14.8543 33.0001 12.1543 32.1301 9.9043 30.6451V30.3001C9.9043 26.8051 13.3993 23.9551 17.6893 23.9551C21.9943 23.9551 25.4743 26.8051 25.4743 30.3001Z"
                        fill="#23B2F4"
                      />
                      <path
                        opacity="0.25"
                        d="M32 18.75C32 23.775 29.405 28.185 25.475 30.735V30.3C25.475 26.805 21.995 23.955 17.69 23.955C13.4 23.955 9.905 26.805 9.905 30.3V30.645C6.05 28.095 3.5 23.73 3.5 18.75C3.5 10.875 9.875 4.5 17.75 4.5C19.715 4.5 21.59 4.88999 23.3 5.60999C23.105 6.20999 23 6.84 23 7.5C23 8.625 23.315 9.69 23.87 10.59C24.17 11.1 24.56 11.565 25.01 11.955C26.06 12.915 27.455 13.5 29 13.5C29.66 13.5 30.29 13.395 30.875 13.185C31.595 14.895 32 16.785 32 18.75Z"
                        fill="#23B2F4"
                      />
                      <path
                        d="M33.455 3.49503C32.375 2.26503 30.77 1.5 29 1.5C27.32 1.5 25.79 2.19003 24.695 3.31503C24.065 3.96003 23.585 4.73999 23.3 5.60999C23.105 6.20999 23 6.84 23 7.5C23 8.625 23.315 9.69 23.87 10.59C24.17 11.1 24.56 11.565 25.01 11.955C26.06 12.915 27.455 13.5 29 13.5C29.66 13.5 30.29 13.395 30.875 13.185C32.255 12.75 33.41 11.805 34.13 10.59C34.445 10.08 34.685 9.49499 34.82 8.89499C34.94 8.44499 35 7.98 35 7.5C35 5.97 34.415 4.56003 33.455 3.49503ZM31.235 8.59497H30.125V9.76501C30.125 10.38 29.615 10.89 29 10.89C28.385 10.89 27.875 10.38 27.875 9.76501V8.59497H26.765C26.15 8.59497 25.64 8.08497 25.64 7.46997C25.64 6.85497 26.15 6.34497 26.765 6.34497H27.875V5.28003C27.875 4.66503 28.385 4.15503 29 4.15503C29.615 4.15503 30.125 4.66503 30.125 5.28003V6.34497H31.235C31.85 6.34497 32.36 6.85497 32.36 7.46997C32.36 8.08497 31.865 8.59497 31.235 8.59497Z"
                        fill="#23B2F4"
                      />
                      <path
                        d="M17.6896 22.0941C20.0175 22.0941 21.9046 20.2069 21.9046 17.8791C21.9046 15.5512 20.0175 13.6641 17.6896 13.6641C15.3617 13.6641 13.4746 15.5512 13.4746 17.8791C13.4746 20.2069 15.3617 22.0941 17.6896 22.0941Z"
                        fill="#23B2F4"
                      />
                    </svg>
                    <p>{t("ALL_PARTICIPANTS")}</p>
                  </div>
                  <h2>{SiteData && SiteData.participants}+</h2>
                </div>
                <div
                  className="participant_panel_right"
                  data-aos="zoom-in"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                    >
                      <path
                        opacity="0.25"
                        d="M26 11.625C25.85 11.625 25.715 11.595 25.565 11.535C25.295 11.415 25.07 11.205 24.95 10.92C24.89 10.785 24.86 10.635 24.86 10.485V4.485C24.86 4.47 24.875 4.455 24.875 4.425C22.94 3.525 20.78 3 18.5 3C10.22 3 3.5 9.72 3.5 18C3.5 26.28 10.22 33 18.5 33C26.78 33 33.5 26.28 33.5 18C33.5 15.72 32.975 13.56 32.06 11.61C32.045 11.61 32.03 11.625 32 11.625H26Z"
                        fill="#23B2F4"
                      />
                      <path
                        d="M27.0668 9.3176C27.1686 9.57895 27.3467 9.79673 27.5884 9.91288C27.7029 9.97096 27.8301 10 27.9573 10L33.0459 10C33.5675 10 34 9.50635 34 8.91107C34 8.31579 33.5675 7.82214 33.0459 7.82214L30.2599 7.82214L35 3.08893C35 1 33.5 2 33.5 2L28.9114 6.28312L28.9114 3.08893C28.9114 2.49365 28.4789 2 27.9573 2C27.4358 2 27.0032 2.49365 27.0032 3.08893L27.0032 8.89655C26.9905 9.04174 27.0159 9.17241 27.0668 9.3176Z"
                        fill="#23B2F4"
                      />
                      <path
                        d="M21.125 17.73L19.625 17.205V13.875H19.745C20.51 13.875 21.125 14.55 21.125 15.375C21.125 15.99 21.635 16.5 22.25 16.5C22.865 16.5 23.375 15.99 23.375 15.375C23.375 13.305 21.755 11.625 19.745 11.625H19.625V11.25C19.625 10.635 19.115 10.125 18.5 10.125C17.885 10.125 17.375 10.635 17.375 11.25V11.625H16.925C15.11 11.625 13.625 13.155 13.625 15.045C13.625 17.235 14.9 17.94 15.875 18.285L17.375 18.81V22.14H17.255C16.49 22.14 15.875 21.465 15.875 20.64C15.875 20.025 15.365 19.515 14.75 19.515C14.135 19.515 13.625 20.025 13.625 20.64C13.625 22.71 15.245 24.39 17.255 24.39H17.375V24.765C17.375 25.38 17.885 25.89 18.5 25.89C19.115 25.89 19.625 25.38 19.625 24.765V24.39H20.075C21.89 24.39 23.375 22.86 23.375 20.97C23.375 18.765 22.1 18.06 21.125 17.73ZM16.61 16.14C16.1 15.96 15.875 15.855 15.875 15.03C15.875 14.385 16.355 13.86 16.925 13.86H17.375V16.395L16.61 16.14ZM20.075 22.125H19.625V19.59L20.39 19.86C20.9 20.04 21.125 20.145 21.125 20.97C21.125 21.6 20.645 22.125 20.075 22.125Z"
                        fill="#23B2F4"
                      />
                    </svg>
                    <p>{t("ASSETS_UNDER_MANAGEMENT")}</p>
                  </div>
                  <h2>{SiteData && SiteData.totalearned}+</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div
              className="why_invest_sec inner_bg_sec"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col-md-12">
                  <h2
                    className="main_title text-center"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1000"
                  >
                    {t("WHY_INVEST_WITH_US")}
                  </h2>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-6"
                  data-aos="flip-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div className="why_invest_box">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M29.685 6.33047C25.245 1.90547 18.045 1.90547 13.635 6.33047C10.53 9.40547 9.59998 13.8305 10.8 17.7305L3.74998 24.7805C3.25498 25.2905 2.90998 26.2955 3.01498 27.0155L3.46498 30.2855C3.62998 31.3655 4.63498 32.3855 5.71498 32.5355L8.98497 32.9855C9.70498 33.0905 10.71 32.7605 11.22 32.2355L12.45 31.0055C12.75 30.7205 12.75 30.2405 12.45 29.9405L9.53998 27.0305C9.10498 26.5955 9.10498 25.8755 9.53998 25.4405C9.97498 25.0055 10.695 25.0055 11.13 25.4405L14.055 28.3655C14.34 28.6505 14.82 28.6505 15.105 28.3655L18.285 25.2005C22.17 26.4155 26.595 25.4705 29.685 22.3955C34.11 17.9705 34.11 10.7555 29.685 6.33047ZM21.75 18.0005C19.68 18.0005 18 16.3205 18 14.2505C18 12.1805 19.68 10.5005 21.75 10.5005C23.82 10.5005 25.5 12.1805 25.5 14.2505C25.5 16.3205 23.82 18.0005 21.75 18.0005Z"
                          fill="#23B2F4"
                        />
                        <path
                          d="M21.75 18C23.8211 18 25.5 16.3211 25.5 14.25C25.5 12.1789 23.8211 10.5 21.75 10.5C19.6789 10.5 18 12.1789 18 14.25C18 16.3211 19.6789 18 21.75 18Z"
                          fill="#23B2F4"
                        />
                      </svg>
                      <h3>{t("SECURE_AND_RELIABLE")}</h3>
                    </div>
                    <p className="para_tag">
                      {t("PLATFORM_SECURITY_BRIEF_EXPLANATION")}
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-6"
                  data-aos="flip-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div className="why_invest_box">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M28.5 13.5C28.5 15.675 27.855 17.67 26.745 19.335C25.125 21.735 22.56 23.43 19.575 23.865C19.065 23.955 18.54 24 18 24C17.46 24 16.935 23.955 16.425 23.865C13.44 23.43 10.875 21.735 9.255 19.335C8.145 17.67 7.5 15.675 7.5 13.5C7.5 7.695 12.195 3 18 3C23.805 3 28.5 7.695 28.5 13.5Z"
                          fill="#23B2F4"
                        />
                        <path
                          d="M31.8746 27.7046L29.3996 28.2896C28.8446 28.4246 28.4096 28.8446 28.2896 29.3996L27.7646 31.6046C27.4796 32.8046 25.9496 33.1646 25.1546 32.2196L17.9996 23.9996L10.8446 32.2346C10.0496 33.1796 8.51963 32.8196 8.23463 31.6196L7.70963 29.4146C7.57463 28.8596 7.13963 28.4246 6.59963 28.3046L4.12463 27.7196C2.98463 27.4496 2.57963 26.0246 3.40463 25.1996L9.25463 19.3496C10.8746 21.7496 13.4396 23.4446 16.4246 23.8796C16.9346 23.9696 17.4596 24.0146 17.9996 24.0146C18.5396 24.0146 19.0646 23.9696 19.5746 23.8796C22.5596 23.4446 25.1246 21.7496 26.7446 19.3496L32.5946 25.1996C33.4196 26.0096 33.0146 27.4346 31.8746 27.7046Z"
                          fill="#23B2F4"
                        />
                        <path
                          d="M18.8709 8.97L19.7559 10.74C19.8759 10.98 20.1909 11.22 20.4759 11.265L22.0809 11.535C23.1009 11.7 23.3409 12.45 22.6059 13.185L21.3609 14.43C21.1509 14.64 21.0309 15.045 21.1059 15.345L21.4659 16.89C21.7509 18.105 21.1059 18.585 20.0259 17.94L18.5259 17.055C18.2559 16.89 17.8059 16.89 17.5359 17.055L16.0359 17.94C14.9559 18.57 14.3109 18.105 14.5959 16.89L14.9559 15.345C15.0159 15.06 14.9109 14.64 14.7009 14.43L13.4559 13.185C12.7209 12.45 12.9609 11.715 13.9809 11.535L15.5859 11.265C15.8559 11.22 16.1709 10.98 16.2909 10.74L17.1759 8.97C17.6109 8.01 18.3909 8.01 18.8709 8.97Z"
                          fill="#23B2F4"
                        />
                      </svg>
                      <h3>{t("EARN_REWARDS_EFFORTLESSLY")}</h3>
                    </div>
                    <p className="para_tag">{t("INVESTMENT_DESCRIPTION")}</p>
                  </div>
                </div>
                <div
                  className="col-lg-6"
                  data-aos="flip-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div className="why_invest_box">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M28.8151 9.54026C27.2551 6.39026 24.2401 4.06526 20.7451 3.30026C17.0851 2.49026 13.3351 3.36026 10.4701 5.67026C7.59008 7.96526 5.95508 11.4003 5.95508 15.0753C5.95508 18.9603 8.28008 23.0253 11.7901 25.3803V26.6253C11.7751 27.0453 11.7601 27.6903 12.2701 28.2153C12.7951 28.7553 13.5751 28.8153 14.1901 28.8153H21.8851C22.6951 28.8153 23.3101 28.5903 23.7301 28.1703C24.3001 27.5853 24.2851 26.8353 24.2701 26.4303V25.3803C28.9201 22.2453 31.8451 15.6303 28.8151 9.54026Z"
                          fill="#23B2F4"
                        />
                        <path
                          d="M22.8907 32.9996C22.8007 32.9996 22.6957 32.9846 22.6057 32.9546C19.5907 32.0996 16.4257 32.0996 13.4107 32.9546C12.8557 33.1046 12.2707 32.7896 12.1207 32.2346C11.9557 31.6796 12.2857 31.0946 12.8407 30.9446C16.2307 29.9846 19.8007 29.9846 23.1907 30.9446C23.7457 31.1096 24.0757 31.6796 23.9107 32.2346C23.7607 32.6996 23.3407 32.9996 22.8907 32.9996Z"
                          fill="#23B2F4"
                        />
                        <path
                          d="M18.0005 20.7744C17.8055 20.7744 17.6105 20.7294 17.4455 20.6244C16.9055 20.3094 16.7255 19.6194 17.0255 19.0944L18.3005 16.8744H17.0405C16.2905 16.8744 15.6755 16.5444 15.3455 15.9894C15.0155 15.4194 15.0455 14.7294 15.4205 14.0694L17.0255 11.2794C17.3405 10.7394 18.0305 10.5594 18.5555 10.8594C19.0955 11.1744 19.2755 11.8644 18.9755 12.3894L17.7005 14.6244H18.9605C19.7105 14.6244 20.3255 14.9544 20.6555 15.5094C20.9855 16.0794 20.9555 16.7694 20.5805 17.4294L18.9755 20.2194C18.7655 20.5794 18.3905 20.7744 18.0005 20.7744Z"
                          fill="#23B2F4"
                        />
                      </svg>
                      <h3>{t("DECENTRALIZATION_MATTERS")}</h3>
                    </div>
                    <p className="para_tag">{t("JOIN_THE_MOVEMENT")}</p>
                  </div>
                </div>
                <div
                  className="col-lg-6"
                  data-aos="flip-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div className="why_invest_box">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M25.0395 4H9.97471C6.67439 4 4 6.67299 4 9.95735V18.7441V19.0142C4 22.3128 6.67439 24.9716 9.96048 24.9716H15.0817C15.8641 24.9716 16.5042 25.6114 16.5042 26.3934V27.5592C16.5042 28.3412 15.8641 28.981 15.0817 28.981H11.8809C11.3261 28.981 10.8709 29.436 10.8709 29.9905C10.8709 30.545 11.3261 31 11.8809 31H23.1617C23.7165 31 24.1718 30.545 24.1718 29.9905C24.1718 29.436 23.7165 28.981 23.1617 28.981H19.961C19.1786 28.981 18.5385 28.3412 18.5385 27.5592V26.3934C18.5385 25.6114 19.1786 24.9716 19.961 24.9716H25.0395C28.3398 24.9716 31 22.2986 31 19.0142V18.7441V9.95735C31 6.67299 28.3256 4 25.0395 4Z"
                          fill="#23B2F4"
                        />
                        <path
                          d="M28.6993 14H21.3007C18.8811 14 18 14.9079 18 17.4299V28.5701C18 31.1065 18.8671 32 21.3007 32H28.6993C31.1189 32 32 31.0921 32 28.5701V17.4299C32 14.8935 31.1329 14 28.6993 14ZM25.007 29.5933C24.1538 29.5933 23.4685 28.8871 23.4685 28.008C23.4685 27.1289 24.1538 26.4227 25.007 26.4227C25.8601 26.4227 26.5455 27.1289 26.5455 28.008C26.5455 28.8871 25.8601 29.5933 25.007 29.5933Z"
                          fill="#23B2F4"
                        />
                        <path
                          opacity="0.4"
                          d="M27 28.5C27 29.3318 26.1091 30 25 30C23.8909 30 23 29.3318 23 28.5C23 27.6682 23.8909 27 25 27C26.1091 27 27 27.6818 27 28.5Z"
                          fill="#23B2F4"
                        />
                      </svg>
                      <h3>{t("USER_FRIENDLY_INTERFACE")}</h3>
                    </div>
                    <p className="para_tag">{t("INTUITIVE_PLATFORM")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <h2 className="main_title">{t("HOW_IT_WORKS")}</h2>
                  <p className="para_tag mb-3">
                    {t("MANTIS_NETWORK_REGISTRATION")}
                  </p>
                  <p className="para_tag mb-3">
                    {t("SIMPLE_PATH_WITH_MANTIS_NETWORK")}
                  </p>
                  <div
                    className="accordion custom_accordian"
                    id="accordion_howworks"
                  >
                    <div className="accordion-item active-accordion">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t("CREATE_YOUR_ACCOUNT")}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion_howworks"
                      >
                        <div className="accordion-body">
                          <p className="para_tag">
                            {t("SIGN_UP_WITH_MANTIS_NETWORK")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {t("EXPLORE_INVESTMENT_OPTIONS")}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordion_howworks"
                      >
                        <div className="accordion-body">
                          <p className="para_tag">
                            {t("DISCOVER_INVESTMENT_OPPORTUNITIES")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {t("BEGIN_INVESTING")}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordion_howworks"
                      >
                        <div className="accordion-body">
                          <p className="para_tag">
                            {t("START_INVESTMENT_JOURNEY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          {t("WATCH_PORTFOLIO_GROW")}
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordion_howworks"
                      >
                        <div className="accordion-body">
                          <p className="para_tag">{t("TRACK_INVESTMENTS")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 mt-lg-0 mt-5 text-center"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <img
                    src={require("../assets/images/img_01.png")}
                    alt="Banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section potfolio_detail_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {t("ASSET_ALLOCATION")}
                </h2>
              </div>

              {investmentDoc &&
                investmentDoc.length > 0 &&
                investmentDoc.map((item, key) => {
                  return (
                    <>
                      <div
                        className="col-lg-4"
                        data-aos="fade-up"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1000"
                      >
                        <div className="potfolio_detail_panel">
                          <h2>{item.strategy}</h2>

                          {key == 0 && (
                            <ul>
                              <li>{t("FOCUS_ON_INCOME_GENERATION")}</li>
                              <li>
                                {t("BTC_MINING_ALLOCATOR_ALLOCATION_BALANCED")}
                              </li>
                              <li>{t("BTC_ALLOCATION_BALANCED")}</li>
                              <li>{t("ETH_ALLOCATION_BALANCED")}</li>
                              <li>
                                {t("MANTIS_STRATEGIES_ALLOCATION_BALANCED")}
                              </li>
                              <li>
                                {t("INVESTOR_PORTFOLIO_DESCRIPTION")}
                              </li>
                            </ul>
                          )}
                          {key == 1 && (
                            <ul>
                              <li>{t("INVESTOR_PORTFOLIO_BLURB")}</li>
                              <li>
                                {t("BTC_MINING_ALLOCATOR_ALLOCATION_GROWTH")}
                              </li>
                              <li>{t("BTC_ALLOCATION_GROWTH")}</li>
                              <li>{t("ETH_ALLOCATION_GROWTH")}</li>
                              <li>
                                {t("MANTIS_STRATEGIES_ALLOCATION_GROWTH")}
                              </li>
                              <li>
                                {t("INVESTOR_PORTFOLIO_DESCRIPTION")}
                              </li>
                            </ul>
                          )}
                          {key == 2 && (
                            <ul>
                              <li>{t("GROWTH_FOCUS_BLURB")}</li>
                              <li>
                                {t(
                                  "BTC_MINING_ALLOCATOR_ALLOCATION_HIGH_YIELD"
                                )}
                              </li>
                              <li>{t("BTC_ALLOCATION_HIGH_YIELD")}</li>
                              <li>{t("ETH_ALLOCATION_HIGH_YIELD")}</li>
                              <li>
                                {t("MANTIS_STRATEGIES_ALLOCATION_HIGH_YIELD")}
                              </li>
                              <li>
                                {t("INVESTOR_PORTFOLIO_DESCRIPTION")}
                              </li>
                            </ul>
                          )}

                          {isAuth ? (
                            <Link
                              to={`/investment-details/${encryptString(
                                item._id,
                                true
                              )}`}
                              className="secondary_btn"
                            >
                              {t("INVEST_TITLE")}
                            </Link>
                          ) : (
                            <Link to={`/register`} className="secondary_btn">
                              {t("INVEST_TITLE")}
                            </Link>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}

              {/* <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="potfolio_detail_panel">
                  <h2>Balanced</h2>
                  <ul>
                    <li>
                      Focus on Income Generation by accruing BTC (Bitcoin)
                      rewards daily in your digital wallet and ETH (Ethereum)
                      staking rewards
                    </li>
                    <li>
                      40% allocation to a BTC mining allocator (this invests
                      into a number of different mining farms based on where we
                      can optimise your returns)
                    </li>
                    <li>30% allocation to BTC</li>
                    <li>20% allocation to ETH (Ethereum)</li>
                    <li>10% allocation to Mantis Strategies seeking Alpha</li>
                    <li>
                      This portfolio is suitable for investors who want to focus
                      on building income and compounding their returns with a
                      tilt towards BTC price exposure
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="potfolio_detail_panel">
                  <h2>High Yield</h2>
                  <ul>
                    <li>Blended focus on Income Generation and Growth</li>
                    <li>
                      30% allocation to a BTC mining allocator (this invests
                      into a number of different mining farms based on where we
                      can optimise your returns)
                    </li>
                    <li>20% allocation to BTC</li>
                    <li>20% allocation to ETH (Ethereum)</li>
                    <li>30% allocation to Mantis Strategies seeking Alpha</li>
                    <li>
                      This portfolio is suitable for investors who want to focus
                      on building income and compounding their returns with a
                      tilt towards BTC price exposure whilst also seeking very
                      high returns from the Decentralised Finance and
                      Alternative coin market
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="potfolio_detail_panel">
                  <h2>Growth</h2>
                  <ul>
                    <li>
                      Focus on Generating Large Growth with a larger allocation
                      to our Alpha seeking Mantis Strategies
                    </li>
                    <li>
                      25% allocation to a BTC mining allocator (this invests
                      into a number of different mining farms based on where we
                      can optimise your returns)
                    </li>
                    <li>10% allocation to BTC</li>
                    <li>15% allocation to ETH (Ethereum)</li>
                    <li>50% allocation to Mantis Strategies seeking Alpha</li>
                    <li>
                      This portfolio is suitable for investors seeking very high
                      returns from the Decentralised Finance and Alternative
                      coin market
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="section pt-0">
          <div className="container">
            <div
              className="assets_allocation_section"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="current_price_div">
                    <div>
                      <label>BTC/USD:</label>
                      <label>$ {btcData?.convertPrice}</label>
                    </div>
                    <div>
                      <label>ETH/USD:</label>
                      <label>$ {ethData?.convertPrice}</label>
                    </div>
                  </div>
                  <ul
                    class="nav nav-pills nav-fill primary_tab"
                    id="pills-tab"
                    role="tablist"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1000"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-balanced-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-balanced"
                        type="button"
                        role="tab"
                        aria-controls="pills-balanced"
                        aria-selected="true"
                      >
                        {t("BALANCED_TITLE")}
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-highyield-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-highyield"
                        type="button"
                        role="tab"
                        aria-controls="pills-highyield"
                        aria-selected="false"
                      >
                        {t("HIGH_YIELD_TITLE")}
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-growth-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-growth"
                        type="button"
                        role="tab"
                        aria-controls="pills-growth"
                        aria-selected="false"
                      >
                        {t("GROWTH_TITLE")}
                      </button>
                    </li>
                  </ul>
                  <div
                    class="tab-content"
                    id="pills-tabContent"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1000"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="pills-balanced"
                      role="tabpanel"
                      aria-labelledby="pills-balanced-tab"
                      tabindex="0"
                    >
                      <PortfolioAChart />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-highyield"
                      role="tabpanel"
                      aria-labelledby="pills-highyield-tab"
                      tabindex="0"
                    >
                      <PortfolioBChart />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-growth"
                      role="tabpanel"
                      aria-labelledby="pills-growth-tab"
                      tabindex="0"
                    >
                      <PortfolioCChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Calculator investmentDoc={investmentDoc} />

        {/* <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  Supported Cryptocurrencies
                </h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <SupportedCrypto />
              </div>
            </div>
          </div>
        </section> */}

        <News data={NewsData} />

        <FAQ data={FaqData} />
        <Footer />
      </div>
    </div>
  );
}
