import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// import api
import { emailVerify, forceLogoutByAdminURL, loginWithurl, logout } from "../api/users";
import { coinRequestVerify } from "../api/walletAction";

// import lib
import { toastAlert } from "../lib/toastAlert";
import { useDispatch, useSelector } from "react-redux";

const EmailVerification = () => {
  const params = useParams();
  const authToken = params.id
  const [one,setOne] = useState(false)
  // const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
	const dispatch = useDispatch();

  const emailActivation = async () => {
    try {
      const { status, message } = await emailVerify({ userId: authToken });
      toastAlert(status === "success" ? status : "error", message, "emailVerify");
      navigate("/login");
    } catch (err) { }
  };

  const acceptCoinRequest = async () => {
    try {
      const { status, message } = await coinRequestVerify({ authToken });
      toastAlert(status === "success" ? status : "error", message, "coinRequestVerify");
      navigate("/");
    } catch (err) { }
  };
	const getloginToken = async () => {
    try {
      let token = localStorage.getItem("user_token");
      // if (token) forceLogout(navigate, dispatch);
      if (token) {
       await forceLogoutByAdminURL(navigate, dispatch);
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));
      const { status, message } = await loginWithurl(
        { userId: params.authToken },
        dispatch
      );
      toastAlert(
        status === "success" ? status : "error",
        message,
        "emailVerify"
      );
      navigate("/dashboard");
    } catch (err) {
      console.log("getloginToken_err", err);
    }
  };
  useEffect(() => {
    let pathname = location.pathname;
    if (pathname == "/email-verification/" + authToken) {
      emailActivation();
    } else if (pathname == "/withdraw-verification/" + authToken) {
      acceptCoinRequest();
    } else if (pathname == "/url-login/" + params.authToken && !one) {
      let bear = `Bearer ${authToken}`;
      // setAuthorization(bear)
      // setAuthToken(bear)
      // navigate('/')
      setOne(true)
      console.log("oneTIME", one);
      !one && getloginToken();
      // oneTime = true;
    }
  }, []);

  return <h3>Loading...</h3>;
};

export default EmailVerification;
