import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const OrderModal = (props) => {
  const { t } = useTranslation();
  //props
  const {
    visible,
    record,
    onHide /* , fetchData */,
    formValue,
    submitHandler,
    handleChange,
    loader,
    error,
  } = props;
  console.log("RECORD:", record);
  return (
    <>
      <Modal show={visible} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("STRATEGY")} - {record.strategy}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("TYPE")}</Form.Label>
              {record?.type && record?.type.length > 0 && (
                <Form.Select onChange={handleChange} name="type" value={formValue.type} >
                  <option disabled value={""}>
                    Select the type
                  </option>
                  {record.type.map((item) => (
                    <option value={item}>
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          {formValue.type !== "flexible" &&  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("PERIOD")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={record.period}
                value={`${record.period} Days`}
                autoFocus
                disabled
              />
            </Form.Group>}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("MANAGEMENT_FEE")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={`${record.manageFee} %`}
                value={`${record.manageFee} %`}
                autoFocus
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("MINIMUM_INVESTMENT")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={record.coin}
                value={`${record.minimumAmount} ${record.coin}`}
                autoFocus
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("AMOUNT")}</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter a invest amount"
                value={formValue.amount}
                name="amount"
                autoFocus
                onChange={handleChange}
              />
              <span className="text-danger">{error && error?.amount}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-flex-button">
            <button
              variant="secondary"
              className="secondary_btn"
              onClick={onHide}
            >
              {t("CLOSE")}
            </button>
            <button
              variant="primary"
              className="secondary_btn"
              onClick={submitHandler}
              disabled={loader}
            >
              {t("INVEST")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderModal;
