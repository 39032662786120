import React, { /* useEffect, */ useState } from "react";
// import { Link } from "react-router-dom";
// import { useSelector } from 'react-redux';

import ClaimModal from "../../components/Investment/ClaimModal";
import DataTable from "react-data-table-component";
// import lib
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { daysToMonths } from "../../lib/calculation";
import { LockedOrderClaim } from "../../api/invest";
import { toastAlert } from "../../lib/toastAlert";
import { encryptObject } from "../../lib/cryptoJS";
import { IncCntObjId } from "../../lib/generalFun";
import { momentFormat } from "../../lib/dateTimeHelper";
import { useTranslation } from "react-i18next";

const intialFormValue = { amount: 0 };
export default function InvestmentDetail(props) {
  const { statkeData, CurDate, refetch, priceConversion } = props;
  const { t, i18n } = useTranslation();
  //Modal
  const [visible, setVisible] = useState(false);
  const [StakingData, setStakingData] = useState({});
  const [loader, setLoader] = useState(false);
  const [errs, setErrors] = useState({});
  const [formValue, setFormValue] = useState(intialFormValue);
  const { amount } = formValue;

  const SetRecord = (item) => {
    setVisible(true);
    setStakingData(item);
  };

  const handlecloseAssetModal = () => {
    setFormValue(intialFormValue);
    setStakingData({});
    setVisible(false);
  };
  const profitCalculation = (strategyInvestCoins) => {
    let profit = 0;
    let totalProfitPercent = 0;
    let totalExpenditure = 0;
    let totalCurrentMarketPrice = 0;
    let totalInvestedPrice = 0;
    for (const item of strategyInvestCoins || []) {
      const marketPrice =
        priceConversion.find((i) => i.baseSymbol === item.coin)?.convertPrice ||
        0;
      const difference =
        marketPrice * item.quantity - item?.price * item.quantity;
      const qtyPrice = item?.price * item?.quantity;
      totalInvestedPrice += qtyPrice;
      if (difference < 0) {
        totalExpenditure -= item.quantity * marketPrice;
      } else {
        totalExpenditure += item.quantity * marketPrice;
      }
      totalCurrentMarketPrice = totalExpenditure;
      profit += difference || 0;
    }
    return (
      ((totalCurrentMarketPrice - totalInvestedPrice) / totalInvestedPrice) *
      100
    );
  };
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      if (Number(amount) === 0) {
        return setErrors({ amount: t("AMOUNT_ZERO") });
      } else if (amount < 0) {
        return setErrors({ amount: t("AMOUNT_NEGATIVE") });
      } else if (StakingData.stakedAmount < amount) {
        //  setErrors({ amount: `Amount must be less than or equal to ${StakingData.stakedAmount}` })
        return setErrors({
          amount: t("AMOUNT_EXCEEDS_STAKED").replace(
            "{{stakedAmount}}",
            StakingData.stakedAmount
          ),
        });
      }
      // setLoader(true)
      const reqData = {
        stakeId: StakingData._id,
        amount: amount,
      };

      let encryptObj = encryptObject(reqData);
      const { status, message } = await LockedOrderClaim({ token: encryptObj });
      const msg = t(message);
      if (status == "success") {
        setLoader(false);
        handlecloseAssetModal();
        setFormValue(intialFormValue);
        refetch();
        toastAlert("success", msg, "signin", "TOP_CENTER");
      } else {
        setLoader(false);
        toastAlert("error", msg, "signin", "TOP_CENTER");
      }
    } catch (error) {
      console.log(error, "-------------error");
    }
  };

  //function
  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (value) {
      setErrors({});
    }
  };

  function countDaysBetweenDates(startDate, endDate, duration) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end - start;

    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference >= 0 && duration > 0) {
      const count = duration - daysDifference;
      return count;
    }
    return duration;
  }
  // Investment History Table Data
  const columns = [
    {
      name: "Invest amount",
      selector: (row) => row.amount,
    },
    {
      name: "Interest",
      selector: (row) => row.settleAmount,
    },

    {
      name: "No.Of Days",
      selector: (row) => `${row.days > 0 ? row.days : "-"}`,
    },
    {
      name: "Currency",
      selector: (row) => row.coin,
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Arial, sans-serif",
        fontSize: "15px", // Change font size
        fontWeight: "600", // Change font size
      },
    },
    cells: {
      style: {
        fontFamily: "Arial, sans-serif",
        fontSize: "15px", // Change font size
      },
    },
    pagination: {
      style: {
        fontFamily: "Arial, sans-serif",
        fontSize: "15px", // Change font size
      },
    },
  };

  console.log("STAKE_DATA", statkeData);

  return (
    <div>
      <ClaimModal
        visible={visible}
        record={StakingData}
        onHide={handlecloseAssetModal}
        handleChange={handleChange}
        formValue={formValue}
        submitHandler={submitHandler}
        loader={loader}
        errors={errs}
      />
      <div className="row dash_box_row">
        <div className="col-lg-12">
          <div className="chart_box">
            <div className="inner_subtitle">
              <h2>{t("INVEST_INVESTMENT_DETAILS")}</h2>
            </div>
            <div className="invest_details_row">
              {statkeData &&
                statkeData.length > 0 &&
                statkeData.map((item, key) => {
                  let btnHide =
                    item &&
                    item.duration == 0 &&
                    item.claimStatus == "active" &&
                    item.redemStatus == "completed" &&
                    item.stakedAmount > 0
                      ? true
                      : false;
                  const strategyCoins = item.strategyInvestCoins;
                  const profitPercent = profitCalculation(strategyCoins);
                  return (
                    <div className="invest_details_single">
                      <div className="invest_details_top">
                        <div className="invest_details_icon">
                          {item.strategy === "Balanced"
                            ? t("BALANCED_TITLE")
                            : item.strategy === "High Yield"
                            ? t("HIGH_YIELD_TITLE")
                            : item.strategy === "Custom Solution"
                            ? t("CUSTOM_SOLUTION")
                            : t("GROWTH_TITLE")}
                        </div>
                        <div class="d-flex flex-column">
                          <h2>
                            {t("INVESTMENT_DETAILS_ID_LABEL")}{" "}
                            {IncCntObjId(item?._id)}
                          </h2>
                          <h2>
                            {item?.stakedAmount} {item?.coin}
                          </h2>
                        </div>
                      </div>
                      <div className="invest_details_info">
                        <div>
                          <label>
                            {" "}
                            {/* {t(
                              "INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL"
                            )} */}
                            {t("PROFIT")}{" "}
                            <label>
                              {isNaN(profitPercent)
                                ? 0
                                : profitPercent.toFixed(2)}{" "}
                              %
                            </label>
                          </label>
                        </div>
                      </div>
                      <div className="invest_details_info">
                        {item?.type !== "flexible" && (
                          <div>
                            <label>
                              {t(
                                "INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL"
                              )}
                            </label>
                            <h5>
                              {item?.duration &&
                                toFixed(daysToMonths(item.duration), 0)}{" "}
                              Months <label>({item?.duration} Days)</label>
                            </h5>
                          </div>
                        )}

                        <div>
                          <label>
                            {t("INVESTMENT_DETAILS_REWARDS_EARNED_LABEL")}
                          </label>
                          <h5>
                            {item?.earnAmount
                              ? toFixedDown(item?.earnAmount, 2)
                              : 0}{" "}
                            {item?.coin}
                          </h5>
                        </div>
                      </div>
                      <div className="invest_details_info">
                        {item?.type !== "flexible" && (
                          <div>
                            <label>
                              {t("INVESTMENT_DETAILS_REMAINING_DAYS_LABEL")}
                            </label>
                            <h5>
                              {countDaysBetweenDates(
                                item?.settleStartDate,
                                new Date(),
                                item?.duration
                              )}{" "}
                              Days
                            </h5>
                          </div>
                        )}
                        <div>
                          <label>
                            {t("INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL")}
                          </label>
                          <h5>
                            {item?.capitalGrowth
                              ? toFixedDown(item?.capitalGrowth, 2)
                              : 0}{" "}
                            {item?.coin}
                          </h5>
                        </div>
                        <div>
                          {item.redemStatus != "completed" &&
                            item.claimStatus == "active" &&
                            !btnHide && (
                              <button className="secondary_btn disabled">
                                {t("LOCKED")}
                              </button>
                            )}
                          {btnHide && (
                            <button
                              className="secondary_btn"
                              onClick={() => SetRecord(item)}
                            >
                              {t("UNLOCKED")}
                            </button>
                          )}
                          {item.claimStatus == "pending" && !btnHide && (
                            <button className="secondary_btn disabled">
                              {t("PROCESS")}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="invest_details_info">
                        <label>
                          {t("INVESTMENT_DETAILS_INVESTED_AT_LABEL")}
                        </label>
                        <h5>{momentFormat(item?.createdAt, "DD-MM-YYYY")} </h5>
                      </div>
                    </div>
                  );
                })}
            </div>
            {statkeData && statkeData.length == 0 && (
              <div className="table-responsive primary_table_div">
                <DataTable
                  className="table primary_table"
                  columns={columns}
                  data={[]}
                  customStyles={customStyles}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
