import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { useTranslation } from "react-i18next";

import ReferralData from "../components/Referral/ReferralData.js"

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Referral() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="inner_head_title">{t("REFERRAL_HEAD_TITLE")}</h2>
            </div>
          </div>

          <ReferralData />
        </div>
      </div>
      <Footer />
    </div>
  );
}
