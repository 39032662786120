// import config
import axios, { handleResp } from "../config/axios";

export const getUserDashboard = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/user/getuserdata`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getnxHistory = async (params) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/user/transaction-history`,
      params,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getPlanSettings = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/user/planSetting`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const getUserPlanSettings = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/user/user-plan-setting`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const getUserProfitDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/user/profit-details`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};
