import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { getEarningHistory, getallorderhistory } from '../../api/invest';
import { toFixedDown } from '../../lib/roundOf';
import { IncCntObjId } from '../../lib/generalFun';
import { useSelector } from 'react-redux';
import { toStaringCase } from '../../lib/stringCase';

// Investment History Table Data
const columns = [
    {
        name: "Investment Id",
        selector: (row) => row._id,
        cell: (record) => `ID ${IncCntObjId(record._id)}`
    },
    {
        name: "Invest amount",
        selector: (row) => row.amount,
        cell: (record) => `${toFixedDown(record.amount, 4)} ${record.coin}`
    },
    {
        name: "Interest",
        selector: (row) => row.settleAmount,
        cell: (record) => `${toFixedDown(record.settleAmount, 2)} ${record.coin}`
    },

    {
        name: "No.Of Days",
        selector: (row) => row.days,
        cell: (record) => `${record.days > 0 ? record.days : '-'}`
    },
    {
        name: "Category",
        selector: (row) => `${row?.category ? toStaringCase(row.category) : '-'}`,
    },
    {
        name: "Created At",
        selector: (row) => row.createdAt,
        cell: (record) => `${dateTimeFormat(record.createdAt)}`,
        width: "20%"
    }
];

const customStyles = {
    headCells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
            fontWeight: '600', // Change font size
        },
    },
    cells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
    pagination: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
};

const EarningHistory = () => {
    const { stackingData } = useSelector((state) => state);
    // state
    const [data, setData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [count, setCount] = useState(0);
    const [pageDoc, setPageDoc] = useState({ 'page': 1, 'limit': 10 })
    const [filter, setFilter] = useState({ 'category': '', 'type': '', 'strategy': '', 'order': 'descend', 'investId': '' })

    const { page, limit } = pageDoc;
    const { category, type, strategy, order, investId } = filter;

    const getUsrHistory = async () => {
        try {
            let data = { page: page, limit: limit, category: category, type: type, strategy: strategy, order: order, investId: investId }
            const { status, result } = await getEarningHistory(data);
            if (status === 'success') {
                setData(result.data)
                setCount(result.count)
            }
        } catch (err) { }
    }

    const GetAllOrderHistory = async () => {
        try {
            const { status, result } = await getallorderhistory();
            if (status === 'success') setOrders(result)

        } catch (err) { }
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let filterData = { ...filter, [name]: value }
        setFilter(filterData)
    }

    const handleSearch = (e) => {
        e.preventDefault();
        getUsrHistory()
    }

    useEffect(() => {
        getUsrHistory()
        GetAllOrderHistory()
    }, [page, limit]);
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="chart_box">
                        <div className="inner_subtitle">
                            <h2>All Earning History</h2>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-start m-3">
                                    <div className='p-1'>
                                        <label className='text-light'>Strategy</label>
                                        <select className="form-select chart_option_btn" name="strategy" onClick={handleChange}>
                                            <option value="">All</option>
                                            {stackingData && stackingData.length > 0 && stackingData.map((el) => {
                                                return (<option value={el._id}>{el.strategy}</option>)
                                            })}
                                        </select>
                                    </div>

                                    <div className='p-1'>
                                        <label className='text-light'>Invest Id</label>
                                        <select className="form-select chart_option_btn" name="investId" onClick={handleChange}>
                                            <option value="">All</option>
                                            {orders && orders.length > 0 && orders.map((el) => {
                                                return (<option value={el._id}>{`ID ${IncCntObjId(el._id)}`}</option>)
                                            })}
                                        </select>
                                    </div>

                                    <div className='p-1'>
                                        <label className='text-light'>Earning Category</label>
                                        <select className="form-select chart_option_btn" name="category" onClick={handleChange}>
                                            <option value="">All</option>
                                            <option value='interest'>ROI</option>
                                            <option value='capital_growth'>Capital Growth</option>
                                        </select>
                                    </div>

                                    <div className='p-1'>
                                        <label className='text-light'>Settle Type</label>
                                        <select className="form-select chart_option_btn" name="type" onClick={handleChange}>
                                            <option value="">All</option>
                                            <option value='interest'>Interest</option>
                                            <option value='redemption'>Redemption</option>
                                        </select>
                                    </div>
                                    <div className='p-1'>
                                        <label className='text-light'>Sort</label>
                                        <select className="form-select chart_option_btn" name="order" onClick={handleChange}>
                                            <option value='descend'>Descend</option>
                                            <option value='ascend'>Ascend</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='py-3'>
                                    <button onClick={handleSearch} className="secondary_btn ">
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive primary_table_div">
                            <DataTable
                                className="table primary_table"
                                columns={columns}
                                data={data}
                                pagination
                                paginationServer
                                paginationTotalRows={count}
                                onChangePage={(page) => setPageDoc({ ...pageDoc, ... { 'page': page } })}
                                onChangeRowsPerPage={(limit) => setPageDoc({ ...pageDoc, ... { 'limit': limit } })}
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EarningHistory;