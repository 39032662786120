export default {
  PRIVACY_SECTION_PRIVACY: "Vie privée",
  LOGIN_LINK_LABEL: "Connexion",
  REGISTER_LINK_LABEL: "S'inscrire",
  CLICK_ME_LABEL: "Cliquez sur moi !",
  LOGIN_EMAIL_PLACEHOLDER: "Entrez votre adresse e-mail",
  EMPOWER_FINANCIAL_FUTURE: "Donnez du pouvoir à votre avenir financier.",
  HOME_DESCRIPTION:
    "Plongez dans le monde de la cryptomonnaie avec des conseils d'experts conçus pour accélérer votre parcours d'investissement. Commencez à investir dans la crypto dès aujourd'hui et voyez votre portefeuille s'agrandir. Mantis Network offre des conseils pour naviguer dans les aspects essentiels de devenir un investisseur crypto réussi.",
  GET_STARTED: "Commencer",
  DASHBOARD: "Tableau de bord",
  ALL_PARTICIPANTS: "Tous les participants",
  ASSETS_UNDER_MANAGEMENT: "Actifs sous gestion",
  WHY_INVEST_WITH_US: "Pourquoi investir avec nous",
  SECURE_AND_RELIABLE: "Sécurité et fiabilité",
  PLATFORM_SECURITY_BRIEF_EXPLANATION:
    "Notre plateforme accorde la priorité à la sécurité. Vos actifs sont protégés par une technologie de pointe et des protocoles de sécurité de premier plan dans l'industrie.",
  EARN_REWARDS_EFFORTLESSLY: "Gagnez des récompenses sans effort",
  INVESTMENT_DESCRIPTION:
    "Investir avec nous signifie que vous gagnez des récompenses sans effort sous forme de cryptomonnaies supplémentaires. Aucune stratégie de trading complexe ou connaissance technique n'est requise.",
  DECENTRALIZATION_MATTERS: "La décentralisation compte",
  JOIN_THE_MOVEMENT:
    "Rejoignez le mouvement vers un avenir décentralisé. En investissant votre crypto, vous contribuez activement à la force et à la stabilité du réseau blockchain.",
  USER_FRIENDLY_INTERFACE: "Interface conviviale",
  INTUITIVE_PLATFORM:
    "Notre plateforme intuitive rend l'investissement simple, que vous soyez débutant ou investisseur expérimenté. Pas de frais cachés ni de processus compliqués - juste une expérience fluide.",
  HOW_IT_WORKS: "Comment ça marche",
  MANTIS_NETWORK_REGISTRATION:
    "Avec Mantis Network, il vous suffit de vous inscrire et de créer un compte sans aucune connaissance de la blockchain ou de la cryptomonnaie.",
  SIMPLE_PATH_WITH_MANTIS_NETWORK: "Votre parcours simple avec Mantis Network",
  CREATE_YOUR_ACCOUNT: "Créez votre compte",
  SIGN_UP_WITH_MANTIS_NETWORK:
    "Inscrivez-vous facilement avec Mantis Network, aucune expérience préalable avec les portefeuilles blockchain ou crypto n'est nécessaire. Notre plateforme conviviale est conçue pour tout le monde.",
  EXPLORE_INVESTMENT_OPTIONS: "Explorez les options d'investissement",
  DISCOVER_INVESTMENT_OPPORTUNITIES:
    "Découvrez une variété d'opportunités d'investissement, y compris le minage de Bitcoin et le jalonnement d'Ethereum, des stratégies Mantis adaptées à vos objectifs financiers.",
  BEGIN_INVESTING: "Commencez à investir",
  START_INVESTMENT_JOURNEY:
    "Avec notre guidance étape par étape, commencez votre parcours d'investissement dans des actifs lucratifs tels que Bitcoin, Ethereum, ALT coins et divers projets au stade initial.",
  WATCH_PORTFOLIO_GROW: "Regardez votre portefeuille croître",
  TRACK_INVESTMENTS:
    "Suivez vos investissements facilement sur Mantis Network et regardez votre portefeuille croître à mesure que vous plongez plus profondément dans le monde des investissements crypto.",
  ASSET_ALLOCATION: "Allocation d'actifs",
  FOCUS_ON_INCOME_GENERATION:
    "Concentrez-vous sur la génération de revenus en accumulant des récompenses BTC (Bitcoin) quotidiennes dans votre portefeuille numérique et des récompenses de jalonnement ETH (Ethereum).",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "Allocation de 40% à un allocateur minier BTC",
  BTC_ALLOCATION: "Allocation de 30% à BTC",
  ETH_ALLOCATION: "Allocation de 20% à ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "Allocation de 10% aux stratégies Mantis cherchant l'Alpha",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Ce portefeuille convient aux investisseurs qui souhaitent se concentrer sur la génération de revenus et la capitalisation de leurs rendements avec une inclinaison vers l'exposition au prix du BTC.",
  INVESTOR_PORTFOLIO_BLURB:
    "Concentration équilibrée sur la génération de revenus et la croissance",
  GROWTH_FOCUS_BLURB:
    "Concentrez-vous sur la génération de croissance importante avec une allocation plus importante à nos stratégies Mantis cherchant l'Alpha",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "Allocation de 30% à un allocateur minier BTC",
  BTC_ALLOCATION: "Allocation de 20% à BTC",
  ETH_ALLOCATION: "Allocation de 20% à ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "Allocation de 30% aux stratégies Mantis cherchant l'Alpha",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Ce portefeuille convient aux investisseurs qui souhaitent se concentrer sur la génération de revenus et la capitalisation de leurs rendements avec une inclinaison vers l'exposition au prix du BTC tout en recherchant également des rendements très élevés sur le marché de la finance décentralisée et des altcoins.",
  INVEST_TITLE: "Investir",
  BALANCED_TITLE: "Équilibré",
  HIGH_YIELD_TITLE: "Rendement élevé",
  GROWTH_TITLE: "Croissance",

  ROI_CALCULATOR_MAIN_TITLE: "Calculateur de ROI",
  ROI_CALCULATOR_INVESTMENTS_LABEL: "Investissements",
  ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL: "Montant de l'Investissement",
  ROI_CALCULATOR_APY_LABEL: "APY En Pourcentage (%)",
  ROI_CALCULATOR_MONTHS_LABEL: "Nombre de Mois",
  ROI_CALCULATOR_INVEST_BUTTON: "Investir",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_1: "1 Mois",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_3: "3 Mois",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_6: "6 Mois",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_9: "9 Mois",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_12: "12 Mois",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_24: "24 Mois",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_48: "48 Mois",
  ROI_CALCULATOR_INTEREST_LABEL: "Intérêt Total:",
  ROI_CALCULATOR_FUTURE_VALUE_LABEL: "Valeur Future:",
  NEWS_COMPONENT_MAIN_TITLE: "Nos actualités et mises à jour",
  NEWS_COMPONENT_VIEW_MORE_BUTTON: "Voir Plus",
  FAQ_COMPONENT_MAIN_TITLE: "Questions Fréquemment Posées",
  FAQ_COMPONENT_VIEW_MORE_BUTTON: "Voir Plus",
  FOOTER_INNOVATIVE_PLATFORM:
    "Mantis Network est une plateforme innovante qui permet aux investisseurs traditionnels de s'exposer facilement aux investissements en cryptomonnaie.",
  FOOTER_FOLLOW_US: "Suivez-nous",
  FOOTER_COPYRIGHT: "Copyright ©",
  FOOTER_ALL_RIGHTS_RESERVED: "Tous droits réservés.",
  FOOTER_ADDRESS: "Adresse",
  FOOTER_CONTACT_US: "Contactez-nous",
  FOOTER_TERMS_OF_SERVICE: "Conditions d'utilisation",
  FOOTER_PRIVACY_POLICY: "Politique de confidentialité",
  INVEST_TITLE: "Investir",
  INVEST_TABLE_HEADER_ASSETS: "Actifs",
  INVEST_TABLE_HEADER_WEIGHTS: "Poids",
  INVEST_ALLOCATION_BTC: "BTC",
  INVEST_ALLOCATION_ETH: "ETH",
  INVEST_ALLOCATION_MINING_POOL: "Pool de Minage BTC",
  INVEST_ALLOCATION_MANTIS_STRATEGIES: "Stratégies Mantis",
  INVEST_ALLOCATION_TOTAL: "Allocation Totale",
  INVEST_MINIMUM_INVESTMENT: "Investissement Minimum",
  INVEST_PROJECTED_ROI: "ROI Projeté (%)",
  INVEST_DETAILS: "Détails",
  INVEST_INVEST_BUTTON: "Investir",
  INVEST_INVESTMENT_DETAILS: "Détails de l'Investissement",
  INVEST_MONTH: "Mois",
  INVEST_INTEREST_RATE: "Taux d'Intérêt",
  INVEST_MONTHS: "Mois",
  INVEST_AMOUNT: "Montant",
  INVEST_INVEST_MODAL_TITLE: "Ordre d'Investissement",
  INVEST_INVEST_MODAL_DESCRIPTION:
    "Veuillez vérifier les détails de votre investissement et procéder à la passation de la commande.",
  INVEST_INVEST_MODAL_AMOUNT_LABEL: "Montant",
  INVEST_INVEST_MODAL_PLACE_ORDER: "Passer la Commande",
  INVEST_INVEST_MODAL_CLOSE: "Fermer",
  INVEST_RESPONSE_MODAL_TITLE_SUCCESS: "Succès",
  INVEST_RESPONSE_MODAL_TITLE_ERROR: "Erreur",
  INVEST_RESPONSE_MODAL_CLOSE: "Fermer",
  INVEST_DASHBOARD_TOTAL_LOCKED_VALUE: "Valeur Totale Verrouillée",
  INVEST_DASHBOARD_CAPITAL_GROWTH: "Croissance du Capital",
  INVEST_DASHBOARD_YOUR_EARNINGS: "Vos Gains",
  INVEST_DASHBOARD_AVAILABLE_BALANCE: "Solde Disponible",
  INVEST_DASHBOARD_WALLET_LINK: "Portefeuille",
  INVEST_DASHBOARD_INVEST_LINK: "Investir",

  INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE: "Détails de l'Investissement",
  INVESTMENT_DETAILS_STRATEGY_LABEL: "Stratégie",
  INVESTMENT_DETAILS_DURATION_LABEL: "Durée",
  INVESTMENT_DETAILS_ALL_OPTION: "Tout",
  INVESTMENT_DETAILS_LOW_OPTION: "Faible",
  INVESTMENT_DETAILS_HIGH_OPTION: "Élevé",
  INVESTMENT_DETAILS_ID_LABEL: "ID",
  INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL:
    "Période Totale d'Investissement",
  INVESTMENT_DETAILS_REWARDS_EARNED_LABEL: "Récompenses Gagnées",
  INVESTMENT_DETAILS_REMAINING_DAYS_LABEL: "Jours Restants",
  INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL: "Croissance du Capital",
  INVESTMENT_DETAILS_VIEW_BUTTON_LABEL: "Voir",
  INVESTMENT_DETAILS_INVESTED_AT_LABEL: "Investi à",

  RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE: "Activité Récente",
  RECENT_ACTIVITY_SEE_MORE_LINK: "Voir plus",
  RECENT_ACTIVITY_TRANSFER_TYPE_LABEL: "Transfert via USD",
  RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL: "Dépôt via USD",
  RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL: "Retrait via USD",
  RECENT_ACTIVITY_DATE_LABEL: "Date",
  RECENT_ACTIVITY_STATUS_LABEL: "Statut",
  RECENT_ACTIVITY_REJECTED_STATUS: "Rejeté",
  RECENT_ACTIVITY_GREEN_TEXT: "texte_vert",
  RECENT_ACTIVITY_RED_TEXT: "texte_rouge",

  FORGOT_PASSWORD_TITLE: "Mot de Passe Oublié",
  FORGOT_PASSWORD_RESET_INFO:
    "Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail enregistrée.",
  FORGOT_PASSWORD_EMAIL_LABEL: "Adresse E-mail",
  FORGOT_PASSWORD_SUBMIT_BUTTON: "Soumettre",
  FORGOT_PASSWORD_GO_TO_LOGIN: "Aller à la Connexion",

  LOGIN_WELCOME_TITLE: "Bienvenue sur",
  LOGIN_MANTIS_NETWORK: "Mantis Network",
  LOGIN_SIGN_IN: "Connectez-vous pour Continuer !",
  LOGIN_EMAIL_LABEL: "Adresse E-mail",
  LOGIN_PASSWORD_LABEL: "Mot de Passe",
  LOGIN_REMEMBER_ME: "Souvenez-vous de moi",
  LOGIN_FORGOT_PASSWORD: "Mot de Passe Oublié?",
  LOGIN_LOGIN_BUTTON: "Connexion",
  LOGIN_DONT_HAVE_ACCOUNT: "Vous n'avez pas de compte?",
  LOGIN_REGISTER_HERE: "Inscrivez-vous ici",
  LOGIN_OTP_VALID_UP_TO: "OTP Valide Jusqu'à",
  LOGIN_YOUR_OTP_SENT:
    "Votre code à usage unique (OTP) a été envoyé à votre adresse e-mail enregistrée.",
  LOGIN_ENTER_OTP: "Entrer l'OTP",
  LOGIN_SUBMIT_BUTTON: "Soumettre",
  LOGIN_RESEND_OTP: "Renvoyer l'OTP",
  NEWS_EVENTS_INNER_HEAD_TITLE: "Actualités et mises à jour",

  PRIVACY_INNER_HEAD_TITLE: "Politique de confidentialité - Mantis Network",
  PRIVACY_PARAGRAPH_1:
    "La protection des données et la confidentialité de vos données personnelles sont des préoccupations importantes de Mantis Network. L'utilisation de notre site web dépend de la collecte de données personnelles, nécessaire lors de l'inscription et pour l'accès utilisateur. Mantis Network respecte strictement toutes les réglementations légales et traite vos données hautement sensibles de manière consciencieuse et responsable. Cependant, nous tenons à souligner qu'une protection absolue des données pendant la transmission sur Internet n'est pas possible et par conséquent, tout accès par des tiers ne peut être complètement évité à tout moment. Néanmoins, vous pouvez supposer que nous prenons toutes les mesures de sécurité techniques et organisationnelles nécessaires pour protéger vos données personnelles contre la perte et l'abus.",

  PRIVACY_SECTION_COOKIES: "Cookies",
  PRIVACY_PARAGRAPH_2:
    "Le site web utilise des cookies de session PHP. Les cookies n'endommagent pas votre ordinateur et ne contiennent pas de virus. Les cookies sont un mécanisme pour stocker des informations afin d'identifier les utilisateurs revenants ou de suivre leur parcours en utilisant le site web. Les cookies sont utilisés pour rendre notre site web plus convivial, confortable et sûr. La désactivation des cookies peut limiter la fonctionnalité de ce site web.",

  PRIVACY_SECTION_LOG_FILES: "Fichiers journaux du serveur",
  PRIVACY_PARAGRAPH_3:
    "Notre serveur collecte et stocke automatiquement des informations dans des fichiers journaux de serveur, que votre navigateur nous transmet automatiquement. Les informations ne contiennent pas de données personnelles. Une corrélation de ces données avec vos données personnelles n'est pas prévue. Nous nous réservons le droit de vérifier rétrospectivement ces données si nous avons connaissance d'indications spécifiques d'une utilisation illégale.",

  PRIVACY_SECTION_SSL: "Chiffrement SSL",
  PRIVACY_PARAGRAPH_4:
    "Pour des raisons de sécurité, ce site utilise le chiffrement SSL pour la transmission des données. Tant que le chiffrement SSL est activé, les données ne peuvent pas être lues par des tiers pendant la transmission.",

  PRIVACY_SECTION_CONTACT_FORM: "Formulaire de contact",
  PRIVACY_PARAGRAPH_5:
    "Si vous nous envoyez un message via le formulaire de contact, nous vous demanderons au moins votre nom et votre adresse e-mail. Ces détails seront transmis et enregistrés avec le message pour un traitement ultérieur. Les informations 'téléphone' et 'adresse' sont facultatives. Nous ne partagerons pas toutes ces informations sans votre consentement.",

  PRIVACY_SECTION_REGISTRATION: "Inscription",
  PRIVACY_PARAGRAPH_6:
    "Après l'inscription, nous enregistrerons votre mot de passe de manière anonyme, seule votre adresse e-mail nous sera visible. Par conséquent, nous ne pouvons réinitialiser les mots de passe que s'ils sont oubliés. Vous avez besoin de votre adresse e-mail et de votre mot de passe pour vous connecter à notre site web. Vous êtes personnellement responsable du secret effectif de vos mots de passe. Ne donnez pas les données de connexion à quelqu'un d'autre.",

  PRIVACY_SECTION_CONSENT: "Déclaration de consentement",
  PRIVACY_PARAGRAPH_7:
    "Avec la participation et l'utilisation de nos services, vous acceptez également le traitement de vos données personnelles. Les services ne peuvent pas être fournis sans le traitement de vos données personnelles.",

  PRIVACY_SECTION_KYC: "Conformité KYC",
  PRIVACY_PARAGRAPH_8:
    "En ce qui concerne les sanctions de l'UE et des États-Unis ainsi que la loi anti-blanchiment, Mantis Network est obligé autant que possible de réaliser et de documenter un contrôle KYC (Know Your Customers), basé sur votre auto-déclaration. Cette procédure nécessite également une preuve de votre identité. Ces données ne seront pas divulguées à des tiers sans votre consentement préalable, sauf si Mantis Network est légalement obligé de le faire. Bien sûr, Mantis Network contactera immédiatement ses clients si un examen révèle des anomalies et ils ont le droit de commenter.",

  PRIVACY_SECTION_THIRD_PARTY: "Services tiers",
  PRIVACY_PARAGRAPH_9:
    "Mantis Network engage d'autres entreprises et individus pour des performances liées à nos services ou à des fins administratives liées aux affaires. Ces fournisseurs de services tiers n'ont accès qu'aussi longtemps et dans la mesure où ils en ont absolument besoin pour le but ou pour ce à quoi ils sont légalement obligés.",

  PRIVACY_SECTION_INFORMATION: "Droit à l'information",
  PRIVACY_PARAGRAPH_10:
    "Vous avez le droit à tout moment d'obtenir des informations sur vos données personnelles ainsi que sur l'objectif et le type de traitement de vos données. Si vous avez des questions sur les données personnelles, vous pouvez toujours nous contacter à l'adresse qui est donnée à la rubrique 'à propos de nous'.",

  PRIVACY_SECTION_TELEGRAM:
    "Politique de confidentialité pour l'utilisation de Telegram",
  PRIVACY_PARAGRAPH_11:
    "Sur notre site web, des fonctionnalités du service Telegram sont intégrées. Ces fonctions sont proposées par Telegram Messenger LLP. 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, Royaume-Uni. En utilisant le lien vers Telegram, vous pouvez rejoindre le 'canal d'information MANTIS NETWORK' avec votre compte Telegram. Vous trouverez plus d'informations dans la politique de confidentialité de Telegram sous votre compte: FAQ de Telegram, F:, Politique de confidentialité.",

  PRIVACY_SECTION_FACEBOOK:
    "Politique de confidentialité pour l'utilisation de Facebook",
  PRIVACY_PARAGRAPH_12:
    "Sur notre site web, des fonctionnalités du service Facebook sont intégrées. Ces fonctions sont proposées par Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irlande. En utilisant le lien vers Facebook, vous pouvez visiter notre compte 'Mantis Network Limited'. Vous trouverez plus d'informations dans la politique de confidentialité de Facebook; https://www.facebook.com/privacy/explanation",

  PROFILE_PROFILE_TITLE: "Profil",
  PROFILE_USERNAME_LABEL: "Nom d'utilisateur",
  PROFILE_EMAIL_LABEL: "E-mail",
  PROFILE_PHONE_NUMBER_LABEL: "Numéro de téléphone",
  PROFILE_CHANGE_USERNAME_LABEL: "Changer de nom d'utilisateur",
  PROFILE_CHANGE_PASSWORD_LABEL: "Changer le mot de passe",
  PROFILE_PASSWORD_DESCRIPTION:
    "Ce mot de passe est utilisé pour vos identifiants de connexion.",
  PROFILE_CHANGE_BUTTON: "Changer",

  CHANGE_PROFILE_CHANGE_USERNAME_TITLE: "Changer de nom d'utilisateur",
  CHANGE_PROFILE_ENTER_NEW_USERNAME: "Entrer le nouveau nom d'utilisateur",
  CHANGE_PROFILE_CLOSE_BUTTON: "Fermer",
  CHANGE_PROFILE_SAVE_BUTTON: "Enregistrer",

  CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE: "Changer le mot de passe",
  CHANGE_PASSWORD_ENTER_OTP: "Entrer le code OTP",
  CHANGE_PASSWORD_ENTER_OLD_PASSWORD: "Entrer l'ancien mot de passe",
  CHANGE_PASSWORD_ENTER_NEW_PASSWORD: "Entrer le nouveau mot de passe",
  CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD: "Confirmer le nouveau mot de passe",
  CHANGE_PASSWORD_CLOSE_BUTTON: "Fermer",
  CHANGE_PASSWORD_SEND_BUTTON: "Envoyer",
  CHANGE_PASSWORD_VERIFY_BUTTON: "Vérifier",
  CHANGE_PASSWORD_SUBMIT_BUTTON: "Soumettre",

  WALLET_DETAILS_TITLE: "Portefeuille",
  WALLET_DETAILS_TOTAL_BALANCE: "Solde total",
  WALLET_DETAILS_INVEST_BUTTON: "Investir",

  DEPOSIT_TITLE: "Dépôt",
  DEPOSIT_SELECT_COIN: "Sélectionner la crypto-monnaie",
  DEPOSIT_SELECT_NETWORK: "Sélectionner le réseau",
  DEPOSIT_ADDRESS_LABEL: "Adresse",
  DEPOSIT_COPY_ICON_ALT: "Copier",
  DEPOSIT_GENERATE_ADDRESS_BUTTON: "Générer une adresse",
  DEPOSIT_QR_IMAGE_ALT: "Image floue",
  DEPOSIT_DEPOSIT_NOTES: "Notes de dépôt",
  DEPOSIT_SEND_ONLY:
    "Envoyez uniquement {currencySymbol} à cette adresse de dépôt.",
  DEPOSIT_MINIMUM_DEPOSIT: "Dépôt minimum : {minimumDeposit} {currencySymbol}",
  DEPOSIT_SENDING_COIN:
    "Envoyer une crypto-monnaie ou un jeton autre que {currencySymbol} à cette adresse peut entraîner la perte de votre dépôt.",

  WITHDRAW_TITLE: "Retirer",
  WITHDRAW_OTP_VALID_UP_TO: "OTP valide jusqu'à",
  WITHDRAW_OTP_LABEL: "OTP",
  WITHDRAW_ENTER_OTP: "Entrer le code OTP",
  WITHDRAW_SUBMIT: "Soumettre",
  WITHDRAW_RESEND_OTP: "Renvoyer le code OTP",
  WITHDRAW_SELECT_COIN: "Sélectionner la crypto-monnaie",
  WITHDRAW_SELECT: "Sélectionner",
  WITHDRAW_AMOUNT: "Montant",
  WITHDRAW_ENTER_AMOUNT: "Entrer le montant",
  WITHDRAW_SELECT_NETWORK: "Sélectionner le réseau",
  WITHDRAW_CONVERSION_RATE: "Taux de conversion",
  WITHDRAW_ENTER_ADDRESS: "Entrer l'adresse",
  WITHDRAW_ADDRESS: "Adresse",
  WITHDRAW_WITHDRAW_NOW: "Retirer maintenant",
  WITHDRAW_WITHDRAW_NOTES: "Notes de retrait",
  WITHDRAW_SEND_ONLY:
    "Envoyez uniquement {{currencySymbol}} à cette adresse de retrait.",
  WITHDRAW_MINIMUM_WITHDRAW:
    "Retrait minimum : {{minimumWithdraw}} {{currencySymbol}}",
  WITHDRAW_MAXIMUM_WITHDRAW:
    "Retrait maximum : {{maximumWithdraw}} {{currencySymbol}}",
  WITHDRAW_WITHDRAWAL_FEE:
    "Frais de retrait : {{withdrawFee}} {{currencySymbol}}",
  WITHDRAW_SENDING_COIN:
    "Envoyer une crypto-monnaie ou un jeton autre que {{currencySymbol}} à cette adresse peut entraîner la perte de votre retrait.",
  TRX_HISTORY_TITLE: "Historique des transactions",

  TERMS_TITLE: "Conditions d'utilisation",
  TERMS_GENERAL: "1. Généralités",
  TERMS_GENERAL_CONTENT_A:
    "a. Ces conditions d'utilisation (ci-après dénommées « TOS ») sont soumises à l'utilisation des services d'hébergement de Mantis Network (ci-après dénommés « Service ») du site Web http://Mantis.Network (ci-après dénommé « Site Web »). Toute utilisation de la terminologie ci-après ou d'autres mots au singulier, au pluriel, en majuscules et/ou il/elle ou ils, votre/son/sa sera interprétée comme interchangeable et donc comme se référant à la même chose.",
  TERMS_GENERAL_CONTENT_B:
    "b. Tout individu, entité ou autre personne morale (ci-après dénommée « Utilisateur ») qui accède au Site Web et utilise les Services doit lire, accepter et confirmer les TOS en premier lieu et accepter d'être lié par les dispositions des TOS avant de devenir membre. Toute participation effective aux Services constituera une telle acceptation. Si l'Utilisateur n'accepte pas et ne respecte pas ces TOS, il n'est pas autorisé à utiliser les Services.",
  TERMS_GENERAL_CONTENT_C:
    "c. Tout Utilisateur des Services confirme être illimité en capacité juridique (capacité contractuelle) et avoir au moins dix-huit (18) ans en s'abonnant aux Services. De plus, tout Utilisateur est conscient de tous les risques possibles liés à l'utilisation des Services, à la technologie de la blockchain et aux crypto-monnaies.",
  TERMS_EXCLUSIONS_LIMITATIONS_TITLE: "2. Exclusions et Limitations",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A:
    'a. Les informations du Site Web seront fournies "telles quelles" dans la mesure maximale permise par la loi. Le Service exclut toutes les représentations et garanties relatives au Site Web et à son contenu ou au contenu qui sera ou pourra être fourni par des affiliés ou tout autre tiers, y compris les inexactitudes ou omissions liées au Site Web et/ou aux Services ainsi que les présentations, démonstrations et descriptions connexes.',
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B:
    "b. La disponibilité du Service ou du Site Web n'est pas garantie ; des opérations de maintenance et des temps d'arrêt peuvent être nécessaires de temps à autre. Le Propriétaire n'est pas responsable du fonctionnement continu et sans faille et de la sécurité des réseaux de crypto-monnaie. Néanmoins, le Propriétaire fera tout son possible pour fournir le Service dans de bonnes conditions et éviter toute conséquence pire.",
  TERMS_LIMITATION_LIABILITIES_TITLE: "3. Limitation des Responsabilités",
  TERMS_LIMITATION_LIABILITIES_CONTENT_A:
    "a. Mantis Network Ltd., ses représentants légaux et actionnaires (ci-après collectivement dénommés « Propriétaire ») ne seront pas responsables des dommages directs ou indirects, consécutifs et accessoires liés au Site Web et/ou aux Services, que les conséquences aient été prévisibles ou non et puissent se produire normalement.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_B:
    "b. En particulier, le Propriétaire ne sera pas responsable des dommages, mais non limité à, des dommages matériels ou logiciels de l'ordinateur de l'Utilisateur, des systèmes et de la programmation, de la perte de données ainsi que de la perte économique, de la perte d'activité ou de la perte de profits.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_C:
    "c. L'Utilisateur confirme être conscient de tous les risques, y compris la possibilité de perte économique ou de perte de profits attendus. Le Propriétaire ne compensera aucune perte irréversible.",
  TERMS_PAYOUTS_TITLE: "4. Paiements",
  TERMS_PAYOUTS_CONTENT_A:
    "a. Mantis Network versera des récompenses proportionnelles au montant investi par l'utilisateur. Toutes les transactions seront traitées en USDT.",
  TERMS_PAYOUTS_CONTENT_B:
    "b. Les paiements seront transférés UNIQUEMENT sur le compte que l'Utilisateur aura mentionné sur son formulaire d'investissement (tableau de bord). Les paiements seront crédités toutes les quatre (4) semaines de manière régulière.",
  TERMS_PAYOUTS_CONTENT_C:
    "c. Le Propriétaire facturera des frais de participation de 2 % sur tous les dépôts et des frais de 25 % sur toutes les récompenses de Mantis Network.",
  TERMS_PAYOUTS_CONTENT_D:
    "d. Toutes les récompenses accumulées par un compte de crypto-monnaie nominatif ne seront payées qu'à cette adresse particulière. Les soldes de portefeuille différents ne peuvent pas être fusionnés.",
  TERMS_PAYOUTS_CONTENT_E:
    "e. Le Propriétaire ne sera pas responsable des changements de valeur de la crypto-monnaie. Le retour sur investissement (ROI) sera calculé sur la base des récompenses réelles pour chaque bloc des dernières 24 heures. Le Propriétaire ne peut garantir qu'une crypto-monnaie conservera une valeur constante et ne peut donc garantir le ROI. Une perte totale est possible à tout moment et tout risque sera supporté par chaque Utilisateur.",
  TERMS_PAYOUTS_CONTENT_F:
    "f. La valeur du portefeuille peut être demandée une fois par trimestre, maximum 4 semaines avant la fin de la période de blocage, gratuitement pour le calcul via le support. Chaque calcul ultérieur sera facturé 25 $ en raison des efforts élevés, montant qui doit être payé d'avance en USDT. La valeur du portefeuille a une validité de 7 jours dans chaque cas. Pour des raisons opérationnelles et en raison des demandes élevées et récurrentes de la valeur du portefeuille, il se peut qu'un calcul de valeur de pool ne soit effectué qu'une fois par trimestre, maximum 4 semaines avant la fin de la période de blocage et transmis.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE: "5. Exigences légales et sanctions",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A:
    "a. L'Utilisateur déclare par son accord à ces TOS qu'il n'a été affecté par aucune sanction ou qu'il n'a été inscrit par aucune autorité de surveillance compétente. Il doit annoncer les changements immédiatement. Le Propriétaire surveillera régulièrement son statut. Un résultat de contrôle positif entraîne l'arrêt immédiat des services.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B:
    "b. De plus, l'Utilisateur confirme avec son consentement aux TOS qu'il agit à ses propres frais.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C:
    "c. Le Propriétaire est en droit d'exiger une preuve de l'identité de l'Utilisateur. Dans le cas de personnes physiques, il s'agirait généralement d'un document officiel d'une autorité (par exemple, une carte d'identité), dans le cas d'une personne morale, d'autorités de surveillance par un extrait actuel du registre du commerce.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D:
    "d. En cas d'informations incorrectes ou incomplètes ainsi que de fraude ou de fausse représentation frauduleuse, l'Utilisateur indemnisera le Propriétaire de toutes les conséquences légales et des dommages financiers. De plus, le Propriétaire suspendra immédiatement l'Utilisateur de l'utilisation du Site Web et des Services. Le Propriétaire se réserve le droit d'engager des actions en justice.",
  TERMS_WAIVER_TITLE: "6. Renonciation",
  TERMS_WAIVER_CONTENT:
    "Aucune renonciation à l'une des dispositions des TOS ne sera réputée constituer une renonciation à toute autre disposition, et une telle renonciation ne constituera pas une renonciation continue sauf disposition contraire expressément prévue par écrit et dûment exécutée par la partie à lier.",
  TERMS_FORCE_MAJEURE_TITLE: "7. Force majeure",
  TERMS_FORCE_MAJEURE_CONTENT:
    "Le Propriétaire ne sera pas responsable en aucun cas ou circonstance qui est hors du contrôle du Propriétaire, y compris, mais sans s'y limiter, les actes de Dieu, les émeutes, les guerres, les inondations, les incendies, les explosions, les grèves et autres événements similaires, qui empêcheront, retarderont, perturberont ou entraîneront une défaillance de la fourniture de Services déjà engagés.",
  TERMS_TERMINATION_TITLE: "8. Résiliation",
  TERMS_TERMINATION_CONTENT_A:
    "Le consentement de l'Utilisateur aux TOS inclut un investissement dans le Mantis Network. La période minimale de verrouillage du portefeuille sera valable pour une période de cent quatre-vingts (180) jours calendaires et sera automatiquement prolongée si l'utilisateur ne résilie pas les TOS d'ici la fin de la période minimale de verrouillage. L'Utilisateur peut résilier ses TOS et l'utilisation des Services en envoyant un e-mail à support@Mantis.Network. La part de la valeur actuelle du portefeuille de Mantis Network sera payée. Le Propriétaire a le droit de résiliation extraordinaire s'il existe des raisons impérieuses contre une continuation de la relation commerciale avec un Utilisateur. En cas de résiliation extraordinaire anticipée du contrat, la part de la valeur actuelle du portefeuille de Mantis Network, déduction faite d'une indemnité de 25 %, sera remboursée. Il n'y a aucun droit concernant les paiements impayés ou futurs.",
  TERMS_SEVERABILITY_CLAUSE_TITLE: "9. Clause de divisibilité",
  TERMS_SEVERABILITY_CLAUSE_CONTENT:
    "Si une disposition des TOS devient invalide, l'invalidité n'affecte pas les autres dispositions des TOS qui peuvent être mises en œuvre sans la disposition invalide, et à cette fin, les dispositions des TOS sont divisibles.",
  TERMS_GOVERNING_LAW_TITLE: "10. Droit applicable",
  TERMS_GOVERNING_LAW_CONTENT:
    "Les droits et obligations du Propriétaire et de l'Utilisateur et des tiers, le cas échéant, en vertu des TOS, du Site Web et des Services sont régis par, et seront interprétés conformément aux lois des îles Vierges britanniques. Tout litige, controverse ou réclamation découlant des TOS, du Site Web et des Services sera finalement réglé par arbitrage conformément aux règles d'arbitrage de la Chambre de commerce internationale (Paris, France). Les procédures d'arbitrage seront menées à Tortola, îles Vierges britanniques. La langue de la procédure sera l'anglais.",

  RESET_PASSWORD_TITLE: "Réinitialiser le mot de passe",
  RESET_PASSWORD_NEW_LABEL: "Nouveau mot de passe",
  RESET_PASSWORD_CONFIRM_LABEL: "Confirmer le mot de passe",
  RESET_PASSWORD_PASSWORD_PLACEHOLDER: "Entrez le mot de passe",
  RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Confirmez le mot de passe",
  RESET_PASSWORD_SHOW_PASSWORD: "Afficher le mot de passe",
  RESET_PASSWORD_SHOW_CONFIRM_PASSWORD:
    "Afficher la confirmation du mot de passe",
  RESET_PASSWORD_PASSWORD_ERROR: "Veuillez entrer un mot de passe valide",
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR:
    "Les mots de passe ne correspondent pas",
  RESET_PASSWORD_SUBMIT_BUTTON: "Soumettre",
  //
  REGISTER_USER_NAME: "Nom d'utilisateur",
  REGISTER_ENTER_USERNAME: "Entrez le nom d'utilisateur",
  REGISTER_EMAIL_ADDRESS: "Adresse e-mail",
  REGISTER_ENTER_EMAIL: "Entrez votre adresse e-mail",
  REGISTER_COUNTRY: "Pays",
  REGISTER_PHONE_NUMBER: "Numéro de téléphone",
  REGISTER_ENTER_MOBILE_NUMBER: "Entrez votre numéro de téléphone portable",
  REGISTER_PASSWORD: "Mot de passe",
  REGISTER_ENTER_PASSWORD: "Entrez le mot de passe",
  REGISTER_CONFIRM_PASSWORD: "Confirmer le mot de passe",
  REGISTER_ENTER_CONFIRM_PASSWORD: "Confirmez le mot de passe",
  REGISTER_REFERRAL_CODE: "Code de parrainage (facultatif)",
  REGISTER_ENTER_REFERRAL_CODE: "Entrez le code de parrainage",
  REGISTER_OTP: "OTP",
  REGISTER_ENTER_OTP: "Entrez l'OTP",
  REGISTER_AGREE_TERMS: "Je comprends",
  REGISTER_TERMS_LINK: "Conditions d'utilisation",
  REGISTER_PRIVACY_LINK: "Politique de confidentialité",
  REGISTER_BUTTON: "S'inscrire",
  REGISTER_ALREADY_REGISTER: "Déjà inscrit?",
  REGISTER_SIGN_IN: "Se connecter",

  REFERRAL_HEAD_TITLE: "Parrainage",
  REFERRAL_TOTAL_REFERRALS_LABEL: "Total des recommandations",
  REFERRAL_TOTAL_INCOME_LABEL: "Total des gains de parrainage",
  REFERRAL_TOTAL_USERS_INVEST_LABEL:
    "Total des utilisateurs parrainés investis",
  REFERRAL_LINK: "Lien de parrainage",
  REFERRAL_LEVEL_INCOME: "Revenu de niveau",
  REFERRAL_LEVEL_1_INCOME: "Revenu de niveau 1",
  REFERRAL_LEVEL_2_INCOME: "Revenu de niveau 2",
  REFERRAL_LEVEL_3_INCOME: "Revenu de niveau 3",
  REFERRAL_HISTORY_LABEL: "Historique de parrainage",

  // BACKEND

  EMAIL_EXISTS: "L'email existe déjà",
  PHONE_EXISTS: "Le numéro de téléphone existe déjà",
  INVALID_REFERRAL_CODE: "Code de parrainage invalide",
  OTP_SENT: "OTP envoyé à votre adresse e-mail, veuillez saisir un OTP",
  EXPIRED_OTP: "OTP expiré",
  INVALID_OTP: "OTP invalide",
  REGISTRATION_SUCCESS:
    "Inscription terminée - Lien d'activation envoyé à votre adresse e-mail",
  SOMETHING_WENT_WRONG: "Quelque chose s'est mal passé",

  INVALID_EMAIL: "Veuillez saisir une adresse e-mail correcte",
  PHONE_NOT_EXISTS: "Le numéro de téléphone n'existe pas",
  IP_BLOCKED: "Votre IP a été bloquée",
  ACCOUNT_LOCKED: "Votre compte est toujours verrouillé",
  ACCOUNT_BLOCKED: "Votre compte a été bloqué. Veuillez réessayer plus tard",
  ACCOUNT_NOT_ACTIVATED: "Votre compte n'est toujours pas activé",
  LOGIN_ATTEMPTS_EXCEEDED:
    "Vous avez trop tenté de vous connecter. Veuillez réessayer dans quelques minutes.",
  PASSWORD_INCORRECT: "Mot de passe incorrect",
  OTP_SENT:
    "OTP envoyé à votre adresse e-mail, OTP est valide uniquement pendant 3 minutes",
  INVALID_MOBILE_NUMBER: "Numéro de téléphone mobile invalide",
  OTP_SENT_MOBILE:
    "OTP envoyé avec succès, Il est valide uniquement pendant 10 minutes",
  OTP_EXPIRED: "OTP expiré",
  INVALID_OTP: "OTP invalide",
  NEED_TWO_FA: "Besoin de code 2FA",
  INVALID_TWO_FA: "Code 2FA invalide",
  EXPIRED_TWO_FA: "Code 2FA expiré",
  OTP_IS_REQUIRED: "OTP est requis",
  LOGIN_SUCCESS: "Connexion réussie !",
  ERROR_SERVER: "Erreur sur le serveur",

  EMAIL_NOT_EXISTS: "L'email n'existe pas",
  CONFIRMATION_LINK_SENT:
    "Le lien de confirmation a été envoyé à votre e-mail enregistré",
  SOMETHING_WENT_WRONG: "Quelque chose s'est mal passé",

  INVALID_LINK: "Lien invalide!",
  OLD_PASSWORD_CANT_BE_NEW_PASSWORD:
    "L'ancien mot de passe ne peut pas être le nouveau mot de passe!",
  PASSWORD_UPDATED_SUCCESSFULLY: "Mot de passe mis à jour avec succès",
  SOMETHING_WENT_WRONG: "Quelque chose s'est mal passé",

  USER_NOT_FOUND: "Utilisateur non trouvé",
  INCORRECT_OLD_PASSWORD: "Ancien mot de passe incorrect",
  NEW_PASSWORD_CANT_BE_OLD_PASSWORD:
    "Le nouveau mot de passe ne peut pas être l'ancien mot de passe !",
  PASSWORD_CHANGED_SUCCESSFULLY: "Mot de passe changé avec succès",

  PROFILE_DETAILS_UPDATED_SUCCESSFULLY:
    "Détails du profil mis à jour avec succès",
  PROFILE_UPDATED_SUCCESSFULLY: "Profil mis à jour avec succès",
  DEACTIVATED: "Désactivé",
  ACTIVATED: "Activé",
  USER_STATUS_CHANGED_SUCCESSFULLY:
    "Statut de l'utilisateur modifié avec succès",
  ACTIVATE_REGISTER_USER: "activate_register_user",
  ACTIVATION_MAIL_SENT_SUCCESSFULLY: "Mail d'activation envoyé avec succès",
  ERROR_ON_SERVER: "Erreur sur le serveur",
  NO_RECORD: "Aucun enregistrement",
  UPDATED_SUCCESS: "Mise à jour réussie",
  INVALID_USER: "Utilisateur invalide",
  INVALID_MOBILE_NO: "Entrez un numéro de mobile correct",
  RESEND_OTP_MOBILE:
    "Code de vérification envoyé à votre numéro de mobile, le code de vérification est valide uniquement pendant 10 minutes",
  NEXT_OTP_AFTER_3_MINUTES: "Prochain code de vérification après 3 minutes",
  RESEND_OTP_EMAIL:
    "Code de vérification envoyé à votre adresse e-mail, le code de vérification est valide uniquement pendant 3 minutes",
  NAME_FIELD_REQUIRED: "Le champ Nom est requis",
  USERNAME_ALPHABETS_SPACES:
    "Le nom d'utilisateur doit contenir des lettres et des espaces",
  USER_NOT_FOUND: "Utilisateur introuvable",
  PROFILE_UPDATED_SUCCESSFULLY: "Profil mis à jour avec succès",
  INVALID_USER_DETAILS: "Détails utilisateur non valides",
  INVALID_TYPE: "Type non valide",
  CODE_VERIFIED: "Code vérifié",
  ERROR_ON_SERVER: "Erreur sur le serveur",
  INVALID_VERIFICATION_CODE: "Code de vérification invalide",
  VERIFICATION_CODE_EXPIRED: "Code de vérification expiré",
  CODE_VERIFIED: "Code vérifié",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER:
    "Quelque chose s'est mal passé, réessayez plus tard",
  REQUIRED: "Requis",
  USERNAME_ALPHA_SPACE:
    "Le nom d'utilisateur ne peut contenir que des lettres et des espaces",
  EMAIL_REQUIRED: "Le champ e-mail est requis",
  INVALID_EMAIL: "E-mail invalide",
  PHONE_NO_REQUIRED: "Le champ du numéro de téléphone est requis",
  COUNTRY_CODE_REQUIRED: "Le champ du code pays est requis",
  PASSWORD_REQUIRED: "Le champ mot de passe est requis",
  INVALID_PASSWORD_FORMAT:
    "Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre, un caractère spécial et avoir une longueur comprise entre 6 et 18 caractères",
  PASSWORD_LENGTH_EXCEEDED:
    "Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre, un caractère spécial et avoir une longueur comprise entre 6 et 18 caractères",
  CONFIRM_PASSWORD_REQUIRED:
    "Le champ de confirmation du mot de passe est requis",
  PASSWORD_MISMATCH:
    "Le mot de passe et la confirmation du mot de passe doivent correspondre",
  TERMS_REQUIRED:
    "Le champ des conditions générales et de la politique est requis",
  USERID_FIELD_IS_REQUIRED: "Le champ UserId est obligatoire",
  FIRST_NAME_FIELD_IS_REQUIRED: "Le champ Prénom est obligatoire",
  LAST_NAME_FIELD_IS_REQUIRED: "Le champ Nom de famille est obligatoire",
  PHONE_CODE_FIELD_IS_REQUIRED: "Le champ Code téléphonique est obligatoire",
  PHONE_NUMBER_FIELD_IS_REQUIRED:
    "Le champ Numéro de téléphone est obligatoire",
  PHONE_NUMBER_IS_INVALID: "Le numéro de téléphone est invalide",
  ADDRESS_FIELD_IS_REQUIRED: "Le champ Adresse est obligatoire",
  COUNTRY_FIELD_IS_REQUIRED: "Le champ Pays est obligatoire",
  CITY_FIELD_IS_REQUIRED: "Le champ Ville est obligatoire",
  POSTAL_CODE_FIELD_IS_REQUIRED: "Le champ Code postal est obligatoire",
  OLD_PASSWORD_FIELD_IS_REQUIRED: "Le champ du mot de passe ancien est requis",
  NEW_PASSWORD_FIELD_IS_REQUIRED: "Le champ du nouveau mot de passe est requis",
  PASSWORD_REQUIREMENTS_NOT_MET:
    "Le mot de passe doit contenir au moins une majuscule, au moins une minuscule, au moins un chiffre, au moins un caractère spécial et avoir entre 6 et 18 caractères",
  CONFIRM_NEW_PASSWORD_FIELD_IS_REQUIRED:
    "Le champ de confirmation du nouveau mot de passe est requis",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Le nouveau mot de passe et la confirmation du mot de passe doivent correspondre",
  OLD_AND_NEW_PASSWORD_MUST_BE_DIFFERENT:
    "L'ancien et le nouveau mot de passe doivent être différents",
  AUTH_TOKEN_FIELD_IS_REQUIRED:
    "Le champ de jeton d'authentification est requis",
  CONFIRM_PASSWORD_FIELD_IS_REQUIRED:
    "Le champ de confirmation du mot de passe est requis",
  NEW_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Le nouveau mot de passe et la confirmation du mot de passe doivent correspondre",
  USER_ASSET_FIELD_IS_REQUIRED: "Le champ de l'actif utilisateur est requis",
  INVALID_TRANSACTION_ID: "ID de transaction non valide",
  REQUIRED: "Obligatoire",
  AMOUNT_FIELD_IS_REQUIRED: "Le champ de montant est requis",
  INVALID_AMOUNT: "Montant invalide",
  USER_ASSET_FIELD_IS_REQUIRED: "Le champ d'actif utilisateur est requis",
  INVALID_CURRENCY: "Devise invalide",
  AMOUNT_FIELD_IS_REQUIRED: "Le champ du montant est requis",
  INVALID_TRANSACTION_ID: "Identifiant de transaction invalide",
  REQUIRED: "Requis",
  INVALID_CURRENCY_ID: "ID de devise invalide",
  RECEIVER_ADDRESS_FIELD_IS_REQUIRED:
    "Le champ d'adresse du destinataire est requis",
  INVALID_ADDRESS: "Adresse invalide",
  AMOUNT_FIELD_IS_REQUIRED: "Le champ du montant est requis",
  PLEASE_ENTER_VALID_AMOUNT: "Veuillez saisir un montant valide",
  MIN_WITHDRAW_AMOUNT_MESSAGE:
    "Veuillez entrer un montant de retrait supérieur ou égal à",
  OTP_STATUS_IS_REQUIRED: "Le statut OTP est requis",
  INVALID_OTP_STATUS: "Statut OTP invalide",
  PLEASE_ENTER_OTP: "Veuillez entrer un OTP",
  THERE_IS_NO_DATA: "Il n'y a pas de données",
  PLEASE_ENTER_AMOUNT_GREATER_THAN_OR_EQUAL_TO:
    "Veuillez saisir un montant supérieur ou égal à ",
  DEPOSIT_REQUEST_SENT_SUCCESSFULLY: "Demande de dépôt envoyée avec succès",
  INVALID_CURRENCY_DATA: "Données de devise non valides",
  INVALID_WITHDRAW_CURRENCY_DATA: "Données de retrait de devise non valides",
  MIN_WITHDRAW_AMOUNT_REQUIRED:
    "Le montant de retrait minimum doit être supérieur ou égal à ",
  MAX_WITHDRAW_AMOUNT_REQUIRED:
    "Le montant de retrait doit être inférieur ou égal à ",
  INSUFFICIENT_BALANCE: "Solde insuffisant",
  WITHDRAW_AMOUNT_TOO_LOW: "Montant de retrait trop faible",
  OTP_SENT_SUCCESSFULLY:
    "OTP envoyé à votre adresse e-mail. Veuillez entrer un OTP",
  EXPIRED_OTP: "OTP expiré",
  INVALID_OTP: "OTP invalide",
  WITHDRAW_REQUEST_SENT_SUCCESSFULLY: "Demande de retrait envoyée avec succès",
  ERROR_ON_SERVER: "Erreur sur le serveur",
  RECORD_NOT_FOUND: "Enregistrement non trouvé!",
  GENERATED_SUCCESSFULLY: "Généré avec succès",
  STAKING_CURRENCY_NOT_EXISTS: "La devise de staking n'existe pas",
  INVESTMENT_PLAN_ADDED_SUCCESSFULLY:
    "Plan d'investissement ajouté avec succès.",
  STAKING_CURRENCY_NOT_EXISTS: "La devise de staking n'existe pas",
  INVESTMENT_PLAN_UPDATED_SUCCESSFULLY:
    "Plan d'investissement mis à jour avec succès",
  FETCH_SUCCESS: "Récupération réussie",
  MIN_AMOUNT_GREATER_THAN_OR_EQUAL:
    "Le montant minimum doit être supérieur ou égal à ",
  AMOUNT_LESS_THAN_OR_EQUAL: "Le montant doit être inférieur ou égal à ",
  INVALID_STAKING: "Staking invalide",
  INVALID_CURRENCY: "Devise invalide",
  DEACTIVE: "Inactif",
  NO_RECORD: "Aucun enregistrement",
  INVALID_ASSET: "Actif invalide",
  INSUFFICIENT_BALANCE: "Il n'y a pas assez d'actif dans votre solde.",
  INVESTMENT_ORDER_ADDED_SUCCESSFULLY:
    "Ordre d'investissement ajouté avec succès.",
  KINDLY_CHOOSE_DURATION_DAYS: "Veuillez choisir la durée en jours",
  NO_RECORD: "Aucun enregistrement",
  ALREADY_CANCELLED: "Déjà annulé",
  INVALID_CURRENCY: "Devise invalide",
  RELEASED_SUCCESSFULLY: "Libéré avec succès",
  ALREADY_REDEMPTION_COMPLETED: "La demande de rachat a déjà été effectuée",
  INSUFFICIENT_AMOUNT: "Montant insuffisant",
  ENTER_VALID_AMOUNT: "Veuillez saisir un montant valide",
  EMAIL_ALREADY_VERIFIED:
    "Votre adresse e-mail a déjà été vérifiée, vous pouvez vous connecter maintenant",
  ACCOUNT_DEACTIVATED_BY_ADMIN:
    "Votre compte a été désactivé par l'administrateur. Veuillez contacter l'administrateur",
  EMAIL_VERIFIED_CAN_LOGIN:
    "Votre adresse e-mail a été vérifiée, vous pouvez maintenant vous connecter",
  AMOUNT_ZERO: "Le montant ne peut pas être nul",
  AMOUNT_NEGATIVE: "Le montant ne peut pas être négatif",
  AMOUNT_EXCEEDS_STAKED:
    "Le montant doit être inférieur ou égal à {{stakedAmount}}",
  SUCCESS_COPY: "Copié avec succès dans le presse-papiers",
  INTEREST_HISTORY: "Historique des intérêts",
  SEE_MORE: "Voir plus",
  STRATEGY: "Stratégie",
  PERIOD: "Période",
  MANAGEMENT_FEE: "Frais de gestion %",
  MINIMUM_INVESTMENT: "Investissement minimum",
  AMOUNT: "Montant",
  CLOSE: "Fermer",
  INVEST: "Investir",
  MONTHLY: "Mensuel",
  PROCESS: "Processus",
  UNLOCKED: "Déverrouillé",
  LOCKED: "Verrouillé",
  DAYS: "Jours",
  LOGOUT_SUCCESS: "Déconnexion réussie",
  INVEST_DASHBOARD_ROI_PERCENT: "Pourcentage de ROI",
  HOME: "Accueil",
  INVEST: "Investir",
  WALLET: "Portefeuille",
  REFERRAL: "Référence",
  DASHBOARD: "Tableau de bord",
  LOGOUT: "Déconnexion",
  THERE_ARE_NO_RECORDS_TO_DISPLAY: "Aucun enregistrement à afficher",
  TOTAL_REFERRAL_INCOME_EARNED: "Revenus totaux des références gagnés",
  TOTAL_REFERRAL_USERS_INVEST:
    "Nombre total d'utilisateurs recommandés investissent",
  TYPE: "Type",
  TRX_ID: "ID de Transaction",
  TO_ADDRESS: "Adresse de Destination",
  FROM_ADDRESS: "Adresse d'Origine",
  CURRENCY: "Devise",
  FEE: "Frais",
  REWARD: "Récompense",
  USER_CODE: "Code utilisateur",
  REWARD_CURRENCY: "Devise de récompense",
  REWARD_LEVEL: "Niveau de récompense",
  REWARD_PERCENT: "Pourcentage de récompense",
  CREATED_AT: "Créé à",
  PROFIT: "Profit",
  CUSTOM_SOLUTION: "Solution Personnalisée",
  BTC_MINING_ALLOCATOR_ALLOCATION_BALANCED:
    "Allocation de 40 % à un allocateur de minage BTC",
  BTC_ALLOCATION_BALANCED: "Allocation de 30 % à BTC",
  ETH_ALLOCATION_BALANCED: "Allocation de 20 % à ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_BALANCED:
    "Allocation de 10 % à des stratégies Mantis à la recherche d'Alpha",
  BTC_MINING_ALLOCATOR_ALLOCATION_GROWTH:
    "Allocation de 25 % à un allocateur de minage BTC",
  BTC_ALLOCATION_GROWTH: "Allocation de 10 % à BTC",
  ETH_ALLOCATION_GROWTH: "Allocation de 15 % à ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_GROWTH:
    "Allocation de 50 % à des stratégies Mantis à la recherche d'Alpha",
  BTC_MINING_ALLOCATOR_ALLOCATION_HIGH_YIELD:
    "Allocation de 30 % à un allocateur de minage BTC",
  BTC_ALLOCATION_HIGH_YIELD: "Allocation de 20 % à BTC",
  ETH_ALLOCATION_HIGH_YIELD: "Allocation de 20 % à ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_HIGH_YIELD:
    "Allocation de 30 % à des stratégies Mantis à la recherche d'Alpha",
};