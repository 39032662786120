import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// api
import { forgotPassword } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
const initialFormValue = {
  email: "",
};
const ForgotPassword = () => {
  const navigate = useNavigate();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const { email, password } = formValue;
  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status, message, errors } = await forgotPassword({ email });
      const msg = t(message)
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", msg, "signup", "TOP_CENTER");
        navigate("/dashboard");
      } else {
        if (errors) {
          const translatedErrors = {};
          Object.keys(errors).forEach((key) => {
            translatedErrors[key] = t(`${errors[key]}`);
          });
          setErrors(translatedErrors);
          return false;
        }
        toastAlert("error", msg, "signup", "TOP_CENTER");
      }
    } catch (err) {}
  };
  return (
    <div
      className="col-lg-6"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
      <div className="auth_box">
        <h2>{t("FORGOT_PASSWORD_TITLE")}</h2>
        <p className="para_tag">{t("FORGOT_PASSWORD_RESET_INFO")}</p>
        <form className="primary_form mt-4 mt-lg-5">
          <div className="form-group">
            <label>{t("FORGOT_PASSWORD_EMAIL_LABEL")}</label>
            <input
              onChange={handleChange}
              value={email}
              name="email"
              className="form-control primary_inp"
              placeholder={t("LOGIN_EMAIL_PLACEHOLDER")}
              type="email"
            />
            <span className="text-danger">{errors?.email}</span>
          </div>
          <div className="form-group">
            <button onClick={handleSubmit} className="secondary_btn">
              {t("FORGOT_PASSWORD_SUBMIT_BUTTON")}
            </button>
          </div>
          <label className="text-center d-block">
            {t("FORGOT_PASSWORD_GO_TO_LOGIN")} <Link to="/login">Login</Link>
          </label>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
