// import package
import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SocketContext from "../../context/SocketContext";

// import action
import { viewUserProfile, logout } from "../../api/users";
import {
  getAllCurrencies,
  getAssetData,
  getPriceConversion,
  getUserSettings,
  priceCNVReducer,
} from "../../api/walletAction";
// import { getRefLevList, getRefLevSettings } from '../../api/referralAction';
import { unReadNotice, FetchunReadNotice } from "../../api/users";

// import lib
import isEmpty from "../../lib/isEmpty";
import { getStackingData } from "../../api/invest";
import isLogin from "../../lib/isLogin";
import { REMOVE_AUTHENTICATION, RESET_ALL, RESET_NOTICE } from "../../constant";
import { useTranslation } from "react-i18next";

const HelperRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation()
  const socketContext = useContext(SocketContext);

  // redux-state
  const { isAuth } = useSelector((state) => state.auth);

  // const currencyOption = useSelector(state => state.currency)

  // function
  async function checkAuth() {
    setTimeout(async () => {
      dispatch({
        type: REMOVE_AUTHENTICATION,
        authData: {
          isAuth: false,
        },
      });
    }, 2000);
  }
  useEffect(() => {
    if (isAuth) {
      viewUserProfile(dispatch);
      getAssetData(dispatch);
      getAllCurrencies(dispatch);
      unReadNotice(dispatch);
    }
    getStackingData(dispatch);
    getPriceConversion(dispatch);
    if (isAuth && !isLogin()) {
      checkAuth();
    }
  }, [isAuth]);

  useEffect(() => {
    socketContext.socket.on("notice", (result) => {
      FetchunReadNotice(dispatch, result);
    });

    socketContext.socket.on("NEW_DEPOSIT", (result) => {
      getAssetData(dispatch);
    });

    socketContext.socket.on("USERSETTING", (result) => {
      getUserSettings(dispatch);
    });

    socketContext.socket.on("FORCE_LOGOUT", (result) => {
      let token = localStorage.getItem("user_token");
      if (!isEmpty(token) && !isEmpty(result) && token != result) {
        logout(navigate, dispatch,t);
      }
    });

    socketContext.socket.on("PRICE_CONVERSION", (result) => {
      dispatch(priceCNVReducer(result));
    });

  }, []);

  return <div />;
};

export default HelperRoute;
