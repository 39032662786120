import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import browser from "browser-detect";
import Bowser from "bowser";
import { useDispatch } from "react-redux";
import { getGeoInfoData, login, resendOtp } from "../../api/users";
import { toastAlert } from "../../lib/toastAlert";
import { Spinner } from "react-bootstrap";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import isEmpty from "../../lib/isEmpty";
import { padSingleDigit } from "../../lib/roundOf";
const initialFormValue = {
  email: "",
  password: "",
  otpStatus: false,
  otp: "",
};
const LoginForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loginHistory, setLoginHistory] = useState({});
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const { email, password, otpStatus, otp } = formValue;

  //otpbox
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(false);
  const [resendOtpBtnStatus, setResendOtpBtnStatus] = useState(false);
  const [reCaptcha, setReCaptcha] = useState("");
  const [loader1, setLoader1] = useState(false);

  const [otpTextBox, setOtpTextBox] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      let loginData = {
        langCode: i18n.language || "en",
        email,
        password,
        otpStatus,
        otp,
        loginHistory,
        roleType: 1,
        otpTextBox: otpStatus,
      };
      const { status, success, message, error } = await login(
        loginData,
        dispatch
      );
      const msg = t(message);
      if (success && status === "success") {
        setFormValue(initialFormValue);
        toastAlert("success", msg, "signin", "TOP_CENTER");
        setLoad(false);
        setTimer(false);
        navigate("/dashboard");
      } else if (success && status === "OTP") {
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        toastAlert("success", msg, "signin", "TOP_CENTER");
        setLoad(false);

        setMinutes(2);
        setSeconds(59);
        setResendOtpBtnStatus(true);
        setOtpTextBox(true);
        setTimer(false);
      } else {
        if (error) {
          const translatedErrors = {};
          Object.keys(error).forEach((key) => {
            translatedErrors[key] = t(`${error[key]}`);
          });
          setErrors(translatedErrors);
          setLoad(false);
          return false;
        }
        toastAlert("error", msg, "signin", "TOP_CENTER");
      }
      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setLoader1(true);
    try {
      let loginData = {
        // langCode:
        email,
        password,
        otpStatus,
        otp,
        loginHistory,
        roleType: 1,
        otpTextBox: false,
      };
      const { status, success, message, error } = await login(
        loginData,
        dispatch
      );
      const msg = t(message);
      if (success && status === "success") {
        setFormValue(initialFormValue);
        toastAlert("success", msg, "signin", "TOP_CENTER");
        setLoad(false);
        setTimer(false);
        navigate("/dashboard");
      } else if (success && status === "OTP") {
        setFormValue({ ...formValue, ...{ otpStatus: true, otp: "" } });
        setErrors({});
        toastAlert("success", msg, "signin", "TOP_CENTER");
        setLoad(false);

        setMinutes(2);
        setSeconds(59);
        setResendOtpBtnStatus(true);
        setOtpTextBox(true);
        setTimer(false);
      } else {
        if (error) {
          const translatedErrors = {};
          Object.keys(error).forEach((key) => {
            translatedErrors[key] = t(`${error[key]}`);
          });
          setErrors(translatedErrors);
          setLoad(false);
          return false;
        }
        toastAlert("error", msg, "signin", "TOP_CENTER");
      }
      setLoader1(false);
    } catch (err) {
      setLoader1(false);
    }
  };

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      const Browser = Bowser.getParser(window.navigator.userAgent);

      var userAgent = window.navigator.userAgent;

      var osVersionRegex = /(Windows NT|Windows) ?([0-9._]+)/;
      var macOSVersionRegex = /(Mac OS X) ?([0-9._]+)/;
      var iOSVersionRegex = /(CPU(?: iPhone)? OS) ?([0-9._]+)/;
      var androidVersionRegex = /(Android) ?([0-9._]+)/;
      var linuxRegex = /(Linux) [xX] ?([0-9._]+)/;
      // Match the Linux OS and its version in the user agent string
      var osVersion;
      var osName;
      if (osVersionRegex.test(userAgent)) {
        osName = userAgent.match(osVersionRegex)[1];
        osVersion = userAgent.match(osVersionRegex)[2];
      } else if (macOSVersionRegex.test(userAgent)) {
        osName = userAgent.match(macOSVersionRegex)[1];
        osVersion = userAgent.match(macOSVersionRegex)[2];
      } else if (iOSVersionRegex.test(userAgent)) {
        osName = userAgent.match(iOSVersionRegex)[1];
        osVersion = userAgent.match(iOSVersionRegex)[2];
      } else if (androidVersionRegex.test(userAgent)) {
        osName = userAgent.match(androidVersionRegex)[1];
        osVersion = userAgent.match(androidVersionRegex)[2];
      } else if (linuxRegex.test(userAgent)) {
        osName = userAgent.match(linuxRegex)[1];
        osVersion = userAgent.match(linuxRegex)[2];
      } else {
        console.log("Operating system version not found.");
      }
      // console.log('---------3333333333333333-----', osVersion, osName)

      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipAddress: result.ip,
        regionName: result.region,
        broswerName: `${Browser.parsedResult.browser.name}${Browser.parsedResult.browser.version}`,
        isMobile: browserResult.mobile,
        os: osName + osVersion,
      });
    } catch (err) {}
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
      if (seconds == 1 && minutes == 0 && resendOtpBtnStatus) {
        setTimer(true);
        setOtpTextBox(false);
        setErrors({});
      }
    };
  });

  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <div
      className="col-lg-6"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
      {!otpStatus ? (
        <div className="auth_box">
          <h2>
            {t("LOGIN_WELCOME_TITLE")} <span>{t("LOGIN_MANTIS_NETWORK")}</span>
          </h2>
          <h2>{t("LOGIN_SIGN_IN")}</h2>
          <form className="primary_form mt-4 mt-lg-5">
            <div className="form-group">
              <label>{t("LOGIN_EMAIL_LABEL")} </label>
              <input
                className="form-control primary_inp"
                placeholder={t("LOGIN_EMAIL_PLACEHOLDER")}
                type="email"
                value={email}
                name="email"
                onChange={handleChange}
              />
              <span className="text-danger">{errors?.email}</span>
            </div>

            <div className="form-group">
              <label>{t("LOGIN_PASSWORD_LABEL")} </label>
              {/* <input
                className="form-control primary_inp"
                placeholder="Enter Password"
                // type="password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChange}
              /> */}
              <div className="input-group">
                <Link
                  className="input-group-text primary_inp"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <i
                    className={`bi bi-eye-slash ${
                      showPassword ? "d-none" : ""
                    }`}
                  ></i>
                  <i
                    className={`bi bi-eye ${showPassword ? "" : "d-none"}`}
                  ></i>
                </Link>
                <input
                  id="dz-password"
                  className="form-control primary_inp"
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors?.password}</span>
            </div>
            <div className="form-group d_flex">
              {/* <div className="form-check form-check-inline">
                <input
                  className="form-check-input primary_checkinput "
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                />
                <label className="form-check-label" for="inlineCheckbox1">
                  Remember me
                </label>
              </div> */}
              <label>
                <Link to="/forgot-password">{t("LOGIN_FORGOT_PASSWORD")}</Link>
              </label>
            </div>

            <div className="form-group">
              <button
                className="secondary_btn"
                onClick={handleSubmit}
                disabled={load}
              >
                {load ? <Spinner size="sm" /> : t("LOGIN_LOGIN_BUTTON")}
              </button>
            </div>
            <label className="text-center d-block">
              {t("LOGIN_DONT_HAVE_ACCOUNT")}{" "}
              <Link to="/register">{t("LOGIN_REGISTER_HERE")}</Link>
            </label>
          </form>
        </div>
      ) : (
        <div>
          {otpStatus && (minutes !== 0 || seconds !== 0) && (
            <p>
              {t("LOGIN_OTP_VALID_UP_TO")}:{" "}
              {`${minutes}:${padSingleDigit(seconds)}`}
            </p>
          )}
          <div class="mb-3">
            <label for="inputPassword" class="form-label">
              {t("LOGIN_YOUR_OTP_SENT")}
            </label>
            <input
              type="number"
              min={0}
              className="form-control primary_inp"
              id="otp"
              placeholder={t("LOGIN_ENTER_OTP")}
              name="otp"
              onChange={handleChange}
              value={otp}
            />
            <span className="text-danger">{errors?.otp}</span>
          </div>
          <div className="form-group">
            {otpTextBox && (
              <button
                className="secondary_btn"
                onClick={handleSubmit}
                disabled={load}
              >
                {load ? <Spinner size="sm" /> : t("LOGIN_SUBMIT_BUTTON")}
              </button>
            )}
            <p></p>
            {timer && (
              <button
                className="secondary_btn"
                onClick={handleResendOtp}
                disabled={loader1}
              >
                {loader1 ? <Spinner size="sm" /> : t("LOGIN_RESEND_OTP")}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
