import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const initialData = {
  labels: [],
  datasets: [
    {
      label: "Invest",
      data: [],
      borderColor: "rgba(255, 206, 86, 1)",
      backgroundColor: "rgba(255, 206, 86, 0.2)",
      fill: true,
    },
    {
      label: "Capital Growth",
      data: [],
      borderColor: "rgba(255, 99, 132, 1)",
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      fill: true,
    },
  ],
};
const list = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const LineChart = ({ roiData }) => {
  console.log("roiDataroiDataroiData00000000000000", roiData?.capital?.label);

  const [filteredData, setFilteredData] = useState(initialData);
  const [labelData, setLabelData] = useState([]);

  const filterData = (range) => {
    const now = new Date();
    let filteredLabels = initialData.labels;
    let filteredDatasets = initialData.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data,
    }));

    switch (range) {
      case "24h":
        // Assuming last date in labels is the most recent
        filteredLabels = initialData.labels.slice(-1);
        filteredDatasets = initialData.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(-1),
        }));
        break;
      case "7d":
        filteredLabels = initialData.labels.slice(-7);
        filteredDatasets = initialData.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(-7),
        }));
        break;
      case "1m":
        filteredLabels = initialData.labels.slice(-30);
        filteredDatasets = initialData.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(-30),
        }));
        break;
      case "3m":
        filteredLabels = initialData.labels.slice(-90);
        filteredDatasets = initialData.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(-90),
        }));
        break;
      case "1y":
      default:
        filteredLabels = initialData.labels;
        filteredDatasets = initialData.datasets;
        break;
    }

    setFilteredData({
      labels: filteredLabels,
      datasets: filteredDatasets,
    });
  };
  const setData = (dateSet, date, label) => {
    if (!date) {
      setLabelData([]);
      return;
    }
    let arr = label ?? [];
    let month, year;
    month = date.split(" ")[0];
    year = date.split(" ")[1];
    const dateIndex = dateSet.findIndex((item) => item == month);
    let index = -1;
    if (dateIndex == 0) {
      year = (Number(year) - 1).toString();
      index = 11;
    } else {
      index = dateIndex - 1;
    }
    const preMonth = dateSet[index];
    arr.unshift(`${preMonth} ${year}`);
    console.log("array unshift", arr);
    setLabelData(arr);
    // return arr;
  };
  const data = {
    labels: labelData,
    datasets: [
      {
        label: "Invest",
        data: [0, ...roiData?.capital?.investData],
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        fill: true,
      },
      {
        label: "Capital Growth",
        data: [0, ...roiData?.capital?.data],

        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        align: "top",
        color: "white",
        formatter: (value) => value.toLocaleString(),
      },
      legend: {
        position: "top",
        labels: {
          color: "white",
        },
      },
      title: {
        display: true,
        text: "User Spending on Stack Plans Over Time",
        color: "white",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Monthly",
          color: "white",
        },
        ticks: {
          color: "white",
          // callback: (value) => `$${value}`,
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "USD",
          color: "white",
        },
        ticks: {
          color: "white",
          callback: (value) => `$${value}`,
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 0,
      },
    },
  };

  useEffect(() => {
    setData(list, roiData?.capital?.label[0], roiData?.capital?.label);
  }, [roiData]);

  return (
    <div
      style={{
        // backgroundColor: "rgba(95, 131, 255, 0.5)",
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      {" "}
      {/* <div style={{ marginBottom: "20px" }}>
        <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "8px 16px",
            margin: "4px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => filterData("24h")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "darkblue")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "blue")}
        >
          24h
        </button>

        <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "8px 16px",
            margin: "4px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => filterData("7d")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "darkblue")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "blue")}
        >
          7d
        </button>

        <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "8px 16px",
            margin: "4px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => filterData("1m")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "darkblue")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "blue")}
        >
          1m
        </button>

        <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "8px 16px",
            margin: "4px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => filterData("3m")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "darkblue")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "blue")}
        >
          3m
        </button>

        <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "8px 16px",
            margin: "4px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => filterData("1y")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "darkblue")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "blue")}
        >
          1y
        </button>
      </div> */}
      <Line data={data} options={options} height={"200px"} />
    </div>
  );
};

export default LineChart;
