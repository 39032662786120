import React, { useEffect, useState } from "react";
import { getSingleRefUsr } from "../../api/referralAction";
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { useSelector } from "react-redux";

import ReferralHistory from "../../components/Referral/RefHistory"
import { useTranslation } from "react-i18next";

// import lib
import config from '../../config';
import { textCopy } from '../../lib/toastAlert';

// // Scroll to Top
// function ScrollToTopOnMount() {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);
//     return null;
// }

export default function ReferralData() {
  const { t, i18n } = useTranslation();

    // redux-state
    const { referralCode } = useSelector((state) => state.account)

    const [Data, setData] = useState({});

    const getRefUsr = async () => {
        try {
            const { status, result } = await getSingleRefUsr();
            if (status === "success") {
                let obj = {
                    totalRef: result?.userId?.refCount ? result?.userId?.refCount : 0,
                    usrInvests: result?.childIds ? result?.childIds.length : 0,
                    level1: result?.level1,
                    level2: result?.level2,
                    level3: result?.level3,
                    totalReward: toFixed((result?.level1 + result?.level2 + result?.level3), 4)
                }

                setData(obj);
            }
        } catch (err) { }
    };

    const refLink = `${config.FRONT_URL}/register?ref=${referralCode}`;
    const message = encodeURIComponent(`Check out this link: ${refLink}`);
    // const whatsappUrl = `https://api.whatsapp.com/send?text=${message}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(refLink)}`;//&text=${message}
    const twitterUrl = `https://twitter.com/intent/tweet?text=${message}`;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(refLink)}`;
    // const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(refLink)}`;

    useEffect(() => {
        getRefUsr()
    }, [])
    return (
      <div>
        <div className="row dash_box_row">
          <div className="col-lg-12">
            <div className="dash_box_shadow">
              <div className="dahboard_overall referral_overall">
                <div className="dahboard_overall_single">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle
                        opacity="0.15"
                        cx="11.75"
                        cy="12"
                        r="11.75"
                        fill="white"
                      />
                      <path
                        d="M11.816 12.2789C11.6541 12.2047 11.4776 12.1863 11.318 12.2446C11.2411 12.2709 11.1714 12.3165 11.1147 12.3757L8.84605 14.7414C8.61351 14.9839 8.64076 15.4145 8.90616 15.6912C9.17156 15.968 9.58448 15.9964 9.81702 15.7539L11.0591 14.4587L11.056 18.8629C11.9874 19.8341 12.3465 18.5356 12.3465 18.5356V14.5473L13.7705 16.0323C14.0359 16.309 14.4489 16.3374 14.6814 16.0949C14.9139 15.8525 14.8867 15.4219 14.6213 15.1451L12.032 12.4451C11.973 12.3717 11.9034 12.3228 11.816 12.2789Z"
                        fill="white"
                      />
                      <path
                        d="M5.06596 10.4029C4.99482 10.5717 4.97713 10.7558 5.03311 10.9221C5.05826 11.0024 5.10203 11.075 5.15875 11.1342L7.42741 13.4999C7.65995 13.7424 8.07287 13.7139 8.33827 13.4372C8.60367 13.1604 8.63092 12.7299 8.39838 12.4874L7.15629 11.1921L10.4331 11.1953C11.3644 10.2242 10.2498 9.84971 10.2498 9.84971H7.24125L8.66533 8.3647C8.93073 8.08794 8.95798 7.65736 8.72544 7.41487C8.4929 7.17238 8.07998 7.2008 7.81458 7.47755L5.22533 10.1776C5.15493 10.2392 5.10801 10.3117 5.06596 10.4029Z"
                        fill="white"
                      />
                      <path
                        d="M18.934 10.4029C19.0052 10.5717 19.0229 10.7558 18.9669 10.9221C18.9417 11.0024 18.898 11.075 18.8413 11.1342L16.5726 13.4999C16.3401 13.7424 15.9271 13.7139 15.6617 13.4372C15.3963 13.1604 15.3691 12.7299 15.6016 12.4874L16.8437 11.1921L13.3308 11.1953C12.3994 10.2242 13.3779 9.84971 13.3779 9.84971H16.7588L15.3347 8.3647C15.0693 8.08794 15.042 7.65736 15.2746 7.41487C15.5071 7.17238 15.92 7.2008 16.1854 7.47755L18.7747 10.1776C18.8451 10.2392 18.892 10.3117 18.934 10.4029Z"
                        fill="white"
                      />
                    </svg>
                    <h4>{t("REFERRAL_TOTAL_REFERRALS_LABEL")}</h4>
                  </div>
                  <h3>{Data && Data.totalRef}</h3>
                </div>

                <div className="dahboard_overall_single">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="28"
                      viewBox="0 0 29 28"
                      fill="none"
                    >
                      <path
                        opacity="0.15"
                        d="M20.332 9.04036C20.2154 9.04036 20.1104 9.01703 19.9937 8.97037C19.7837 8.87703 19.6087 8.7137 19.5154 8.49203C19.4687 8.38703 19.4454 8.27036 19.4454 8.1537V3.48703C19.4454 3.47536 19.457 3.46369 19.457 3.44036C17.952 2.74036 16.272 2.33203 14.4987 2.33203C8.0587 2.33203 2.83203 7.5587 2.83203 13.9987C2.83203 20.4387 8.0587 25.6654 14.4987 25.6654C20.9387 25.6654 26.1654 20.4387 26.1654 13.9987C26.1654 12.2254 25.757 10.5454 25.0454 9.0287C25.0337 9.0287 25.022 9.04036 24.9987 9.04036H20.332Z"
                        fill="white"
                      />
                      <path
                        d="M21.1618 7.24659C21.2409 7.44986 21.3795 7.61924 21.5675 7.70958C21.6565 7.75475 21.7555 7.77734 21.8544 7.77734L25.8122 7.77734C26.2178 7.77734 26.5543 7.3934 26.5543 6.9304C26.5543 6.4674 26.2178 6.08345 25.8122 6.08345L23.6453 6.08345L27.332 2.40207C27.332 0.777344 26.1654 1.55512 26.1654 1.55512L22.5965 4.88643L22.5965 2.40207C22.5965 1.93907 22.2601 1.55512 21.8544 1.55512C21.4487 1.55512 21.1123 1.93907 21.1123 2.40207L21.1123 6.9191C21.1024 7.03203 21.1222 7.13366 21.1618 7.24659Z"
                        fill="white"
                      />
                      <path
                        d="M16.5404 13.79L15.3737 13.3817V10.7917H15.467C16.062 10.7917 16.5404 11.3167 16.5404 11.9583C16.5404 12.4367 16.937 12.8333 17.4154 12.8333C17.8937 12.8333 18.2904 12.4367 18.2904 11.9583C18.2904 10.3483 17.0303 9.04167 15.467 9.04167H15.3737V8.75C15.3737 8.27167 14.977 7.875 14.4987 7.875C14.0204 7.875 13.6237 8.27167 13.6237 8.75V9.04167H13.2737C11.862 9.04167 10.707 10.2317 10.707 11.7017C10.707 13.405 11.6987 13.9533 12.457 14.2217L13.6237 14.63V17.22H13.5304C12.9354 17.22 12.457 16.695 12.457 16.0533C12.457 15.575 12.0604 15.1783 11.582 15.1783C11.1037 15.1783 10.707 15.575 10.707 16.0533C10.707 17.6633 11.967 18.97 13.5304 18.97H13.6237V19.2617C13.6237 19.74 14.0204 20.1367 14.4987 20.1367C14.977 20.1367 15.3737 19.74 15.3737 19.2617V18.97H15.7237C17.1354 18.97 18.2904 17.78 18.2904 16.31C18.2904 14.595 17.2987 14.0467 16.5404 13.79ZM13.0287 12.5533C12.632 12.4133 12.457 12.3317 12.457 11.69C12.457 11.1883 12.8304 10.78 13.2737 10.78H13.6237V12.7517L13.0287 12.5533ZM15.7237 17.2083H15.3737V15.2367L15.9687 15.4467C16.3654 15.5867 16.5404 15.6683 16.5404 16.31C16.5404 16.8 16.167 17.2083 15.7237 17.2083Z"
                        fill="white"
                      />
                    </svg>
                    <h4>{t("TOTAL_REFERRAL_INCOME_EARNED")}</h4>
                  </div>
                  <h3>$ {Data && toFixedDown(Data?.totalReward, 4)}</h3>
                </div>

                <div className="dahboard_overall_single">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        opacity="0.15"
                        d="M19.832 9.04036C19.7154 9.04036 19.6104 9.01703 19.4937 8.97037C19.2837 8.87703 19.1087 8.7137 19.0154 8.49203C18.9687 8.38703 18.9454 8.27036 18.9454 8.1537V3.48703C18.9454 3.47536 18.957 3.46369 18.957 3.44036C17.452 2.74036 15.772 2.33203 13.9987 2.33203C7.5587 2.33203 2.33203 7.5587 2.33203 13.9987C2.33203 20.4387 7.5587 25.6654 13.9987 25.6654C20.4387 25.6654 25.6654 20.4387 25.6654 13.9987C25.6654 12.2254 25.257 10.5454 24.5454 9.0287C24.5337 9.0287 24.522 9.04036 24.4987 9.04036H19.832Z"
                        fill="white"
                      />
                      <path
                        d="M26.1702 2.53075C26.0911 2.32749 25.9526 2.1581 25.7646 2.06776C25.6755 2.02259 25.5766 2 25.4776 2L21.5199 2C21.1142 2 20.7778 2.38395 20.7778 2.84694C20.7778 3.30994 21.1142 3.69389 21.5199 3.69389L23.6867 3.69389L20 7.37528C20 9 21.1667 8.22222 21.1667 8.22222L24.7355 4.89091V7.37528C24.7355 7.83827 25.0719 8.22222 25.4776 8.22222C25.8833 8.22222 26.2197 7.83827 26.2197 7.37528V2.85824C26.2296 2.74531 26.2098 2.64368 26.1702 2.53075Z"
                        fill="white"
                      />
                      <path
                        d="M16.0404 13.79L14.8737 13.3817V10.7917H14.967C15.562 10.7917 16.0404 11.3167 16.0404 11.9583C16.0404 12.4367 16.437 12.8333 16.9154 12.8333C17.3937 12.8333 17.7904 12.4367 17.7904 11.9583C17.7904 10.3483 16.5303 9.04167 14.967 9.04167H14.8737V8.75C14.8737 8.27167 14.477 7.875 13.9987 7.875C13.5204 7.875 13.1237 8.27167 13.1237 8.75V9.04167H12.7737C11.362 9.04167 10.207 10.2317 10.207 11.7017C10.207 13.405 11.1987 13.9533 11.957 14.2217L13.1237 14.63V17.22H13.0304C12.4354 17.22 11.957 16.695 11.957 16.0533C11.957 15.575 11.5604 15.1783 11.082 15.1783C10.6037 15.1783 10.207 15.575 10.207 16.0533C10.207 17.6633 11.467 18.97 13.0304 18.97H13.1237V19.2617C13.1237 19.74 13.5204 20.1367 13.9987 20.1367C14.477 20.1367 14.8737 19.74 14.8737 19.2617V18.97H15.2237C16.6354 18.97 17.7904 17.78 17.7904 16.31C17.7904 14.595 16.7987 14.0467 16.0404 13.79ZM12.5287 12.5533C12.132 12.4133 11.957 12.3317 11.957 11.69C11.957 11.1883 12.3304 10.78 12.7737 10.78H13.1237V12.7517L12.5287 12.5533ZM15.2237 17.2083H14.8737V15.2367L15.4687 15.4467C15.8654 15.5867 16.0404 15.6683 16.0404 16.31C16.0404 16.8 15.667 17.2083 15.2237 17.2083Z"
                        fill="white"
                      />
                    </svg>
                    <h4>{t("TOTAL_REFERRAL_USERS_INVEST")}</h4>
                  </div>
                  <h3>{Data && Data?.usrInvests}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row dash_box_row">
          <div className="col-xl-4">
            <div className="chart_box">
              <div className="inner_subtitle">
                <h2>{t("REFERRAL_LINK")}</h2>
              </div>
              <div className="dash_box_shadow">
                {refLink && (
                  <div className="referral_link_grp">
                    <input
                      className="form-control"
                      type="text"
                      value={refLink}
                      disabled
                    />
                    <img
                      src={require("../../assets/images/copy_icon.png")}
                      alt="Copy"
                      className="img-fluid"
                      onClick={() => textCopy(refLink)}
                    />
                  </div>
                )}
                <ul className="referral_share_links d-flex justify-content-center">
                  {/* referral_share_links */}
                  {facebookUrl && (
                    <li>
                      <a href={facebookUrl} target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                  )}
                  {twitterUrl && (
                    <li>
                      <a href={twitterUrl} target="_blank">
                        <i className="fa-brands fa-x-twitter"></i>
                      </a>
                    </li>
                  )}
                  {telegramUrl && (
                    <li>
                      <a href={telegramUrl} target="_blank">
                        <i className="fa-solid fa-paper-plane"></i>
                      </a>
                    </li>
                  )}
                  {/* {linkedinUrl && <li><a href={linkedinUrl} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></li>}
                                {whatsappUrl && <li><a href={whatsappUrl} target="_blank"><i className="fa-brands fa-whatsapp"></i></a></li>} */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="chart_box">
              <div className="inner_subtitle">
                <h2>{t("REFERRAL_LEVEL_INCOME")}</h2>
              </div>
              <div className="dash_box_shadow">
                <div className="dahboard_overall">
                  <div className="dahboard_overall_single">
                    <div>
                      <h4>{t("REFERRAL_LEVEL_1_INCOME")}</h4>
                    </div>
                    <h3>$ {Data && toFixedDown(Data.level1, 4)}</h3>
                  </div>

                  <div className="dahboard_overall_single">
                    <div>
                      <h4>{t("REFERRAL_LEVEL_2_INCOME")}</h4>
                    </div>
                    <h3>$ {Data && toFixedDown(Data.level2, 4)}</h3>
                  </div>

                  <div className="dahboard_overall_single">
                    <div>
                      <h4>{t("REFERRAL_LEVEL_3_INCOME")}</h4>
                    </div>
                    <h3>$ {Data && toFixedDown(Data.level3, 4)}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReferralHistory />
      </div>
    );
};