// import package
import moment from 'moment';

// import lib
import isEmpty from './isEmpty';

export const dateTimeFormat = (dateTime, format = 'YYYY-MM-DD HH:mm') => {
    try {
        if (!isEmpty(dateTime)) {
            let newDateTime = new Date(dateTime);
            if (format.includes('YYYY')) {
                format = format.replace('YYYY', newDateTime.getFullYear())
            }

            if (format.includes('MM')) {
                let month = newDateTime.getMonth() + 1;
                month = month > 9 ? month : `0${month}`
                format = format.replace('MM', month)
            }

            if (format.includes('DD')) {
                let date = newDateTime.getDate();
                date = date > 9 ? date : `0${date}`
                format = format.replace('DD', date)
            }

            if (format.includes('HH')) {
                let hour = newDateTime.getHours();
                hour = hour > 9 ? hour : `0${hour}`
                format = format.replace('HH', hour)
            }

            if (format.includes('mm')) {
                let minute = newDateTime.getMinutes();
                minute = minute > 9 ? minute : `0${minute}`
                format = format.replace('mm', minute)
            }

            return format

        } else {
            return 'fff'
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        return 'ggg'
    }
}

export const momentFormat = (dateTime, format = 'YYYY-MM-DD HH:mm') => {
    try {
        if (!isEmpty(dateTime)) {
            let newDateTime = new Date(dateTime);
            return moment(newDateTime).format(format)
        }
        return ''
    } catch (err) {
        return ''
    }
}

export const splitTimeMins = (time) => {
    try {
        let splitVal = time.split(':')
        return {
            time: splitVal[0],
            mins: splitVal[1],
            type: splitVal[0] >= 12 ? 'PM' : 'AM'
        }
    } catch (err) {
        console.log(err, '---err')
        return {
            time: '00',
            mins: '00'
        }
    }
}

export const setUTCTime = (res, hours, minutes) => {
    let now = new Date(res);
    // Set the desired hours and minutes
    now.setUTCHours(hours);
    now.setUTCMinutes(minutes);
    now.setUTCSeconds(0);
    if (!(now > new Date())) {
        let new1 = new Date(res).getDate() + 1;
        now.setUTCDate(new1);
    }
    return now.toUTCString();  // or now.toISOString() for a different format
}

export const utcToTimestamp = (utcTime) => {
    const date = new Date(utcTime);
    return date.getTime();
}
export const convertDaysToMonth = (day = 30) => {
    let month = 1

}

export const fillMissingMonths = (input) => {
    const { label, data } = input;

    if (!label || !data || label.length !== data.length) {
        throw new Error('Invalid input format');
    }

    const result = {
        label: [],
        data: [],
    };

    const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Extract the month and year from the input label
    const [inputMonth, inputYear] = label[0].split(' ');

    for (let i = 0; i < 12; i++) {
        const month = monthLabels[(monthLabels.indexOf(inputMonth) + i) % 12];
        const year = (inputMonth === 'Dec' && i > 0) ? parseInt(inputYear) + 1 : parseInt(inputYear);

        const newLabel = `${month} ${year}`;
        const dataIndex = label.indexOf(newLabel);

        result.label.push(newLabel);
        result.data.push(dataIndex !== -1 ? data[dataIndex] : 0);
    }

    return result;
}