import React, { useEffect, useState } from "react";
// AOS
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
// Slider
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ReactApexChart from "react-apexcharts";
import isEmpty from "../../lib/isEmpty";
import { roundToNearestPointFive, toFixedDown } from "../../lib/roundOf";
import { encryptString } from "../../lib/cryptoJS";
import { Link } from "react-router-dom";
import store from "../../store";
import { findPerc, isLeapYear } from "../../lib/calculation";
let initValue = {
    amount: '100',
    days: '',
    apy: '10',
    interest: '0',
    totalAmount: '0',
    intPercent: '0',
    totalPercent: '0'
}
export const Calculator = (props) => {
const { t } = useTranslation();

    const { investmentDoc } = props;

    const { isAuth } = store.getState().auth;

    // const [sliderValueAPY, setSliderValueAPY] = useState(20);
    const [ActiveStrategy, setActiveStrategy] = useState('');
    const [formValue, setFromvalue] = useState(initValue);
    const { amount, days, apy, interest, totalAmount, intPercent, totalPercent } = formValue;

    const handleSliderChangeAPY = (value) => {
        if (value <= 0) return
        setFromvalue({ ...formValue, ...{ 'apy': value } })
    };

    const handleSelect = (e) => {
        e.preventDefault();
        let { value } = e.target;

        let totalDays = 0;
        Array.from({ length: parseInt(value, 10) }, (_, index) => {
            let currentYear = new Date().getFullYear();
            totalDays += new Date(currentYear, index + 1, 0).getDate();
        });

        setFromvalue({ ...formValue, ...{ ['days']: totalDays } })
    };

    const handleActiveStrategy = (e) => {
        e.preventDefault();
        let { value } = e.target;
        if (value) {
            if (value == '') return
            let data = investmentDoc && investmentDoc.find((el) => el._id.toString() == value.toString())
            setActiveStrategy(data)
            setFromvalue({ ...formValue, ...{ ['apy']: data.staticroi, ['amount']: data.minimumAmount } })
        } else {
            let value = investmentDoc[0]
            setActiveStrategy(value)
            setFromvalue({ ...formValue, ...{ ['apy']: value.staticroi, ['amount']: value.minimumAmount } })
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target
        if (value < 0) return
        if (name == 'apy') {
            if (value > 100) return
        }
        if (value <= 0) return setFromvalue({ ...formValue, ...{ [name]: '' } })
        setFromvalue({ ...formValue, ...{ [name]: value } })
    }

    // Custom styles for the track and handle
    const trackStyle = { backgroundColor: "#23B2F4", height: 4 };
    const handleStyle = {
        borderColor: "#C6EDFF",
        borderWidth: 6,
        height: 24,
        width: 24,
        marginLeft: 0,
        marginTop: -10,
        backgroundColor: "#23B2F4",
    };

    // ROI Chart
    const chartData = {
        series: [intPercent, totalPercent],
        labels: ["Interest", "Future"],
    };

    const chartOptions = {
        chart: {
            type: "donut",
        },
        labels: chartData.labels,
        stroke: {
            width: "1",
        },
        legend: {
            fontSize: "18px",
            fontFamily: "Craft Gothic",
            labels: {
                colors: "#314259",
                useSeriesColors: false,
            },
            markers: {
                width: 14,
                height: 14,
                radius: 0,
                offsetX: -3,
                fillColors: ["#23B2F4", "#314259"],
            },
            position: "bottom",
            itemMargin: {
                horizontal: 50,
                vertical: 20,
            },
        },
        fill: {
            colors: ["#23B2F4", "#314259"],
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        responsive: [
            {
                breakpoint: 575,
                options: {
                    chart: {
                        width: 400,
                    },
                    legend: {
                        show: true
                    }
                },
            },
            {
                breakpoint: 380,
                options: {
                    chart: {
                        width: 356,
                    },
                    legend: {
                        show: true
                    }
                },
            },
        ],
    };

    useEffect(() => {
        loadScript();
        AOS.init();
    }, []);

    function loadScript() {
        // Accordian Add class
        const accordionItems = document.querySelectorAll(
            ".custom_accordian .accordion-item"
        );
        accordionItems.forEach((item, index) => {
            item.addEventListener("click", () => {
                accordionItems.forEach((otherItem, otherIndex) => {
                    if (index !== otherIndex) {
                        otherItem.classList.remove("active-accordion");
                    }
                });
                item.classList.add("active-accordion");
            });
        });
    }

    useEffect(() => {
        if (!isEmpty(amount) && !isEmpty(apy) && !isEmpty(days)) {
            let NoOfDay = isLeapYear(new Date().getFullYear()) ? 366 : 365;
            let interestPerDay = ((amount * (apy / 100)) / NoOfDay)
            let totalInterest = days * interestPerDay;
            let total = parseFloat(totalInterest) + parseFloat(amount)
            let interestPercent = findPerc(toFixedDown(totalInterest, 0), total)
            let totalPercent = 100 - findPerc(toFixedDown(totalInterest, 0), total)
            return setFromvalue({ ...formValue, ...{ ['interest']: toFixedDown(totalInterest, 0), ['totalAmount']: toFixedDown(total, 0), ['intPercent']: roundToNearestPointFive(interestPercent), ['totalPercent']: roundToNearestPointFive(totalPercent) } })
        } else {
            return setFromvalue({ ...formValue, ...{ ['interest']: 0, ['totalAmount']: 0, ['intPercent']: 0, ['totalPercent']: 0 } })
        }
    }, [amount, days, apy]);

    useEffect(() => {
        let value = investmentDoc && investmentDoc[0];
        let now = new Date();
        let days = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        if (value) {
            setActiveStrategy(value)
            setFromvalue({ ...formValue, ...{ ['apy']: value.staticroi, ['amount']: toFixedDown(value.minimumAmount, 0), ['days']: days } })
        }
    }, [])

    return (
      <section className="section">
        <div className="container">
          <div
            className="roi_calc_sec inner_bg_sec"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <div className="row">
              <div className="col-md-12">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {t("ROI_CALCULATOR_MAIN_TITLE")}
                </h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="roi_calc_box">
                  <div className="form-group">
                    <div className="roi_flex">
                      <label>{t("ROI_CALCULATOR_INVESTMENTS_LABEL")}</label>
                      <select
                        className="form-select"
                        onClick={handleActiveStrategy}
                      >
                        {investmentDoc &&
                          investmentDoc.length > 0 &&
                          investmentDoc.map((el) => {
                            return (
                              <option value={el._id}>{el.strategy}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="roi_flex">
                      <label>
                        {t("ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL")}
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          value={amount}
                          name="amount"
                          onChange={handleChange}
                        />
                        <span className="input-group-text" id="basic-addon2">
                          USD
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="roi_flex">
                      <label>{t("ROI_CALCULATOR_APY_LABEL")}</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          value={apy}
                          name="apy"
                          onChange={handleChange}
                          disabled
                        />
                        <span className="input-group-text" id="basic-addon2">
                          %
                        </span>
                      </div>
                    </div>
                    {/* <Slider
                                  min={0}
                                  max={100}
                                  value={apy}
                                  step={1}
                                  onChange={handleSliderChangeAPY}
                                  trackStyle={trackStyle}
                                  handleStyle={handleStyle}
                                  railStyle={{ backgroundColor: "#E8E8E8", height: 4 }}
                              /> */}
                  </div>

                  <div className="form-group">
                    <div className="roi_flex">
                      <label>{t("ROI_CALCULATOR_MONTHS_LABEL")}</label>
                      <select className="form-select" onClick={handleSelect}>
                        <option value="1">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_1")}
                        </option>
                        <option value="3">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_3")}
                        </option>
                        <option value="6">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_6")}
                        </option>
                        <option value="9">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_9")}
                        </option>
                        <option value="12">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_12")}
                        </option>
                        <option value="24">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_24")}
                        </option>
                        <option value="48">
                          {" "}
                          {t("ROI_CALCULATOR_SELECT_MONTH_OPTION_48")}
                        </option>
                      </select>
                    </div>
                  </div>
                  {isAuth ? (
                    <Link
                      to={`/investment-details/${encryptString(
                        ActiveStrategy._id,
                        true
                      )}`}
                      className="secondary_btn"
                    >
                      {t("ROI_CALCULATOR_INVEST_BUTTON")}
                    </Link>
                  ) : (
                    <Link to={`/register`} className="secondary_btn">
                      {t("ROI_CALCULATOR_INVEST_BUTTON")}
                    </Link>
                  )}
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="roi_calc_box roi_chart_box">
                  <ReactApexChart
                    options={chartOptions}
                    series={chartData.series}
                    type="donut"
                    width={400}
                    className="donut_chart"
                  />
                  <div className="roi_calc_value">
                    <div>
                      <label>{t("ROI_CALCULATOR_INTEREST_LABEL")}</label>
                      <label>$ {formValue.interest}</label>
                    </div>
                    <div>
                      <label>{t("ROI_CALCULATOR_FUTURE_VALUE_LABEL")}</label>
                      <label>$ {formValue.totalAmount}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}