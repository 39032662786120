import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { getUsrSettleList } from "../../api/invest";
import { decryptString } from "../../lib/cryptoJS";
import { toFixedDown } from "../../lib/roundOf";
import { IncCntObjId } from "../../lib/generalFun";
import { Link } from "react-router-dom";
import { toStaringCase } from "../../lib/stringCase";
import { toastAlert } from "../../lib/toastAlert";
import { useTranslation } from "react-i18next";
// Investment History Table Data

const customStyles = {
  headCells: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
      fontWeight: "600", // Change font size
    },
  },
  cells: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
    },
  },
  pagination: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
    },
  },
};

const SettleHistory = (props) => {
  const { Id } = props;
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        name: "Investment Id",
        selector: (row) => row._id,
        cell: (record) => {
          return (
            <div>
              <p>
                {`ID ${IncCntObjId(record._id)}`}{" "}
                <i
                  class="fas fa-copy"
                  onClick={() => handleCopy(record._id)}
                ></i>
              </p>
            </div>
          );
        },
      },
      {
        name: "Invest amount",
        selector: (row) => row.amount,
        cell: (record) => `${toFixedDown(record.amount, 4)} ${record.coin}`,
      },
      {
        name: "Interest",
        selector: (row) => row.settleAmount,
        cell: (record) =>
          `${toFixedDown(record.settleAmount, 2)} ${record.coin}`,
      },

      {
        name: "No.Of Days",
        selector: (row) => row.days,
        cell: (record) => `${record.days > 0 ? record.days : "-"}`,
      },
      {
        name: "Category",
        selector: (row) =>
          `${row?.category ? toStaringCase(row.category) : "-"}`,
      },
      {
        name: "Created At",
        selector: (row) => row.createdAt,
        cell: (record) => `${dateTimeFormat(record.createdAt)}`,
        width: "20%",
      },
    ],
    []
  );
  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ page: 1, limit: 10 });

  const { page, limit } = pageDoc;
  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);
    toastAlert("success", t("SUCCESS_COPY"), "withdraw");
  };
  const getRefUsrHistory = async () => {
    try {
      let decrypt = decryptString(Id, true);
      let data = { stakeId: decrypt, page: page, limit: limit };
      const { status, result } = await getUsrSettleList(data);

      if (status === "success") {
        setData(result.data);
        setCount(result.count);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getRefUsrHistory();
  }, [page, limit]);
  console.log(data, "---89080990090990data");
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="chart_box">
            <div className="inner_subtitle title_flex">
              <h2>{t("INTEREST_HISTORY")}</h2>
              <Link to="/history" className="history_link">
                {t("SEE_MORE")}
              </Link>
            </div>
            <div className="table-responsive primary_table_div">
              <DataTable
                className="table primary_table"
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={count}
                onChangePage={(page) =>
                  setPageDoc({ ...pageDoc, ...{ page: page } })
                }
                onChangeRowsPerPage={(limit) =>
                  setPageDoc({ ...pageDoc, ...{ limit: limit } })
                }
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettleHistory;
