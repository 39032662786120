// import constant
import { SET_STACKING_DATA, RESET_ALL } from "../constant";

const initialValue = [];

const stackingData = (state = initialValue, action) => {
  switch (action.type) {
    case SET_STACKING_DATA:
      return [...action.data];
    case RESET_ALL: {
      return initialValue
    }
    default:
      return state;
  }
};

export default stackingData;
