import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

// api
import { forgotPassword, resetPassword } from "../../api/users";
import { useTranslation } from "react-i18next";

// lib
import { toastAlert } from "../../lib/toastAlert";
const initialFormValue = {
  email: "",
};
const ResetPassword = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  let authToken = id
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const { password, confirmPassword } = formValue;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const handleSubmit = async (e) => {
    try {
      const { status, message, errors } = await resetPassword({ authToken, password, confirmPassword });
      if (status == 'success') {
        setFormValue(initialFormValue)
        toastAlert('success', message, 'signup', 'TOP_CENTER');
        navigate('/login')
      } else {
        if (errors) {
          const translatedErrors = {};
          Object.keys(errors).forEach((key) => {
            translatedErrors[key] = t(`${errors[key]}`);
          });
          setErrors(translatedErrors);
          return false;
        }
        toastAlert('error', message, 'signup', 'TOP_CENTER');
      }
    } catch (err) { }
  }
  return (
    <div
      className="col-lg-6"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
      <div className="auth_box">
        <h2>{t("RESET_PASSWORD_TITLE")}</h2>
        <form className="auth_form">
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              {t("RESET_PASSWORD_NEW_LABEL")}
            </label>
            <div className="input-group">
              <Link
                className="input-group-text primary_inp"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={`bi bi-eye-slash ${showPassword ? "d-none" : ""}`}
                ></i>
                <i className={`bi bi-eye ${showPassword ? "" : "d-none"}`}></i>
              </Link>
              <input
                id="password"
                className="form-control primary_inp"
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                placeholder={t("RESET_PASSWORD_PASSWORD_PLACEHOLDER")}
                value={password}
                onChange={handleChange}
              />
            </div>
            <span className="text-danger">{errors?.password}</span>
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              {t("RESET_PASSWORD_CONFIRM_LABEL")}
            </label>
            <div className="input-group">
              <Link
                className="input-group-text primary_inp"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <i
                  className={`bi bi-eye-slash ${
                    showConfirmPassword ? "d-none" : ""
                  }`}
                ></i>
                <i
                  className={`bi bi-eye ${showConfirmPassword ? "" : "d-none"}`}
                ></i>
              </Link>
              <input
                id="confirmPassword"
                className="form-control primary_inp"
                type={`${showConfirmPassword ? "text" : "password"}`}
                name="confirmPassword"
                placeholder={t("RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER")}
                value={confirmPassword}
                onChange={handleChange}
              />
            </div>
            <span className="text-danger">{errors?.confirmPassword}</span>
          </div>
          <div className="text-center mt-4 mb-4">
            <button
              type="button"
              className="w-100 secondary_btn primary_btn_with_bg primary_btn_transform"
              onClick={handleSubmit}
            >
              {t("RESET_PASSWORD_SUBMIT_BUTTON")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
