import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { Link } from "react-router-dom";
import { getServerTime } from "../api/users.js";
import { useDispatch, useSelector } from "react-redux";

import { encryptObject, encryptString } from "../lib/cryptoJS.js";
import { OrderPlaceLocked, getStackingData } from "../api/invest.js";
import { getUserSettings } from "../api/walletAction.js";
import isEmpty from "../lib/isEmpty.js";
import { useTranslation } from "react-i18next";

import OrderModal from "../components/Investment/orderModal.js";
import { toastAlert } from "../lib/toastAlert.js";
import ResponseModal from "../components/ResponseModal.js";
import { getPlanSettings, getUserPlanSettings } from "../api/dashboard.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const intialFormValue = { amount: "", type: "fixed" };
export default function Invest() {
  const investmentDoc = useSelector((state) => state.stackingData);
  const userSettings = useSelector((state) => state.userSettings);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  //state
  const [CurDate, setCurDate] = useState();

  //Modal
  const [visible, setVisible] = useState(false);
  const [StakingData, setStakingData] = useState({});
  const [errs, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  //response modal
  const [response, setResponse] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [planSetting, setPlanSetting] = useState([]);

  const [formValue, setFormValue] = useState(intialFormValue);
  const { amount, type } = formValue;

  const fetchData = async () => {
    let { status, result } = await getServerTime();
    if (status == "success") {
      setCurDate(result);
    }
  };

  const fetchInvestData = async () => {
    await getStackingData(dispatch);
    await getUserSettings(dispatch);
    let { status, result } = await getServerTime();
    if (status == "success") {
      setCurDate(result);
    }
  };

  const findMonths = (monthlist, CurDate) => {
    let nowmonth = new Date(CurDate).getMonth();
    let month = monthlist.find((el) => el.month == nowmonth);
    return month;
  };

  //Function
  const SetRecord = (item) => {
    setVisible(true);
    setStakingData(item);
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      if (Number(amount) === 0) {
        return setErrors({ amount: t("AMOUNT_ZERO") });
      } else if (amount < 0) {
        return setErrors({ amount: t("AMOUNT_NEGATIVE") });
      } else if (StakingData.minimumAmount > amount) {
        return setErrors({
          amount: t("AMOUNT_EXCEEDS_STAKED").replace(
            "{{stakedAmount}}",
            StakingData.minimumAmount
          ),
        });
      } /* else if (StakingData.maximumAmount < amount) {
        return setErrors({ amount: `Amount must be less than or equal to ${StakingData.maximumAmount}` })
      } */

      setLoader(true);
      const reqData = {
        stakeId: StakingData._id,
        price: amount,
        duration_days: StakingData.period,
        currencyId: StakingData.currencyId,
        type,
      };
      console.log("req body is ", reqData);
      let encryptObj = encryptObject(reqData);
      const { status, message } = await OrderPlaceLocked({ token: encryptObj });
      const msg = t(message);
      console.log("Msg", msg);
      if (status == "success") {
        setLoader(false);
        handlecloseAssetModal();
        setFormValue(intialFormValue);
        // toastAlert("success", message, "signin", "TOP_CENTER");
        refetch();
        setResponseData({ message: msg, type: "Investment Order" });
        setResponse(true);
      } else {
        setLoader(false);
        // toastAlert("error", message, "signin", "TOP_CENTER");
        setResponseData({ message: msg, type: "Investment Order" });
        setResponse(true);
      }
    } catch (error) {
      console.log(error, "-------------error");
    }
  };
  const handlecloseAssetModal = () => {
    setFormValue(intialFormValue);
    setStakingData({});
    setVisible(false);
  };

  const handlecloseResponseModal = () => {
    setResponse(false);
    setResponseData({});
  };

  //function
  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (value) setErrors({});
  };

  const refetch = async () => {
    fetchData();
    fetchInvestData();
  };

  const fetchPlanSettings = async () => {
    try {
      const { status, result } = await getPlanSettings();

      if (status === "success") {
        const { status: userPlanStatus, result: userPlanSettingResult } =
          await getUserPlanSettings();
        if (userPlanStatus === "success") {
          // userPlanSettingResult
          const index = result.findIndex(
            (item) => item.strategy == "Custom Solution"
          );
          result[index] = userPlanSettingResult;
        }
        const updatedSettings = result.map((setting) => {
          const investment = investmentDoc.find(
            (doc) => doc._id === setting._id
          );
          return { ...investment, ...setting };
        });
        setPlanSetting(updatedSettings);
      }
    } catch (error) {
      console.log("Error fetching plan settings:", error);
    }
  };
  console.log("responseData", responseData);

  useEffect(() => {
    fetchData();
    fetchInvestData();
    fetchPlanSettings();
  }, []);
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <OrderModal
        visible={visible}
        record={StakingData}
        onHide={handlecloseAssetModal}
        handleChange={handleChange}
        formValue={formValue}
        submitHandler={submitHandler}
        loader={loader}
        error={errs}
      />

      <ResponseModal
        show={response}
        record={responseData}
        onHide={handlecloseResponseModal}
      />

      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="inner_head_title">{t("INVEST_TITLE")}</h2>
            </div>
          </div>
          <div className="row dash_box_row">
            <div className="col-lg-12">
              {planSetting &&
                planSetting.length > 0 &&
                planSetting.map((item, key) => {
                  return (
                    <div className="invest_panel" key={key}>
                      <div className="invest_panel_wrapper">
                        <div className="invest_panel_left">
                          <div className="table-responsive">
                            <table class="table portfolio_table">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {t("INVEST_TABLE_HEADER_ASSETS")}
                                  </th>
                                  <th scope="col">
                                    {t("INVEST_TABLE_HEADER_WEIGHTS")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    {t("INVEST_ALLOCATION_BTC")}
                                  </th>
                                  <td>{item.planSetting.btc} %</td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {t("INVEST_ALLOCATION_ETH")}
                                  </th>
                                  <td>{item.planSetting.eth} %</td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {t("INVEST_ALLOCATION_MINING_POOL")}
                                  </th>
                                  <td>{item.planSetting.btcmining} %</td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {t("INVEST_ALLOCATION_MANTIS_STRATEGIES")}
                                  </th>
                                  <td>{item.planSetting.mantisStrategies} %</td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {t("INVEST_ALLOCATION_TOTAL")}
                                  </th>
                                  <td>100.00%</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="invest_panel_content">
                          <h1>
                            {item.strategy == "Balanced"
                              ? t("BALANCED_TITLE")
                              : item.strategy == "High Yield"
                              ? t("HIGH_YIELD_TITLE")
                              : item.strategy === "Custom Solution"
                              ? t("CUSTOM_SOLUTION")
                              : t("GROWTH_TITLE")}
                          </h1>
                          <div className="invest_panel_details">
                            <div>
                              <label>{t("INVEST_MINIMUM_INVESTMENT")}</label>

                              <h2>
                                {" "}
                                $ {item.minimumAmount}{" "}
                                {/* - {item.maximumAmount} */}
                              </h2>
                            </div>
                            <div>
                              <label>{t("INVEST_PROJECTED_ROI")}</label>

                              <h2>{item && item.staticroi}%</h2>
                            </div>

                            {/* <Link
                              to={`/investment-details/${encryptString(
                                item._id,
                                true
                              )}`}
                              className="secondary_btn"
                            >
                              Details
                            </Link> */}
                            <div className="flex_btns">
                              <Link
                                to={`/investment-details/${encryptString(
                                  item._id,
                                  true
                                )}`}
                                className="secondary_btn"
                              >
                                {t("INVEST_DETAILS")}
                              </Link>

                              <button
                                className="secondary_btn w-auto"
                                onClick={() => SetRecord(item)}
                              >
                                {t("INVEST_INVEST_BUTTON")}
                              </button>
                            </div>
                          </div>
                          {item.strategy === "Custom Solution" && (
                            <div className="mt-5">
                              <p>
                                Tailored according to your investment , risk
                                appetite market conditions sentiments and our
                                expertise
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
