import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { getUsrClaimList } from '../../api/invest';
import { decryptString } from '../../lib/cryptoJS';
import { toFixedDown } from '../../lib/roundOf';
import { IncCntObjId } from '../../lib/generalFun';
import { capitalize } from '../../lib/stringCase';
import { Link } from 'react-router-dom';

// Investment History Table Data
const columns = [
    {
        name: "Investment Id",
        selector: (row) => row.orderId,
        // cell: (record) => `ID ${IncCntObjId(record.orderId)}`
        cell: (record) => {
            if (record?.orderId != '' && record?.orderId != null) {
                return `ID ${IncCntObjId(record?.orderId)}`
            } else {
                return `-`
            }
        }
    },
    {
        name: "Redem Amount",
        selector: (row) => row.amount,
        cell: (record) => {
            if (record?.amount != '' && record?.amount != null) {
                return `${toFixedDown(record.amount, 4)} ${record.currencySymbol}`
            } else {
                return `-`
            }
        }
    },
    {
        name: "Currency",
        selector: (row) => row.currencySymbol,
    },
    {
        name: "Status",
        selector: (row) => row.status,
        cell: (record) => {
            if (record && record.status == 'rejected') {
                return `${capitalize(record.status)} ${record.reason}`
            } else {
                return `${capitalize(record.status)}`
            }
        }
    },
    {
        name: "Created At",
        selector: (row) => row.createdAt,
        cell: (record) => `${dateTimeFormat(record.createdAt)}`
    }
];

const customStyles = {
    headCells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
            fontWeight: '600', // Change font size
        },
    },
    cells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
    pagination: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
};

const ClaimHistory = (props) => {
    const { Id } = props;
    // state
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [pageDoc, setPageDoc] = useState({ 'page': 1, 'limit': 10 })

    const { page, limit } = pageDoc;

    const getRefUsrHistory = async () => {
        try {
            let decrypt = decryptString(Id, true);
            let data = { stakeId: decrypt, page: page, limit: limit }
            const { status, result } = await getUsrClaimList(data);

            if (status === 'success') {
                setData(result.data)
                setCount(result.count)
            }
        } catch (err) { }
    }

    useEffect(() => {
        getRefUsrHistory()
    }, [page, limit]);

    return (
        <div className='mt-3'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="chart_box">
                        <div className="inner_subtitle title_flex">
                            <h2>Redeem History</h2>
                            <Link to="/history" className="history_link">See more</Link>
                        </div>
                        <div className="table-responsive primary_table_div">
                            <DataTable
                                className="table primary_table"
                                columns={columns}
                                data={data}
                                pagination
                                paginationServer
                                paginationTotalRows={count}
                                onChangePage={(page) => setPageDoc({ ...pageDoc, ... { 'page': page } })}
                                onChangeRowsPerPage={(limit) => setPageDoc({ ...pageDoc, ... { 'limit': limit } })}
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ClaimHistory;