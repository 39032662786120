import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../lib/isEmpty";

import QRCode from 'qrcode.react';
// import { getCreateAssetData } from "../../api/walletAction";
import { textCopy/* , toastAlert */ } from "../../lib/toastAlert";
import { upperCase } from "../../lib/stringCase";
import { useTranslation } from "react-i18next";
import { createAddressNew } from "../../api/walletAction";
import { toastAlert } from "../../lib/toastAlert";

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const imageStyle = {
  maxWidth: '100%',
  height: 'auto',
  transition: 'filter 0.5s ease',
  filter: 'blur(5px)',
};


const Deposit = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [tokens, setTokens] = useState([]);
  const [depValues, setDepValues] = useState({ symbol: "", type: "" });
  const [address, setAddress] = useState("");
  const { currencies } = useSelector((state) => state);
  const { wallet } = useSelector((state) => state);
  const [currency, setCurrency] = useState([]);

  const [CurrencyData, setCurrencyData] = useState({});

  const [loader, setloader] = useState(false)
  const [assetId, setAssetId] = useState('')

  const setCurrencyList = () => {
    let curList = [];
    if (!isEmpty(currencies) && currencies.length > 0) {
      for (let i = 0; i < currencies.length; i++) {
        currencies[i].status === "active" &&
          !["USD", "ETH", "BTC"].includes(currencies[i].currencySymbol) &&
          curList.push({
            type: currencies[i].type,
            symbol: currencies[i].currencySymbol,
          });
      }

      if (curList.length > 0) {
        let uniqueArray = Array.from(
          new Set(curList.map((obj) => obj.symbol))
        ).map((id) => curList.find((obj) => obj.symbol === id));
        curList.length > 0 && setCurrency(uniqueArray);
      }
    }
  };

  const setTokenType = (symbol) => {
    setAddress("");
    let arr = [];
    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i].currencySymbol === symbol) {
        arr.push(currencies[i].tokenType);
      }
    }
    setTokens(arr);
    arr.length > 0 && arr[0] !== ""
      ? setDepValues((prev) => ({
          ...prev,
          type: "token",
          tokenType: arr[0],
        }))
      : setDepValues((prev) => ({
          ...prev,
          type: "crypto",
        }));

    // console.log(arr[0], "1qaaaaaaaaaaaaaaaaaaaaaa", depValues)
    if (arr.length > 0 && arr[0] !== "") {
      let currency =
        currencies &&
        currencies.length > 0 &&
        currencies.find((el) => el.tokenType == arr[0]);
      setCurrencyData(currency);
    }
  };

  const depositDetails = () => {
    setAddress("");
    let det =
      depValues &&
      wallet &&
      wallet.length > 0 &&
      wallet.find((item) => item.coin === depValues.symbol);


    if (det) {
      console.log(det, "-detdetdet")
      setAssetId(det._id)
    }

    if (depValues.type == "token") {
      let tokenDet = det.tokenAddressArray.find(
        (item) => item && depValues && item.tokenType === depValues.tokenType
      );
      !isEmpty(tokenDet) && setAddress(tokenDet.address);

      if (tokenDet && tokenDet.currencyId) {
        let currency =
          currencies &&
          currencies.length > 0 &&
          currencies.find(
            (el) => el._id.toString() == tokenDet.currencyId.toString()
          );
        setCurrencyData(currency);
      }
    } else {
      !isEmpty(det) && setAddress(det.address);
    }
  };

  useEffect(() => {
    depositDetails();
  }, [depValues]);

  useEffect(() => {
    setCurrencyList();
  }, []);

  // const handleCreate = async (e) => {
  //   e.preventDefault();
  //   console.log(depValues, "111111")

  //   let reqData = depValues;
  //   let { status, loading, message } = await getCreateAssetData(reqData, dispatch);
  //   if (status == 'success') {
  //     setloader(loading)
  //     toastAlert("success", message, "signin", "TOP_CENTER");
  //   } else {
  //     setloader(loading)
  //     toastAlert("error", message, "signin", "TOP_CENTER");
  //   }
  // }

  // console.log(depValues, "-=90009090090676767")
  // console.log(currencies, "-=currencies2342")
  // console.log(CurrencyData, "-=CurrencyDataCurrencyData23423")

  const handleCreate = async (e) => {
    e.preventDefault();
    console.log(depValues, "111111", CurrencyData, assetId)
    setloader(true)
    let reqData = { depValues, CurrencyData, assetId };
    let { status, loading, message } = await createAddressNew(reqData, dispatch);
    if (status == 'success') {
      setloader(loading)
      toastAlert("success", message, "signin", "TOP_CENTER");
      window.location.reload(false);
    } else {
      setloader(loading)
      toastAlert("error", message, "signin", "TOP_CENTER");
    }
  }

  const handleSet = (e) => {
    console.log(e.target, "----tata")
    // setDepValues((prev) => ({
    //   ...prev,
    //   symbol: e.target.value,
    // }));
    // setTokenType(e.target.value);
  }

  return (
    <>
      <div className="col-xl-6">
        <div className="chart_box">
          <div className="inner_subtitle">
            <h2>{t("DEPOSIT_TITLE")}</h2>
          </div>
          <div className="deposit_form">
            <form className="primary_form">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{t("DEPOSIT_SELECT_COIN")}</label>
                    <select
                      className="form-select primary_inp"
                      onChange={(e) => {
                        setDepValues((prev) => ({
                          ...prev,
                          symbol: e.target.value,
                        }));
                        setTokenType(e.target.value);
                      }}
                    >
                      <option>{t("DEPOSIT_SELECT_COIN")}</option>
                      {!isEmpty(currency) &&
                        currency.length > 0 &&
                        currency.map((item) => {
                          if (item.symbol === "USDT")
                            return (
                              <option key={item.symbol}>{item.symbol}</option>
                            );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  {tokens.length > 0 && tokens[0] !== "" && (
                    <div className="form-group">
                      <label>{t("DEPOSIT_SELECT_NETWORK")}</label>
                      <select
                        className="form-select primary_inp"
                        onChange={(e) => {
                          setDepValues((prev) => ({
                            ...prev,
                            tokenType: e.target.value,
                          }));
                        }}
                      >
                        {!isEmpty(tokens) &&
                          tokens.length > 0 &&
                          tokens.map((item) => (
                            <option key={item} value={item}>
                              {upperCase(item)}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>

                  {!isEmpty(address) &&
                    < div className="row g-3">
                      <div className="col-md">
                        <div className="form-group">
                    <label>{t("DEPOSIT_ADDRESS_LABEL")}</label>

                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control primary_inp"
                              value={address}
                            />
                            <span className="input-group-text">
                              <img
                                src={require("../../assets/images/copy_icon.png")}
                                alt={t("DEPOSIT_COPY_ICON_ALT")}
                                className="img-fluid"
                                onClick={() => textCopy(address)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>}
            </form>
            {!address && (
              <div style={containerStyle}>
                <img
                  alt={t("DEPOSIT_QR_IMAGE_ALT")}
                  style={imageStyle}
                  src={require("../../assets/images/qr.png")}
                  className="img-fluid"
                />
              </div>
            )}
            {address && (
              <div className="qr_box">
                <QRCode value={address} />
              </div>
            )}
          </div>
          {!isEmpty(CurrencyData) && (
            <div className="text-light">
              <h5>{t("DEPOSIT_DEPOSIT_NOTES")}</h5>
              <ul>
                <li>
                  {t("DEPOSIT_SEND_ONLY", {
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
                <li>
                  {t("DEPOSIT_MINIMUM_DEPOSIT", {
                    minimumDeposit: CurrencyData.minimumDeposit,
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
                <li>
                  {t("DEPOSIT_SENDING_COIN", {
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Deposit;
