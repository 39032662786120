import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function CapitalGrowthChart(props) {
  const { original } = props;
  const {t} = useTranslation()

  const series = [
    {
      name: t("INVEST_DASHBOARD_INVEST_LINK"),
      data: original?.capital?.investData || [],
    },
    {
      name: t("INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL"),
      data: original?.capital?.data || [],
    },
  ];

  const options = {
    chart: {
      foreColor: "#fff",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    xaxis: {
      categories: (original && original?.capital?.label) || [],
      // categories: [],
      // categories: [
      //   "Apr 2023",
      //   "May 2023",
      //   "Jun 2023",
      //   "Jul 2023",
      //   "Aug 2023",
      //   "Sep 2023",
      //   "Oct 2023",
      //   "Nov 2023",
      //   "Dec 2023",
      // ],
      labels: {
        style: {
          colors: "#fff",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      title: {
        text: t("MONTHLY"),
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
        margin: 10,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
      title: {
        text: "USD",
        offsetX: -8,
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    grid: {
      borderColor: "rgba(217, 217, 217, 0.18)",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 3,
    },
    toolbar: {
      show: false,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="area_chart"
      />
    </>
  );
}
