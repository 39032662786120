// import constant
import { SET_REFERRAL_SETTINGS } from '../constant';

const initialValue = {};

const referralSettings = (state = initialValue, action) => {
    switch (action.type) {
        case SET_REFERRAL_SETTINGS:
            return action.data;
        default:
            return state;
    }
}

export default referralSettings;