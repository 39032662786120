// Authentication
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const REMOVE_AUTHENTICATION = 'REMOVE_AUTHENTICATION';
// // Current User
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
// REFERRAL
export const SET_REFERRAL_LIST = 'SET_REFERRAL_LIST';
export const SET_REFERRAL_SETTINGS = 'SET_REFERRAL_SETTINGS';
// CURRENCY
export const SET_CURRENCY_LIST = "SET_CURRENCY_LIST"
export const SET_PRICE_CONVERSION = "SET_PRICE_CONVERSION";
// Wallet
export const SET_USER_WALLET_LIST = "SET_USER_WALLET_LIST";
// NOTICE
export const SET_UNREAD_NOTICE = 'SET_UNREAD_NOTICE';
export const UPDATE_NOTICE_POPUP = 'UPDATE_NOTICE_POPUP';
export const RESET_NOTICE = 'RESET_NOTICE'
//2FA
export const UPDATE_2FA_STATUS = 'UPDATE_2FA_STATUS'
// USER_SETTINGS
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
//STAKING
export const SET_INVESTMENT_LIST = 'SET_INVESTMENT_LIST'
export const SET_STACKING_DATA = 'SET_STACKING_DATA'

export const SET_LANGUAGE_OPTION = "SET_LANGUAGE_OPTION";

export const RESET_ALL = 'RESET_ALL'