// import constant
import { SET_REFERRAL_LIST } from '../constant';

const initialValue = [];

const referralLevel = (state = initialValue, action) => {
    switch (action.type) {
        case SET_REFERRAL_LIST:
            return action.data;
        default:
            return state;
    }
}

export default referralLevel;