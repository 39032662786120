// import config
import axios, { handleResp } from '../config/axios'

// import constant
import { SET_INVESTMENT_LIST, SET_STACKING_DATA } from '../constant';

export const getInvestments = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getStaking`
        });

        dispatch({
            type: SET_INVESTMENT_LIST,
            data: respData.data.result
        })

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const getStackingData = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getStaking`,
        });
        dispatch(setStackingData(respData.data.result));
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const setStackingData = (data) => {
    return {
        type: SET_STACKING_DATA,
        data
    }
}

export const getInvestDetails = async (stakeId) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getStake/${stakeId}`
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            data: respData.data.data
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const AllInvestments = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/stake/getStakes`,
            params: data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const OrderPlaceLocked = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/orderPlaceLocked`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const GetAllOrderhistory = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/stake/getAllStakes`
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const getUsrSettleList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/stake/findid-settle`,
            params: data
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const LockedOrderClaim = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/stake/usrorder-claim`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const GetFilterStakeshistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/stake/filterStakes`,
            params: data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const getUsrClaimList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/stake/findid-claim`,
            params: data
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getStrategyData = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/active-order`,
            params: data
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getInvestIdChart = async (stakeId) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getinvest-chart/${stakeId}`
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            data: respData.data.data
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const getDashboardChart = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getinvest-chart`,
            params: data
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}
export const getLatestInvestChart = async (data) => {
    try {
        let respData = await axios({
          method: "get",
          url: `/api/user/get-latest-five-invest-chart`,
        //   params: data,
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        console.log("getLatestError",err)
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getEarningHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/history`,
            params: data
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getallorderhistory = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/userall-order`
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}
