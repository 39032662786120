// import constant
import { SET_CURRENCY_LIST, RESET_ALL } from "../constant";

const initialValue = [];

const currency = (state = initialValue, action) => {
  switch (action.type) {
    case SET_CURRENCY_LIST:
      return action.data;
    case RESET_ALL: {
      return initialValue
    }
    default:
      return state;
  }
};

export default currency;
