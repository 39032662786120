import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
// import lib
import { dynamicFind/* , toFixed, toFixedDown */ } from "../../lib/roundOf";
import isEmpty from "../../lib/isEmpty";
import { getSiteSetting } from "../../api/users";
import { getUserDashboard } from "../../api/dashboard";
// import { filterDuplicates } from "../../lib/stringCase";
// import { getInvestDetails } from "../../api/invest.js";

// function getPreviousMonth(currentMonth, orderMonth, monthsArray) {
//   // Ensure the currentMonth is a valid number (0 to 11)
//   if (typeof currentMonth !== "number" || currentMonth < 0 || currentMonth > 11) {
//     throw new Error("Invalid currentMonth value. Please provide a number between 0 and 11.");
//   }

//   // Get the index of the current month in the array
//   const currentMonthIndex = monthsArray.findIndex((month) => month.month === currentMonth);

//   // If the current month is not found in the array, return 0
//   if (currentMonthIndex === -1) {
//     return 0;
//   }

//   // If the current month is not January, return the previous month
//   if (currentMonthIndex !== 0) {
//     return monthsArray[currentMonthIndex - 1].capGrowth;
//   }

//   // If the current month is January, return the last month (December)
//   return monthsArray[11].capGrowth;
// }

export default function Invest() {
  const [activeData, setActiveData] = useState({ amount: 0, coin: "USD", capGrowth: 0 })
  const [SiteData, setSiteData] = useState({});
  const [Investment, setInvestment] = useState({ activeOrder: {}, yourEarning: {} });
  const { wallet } = useSelector((state) => state);
  const { priceConversion } = useSelector((state) => state);
  const { t, i18n } = useTranslation();

  let findwallet = wallet && wallet.length > 0 && wallet.find((el) => el.coin == "USD");

  let usrdash = SiteData?.usrdashboard;

  const { activeOrder, yourEarning } = Investment;

  const setDetail = async (activeOrder) => {
    if (!isEmpty(activeOrder)) {
      let capGrowth = 0;
      // let ActiveOrders = filterDuplicates(activeOrder, "_id");
      let sum = activeOrder.reduce((acc, curr) => acc + curr.amount, 0);
      capGrowth = activeOrder.reduce((a, current) => a + current.capitalGrowth, 0);
      // let month = new Date().getMonth();
      // await Promise.all(
      //   ActiveOrders && ActiveOrders?.length > 0 && ActiveOrders.map(async (item) => {
      //     const { status, result, data } = await getInvestDetails(item._id);
      //     if (status) {
      //       let stakeData = data && data.length > 0 && data[0];
      //       let firstData = result.monthList;
      //       let usrdata = userSettings && userSettings?.stakeSetting;
      //       if (!isEmpty(usrdata)) {
      //         let findData = usrdata && usrdata.length > 0 && usrdata.find((el) => el.stakeId.toString() == result._id.toString())
      //         let isData = findData.monthlist.every(el => el.capGrowth == null);
      //         console.log(isData, "---isData243223")
      //         if (!isData) {
      //           console.log(findData.monthlist, "---findData.monthlist35463456")
      //           firstData = findData.monthlist
      //         }
      //         let orderMonth = stakeData && stakeData.date && new Date(stakeData.date).getMonth();
      //         console.log(month, orderMonth, firstData, "---2222222333333333333444444444444", month != orderMonth)
      //         let Check = month != orderMonth ? getPreviousMonth(month, orderMonth, firstData) : 0;
      //         capGrowth += stakeData && stakeData.amount > 0 && Check > 0 ? stakeData.amount + (Check * parseFloat(stakeData.amount)) / 100 : 0;
      //       }
      //     }
      //   })
      // )
      let data = {
        amount: sum,
        coin: "USD",
        capGrowth: capGrowth > 0 ? capGrowth : 0,
      };
      setActiveData({ ...activeData, ...data })
    }
  }

  const getSiteSettingFunc = async () => {
    try {
      const { status, result } = await getSiteSetting();
      if (status == 'success') setSiteData(result)
    } catch (err) { console.log("Err on GetAllOrderhistory", err) }
  }

  const getUserInvestment = async () => {
    try {
      const { status, result } = await getUserDashboard();
      if (status == 'success') {
        // console.log(result, "---result89089989898989")
        let activeOrder = result && result.activeOrders;
        let yourEarning = result && result.stakeSettle && result.stakeSettle[0]
          ? result.stakeSettle[0] : { amount: 0, coin: "USD" };
        setDetail(activeOrder)
        setInvestment({ ...Investment, ...{ 'activeOrder': activeOrder, 'yourEarning': yourEarning } })
      }
    } catch (err) { console.log("Err on getUserInvestment", err) }
  }


  useEffect(() => {
    getSiteSettingFunc()
    getUserInvestment()
  }, []);

  const btcData = dynamicFind(priceConversion, 'BTC');
  const ethData = dynamicFind(priceConversion, 'ETH');

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="current_price_div current_price_div_dash">
            <div>
              <label>BTC/USD:</label>
              <label>$ {btcData?.convertPrice}</label>
            </div>
            <div>
              <label>ETH/USD:</label>
              <label>$ {ethData?.convertPrice}</label>
            </div>
          </div>
        </div>
      </div>
      <div className="row dash_box_row">
        <div className="col-xl-7">
          <div className="dash_box_shadow">
            <div className="dahboard_overall">
              <div className="dahboard_overall_single">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M11.6557 20.9773C16.8039 20.9773 20.9773 16.8039 20.9773 11.6557C20.9773 6.50744 16.8039 2.33398 11.6557 2.33398C6.50744 2.33398 2.33398 6.50744 2.33398 11.6557C2.33398 16.8039 6.50744 20.9773 11.6557 20.9773Z"
                      fill="white"
                    />
                    <path
                      d="M25.6308 18.6541C25.6308 22.5041 22.5041 25.6308 18.6541 25.6308C16.2741 25.6308 14.1858 24.4408 12.9258 22.6324C18.0124 22.0608 22.0608 18.0124 22.6324 12.9258C24.4408 14.1858 25.6308 16.2741 25.6308 18.6541Z"
                      fill="white"
                    />
                    <path
                      d="M13.359 11.3285L10.559 10.3485C10.279 10.2552 10.2207 10.2318 10.2207 9.82352C10.2207 9.52018 10.4307 9.27518 10.699 9.27518H12.449C12.8223 9.27518 13.1257 9.61352 13.1257 10.0335C13.1257 10.5118 13.5223 10.9085 14.0007 10.9085C14.479 10.9085 14.8757 10.5118 14.8757 10.0335C14.8757 8.69185 13.8373 7.59518 12.5423 7.53685V7.47852C12.5423 7.00018 12.1457 6.60352 11.6673 6.60352C11.189 6.60352 10.7923 6.98852 10.7923 7.47852V7.53685H10.6873C9.46232 7.53685 8.45898 8.56352 8.45898 9.83518C8.45898 10.9435 8.94898 11.6552 9.96398 12.0052L12.7757 12.9852C13.0557 13.0785 13.114 13.1018 13.114 13.5102C13.114 13.8135 12.904 14.0585 12.6357 14.0585H10.8857C10.5123 14.0585 10.209 13.7202 10.209 13.3002C10.209 12.8218 9.81232 12.4252 9.33398 12.4252C8.85565 12.4252 8.45898 12.8218 8.45898 13.3002C8.45898 14.6418 9.49732 15.7385 10.7923 15.7968V15.8668C10.7923 16.3452 11.189 16.7418 11.6673 16.7418C12.1457 16.7418 12.5423 16.3452 12.5423 15.8668V15.8085H12.6473C13.8723 15.8085 14.8757 14.7818 14.8757 13.5102C14.8757 12.4018 14.374 11.6902 13.359 11.3285Z"
                      fill="white"
                    />
                  </svg>
                  <h4>{t("INVEST_DASHBOARD_TOTAL_LOCKED_VALUE")}</h4>
                  <i
                    className="fa-solid fa-circle-info"
                    data-tooltip-id="tooltip1"
                    data-tooltip-html={usrdash && usrdash.investment}
                  ></i>
                  <Tooltip id="tooltip1" className="dash_tootip_info" />
                </div>
                <h3>
                  {parseInt(activeData.amount)} {activeData.coin}
                </h3>
              </div>

              <div className="dahboard_overall_single">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M14.0007 25.5501C20.444 25.5501 25.6673 20.3268 25.6673 13.8835C25.6673 7.44014 20.444 2.2168 14.0007 2.2168C7.55733 2.2168 2.33398 7.44014 2.33398 13.8835C2.33398 20.3268 7.55733 25.5501 14.0007 25.5501Z"
                      fill="white"
                    />
                    <path
                      d="M16.6368 14L14.8752 13.3817V9.42667H15.2952C16.2402 9.42667 17.0102 10.255 17.0102 11.27C17.0102 11.7483 17.4068 12.145 17.8852 12.145C18.3635 12.145 18.7602 11.7483 18.7602 11.27C18.7602 9.28667 17.2085 7.67667 15.2952 7.67667H14.8752V7C14.8752 6.52167 14.4785 6.125 14.0002 6.125C13.5218 6.125 13.1252 6.52167 13.1252 7V7.67667H12.3668C10.6402 7.67667 9.22852 9.135 9.22852 10.92C9.22852 13.0083 10.4418 13.6733 11.3635 14L13.1252 14.6183V18.5617H12.7052C11.7602 18.5617 10.9902 17.7333 10.9902 16.7183C10.9902 16.24 10.5935 15.8433 10.1152 15.8433C9.63685 15.8433 9.24018 16.24 9.24018 16.7183C9.24018 18.7017 10.7918 20.3117 12.7052 20.3117H13.1252V21C13.1252 21.4783 13.5218 21.875 14.0002 21.875C14.4785 21.875 14.8752 21.4783 14.8752 21V20.3233H15.6335C17.3602 20.3233 18.7718 18.865 18.7718 17.08C18.7602 14.98 17.5469 14.315 16.6368 14ZM11.9468 12.355C11.3518 12.145 10.9902 11.9467 10.9902 10.9317C10.9902 10.1033 11.6085 9.43833 12.3785 9.43833H13.1368V12.775L11.9468 12.355ZM15.6335 18.5733H14.8752V15.2367L16.0535 15.645C16.6485 15.855 17.0102 16.0533 17.0102 17.0683C17.0102 17.8967 16.3918 18.5733 15.6335 18.5733Z"
                      fill="white"
                    />
                  </svg>
                  <h4>{t("INVEST_DASHBOARD_CAPITAL_GROWTH")}</h4>
                  <i
                    className="fa-solid fa-circle-info"
                    data-tooltip-id="tooltip2"
                    data-tooltip-html={usrdash && usrdash.capital}
                  ></i>
                  <Tooltip id="tooltip2" className="dash_tootip_info" />
                </div>
                <h3>
                  {activeData && parseInt(activeData.capGrowth)}{" "}
                  {activeData && activeData.coin}
                </h3>
              </div>

              <div className="dahboard_overall_single">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M19.832 9.04036C19.7154 9.04036 19.6104 9.01703 19.4937 8.97037C19.2837 8.87703 19.1087 8.7137 19.0154 8.49203C18.9687 8.38703 18.9454 8.27036 18.9454 8.1537V3.48703C18.9454 3.47536 18.957 3.46369 18.957 3.44036C17.452 2.74036 15.772 2.33203 13.9987 2.33203C7.5587 2.33203 2.33203 7.5587 2.33203 13.9987C2.33203 20.4387 7.5587 25.6654 13.9987 25.6654C20.4387 25.6654 25.6654 20.4387 25.6654 13.9987C25.6654 12.2254 25.257 10.5454 24.5454 9.0287C24.5337 9.0287 24.522 9.04036 24.4987 9.04036H19.832Z"
                      fill="white"
                    />
                    <path
                      d="M20.6618 7.24659C20.7409 7.44986 20.8795 7.61924 21.0675 7.70958C21.1565 7.75475 21.2555 7.77734 21.3544 7.77734L25.3122 7.77734C25.7178 7.77734 26.0543 7.3934 26.0543 6.9304C26.0543 6.4674 25.7178 6.08345 25.3122 6.08345L23.1453 6.08345L26.832 2.40207C26.832 0.777344 25.6654 1.55512 25.6654 1.55512L22.0965 4.88643L22.0965 2.40207C22.0965 1.93907 21.7601 1.55512 21.3544 1.55512C20.9487 1.55512 20.6123 1.93907 20.6123 2.40207L20.6123 6.9191C20.6024 7.03203 20.6222 7.13366 20.6618 7.24659Z"
                      fill="white"
                    />
                    <path
                      d="M16.0423 13.79L14.8757 13.3817V10.7917H14.969C15.564 10.7917 16.0423 11.3167 16.0423 11.9583C16.0423 12.4367 16.439 12.8333 16.9173 12.8333C17.3957 12.8333 17.7923 12.4367 17.7923 11.9583C17.7923 10.3483 16.5323 9.04167 14.969 9.04167H14.8757V8.75C14.8757 8.27167 14.479 7.875 14.0007 7.875C13.5223 7.875 13.1257 8.27167 13.1257 8.75V9.04167H12.7757C11.364 9.04167 10.209 10.2317 10.209 11.7017C10.209 13.405 11.2007 13.9533 11.959 14.2217L13.1257 14.63V17.22H13.0323C12.4373 17.22 11.959 16.695 11.959 16.0533C11.959 15.575 11.5623 15.1783 11.084 15.1783C10.6057 15.1783 10.209 15.575 10.209 16.0533C10.209 17.6633 11.469 18.97 13.0323 18.97H13.1257V19.2617C13.1257 19.74 13.5223 20.1367 14.0007 20.1367C14.479 20.1367 14.8757 19.74 14.8757 19.2617V18.97H15.2256C16.6373 18.97 17.7923 17.78 17.7923 16.31C17.7923 14.595 16.8007 14.0467 16.0423 13.79ZM12.5306 12.5533C12.134 12.4133 11.959 12.3317 11.959 11.69C11.959 11.1883 12.3323 10.78 12.7757 10.78H13.1257V12.7517L12.5306 12.5533ZM15.2256 17.2083H14.8757V15.2367L15.4707 15.4467C15.8673 15.5867 16.0423 15.6683 16.0423 16.31C16.0423 16.8 15.669 17.2083 15.2256 17.2083Z"
                      fill="white"
                    />
                  </svg>
                  <h4>{t("INVEST_DASHBOARD_YOUR_EARNINGS")}</h4>
                  <i
                    className="fa-solid fa-circle-info"
                    data-tooltip-id="tooltip3"
                    data-tooltip-html={usrdash && usrdash.earning}
                  ></i>
                  <Tooltip id="tooltip3" className="dash_tootip_info" />
                </div>
                <h3>
                  {parseInt(yourEarning.amount)} {yourEarning.coin}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5">
          <div className="dash_box_shadow">
            <div className="dash_avail_bal">
              <div className="dahboard_overall_single">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M21.5846 14.7578V19.0745C21.5846 22.7145 18.1896 25.6661 14.0013 25.6661C9.81297 25.6661 6.41797 22.7145 6.41797 19.0745V14.7578C6.41797 18.3978 9.81297 20.9995 14.0013 20.9995C18.1896 20.9995 21.5846 18.3978 21.5846 14.7578Z"
                      fill="white"
                    />
                    <path
                      opacity="0.3"
                      d="M21.5846 8.92578V14.7591C21.5846 18.3991 18.1896 21.0008 14.0013 21.0008C9.81297 21.0008 6.41797 18.3991 6.41797 14.7591V8.92578C6.41797 9.98745 6.70964 10.9674 7.22297 11.8074C8.47131 13.8608 11.038 15.1674 14.0013 15.1674C16.9646 15.1674 19.5313 13.8608 20.7796 11.8074C21.293 10.9674 21.5846 9.98745 21.5846 8.92578Z"
                      fill="white"
                    />
                    <path
                      d="M21.5846 8.92565C21.5846 9.98732 21.293 10.9673 20.7796 11.8073C19.5313 13.8607 16.9646 15.1673 14.0013 15.1673C11.038 15.1673 8.47131 13.8607 7.22297 11.8073C6.70964 10.9673 6.41797 9.98732 6.41797 8.92565C6.41797 5.28565 9.81297 2.33398 14.0013 2.33398C16.1013 2.33398 17.9913 3.06898 19.368 4.25898C20.733 5.46065 21.5846 7.10565 21.5846 8.92565Z"
                      fill="white"
                    />
                  </svg>
                  <h4>{t("INVEST_DASHBOARD_AVAILABLE_BALANCE")}</h4>
                </div>
                <h3>$ {findwallet && parseInt(findwallet.depositBal)}</h3>
              </div>
              <div className="flex_btns align-items-center">
                <Link to="/wallet" className="secondary_btn my-1">
                  {t("INVEST_DASHBOARD_WALLET_LINK")}
                </Link>
                <Link to="/invest" className="btn button animatedButton">
                  {t("INVEST_DASHBOARD_INVEST_LINK")}
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>
                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
