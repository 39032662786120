import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { toFixedDown } from "../../lib/roundOf";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WalletDetails = (props) => {
  const { refetch } = props;
  const [convertValues, setConvertValues] = useState({
    covertPrice: 0,
    depositBal: 0,
  });
  const { t, i18n } = useTranslation();

  //useSelect
  const { wallet } = useSelector((state) => state);
  const { priceConversion } = useSelector((state) => state);

  let findwallet =
    wallet && wallet.length > 0 && wallet.find((el) => el.coin == "USD");

  useEffect(() => {
    if (findwallet && priceConversion) {
      let bal = findwallet?.depositBal ? findwallet?.depositBal : 0;
      conversion(bal);
    }
  }, []);

  const conversion = (amount) => {
    const cnv = priceConversion.find(
      (item) => item.baseSymbol === "BTC" && item.convertSymbol === "USD"
    );
    if (cnv && !isEmpty(cnv.convertPrice))
      !isEmpty(amount) &&
        setConvertValues({
          covertPrice: toFixedDown(amount / cnv.convertPrice, 6),
          depositBal: toFixedDown(amount, 0),
        });
  };

  const refresh = () => {
    let find =
      wallet && wallet.length > 0 && wallet.find((el) => el.coin == "USD");
    if (find && priceConversion) {
      let bal = find?.depositBal ? find?.depositBal : 0;
      conversion(bal);
    }
  };

  useEffect(() => {
    if (refetch) {
      setTimeout(refresh(), 4000);
    }
  }, [refetch]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <h2 className="inner_head_title">{t("WALLET_DETAILS_TITLE")}</h2>
        </div>
      </div>
      <div className="row dash_box_row">
        <div className="col-lg-12">
          <div className="dash_box_shadow">
            <div className="dahboard_overall">
              <div className="dahboard_overall_single">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M17.4987 7.17428H6.9987C4.42036 7.17428 2.33203 9.26261 2.33203 11.8409V7.62922C2.33203 5.24922 4.25703 3.32422 6.63703 3.32422H13.1937C15.5737 3.32422 17.4987 4.79428 17.4987 7.17428Z"
                      fill="white"
                      fill-opacity="0.8"
                    />
                    <path
                      d="M25.6676 14.7232V17.1266C25.6676 17.7799 25.1309 18.3166 24.4659 18.3166H22.2142C20.9542 18.3166 19.7992 17.3949 19.6942 16.1349C19.6242 15.3999 19.9042 14.7116 20.3942 14.2332C20.8259 13.7899 21.4209 13.5332 22.0742 13.5332H24.4659C25.1309 13.5332 25.6676 14.0699 25.6676 14.7232Z"
                      fill="white"
                      fill-opacity="0.8"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.762 16.5441C19.5287 15.6691 19.8087 14.8058 20.392 14.2341C20.8237 13.7908 21.4187 13.5341 22.072 13.5341H23.332V11.8424C23.332 9.27578 21.232 7.17578 18.6654 7.17578H6.9987C4.42036 7.17578 2.33203 9.26411 2.33203 11.8424V20.0091C2.33203 22.5874 4.42036 24.6758 6.9987 24.6758H18.6654C21.2437 24.6758 23.332 22.5874 23.332 20.0091V18.3174H22.247C21.127 18.3174 20.0537 17.6291 19.762 16.5441ZM8.16504 13.884H15.165C15.6434 13.884 16.04 13.4874 16.04 13.009C16.04 12.5307 15.6434 12.134 15.165 12.134H8.16504C7.68671 12.134 7.29004 12.5307 7.29004 13.009C7.29004 13.4874 7.68671 13.884 8.16504 13.884Z"
                      fill="white"
                    />
                  </svg>
                  <h4>{t("WALLET_DETAILS_TOTAL_BALANCE")}</h4>
                </div>
                <h2 className="wallet_bal">
                  {convertValues && convertValues?.depositBal} USD ≈{" "}
                  <span>{convertValues && convertValues?.covertPrice} BTC</span>
                </h2>
              </div>
              <div className="flex_btns">
                {/* <button className='secondary_btn'>{t("WALLET_DETAILS_DASHBOARD_BUTTON")}</button> */}
                <div className="flex_btns flex_btns_chg flex-wrap flex-sm-nowrap">
                  <a
                    className="secondary_btn"
                    href="https://onramp.money/main/buy/?appId=1"
                  >
                    Buy Crypto
                  </a>
                  <Link to="/invest" className="secondary_btn">
                    {t("WALLET_DETAILS_INVEST_BUTTON")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletDetails;
