import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useTranslation } from "react-i18next";
import { getNews } from "../api/users.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function NewsAndEvents() {
  const [NewsData, setNewsData] = useState([]);
  const [ImageUrl, setImageUrl] = useState("");
  const { t, i18n } = useTranslation();

  const fetchNews = async () => {
    let { status, result } = await getNews();
    if (status === "success") {
      setNewsData(result.data);
      setImageUrl(result.imageUrl);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="inner_head_title mb-4">
                {t("NEWS_EVENTS_INNER_HEAD_TITLE")}
              </h2>
            </div>
          </div>

          <div className="row dash_box_row">
            {NewsData &&
              NewsData.length > 0 &&
              NewsData.map((item, key) => {
                return (
                  <div className="col-lg-4">
                    <div className="news_box news_box_inner">
                      <div class="img-container">
                        <img src={`${ImageUrl}/${item.image}`} alt="Banner" />
                      </div>
                      <h2 className="main_title">{item.title}</h2>
                      <p className="para_tag">{item.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
