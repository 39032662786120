export default {
  PRIVACY_SECTION_PRIVACY: "Privacidad",
  LOGIN_LINK_LABEL: "Iniciar sesión",
  REGISTER_LINK_LABEL: "Registrarse",
  CLICK_ME_LABEL: "Haz clic en mí",
  LOGIN_EMAIL_PLACEHOLDER: "Ingrese su dirección de correo electrónico",
  EMPOWER_FINANCIAL_FUTURE: "Potencia tu futuro financiero.",
  HOME_DESCRIPTION:
    "Sumérgete en el mundo de las criptomonedas con orientación experta diseñada para acelerar tu viaje de inversión. Comienza a invertir en cripto hoy y observa cómo crece tu cartera. Mantis Network ofrece orientación para navegar por los conceptos básicos de convertirte en un inversor exitoso en cripto.",
  GET_STARTED: "Comenzar",
  DASHBOARD: "Tablero",
  ALL_PARTICIPANTS: "Todos los Participantes",
  ASSETS_UNDER_MANAGEMENT: "Activos bajo Gestión",
  WHY_INVEST_WITH_US: "Por qué invertir con nosotros",
  SECURE_AND_RELIABLE: "Seguro y Confiable",
  PLATFORM_SECURITY_BRIEF_EXPLANATION:
    "Nuestra plataforma prioriza la seguridad. Sus activos están protegidos por tecnología de vanguardia y protocolos de seguridad líderes en la industria.",
  EARN_REWARDS_EFFORTLESSLY: "Gane recompensas sin esfuerzo",
  INVESTMENT_DESCRIPTION:
    "Invertir con nosotros significa que ganas recompensas sin esfuerzo en forma de criptomonedas adicionales. No se requieren estrategias comerciales complejas ni conocimientos técnicos.",
  DECENTRALIZATION_MATTERS: "La descentralización importa",
  JOIN_THE_MOVEMENT:
    "Únete al movimiento hacia un futuro descentralizado. Al invertir tu cripto, contribuyes activamente a la fuerza y estabilidad de la red blockchain.",
  USER_FRIENDLY_INTERFACE: "Interfaz Amigable para el Usuario",
  INTUITIVE_PLATFORM:
    "Nuestra plataforma intuitiva hace que la inversión sea sencilla tanto para principiantes como para inversores experimentados. Sin tarifas ocultas ni procesos complicados, solo una experiencia fluida.",
  HOW_IT_WORKS: "Cómo Funciona",
  MANTIS_NETWORK_REGISTRATION:
    "Con Mantis Network, simplemente necesitas registrarte y crear una cuenta sin necesidad de conocimientos previos sobre blockchain o cripto.",
  SIMPLE_PATH_WITH_MANTIS_NETWORK: "Tu Ruta Sencilla con Mantis Network",
  CREATE_YOUR_ACCOUNT: "Crea tu Cuenta",
  SIGN_UP_WITH_MANTIS_NETWORK:
    "Regístrate fácilmente con Mantis Network, sin necesidad de experiencia previa en billetera blockchain o cripto. Nuestra plataforma fácil de usar está diseñada para todos.",
  EXPLORE_INVESTMENT_OPTIONS: "Explora Opciones de Inversión",
  DISCOVER_INVESTMENT_OPPORTUNITIES:
    "Descubre una variedad de oportunidades de inversión, incluida la minería de Bitcoin y el staking de Ethereum, estrategias personalizadas de Mantis para adaptarse a tus objetivos financieros.",
  BEGIN_INVESTING: "Comienza a Invertir",
  START_INVESTMENT_JOURNEY:
    "Con nuestra orientación paso a paso, comienza tu viaje de inversión en activos lucrativos como Bitcoin, Ethereum, ALT coins y diversos proyectos en sus primeras etapas.",
  WATCH_PORTFOLIO_GROW: "Observa Cómo Crece tu Cartera",
  TRACK_INVESTMENTS:
    "Realiza un seguimiento de tus inversiones fácilmente en Mantis Network y observa cómo crece tu cartera a medida que te sumerges más en el mundo de las inversiones en cripto.",
  ASSET_ALLOCATION: "Asignación de Activos",
  FOCUS_ON_INCOME_GENERATION:
    "Concéntrate en la generación de ingresos acumulando recompensas BTC (Bitcoin) diarias en tu billetera digital y recompensas por staking de ETH (Ethereum).",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "Asignación del 40% a un asignador de minería de BTC",
  BTC_ALLOCATION: "Asignación del 30% a BTC",
  ETH_ALLOCATION: "Asignación del 20% a ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "Asignación del 10% a Estrategias Mantis en busca de Alpha",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Este portafolio es adecuado para inversores que desean enfocarse en la generación de ingresos y la acumulación de rendimientos con inclinación hacia la exposición al precio de BTC.",
  INVESTOR_PORTFOLIO_BLURB:
    "Enfoque Equilibrado en Generación de Ingresos y Crecimiento",
  GROWTH_FOCUS_BLURB:
    "Enfócate en Generar un Gran Crecimiento con una mayor asignación a nuestras Estrategias Mantis que buscan Alpha",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "Asignación del 30% a un asignador de minería de BTC",
  BTC_ALLOCATION: "Asignación del 20% a BTC",
  ETH_ALLOCATION: "Asignación del 20% a ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "Asignación del 30% a Estrategias Mantis que buscan Alpha",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Este portafolio es adecuado para inversores que desean enfocarse en la generación de ingresos y la acumulación de rendimientos con inclinación hacia la exposición al precio de BTC, al tiempo que buscan rendimientos muy altos en el mercado de Finanzas Descentralizadas y monedas alternativas.",
  INVEST_TITLE: "Invertir",
  BALANCED_TITLE: "Equilibrado",
  HIGH_YIELD_TITLE: "Alto Rendimiento",
  GROWTH_TITLE: "Crecimiento",
  ROI_CALCULATOR_MAIN_TITLE: "Calculadora de ROI",
  ROI_CALCULATOR_INVESTMENTS_LABEL: "Inversiones",
  ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL: "Cantidad de Inversión",
  ROI_CALCULATOR_APY_LABEL: "APY En Porcentaje (%)",
  ROI_CALCULATOR_MONTHS_LABEL: "Número de Meses",
  ROI_CALCULATOR_INVEST_BUTTON: "Invertir",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_1: "1 Mes",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_3: "3 Meses",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_6: "6 Meses",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_9: "9 Meses",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_12: "12 Meses",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_24: "24 Meses",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_48: "48 Meses",
  ROI_CALCULATOR_INTEREST_LABEL: "Interés Total:",
  ROI_CALCULATOR_FUTURE_VALUE_LABEL: "Valor Futuro:",
  NEWS_COMPONENT_MAIN_TITLE: "Nuestras noticias y Actualizaciones",
  NEWS_COMPONENT_VIEW_MORE_BUTTON: "Ver Más",
  FAQ_COMPONENT_MAIN_TITLE: "Preguntas Frecuentes",
  FAQ_COMPONENT_VIEW_MORE_BUTTON: "Ver Más",
  FOOTER_INNOVATIVE_PLATFORM:
    "Mantis Network es una plataforma innovadora que permite a los inversores tradicionales obtener fácilmente exposición a inversiones en criptomonedas.",
  FOOTER_FOLLOW_US: "Síguenos",
  FOOTER_COPYRIGHT: "Derechos de Autor ©",
  FOOTER_ALL_RIGHTS_RESERVED: "Todos los Derechos Reservados.",
  FOOTER_ADDRESS: "Dirección",
  FOOTER_CONTACT_US: "Contáctenos",
  FOOTER_TERMS_OF_SERVICE: "Términos de Servicio",
  FOOTER_PRIVACY_POLICY: "Política de Privacidad",

  INVEST_TITLE: "Invertir",
  INVEST_TABLE_HEADER_ASSETS: "Activos",
  INVEST_TABLE_HEADER_WEIGHTS: "Pesos",
  INVEST_ALLOCATION_BTC: "BTC",
  INVEST_ALLOCATION_ETH: "ETH",
  INVEST_ALLOCATION_MINING_POOL: "Grupo de Minería BTC",
  INVEST_ALLOCATION_MANTIS_STRATEGIES: "Estrategias Mantis",
  INVEST_ALLOCATION_TOTAL: "Asignación Total",
  INVEST_MINIMUM_INVESTMENT: "Inversión Mínima",
  INVEST_PROJECTED_ROI: "ROI Proyectado (%)",
  INVEST_DETAILS: "Detalles",
  INVEST_INVEST_BUTTON: "Invertir",
  INVEST_INVESTMENT_DETAILS: "Detalles de la Inversión",
  INVEST_MONTH: "Mes",
  INVEST_INTEREST_RATE: "Tasa de Interés",
  INVEST_MONTHS: "Meses",
  INVEST_AMOUNT: "Monto",
  INVEST_INVEST_MODAL_TITLE: "Orden de Inversión",
  INVEST_INVEST_MODAL_DESCRIPTION:
    "Revise los detalles de su inversión y proceda a realizar el pedido.",
  INVEST_INVEST_MODAL_AMOUNT_LABEL: "Monto",
  INVEST_INVEST_MODAL_PLACE_ORDER: "Realizar Pedido",
  INVEST_INVEST_MODAL_CLOSE: "Cerrar",
  INVEST_RESPONSE_MODAL_TITLE_SUCCESS: "Éxito",
  INVEST_RESPONSE_MODAL_TITLE_ERROR: "Error",
  INVEST_RESPONSE_MODAL_CLOSE: "Cerrar",
  INVEST_DASHBOARD_TOTAL_LOCKED_VALUE: "Valor Total Bloqueado",
  INVEST_DASHBOARD_CAPITAL_GROWTH: "Crecimiento del Capital",
  INVEST_DASHBOARD_YOUR_EARNINGS: "Sus Ganancias",
  INVEST_DASHBOARD_AVAILABLE_BALANCE: "Saldo Disponible",
  INVEST_DASHBOARD_WALLET_LINK: "Billetera",
  INVEST_DASHBOARD_INVEST_LINK: "Invertir",
  INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE: "Detalles de la Inversión",
  INVESTMENT_DETAILS_STRATEGY_LABEL: "Estrategia",
  INVESTMENT_DETAILS_DURATION_LABEL: "Duración",
  INVESTMENT_DETAILS_ALL_OPTION: "Todo",
  INVESTMENT_DETAILS_LOW_OPTION: "Bajo",
  INVESTMENT_DETAILS_HIGH_OPTION: "Alto",
  INVESTMENT_DETAILS_ID_LABEL: "ID",
  INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL:
    "Período Total de Inversión",
  INVESTMENT_DETAILS_REWARDS_EARNED_LABEL: "Recompensas Ganadas",
  INVESTMENT_DETAILS_REMAINING_DAYS_LABEL: "Días Restantes",
  INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL: "Crecimiento del Capital",
  INVESTMENT_DETAILS_VIEW_BUTTON_LABEL: "Ver",
  INVESTMENT_DETAILS_INVESTED_AT_LABEL: "Invertido en",

  RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE: "Actividad Reciente",
  RECENT_ACTIVITY_SEE_MORE_LINK: "Ver más",
  RECENT_ACTIVITY_TRANSFER_TYPE_LABEL: "Transferencia a través de USD",
  RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL: "Depósito a través de USD",
  RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL: "Retiro a través de USD",
  RECENT_ACTIVITY_DATE_LABEL: "Fecha",
  RECENT_ACTIVITY_STATUS_LABEL: "Estado",
  RECENT_ACTIVITY_REJECTED_STATUS: "Rechazado",
  RECENT_ACTIVITY_GREEN_TEXT: "green_txt",
  RECENT_ACTIVITY_RED_TEXT: "red_txt",
  FORGOT_PASSWORD_TITLE: "Olvidé la Contraseña",
  FORGOT_PASSWORD_RESET_INFO:
    "El enlace para restablecer la contraseña ha sido enviado a su dirección de correo electrónico registrada",
  FORGOT_PASSWORD_EMAIL_LABEL: "Dirección de Correo Electrónico",
  FORGOT_PASSWORD_SUBMIT_BUTTON: "Enviar",
  FORGOT_PASSWORD_GO_TO_LOGIN: "Ir a Iniciar Sesión",

  LOGIN_WELCOME_TITLE: "Bienvenido a",
  LOGIN_MANTIS_NETWORK: "Mantis Network",
  LOGIN_SIGN_IN: "Inicia Sesión para Continuar",
  LOGIN_EMAIL_LABEL: "Dirección de Correo Electrónico",
  LOGIN_PASSWORD_LABEL: "Contraseña",
  LOGIN_REMEMBER_ME: "Recuérdame",
  LOGIN_FORGOT_PASSWORD: "¿Olvidaste tu Contraseña?",
  LOGIN_LOGIN_BUTTON: "Iniciar Sesión",
  LOGIN_DONT_HAVE_ACCOUNT: "¿No tienes una cuenta?",
  LOGIN_REGISTER_HERE: "Regístrate Aquí",
  LOGIN_OTP_VALID_UP_TO: "OTP Válido Hasta",
  LOGIN_YOUR_OTP_SENT:
    "Tu Código de Acceso Único (OTP) ha sido enviado a tu correo electrónico registrado",
  LOGIN_ENTER_OTP: "Ingresar OTP",
  LOGIN_SUBMIT_BUTTON: "Enviar",
  LOGIN_RESEND_OTP: "Reenviar OTP",

  NEWS_EVENTS_INNER_HEAD_TITLE: "Noticias y Actualizaciones",

  PRIVACY_INNER_HEAD_TITLE: "Política de Privacidad - Mantis Network",
  PRIVACY_PARAGRAPH_1:
    "La protección de datos y la confidencialidad de sus datos personales son una preocupación importante de Mantis Network. El uso de nuestro sitio web depende de la recopilación de datos personales, que es necesaria en el registro y para su acceso de usuario. Mantis Network cumple estrictamente con todas las regulaciones legales y procesa sus datos altamente sensibles de manera consciente y responsable. Sin embargo, queremos señalar que la protección absoluta de los datos durante la transmisión por Internet no es posible y, como consecuencia, no se puede evitar completamente el acceso por parte de terceros en cualquier momento. No obstante, puede suponer que tomamos todas las medidas de seguridad técnicas y organizativas necesarias para proteger sus datos personales contra pérdidas y usos indebidos",
  PRIVACY_SECTION_COOKIES: "Cookies",
  PRIVACY_PARAGRAPH_2:
    "El sitio web utiliza cookies de sesión PHP. Las cookies no dañan su computadora ni contienen virus. Las cookies son un mecanismo para almacenar información que permite identificar a los usuarios que regresan o rastrear su camino al utilizar el sitio web. Las cookies se utilizan para hacer que nuestro sitio web sea más fácil de usar, cómodo y seguro. Desactivar las cookies puede limitar la funcionalidad de este sitio web",
  PRIVACY_SECTION_LOG_FILES: "Archivos de registro del servidor",
  PRIVACY_PARAGRAPH_3:
    "Nuestro servidor recopila y almacena automáticamente información en los llamados archivos de registro del servidor, que su navegador nos transmite automáticamente. La información no contiene datos personales. No se pretende correlacionar estos datos con sus datos personales. Nos reservamos el derecho de verificar estos datos retrospectivamente si tenemos conocimiento de indicaciones específicas de uso ilegal",
  PRIVACY_SECTION_SSL: "Cifrado SSL",
  PRIVACY_PARAGRAPH_4:
    "Por razones de seguridad, este sitio utiliza cifrado SSL para la transmisión de datos. Mientras el cifrado SSL esté habilitado, los datos no pueden ser leídos por terceros durante la transmisión",
  PRIVACY_SECTION_CONTACT_FORM: "Formulario de contacto",
  PRIVACY_PARAGRAPH_5:
    "Si nos envía un mensaje a través del formulario de 'CONTACTO', se le pedirá al menos su nombre y su dirección de correo electrónico. Estos detalles se transmitirán y guardarán junto con el mensaje para su procesamiento posterior. La información 'teléfono' y 'dirección' es voluntaria. No compartiremos toda esta información sin su consentimiento",
  PRIVACY_SECTION_REGISTRATION: "Registro",
  PRIVACY_PARAGRAPH_6:
    "Después del registro, guardaremos su contraseña de forma anónima, solo su dirección de correo electrónico será visible para nosotros. En consecuencia, solo podemos restablecer contraseñas si se olvidan. Necesita su dirección de correo electrónico y contraseña para iniciar sesión en nuestro sitio web. Usted es personalmente responsable de la confidencialidad efectiva de sus contraseñas. No proporcione los datos de inicio de sesión a nadie más",
  PRIVACY_SECTION_CONSENT: "Declaración de Consentimiento",
  PRIVACY_PARAGRAPH_7:
    "Con la participación y el uso de nuestros servicios, también acepta el procesamiento de sus datos personales. Los servicios no pueden proporcionarse sin el procesamiento de sus datos personales",
  PRIVACY_SECTION_KYC: "Cumplimiento de KYC",
  PRIVACY_PARAGRAPH_8:
    "Con respecto a las sanciones de la UE y los EE. UU., así como a la Ley contra el Lavado de Dinero, Mantis Network está obligado, en la medida de lo posible, a llevar a cabo y documentar una verificación KYC (Conozca a su Cliente) basada en su auto revelación. Este procedimiento también requiere prueba de su identidad. Estos datos no se divulgarán a terceros sin su consentimiento previo, a menos que Mantis Network esté legalmente obligado a proporcionarlos. Por supuesto, Mantis Network contactará inmediatamente a sus clientes si una revisión conduce a anomalías y tienen derecho a comentar",
  PRIVACY_SECTION_THIRD_PARTY: "Servicios de Terceros",
  PRIVACY_PARAGRAPH_9:
    "Mantis Network contrata a otras empresas e individuos para realizar actuaciones relacionadas con nuestros servicios o para fines administrativos relacionados con el negocio. Estos proveedores de servicios de terceros solo tienen acceso mientras y en la medida que absolutamente necesiten para el propósito o por lo que están legalmente obligados",
  PRIVACY_SECTION_INFORMATION: "Derecho a la Información",
  PRIVACY_PARAGRAPH_10:
    "Usted tiene el derecho en cualquier momento de obtener información sobre sus datos personales, así como el propósito y el tipo de procesamiento de sus datos. Si tiene alguna pregunta sobre datos personales, siempre puede ponerse en contacto con nosotros en la dirección que se indica en „sobre nosotros“",
  PRIVACY_SECTION_TELEGRAM: "Política de Privacidad para el Uso de Telegram",
  PRIVACY_PARAGRAPH_11:
    "En nuestro sitio web están integradas funciones del servicio Telegram. Estas funciones son ofrecidas por Telegram Messenger LLP. 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, Reino Unido. Al utilizar el enlace a Telegram, puede unirse al 'canal de información de MANTIS NETWORK' con su cuenta de Telegram. Puede encontrar más información en la política de privacidad de Telegram en su cuenta: Telegram FAQ, F:, Privacy Policy",
  PRIVACY_SECTION_FACEBOOK: "Política de Privacidad para el Uso de Facebook",
  PRIVACY_PARAGRAPH_12:
    "En nuestro sitio web están integradas funciones del servicio Facebook. Estas funciones son ofrecidas por Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irlanda. Al utilizar el enlace a Facebook, puede visitar nuestra cuenta 'Mantis Network Limited'. Puede encontrar más información en la política de privacidad de Facebook; https://www.facebook.com/privacy/explanation",
  PROFILE_PROFILE_TITLE: "Perfil",
  PROFILE_USERNAME_LABEL: "Nombre de Usuario",
  PROFILE_EMAIL_LABEL: "Correo Electrónico",
  PROFILE_PHONE_NUMBER_LABEL: "Número de Teléfono",
  PROFILE_CHANGE_USERNAME_LABEL: "Cambiar Nombre de Usuario",
  PROFILE_CHANGE_PASSWORD_LABEL: "Cambiar Contraseña",
  PROFILE_PASSWORD_DESCRIPTION:
    "Esta contraseña se utiliza para sus credenciales de inicio de sesión.",
  PROFILE_CHANGE_BUTTON: "Cambiar",

  CHANGE_PROFILE_CHANGE_USERNAME_TITLE: "Cambiar Nombre de Usuario",
  CHANGE_PROFILE_ENTER_NEW_USERNAME: "Ingrese el nuevo nombre de usuario",
  CHANGE_PROFILE_CLOSE_BUTTON: "Cerrar",
  CHANGE_PROFILE_SAVE_BUTTON: "Guardar",

  CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE: "Cambiar Contraseña",
  CHANGE_PASSWORD_ENTER_OTP: "Ingresar OTP",
  CHANGE_PASSWORD_ENTER_OLD_PASSWORD: "Ingresar Contraseña Antigua",
  CHANGE_PASSWORD_ENTER_NEW_PASSWORD: "Ingresar Nueva Contraseña",
  CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD: "Ingresar Confirmación de Contraseña",
  CHANGE_PASSWORD_CLOSE_BUTTON: "Cerrar",
  CHANGE_PASSWORD_SEND_BUTTON: "Enviar",
  CHANGE_PASSWORD_VERIFY_BUTTON: "Verificar",
  CHANGE_PASSWORD_SUBMIT_BUTTON: "Enviar",

  WALLET_DETAILS_TITLE: "Billetera",
  WALLET_DETAILS_TOTAL_BALANCE: "Saldo Total",
  WALLET_DETAILS_INVEST_BUTTON: "Invertir",

  DEPOSIT_TITLE: "Depósito",
  DEPOSIT_SELECT_COIN: "Seleccionar Moneda",
  DEPOSIT_SELECT_NETWORK: "Seleccionar Red",
  DEPOSIT_ADDRESS_LABEL: "Dirección",
  DEPOSIT_COPY_ICON_ALT: "Copiar",
  DEPOSIT_GENERATE_ADDRESS_BUTTON: "Generar Dirección",
  DEPOSIT_QR_IMAGE_ALT: "Imagen Borrosa",
  DEPOSIT_DEPOSIT_NOTES: "Notas de Depósito",
  DEPOSIT_SEND_ONLY:
    "Solo enviar {currencySymbol} a esta dirección de depósito.",
  DEPOSIT_MINIMUM_DEPOSIT: "Depósito Mínimo: {minimumDeposit} {currencySymbol}",
  DEPOSIT_SENDING_COIN:
    "Enviar monedas o tokens que no sean {currencySymbol} a esta dirección puede resultar en la pérdida de su depósito.",
  WITHDRAW_TITLE: "Retirar",
  WITHDRAW_OTP_VALID_UP_TO: "OTP Válido Hasta",
  WITHDRAW_OTP_LABEL: "OTP",
  WITHDRAW_ENTER_OTP: "Ingresar OTP",
  WITHDRAW_SUBMIT: "Enviar",
  WITHDRAW_RESEND_OTP: "Reenviar OTP",
  WITHDRAW_SELECT_COIN: "Seleccionar Moneda",
  WITHDRAW_SELECT: "Seleccionar",
  WITHDRAW_AMOUNT: "Monto",
  WITHDRAW_ENTER_AMOUNT: "Ingresar Monto",
  WITHDRAW_SELECT_NETWORK: "Seleccionar Red",
  WITHDRAW_CONVERSION_RATE: "Tasa de Conversión",
  WITHDRAW_ENTER_ADDRESS: "Ingresar Dirección",
  WITHDRAW_ADDRESS: "Dirección",
  WITHDRAW_WITHDRAW_NOW: "Retirar Ahora",
  WITHDRAW_WITHDRAW_NOTES: "Notas de Retiro",
  WITHDRAW_SEND_ONLY:
    "Enviar solo {{currencySymbol}} a esta dirección de retiro.",
  WITHDRAW_MINIMUM_WITHDRAW:
    "Retiro Mínimo: {{minimumWithdraw}} {{currencySymbol}}",
  WITHDRAW_MAXIMUM_WITHDRAW:
    "Retiro Máximo: {{maximumWithdraw}} {{currencySymbol}}",
  WITHDRAW_WITHDRAWAL_FEE:
    "Tarifa de Retiro: {{withdrawFee}} {{currencySymbol}}",
  WITHDRAW_SENDING_COIN:
    "Enviar monedas o tokens que no sean {{currencySymbol}} a esta dirección puede resultar en la pérdida de su retiro.",
  TRX_HISTORY_TITLE: "Historial de transacciones",
  TERMS_TITLE: "Términos de uso",
  TERMS_GENERAL: "1. General",
  TERMS_GENERAL_CONTENT_A:
    'a. Estos Términos de Servicio (en adelante "TOS") se aplican al uso de los servicios de alojamiento de Mantis Network (en adelante "Servicio") del sitio web http://Mantis.Network (en adelante "Sitio web"). Cualquier uso de la terminología aquí presente u otras palabras en singular, plural, mayúsculas y/o él/ella o ellos, su/sus deberá interpretarse como intercambiable y, por lo tanto, como referencia a lo mismo.',
  TERMS_GENERAL_CONTENT_B:
    'b. Cualquier individuo, entidad u otra persona jurídica (en adelante "Usuario") que tenga acceso al Sitio web y utilice los Servicios debe leer, aceptar y confirmar los TOS primero y aceptar quedar vinculado por las disposiciones de los TOS antes de convertirse en miembro. Cualquier participación efectiva en los Servicios constituirá tal aceptación. Si el Usuario no está de acuerdo y no cumple con estos TOS, no se le permite utilizar los Servicios.',
  TERMS_GENERAL_CONTENT_C:
    "c. Cualquier Usuario de los Servicios confirma ser ilimitado en cuanto a su capacidad de actuar (capacidad contractual) y tener al menos dieciocho (18) años al suscribirse a los Servicios. Además, cualquier Usuario es consciente de todos los posibles riesgos relacionados con el uso de los Servicios, la tecnología blockchain y las criptomonedas.",
  TERMS_EXCLUSIONS_LIMITATIONS_TITLE: "2. Exclusiones y Limitaciones",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A:
    "a. La información del sitio web se proporcionará 'tal cual' en la mayor medida permitida por la ley. El servicio excluye todas las representaciones y garantías relacionadas con el sitio web y su contenido, o el contenido que pueda ser proporcionado por cualquier afiliado o cualquier otro tercero, incluyendo cualquier inexactitud u omisión relacionada con el sitio web y/o los servicios, así como las presentaciones, demostraciones y descripciones relacionadas.",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B:
    "b. No se garantiza el tiempo de actividad del servicio o del sitio web; puede ser necesario realizar mantenimiento y tiempo de inactividad de vez en cuando. El propietario no es responsable de la operación continua y sin fallos y la seguridad de las redes de criptomonedas. Sin embargo, el propietario hará todo lo posible para proporcionar el servicio en buenas condiciones y evitar cualquier consecuencia peor.",
  TERMS_LIMITATION_LIABILITIES_TITLE: "3. Limitación de Responsabilidades",
  TERMS_LIMITATION_LIABILITIES_CONTENT_A:
    "a. Mantis Network Ltd., sus representantes legales y accionistas (en adelante, el 'propietario') no serán responsables de ningún daño directo o indirecto, consecuente e incidental relacionado con o causado por el sitio web y/o los servicios, ya sea que las consecuencias fueran previsibles o no y puedan ocurrir en un caso normal como suelen suceder las cosas.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_B:
    "b. Especialmente, el propietario no será responsable de daños como, pero no limitados a, daños en el hardware o software de la computadora del usuario, sistemas y programación, o pérdida de datos, así como pérdida económica, pérdida de negocios o pérdida de beneficios.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_C:
    "c. El usuario confirma conocer todos los riesgos, incluida la posibilidad de pérdida económica o pérdida de beneficios esperados. El propietario no compensará ninguna pérdida irreversible.",
  TERMS_PAYOUTS_TITLE: "4. Pagos",
  TERMS_PAYOUTS_CONTENT_A:
    "a. Mantis Network pagará recompensas proporcionalmente al monto de inversión realizado por el usuario. Todas las transacciones se procesarán en la moneda USDT.",
  TERMS_PAYOUTS_CONTENT_B:
    "b. Los pagos solo se transferirán a la cuenta que el usuario haya mencionado en su formulario de inversión (panel). Los pagos se acreditarán después de cada cuatro (4) semanas de manera regular.",
  TERMS_PAYOUTS_CONTENT_C:
    "c. El propietario cobrará por los servicios una tarifa de ingreso del 2% sobre todos los depósitos y una tarifa del 25% sobre todas las recompensas de Mantis Network.",
  TERMS_PAYOUTS_CONTENT_D:
    "d. Cualquier recompensa acumulada en una cuenta de criptomoneda nominada solo se pagará a esa dirección en particular. Los saldos de billeteras diferentes no se pueden fusionar.",
  TERMS_PAYOUTS_CONTENT_E:
    "e. El propietario no será responsable de los cambios en el valor de las criptomonedas. El Retorno de Inversión (ROI) se calculará en función de las recompensas reales de cada bloque de las últimas 24 horas. El propietario no puede garantizar que ninguna criptomoneda mantendrá un valor constante y, por lo tanto, no puede garantizar el ROI. La pérdida total es posible en cualquier momento y cualquier riesgo será asumido por cada usuario.",
  TERMS_PAYOUTS_CONTENT_F:
    "f. El valor de la cartera se puede solicitar una vez por trimestre, máximo 4 semanas antes del final del período de retención de forma gratuita para el cálculo a través del soporte. Cada cálculo adicional se cobrará con $25 debido al alto esfuerzo, que debe pagarse por adelantado en USDT. El valor de la cartera tiene una validez de 7 días en cada caso. Por razones operativas y debido a las solicitudes altas y recurrentes del valor de la cartera, puede ser que un cálculo del valor del pool se realice solo una vez por trimestre, máximo 4 semanas antes del final del período de retención y se entregue.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE: "5. Requisitos Legales y Sanciones",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A:
    "a. El usuario declara, mediante su acuerdo con estos TOS, que no ha sido afectado por ninguna sanción o que ha sido incluido en alguna autoridad supervisora competente. Debe anunciar cambios inmediatamente. El propietario supervisará su estado regularmente. Un resultado positivo en la evaluación lleva a la cesación inmediata de los servicios.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B:
    "b. Además, el usuario confirma con su consentimiento a los TOS que actúa a su propio costo.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C:
    "c. El propietario tiene derecho a solicitar pruebas de la identidad del usuario. En el caso de personas naturales, esto suele ser un documento oficial de una autoridad (por ejemplo, la tarjeta de identidad), en el caso de una entidad legal, las autoridades de supervisión requieren un extracto actual del registro comercial.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D:
    "d. En caso de información incorrecta o incompleta, así como fraude o representación fraudulenta, el usuario indemnizará al propietario de todas las consecuencias legales y daños financieros. Además, el propietario suspenderá inmediatamente al usuario del uso del sitio web y servicios. El propietario se reserva el derecho de emprender acciones legales.",
  TERMS_WAIVER_TITLE: "6. Renuncia",
  TERMS_WAIVER_CONTENT:
    "Ninguna renuncia de cualquiera de las disposiciones de los TOS se considerará una renuncia de cualquier otra disposición, ni dicha renuncia constituirá una renuncia continua a menos que se disponga expresamente por escrito debidamente ejecutado por la parte que deba quedar obligada.",
  TERMS_FORCE_MAJEURE_TITLE: "7. Fuerza Mayor",
  TERMS_FORCE_MAJEURE_CONTENT:
    "El Propietario no será responsable en ningún evento o circunstancia que esté fuera del control del Propietario, incluyendo pero no limitado a actos de Dios, disturbios, guerras, inundaciones, incendios, explosiones, huelgas y otros eventos similares, que evitarán, retrasarán, interrumpirán o harán que fallen la provisión de Servicios ya comprometidos.",
  TERMS_TERMINATION_TITLE: "8. Terminación",
  TERMS_TERMINATION_CONTENT_A:
    "El consentimiento del Usuario a los TOS incluye una inversión en la Red Mantis. El período mínimo de bloqueo de la cartera será válido por un período de (180) ciento ochenta días calendario y se prolongará automáticamente si el usuario no termina los TOS al final del período mínimo de bloqueo. El Usuario puede terminar sus TOS y el uso de los Servicios enviando un correo electrónico a support@Mantis.Network. Se pagará la participación del valor actual de la cartera de Mantis Network. El Propietario tiene el derecho de terminación extraordinaria si existen razones categóricas en contra de la continuación de la relación comercial con un Usuario. En caso de una terminación extraordinaria prematura del contrato, se reembolsará la participación del valor actual de la cartera de Mantis Network, menos una tarifa de procesamiento del 25%. No hay derecho con respecto a los pagos no realizados o futuros.",
  TERMS_SEVERABILITY_CLAUSE_TITLE: "9. Cláusula de Separabilidad",
  TERMS_SEVERABILITY_CLAUSE_CONTENT:
    "Si alguna disposición de los TOS se vuelve inválida, la invalidez no afecta a otras disposiciones de los TOS que puedan tener efecto sin la disposición inválida, y a este respecto, las disposiciones de los TOS son separables.",
  TERMS_GOVERNING_LAW_TITLE: "10. Ley Aplicable",
  TERMS_GOVERNING_LAW_CONTENT:
    "Los derechos y obligaciones del Propietario y del Usuario y terceros, en su caso, de conformidad con los TOS, el Sitio Web y el Servicio se rigen por, y se interpretarán de conformidad con las leyes de las Islas Vírgenes Británicas. Cualquier disputa, controversia o reclamación que surja de o en relación con los TOS, el Sitio Web y el Servicio será finalmente resuelta por arbitraje de acuerdo con las Reglas de Arbitraje de la Cámara de Comercio Internacional (París, Francia). El procedimiento de arbitraje se llevará a cabo en Tortola, Islas Vírgenes Británicas. El idioma del procedimiento será el inglés.",

  RESET_PASSWORD_TITLE: "Restablecer contraseña",
  RESET_PASSWORD_NEW_LABEL: "Nueva contraseña",
  RESET_PASSWORD_CONFIRM_LABEL: "Confirmar contraseña",
  RESET_PASSWORD_PASSWORD_PLACEHOLDER: "Ingrese la contraseña",
  RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER:
    "Ingrese la contraseña de confirmación",
  RESET_PASSWORD_SHOW_PASSWORD: "Mostrar contraseña",
  RESET_PASSWORD_SHOW_CONFIRM_PASSWORD: "Mostrar contraseña de confirmación",
  RESET_PASSWORD_PASSWORD_ERROR: "Por favor, ingrese una contraseña válida",
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR: "Las contraseñas no coinciden",
  RESET_PASSWORD_SUBMIT_BUTTON: "Enviar",

  REGISTER_USER_NAME: "Nombre de usuario",
  REGISTER_ENTER_USERNAME: "Ingrese el nombre de usuario",
  REGISTER_EMAIL_ADDRESS: "Dirección de correo electrónico",
  REGISTER_ENTER_EMAIL: "Ingrese su dirección de correo electrónico",
  REGISTER_COUNTRY: "País",
  REGISTER_PHONE_NUMBER: "Número de teléfono",
  REGISTER_ENTER_MOBILE_NUMBER: "Ingrese su número de teléfono móvil",
  REGISTER_PASSWORD: "Contraseña",
  REGISTER_ENTER_PASSWORD: "Ingrese la contraseña",
  REGISTER_CONFIRM_PASSWORD: "Confirmar contraseña",
  REGISTER_ENTER_CONFIRM_PASSWORD: "Ingrese la contraseña de confirmación",
  REGISTER_REFERRAL_CODE: "Código de referencia (opcional)",
  REGISTER_ENTER_REFERRAL_CODE: "Ingrese el código de referencia",
  REGISTER_OTP: "OTP",
  REGISTER_ENTER_OTP: "Ingrese el OTP",
  REGISTER_AGREE_TERMS: "Entiendo",
  REGISTER_TERMS_LINK: "Términos de uso",
  REGISTER_PRIVACY_LINK: "Política de privacidad",
  REGISTER_BUTTON: "Registrarse",
  REGISTER_ALREADY_REGISTER: "¿Ya registrado?",
  REGISTER_SIGN_IN: "Iniciar sesión",

  REFERRAL_HEAD_TITLE: "Referencia",
  REFERRAL_TOTAL_REFERRALS_LABEL: "Total de referencias",
  REFERRAL_TOTAL_INCOME_LABEL: "Ingresos totales por referencia",
  REFERRAL_TOTAL_USERS_INVEST_LABEL:
    "Total de usuarios recomendados que invierten",
  REFERRAL_LINK: "Enlace de referencia",
  REFERRAL_LEVEL_INCOME: "Ingresos por nivel",
  REFERRAL_LEVEL_1_INCOME: "Ingresos de nivel 1",
  REFERRAL_LEVEL_2_INCOME: "Ingresos de nivel 2",
  REFERRAL_LEVEL_3_INCOME: "Ingresos de nivel 3",
  REFERRAL_HISTORY_LABEL: "Historial de referencias",

  // BACKEND

  EMAIL_EXISTS: "El correo electrónico ya existe",
  PHONE_EXISTS: "El número de teléfono ya existe",
  INVALID_REFERRAL_CODE: "Código de referencia no válido",
  OTP_SENT:
    "OTP enviado a su dirección de correo electrónico, por favor ingrese un OTP",
  EXPIRED_OTP: "OTP caducado",
  INVALID_OTP: "OTP no válido",
  REGISTRATION_SUCCESS:
    "Registro completado - Enlace de activación enviado a su dirección de correo electrónico",
  SOMETHING_WENT_WRONG: "Algo salió mal",

  INVALID_EMAIL:
    "Por favor, introduzca una dirección de correo electrónico correcta",
  PHONE_NOT_EXISTS: "El número de teléfono no existe",
  IP_BLOCKED: "Su IP ha sido bloqueada",
  ACCOUNT_LOCKED: "Su cuenta sigue bloqueada",
  ACCOUNT_BLOCKED:
    "Su cuenta ha sido bloqueada. Por favor, inténtelo de nuevo más tarde",
  ACCOUNT_NOT_ACTIVATED: "Su cuenta aún no está activada",
  LOGIN_ATTEMPTS_EXCEEDED:
    "Ha excedido el número máximo de intentos de inicio de sesión. Por favor, inténtelo de nuevo después de unos minutos.",
  PASSWORD_INCORRECT: "Contraseña incorrecta",
  OTP_SENT:
    "OTP enviado a su dirección de correo electrónico, OTP es válido solo por 3 minutos",
  INVALID_MOBILE_NUMBER: "Número de móvil no válido",
  OTP_SENT_MOBILE: "OTP enviado correctamente, solo es válido por 10 minutos",
  OTP_EXPIRED: "OTP caducado",
  INVALID_OTP: "OTP no válido",
  NEED_TWO_FA: "Se necesita código 2FA",
  INVALID_TWO_FA: "Código 2FA no válido",
  EXPIRED_TWO_FA: "Código 2FA caducado",
  OTP_IS_REQUIRED: "Se requiere OTP",
  LOGIN_SUCCESS: "¡Inicio de sesión exitoso!",
  ERROR_SERVER: "Error en el servidor",

  EMAIL_NOT_EXISTS: "El correo electrónico no existe",
  CONFIRMATION_LINK_SENT:
    "Se ha enviado el enlace de confirmación a su correo registrado",
  SOMETHING_WENT_WRONG: "Algo salió mal",

  INVALID_LINK: "Enlace inválido!",
  OLD_PASSWORD_CANT_BE_NEW_PASSWORD:
    "La contraseña anterior no puede ser la nueva contraseña!",
  PASSWORD_UPDATED_SUCCESSFULLY: "Contraseña actualizada correctamente",
  SOMETHING_WENT_WRONG: "Algo salió mal",

  USER_NOT_FOUND: "Usuario no encontrado",
  INCORRECT_OLD_PASSWORD: "Contraseña anterior incorrecta",
  NEW_PASSWORD_CANT_BE_OLD_PASSWORD:
    "¡La nueva contraseña no puede ser la antigua!",
  PASSWORD_CHANGED_SUCCESSFULLY: "Contraseña cambiada exitosamente",

  PROFILE_DETAILS_UPDATED_SUCCESSFULLY:
    "Detalles del perfil actualizados correctamente",
  PROFILE_UPDATED_SUCCESSFULLY: "Perfil actualizado correctamente",
  SOMETHING_WENT_WRONG: "Algo salió mal",

  DEACTIVATED: "Desactivado",
  ACTIVATED: "Activado",
  USER_STATUS_CHANGED_SUCCESSFULLY: "Estado de usuario cambiado exitosamente",
  ACTIVATE_REGISTER_USER: "activate_register_user",
  ACTIVATION_MAIL_SENT_SUCCESSFULLY:
    "Correo de activación enviado exitosamente",
  ERROR_ON_SERVER: "Error en el servidor",

  NO_RECORD: "Sin registros",
  UPDATED_SUCCESS: "Actualización exitosa",
  INVALID_USER: "Usuario inválido",
  INVALID_MOBILE_NO: "Ingrese un número de móvil correcto",
  RESEND_OTP_MOBILE:
    "Código de verificación enviado a su número de móvil, el código de verificación es válido solo por 10 minutos",
  NEXT_OTP_AFTER_3_MINUTES:
    "Próximo código de verificación después de 3 minutos",
  RESEND_OTP_EMAIL:
    "Código de verificación enviado a su dirección de correo electrónico, el código de verificación es válido solo por 3 minutos",
  NAME_FIELD_REQUIRED: "El campo Nombre es obligatorio",
  USERNAME_ALPHABETS_SPACES:
    "El nombre de usuario debe contener letras y espacios",
  USER_NOT_FOUND: "Usuario no encontrado",
  PROFILE_UPDATED_SUCCESSFULLY: "Perfil actualizado correctamente",
  INVALID_USER_DETAILS: "Detalles de usuario no válidos",
  INVALID_TYPE: "Tipo no válido",
  CODE_VERIFIED: "Código verificado",
  ERROR_ON_SERVER: "Error en el servidor",
  INVALID_VERIFICATION_CODE: "Código de verificación inválido",
  VERIFICATION_CODE_EXPIRED: "Código de verificación caducado",
  CODE_VERIFIED: "Código verificado",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER:
    "Algo salió mal, inténtelo de nuevo más tarde",
  REQUIRED: "Requerido",
  USERNAME_ALPHA_SPACE:
    "El nombre de usuario solo puede contener letras y espacios",
  EMAIL_REQUIRED: "El campo de correo electrónico es obligatorio",
  INVALID_EMAIL: "Correo electrónico inválido",
  PHONE_NO_REQUIRED: "El campo del número de teléfono es obligatorio",
  COUNTRY_CODE_REQUIRED: "El campo del código de país es obligatorio",
  PASSWORD_REQUIRED: "El campo de contraseña es obligatorio",
  INVALID_PASSWORD_FORMAT:
    "La contraseña debe contener al menos una mayúscula, una minúscula, un número, un carácter especial y tener una longitud entre 6 y 18 caracteres",
  PASSWORD_LENGTH_EXCEEDED:
    "La contraseña debe contener al menos una mayúscula, una minúscula, un número, un carácter especial y tener una longitud entre 6 y 18 caracteres",
  CONFIRM_PASSWORD_REQUIRED:
    "El campo de confirmación de contraseña es obligatorio",
  PASSWORD_MISMATCH:
    "La contraseña y la confirmación de contraseña deben coincidir",
  TERMS_REQUIRED: "El campo de Términos y Política es obligatorio",
  USERID_FIELD_IS_REQUIRED: "El campo UserId es obligatorio",
  FIRST_NAME_FIELD_IS_REQUIRED: "El campo Nombre es obligatorio",
  LAST_NAME_FIELD_IS_REQUIRED: "El campo Apellido es obligatorio",
  PHONE_CODE_FIELD_IS_REQUIRED: "El campo Código de país es obligatorio",
  PHONE_NUMBER_FIELD_IS_REQUIRED: "El campo Número de teléfono es obligatorio",
  PHONE_NUMBER_IS_INVALID: "El número de teléfono es inválido",
  ADDRESS_FIELD_IS_REQUIRED: "El campo Dirección es obligatorio",
  COUNTRY_FIELD_IS_REQUIRED: "El campo País es obligatorio",
  CITY_FIELD_IS_REQUIRED: "El campo Ciudad es obligatorio",
  POSTAL_CODE_FIELD_IS_REQUIRED: "El campo Código postal es obligatorio",

  OLD_PASSWORD_FIELD_IS_REQUIRED:
    "El campo de contraseña antigua es obligatorio",
  NEW_PASSWORD_FIELD_IS_REQUIRED: "El campo de nueva contraseña es obligatorio",
  PASSWORD_REQUIREMENTS_NOT_MET:
    "La contraseña debe contener al menos una mayúscula, al menos una minúscula, al menos un número, al menos un carácter especial y tener entre 6 y 18 caracteres de longitud",
  CONFIRM_NEW_PASSWORD_FIELD_IS_REQUIRED:
    "El campo de confirmar nueva contraseña es obligatorio",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "La nueva contraseña y la confirmación de la contraseña deben coincidir",
  OLD_AND_NEW_PASSWORD_MUST_BE_DIFFERENT:
    "La contraseña antigua y la nueva contraseña deben ser diferentes",
  AUTH_TOKEN_FIELD_IS_REQUIRED:
    "El campo de token de autenticación es obligatorio",
  CONFIRM_PASSWORD_FIELD_IS_REQUIRED:
    "El campo de confirmar contraseña es obligatorio",
  NEW_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "La nueva contraseña y la confirmación de la contraseña deben coincidir",

  USER_ASSET_FIELD_IS_REQUIRED: "El campo de activo del usuario es obligatorio",
  INVALID_TRANSACTION_ID: "Id de transacción no válido",
  REQUIRED: "Requerido",
  AMOUNT_FIELD_IS_REQUIRED: "El campo de cantidad es obligatorio",
  INVALID_AMOUNT: "Cantidad no válida",
  USER_ASSET_FIELD_IS_REQUIRED: "El campo de activo de usuario es obligatorio",
  INVALID_CURRENCY: "Moneda inválida",
  AMOUNT_FIELD_IS_REQUIRED: "El campo de cantidad es obligatorio",
  INVALID_TRANSACTION_ID: "ID de transacción no válido",
  REQUIRED: "Requerido",
  INVALID_CURRENCY_ID: "ID de moneda inválido",
  RECEIVER_ADDRESS_FIELD_IS_REQUIRED:
    "Se requiere el campo de dirección del receptor",
  INVALID_ADDRESS: "Dirección no válida",
  AMOUNT_FIELD_IS_REQUIRED: "Se requiere el campo de cantidad",
  PLEASE_ENTER_VALID_AMOUNT: "Por favor ingrese una cantidad válida",
  MIN_WITHDRAW_AMOUNT_MESSAGE:
    "Por favor ingrese una cantidad de retiro mayor o igual a",
  OTP_STATUS_IS_REQUIRED: "Se requiere el estado OTP",
  INVALID_OTP_STATUS: "Estado OTP no válido",
  PLEASE_ENTER_OTP: "Por favor ingrese un OTP",
  THERE_IS_NO_DATA: "No hay datos",
  PLEASE_ENTER_AMOUNT_GREATER_THAN_OR_EQUAL_TO:
    "Por favor, introduzca un monto mayor o igual a ",
  DEPOSIT_REQUEST_SENT_SUCCESSFULLY:
    "Solicitud de depósito enviada correctamente",
  INVALID_CURRENCY_DATA: "Datos de moneda no válidos",
  INVALID_WITHDRAW_CURRENCY_DATA: "Datos de moneda de retiro no válidos",
  MIN_WITHDRAW_AMOUNT_REQUIRED:
    "El monto mínimo de retiro debe ser mayor o igual a ",
  MAX_WITHDRAW_AMOUNT_REQUIRED: "El monto de retiro debe ser menor o igual a ",
  INSUFFICIENT_BALANCE: "Saldo insuficiente",
  WITHDRAW_AMOUNT_TOO_LOW: "Monto de retiro demasiado bajo",
  OTP_SENT_SUCCESSFULLY:
    "OTP enviado a su dirección de correo electrónico. Por favor, ingrese un OTP",
  EXPIRED_OTP: "OTP caducado",
  INVALID_OTP: "OTP no válido",
  WITHDRAW_REQUEST_SENT_SUCCESSFULLY:
    "Solicitud de retiro enviada exitosamente",
  ERROR_ON_SERVER: "Error en el servidor",
  RECORD_NOT_FOUND: "¡Registro no encontrado!",
  GENERATED_SUCCESSFULLY: "Generado exitosamente",
  STAKING_CURRENCY_NOT_EXISTS: "La moneda de staking no existe",
  INVESTMENT_PLAN_ADDED_SUCCESSFULLY:
    "Plan de inversión agregado exitosamente.",
  STAKING_CURRENCY_NOT_EXISTS: "La moneda de participación no existe",
  INVESTMENT_PLAN_UPDATED_SUCCESSFULLY:
    "Plan de inversión actualizado correctamente",
  FETCH_SUCCESS: "Recuperación exitosa",
  MIN_AMOUNT_GREATER_THAN_OR_EQUAL: "El monto mínimo debe ser mayor o igual a ",
  AMOUNT_LESS_THAN_OR_EQUAL: "El monto debe ser menor o igual a ",
  INVALID_STAKING: "Staking inválido",
  INVALID_CURRENCY: "Moneda inválida",
  DEACTIVE: "Inactivo",
  NO_RECORD: "Sin registro",
  INVALID_ASSET: "Activo no válido",
  INSUFFICIENT_BALANCE: "No hay suficiente activo en su saldo.",
  INVESTMENT_ORDER_ADDED_SUCCESSFULLY:
    "Orden de inversión agregada correctamente.",
  KINDLY_CHOOSE_DURATION_DAYS: "Por favor, elija los días de duración",
  NO_RECORD: "No hay registro",
  ALREADY_CANCELLED: "Ya cancelado",
  INVALID_CURRENCY: "Moneda inválida",
  RELEASED_SUCCESSFULLY: "Liberado exitosamente",
  ALREADY_REDEMPTION_COMPLETED: "Ya se ha completado la redención",
  INSUFFICIENT_AMOUNT: "Cantidad insuficiente",
  ENTER_VALID_AMOUNT: "Ingrese una cantidad válida",
  EMAIL_ALREADY_VERIFIED:
    "Su correo electrónico ya ha sido verificado, puede iniciar sesión",
  ACCOUNT_DEACTIVATED_BY_ADMIN:
    "Su cuenta ha sido desactivada por el administrador. Por favor, póngase en contacto con el administrador",
  EMAIL_VERIFIED_CAN_LOGIN:
    "Su correo electrónico ha sido verificado, puede iniciar sesión ahora",
  AMOUNT_ZERO: "El monto no puede ser cero",
  AMOUNT_NEGATIVE: "El monto no puede ser negativo",
  AMOUNT_EXCEEDS_STAKED: "El monto debe ser menor o igual a {{stakedAmount}}",
  SUCCESS_COPY: "Copiado correctamente al portapapeles",
  INTEREST_HISTORY: "Historial de intereses",
  SEE_MORE: "Ver más",
  STRATEGY: "Estrategia",
  PERIOD: "Período",
  MANAGEMENT_FEE: "Tarifa de gestión %",
  MINIMUM_INVESTMENT: "Inversión mínima",
  AMOUNT: "Cantidad",
  CLOSE: "Cerrar",
  INVEST: "Invertir",
  MONTHLY: "Mensual",
  PROCESS: "Proceso",
  UNLOCKED: "Desbloqueado",
  LOCKED: "Bloqueado",
  DAYS: "Días",
  LOGOUT_SUCCESS: "Cierre de sesión exitoso",
  INVEST_DASHBOARD_ROI_PERCENT: "Porcentaje de ROI",
  HOME: "Inicio",
  INVEST: "Invertir",
  WALLET: "Billetera",
  REFERRAL: "Referencia",
  DASHBOARD: "Tablero",
  LOGOUT: "Cerrar sesión",
  THERE_ARE_NO_RECORDS_TO_DISPLAY: "No hay registros para mostrar",
  TOTAL_REFERRAL_INCOME_EARNED: "Total de Ingresos por Referidos Ganados",
  TOTAL_REFERRAL_USERS_INVEST: "Total de Usuarios Referidos Invertidos",
  TYPE: "Tipo",
  TRX_ID: "ID de Transacción",
  TO_ADDRESS: "Dirección de Destino",
  FROM_ADDRESS: "Dirección de Origen",
  CURRENCY: "Moneda",
  FEE: "Tarifa",
  REWARD: "Recompensa",
  USER_CODE: "Código de usuario",
  REWARD_CURRENCY: "Moneda de recompensa",
  REWARD_LEVEL: "Nivel de recompensa",
  REWARD_PERCENT: "Porcentaje de recompensa",
  CREATED_AT: "Creado en",
  CUSTOM_SOLUTION: "Solución Personalizada",
  PROFIT: "Beneficio",
  BTC_MINING_ALLOCATOR_ALLOCATION_BALANCED:
    "Asignación del 40 % a un asignador de minería de BTC",
  BTC_ALLOCATION_BALANCED: "Asignación del 30 % a BTC",
  ETH_ALLOCATION_BALANCED: "Asignación del 20 % a ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_BALANCED:
    "Asignación del 10 % a Estrategias de Mantis en busca de Alfa",
  BTC_MINING_ALLOCATOR_ALLOCATION_GROWTH:
    "Asignación del 25 % a un asignador de minería de BTC",
  BTC_ALLOCATION_GROWTH: "Asignación del 10 % a BTC",
  ETH_ALLOCATION_GROWTH: "Asignación del 15 % a ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_GROWTH:
    "Asignación del 50 % a Estrategias de Mantis en busca de Alfa",
  BTC_MINING_ALLOCATOR_ALLOCATION_HIGH_YIELD:
    "Asignación del 30 % a un asignador de minería de BTC",
  BTC_ALLOCATION_HIGH_YIELD: "Asignación del 20 % a BTC",
  ETH_ALLOCATION_HIGH_YIELD: "Asignación del 20 % a ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_HIGH_YIELD:
    "Asignación del 30 % a Estrategias de Mantis en busca de Alfa",
};
