// helper route
import ConditionRoute from "./components/Route/ConditionRoute";




// import pages
import Home from "./pages/home";
import Wallet from "./pages/wallet";
import Login from "./pages/login";
import Register from "./pages/register";
import ForgotPassword from "./pages/forgot-password";
import Referral from "./pages/referral";
import Invest from "./pages/invest";
import InvestmentDetails from "./pages/investment-details";
import Dashboard from "./pages/dashboard";
import EmailVerification from "./pages/email-verification";
import ResetPassword from "./pages/reset-password";
import NewsEvents from "./pages/news-events";

import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Profile from "./pages/profile";
import History from "./pages/history";
import URL_LOGIN from "./pages/url-login";


const initialRoutes = [
  // PUBLIC
  { path: "/", type: "public", component: Home },
  {
    path: "email-verification/:id",
    type: "public",
    component: EmailVerification,
  },

  // AUTH
  { path: "login", type: "auth", component: Login },
  { path: "register", type: "auth", component: Register },
  { path: "forgot-password", type: "auth", component: ForgotPassword },
  { path: "reset-password/:id", type: "auth", component: ResetPassword },
  { path: "privacy", type: "public", component: Privacy },
  { path: "terms", type: "public", component: Terms },
  { path: "url-login/:authToken", type: "public", component: URL_LOGIN },

  // PRIVATE
  { path: "dashboard", type: "private", component: Dashboard },
  { path: "referral", type: "private", component: Referral },
  { path: "wallet", type: "private", component: Wallet },
  { path: "invest", type: "private", component: Invest },
  {
    path: "investment-details/:id",
    type: "private",
    component: InvestmentDetails,
  },
  { path: "news-events", type: "public", component: NewsEvents },
  { path: "profile", type: "private", component: Profile },
  { path: "history", type: "private", component: History },
];

const routes = initialRoutes.map(({ type, component: Component, ...rest }) => {
  return {
    element: (
      <ConditionRoute type={type}>
        <Component />
      </ConditionRoute>
    ),
    ...rest,
  };
});

export default routes;
