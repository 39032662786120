import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { padSingleDigit, toFixedDown } from "../../lib/roundOf";
import { coinWithdrawReq } from "../../api/walletAction";
import { toastAlert } from "../../lib/toastAlert";
import { upperCase } from "../../lib/stringCase";

import ResponseModal from "../ResponseModal";
import { useTranslation } from "react-i18next";

let initialValue = {
  amount: 0,
  receiverAddress: "",
  otpStatus: false,
  otp: "",
};

const Withdraw = (props) => {
  const { refetch } = props;
  const { t, i18n } = useTranslation();
  const [tokens, setTokens] = useState([]);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [depValues, setDepValues] = useState({ symbol: "", type: "" });
  const { currencies } = useSelector((state) => state);
  const { priceConversion } = useSelector((state) => state);
  // const { wallet } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState([]);
  const [CurrencyData, setCurrencyData] = useState({});
  const { amount, receiverAddress, otpStatus, otp } = formValue;

  //response modal
  const [response, setResponse] = useState(false);
  const [responseData, setResponseData] = useState({});

  //otpbox
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(false);
  const [resendOtpBtnStatus, setResendOtpBtnStatus] = useState(false);
  const [otpTextBox, setOtpTextBox] = useState(false);

  const setCurrencyList = () => {
    let curList = [];
    if (!isEmpty(currencies) && currencies.length > 0) {
      let currency =
        currencies &&
        currencies.length > 0 &&
        currencies.find((el) => el.currencySymbol == "USDT");
      let firstValue = {
        type: currency.type,
        symbol: currency.currencySymbol,
        tokenType: currency.type === "token" ? currency.tokenType : "",
        decimal: currency.decimals,
        _id: currency._id,
      };
      setDepValues(firstValue);
      for (let i = 0; i < currencies.length; i++) {
        currencies[i].status === "active" &&
          currencies[i].currencySymbol !== "USD" &&
          curList.push({
            type: currencies[i].type,
            symbol: currencies[i].currencySymbol,
          });
      }
      if (curList.length > 0) {
        let uniqueArray = Array.from(
          new Set(curList.map((obj) => obj.symbol))
        ).map((id) => curList.find((obj) => obj.symbol === id));
        curList.length > 0 && setCurrency(uniqueArray);
      }
    }
  };

  const setTokenType = (symbol) => {
    console.log("symbol: ", symbol);
    let arr = [];
    let decimal = "";
    let _id = "";
    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i].currencySymbol === symbol) {
        arr.push(currencies[i].tokenType);
        decimal = currencies[i].decimals;
        _id = currencies[i]._id;
      }
    }
    setTokens(arr);
    let CurrencyId = "";
    if (depValues && depValues?._id) {
      let currency =
        currencies &&
        currencies.length > 0 &&
        currencies.find((el) => el.tokenType == arr[0]);
      setCurrencyData(currency);
      CurrencyId = currency?._id;
    }

    setDepValues((prev) => ({
      ...prev,
      type: arr.length > 0 && arr[0] !== "" ? "token" : "crypto",
      tokenType: arr.length > 0 && arr[0] !== "" ? arr[0] : "",
      decimal,
    }));
    conversion();
  };

  const conversion = () => {
    const cnv = priceConversion.find(
      (item) =>
        item.baseSymbol === depValues.symbol && item.convertSymbol === "USD"
    );

    !isEmpty(amount) && depValues.symbol !== "USDT" && !isEmpty(cnv)
      ? setPrice(amount / cnv.convertPrice)
      : depValues.symbol === "USDT"
      ? setPrice(amount)
      : setPrice(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const handleSelectCoin = async (e) => {
    let currencyData = currencies.find(
      (item) => item.currencySymbol == e.target.value
    );
    setDepValues((prev) => ({
      ...prev,
      symbol: e.target.value,
      _id: currencyData?._id,
    }));

    setTokenType(e.target.value);
    setErrors({});
  };

  // const SelectCurrency = (tokenType) => {
  //   setDepValues((prev) => ({
  //     ...prev,
  //     tokenType: tokenType,
  //   }));
  //   if (depValues && depValues._id) {
  //     let currency = currencies && currencies.length > 0 && currencies.find((el) => el._id.toString() == depValues._id.toString());
  //     setCurrencyData(currency)
  //   }
  //   if (tokenType) {
  //     setErrors({})
  //   }
  // }

  const SelectNetwork = (e) => {
    e.preventDefault();
    const { value } = e.target;
    let CurrencyId = "";
    if (value && value != "") {
      let currency =
        currencies &&
        currencies.length > 0 &&
        currencies.find((el) => el.tokenType == value);
      setCurrencyData(currency);
      CurrencyId = currency._id;
    }
    setDepValues((prev) => ({
      ...prev,
      tokenType: value,
      _id: CurrencyId,
    }));
    if (value) {
      setErrors({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let data = {
        currencyId: depValues._id,
        receiverAddress,
        amount,
        otpStatus: otpTextBox,
        otp,
        coin: depValues.symbol,
        tokenType: depValues.tokenType,
      };

      // return
      const { status, message, errors } = await coinWithdrawReq(data, dispatch);
      if (status === "success") {
        setLoading(false);
        // toastAlert("success", message, "coinWithdrawReq");
        setErrors({});
        setFormValue(initialValue);
        setOtpTextBox(false);
        setResponse(true);
        setResponseData({ message, type: "Withdraw" });
        refetch();
      } else if (status === "OTP") {
        setLoading(false);
        toastAlert("success", message, "coinWithdrawReq");
        setFormValue({ ...formValue, ...{ otpStatus: true } });

        setMinutes(2);
        setSeconds(59);
        setResendOtpBtnStatus(true);
        setOtpTextBox(true);
        setTimer(false);
      } else {
        setLoading(false);
        if (errors) {
          setErrors(errors);
        }
        if (message) {
          setResponse(true);
          setResponseData({ message, type: "Withdraw" });
          // toastAlert("error", message, "coinWithdrawReq");
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handlecloseResponseModal = () => {
    setResponse(false);
    setResponseData({});
  };

  useEffect(() => {
    conversion();
  }, [amount, depValues?.symbol]);

  useEffect(() => {
    setCurrencyList();
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
      if (seconds == 1 && minutes == 0 && resendOtpBtnStatus) {
        setTimer(true);
        setOtpTextBox(false);
      }
    };
  });
  console.log("-----------dep Value", depValues);
  return (
    <>
      <ResponseModal
        show={response}
        record={responseData}
        onHide={handlecloseResponseModal}
      />

      <div className="col-xl-6">
        <div className="chart_box">
          <div className="inner_subtitle">
            <h2>{t("WITHDRAW_TITLE")}</h2>
          </div>
          {otpStatus ? (
            <div>
              {otpStatus && (minutes !== 0 || seconds !== 0) && (
                <p className="text-light">
                  {" "}
                  {t("WITHDRAW_OTP_VALID_UP_TO")} :{" "}
                  {`${minutes}:${padSingleDigit(seconds)}`}{" "}
                </p>
              )}
              <div className="mb-3">
                <label htmlFor="otp" className="form-label text-light">
                  {t("WITHDRAW_OTP_LABEL")}
                </label>
                <input
                  type="number"
                  className="form-control primary_inp"
                  id="otp"
                  placeholder={t("WITHDRAW_ENTER_OTP")}
                  name="otp"
                  onChange={handleChange}
                  value={otp}
                />
                <span className="text-danger">{errors?.otp}</span>
              </div>

              {otpTextBox ? (
                <div className="form-group">
                  <button onClick={handleSubmit} className="secondary_btn">
                    {t("WITHDRAW_SUBMIT")}
                  </button>
                </div>
              ) : (
                <div className="form-group">
                  <button onClick={handleSubmit} className="secondary_btn">
                    {t("WITHDRAW_RESEND_OTP")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <form className="primary_form">
              <div className="row g-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{t("WITHDRAW_SELECT_COIN")}</label>
                    <select
                      className="form-select primary_inp"
                      onClick={handleSelectCoin}
                    >
                      <option value={""}>{t("WITHDRAW_SELECT")}</option>
                      {!isEmpty(currency) &&
                        currency.length > 0 &&
                        currency.map((item) => {
                          if (item.symbol === "USDT")
                            return (
                              <option key={item.symbol}>{item.symbol}</option>
                            );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-group">
                    <label>{t("WITHDRAW_AMOUNT")}</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control primary_inp"
                        placeholder={t("WITHDRAW_ENTER_AMOUNT")}
                        value={amount}
                        name="amount"
                        onChange={handleChange}
                      />
                      <span className="input-group-text">USD</span>
                    </div>
                    <span className="text-danger">{errors?.amount}</span>
                  </div>
                </div>
                <div className="col-md">
                  {tokens.length > 0 && tokens[0] !== "" && (
                    <div className="form-group">
                      <label>{t("WITHDRAW_SELECT_NETWORK")}</label>
                      <select
                        className="form-select primary_inp"
                        onChange={SelectNetwork}
                        value={depValues?.tokenType}
                      >
                        {!isEmpty(tokens) &&
                          tokens.length > 0 &&
                          tokens.map((item) => (
                            <option key={item} value={item}>
                              {upperCase(item)}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className="row g-3">
                <div className="form-group">
                  <label>{t("WITHDRAW_CONVERSION_RATE")}</label>
                  <input
                    type="text"
                    className="form-control primary_inp"
                    placeholder={t("WITHDRAW_ENTER_ADDRESS")}
                    disabled
                    value={`${
                      price > 0 ? toFixedDown(price, depValues.decimal) : 0
                    } ${depValues.symbol}`}
                  />
                  <span className="text-danger">{errors?.finalAmount}</span>
                </div>
                <div className="col-md">
                  <div className="form-group">
                    <label>{t("WITHDRAW_ADDRESS")}</label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      placeholder={t("WITHDRAW_ENTER_ADDRESS")}
                      name="receiverAddress"
                      value={receiverAddress}
                      onChange={handleChange}
                    />
                    <span className="text-danger">
                      {errors?.receiverAddress}
                    </span>
                  </div>
                </div>
              </div>
              <button
                onClick={handleSubmit}
                disabled={loading}
                className="secondary_btn"
              >
                {t("WITHDRAW_WITHDRAW_NOW")}
              </button>
            </form>
          )}
          <p></p>
          {!isEmpty(CurrencyData) && (
            <div className="text-light">
              <h5>{t("WITHDRAW_WITHDRAW_NOTES")}</h5>
              <ul>
                <li>
                  {t("WITHDRAW_SEND_ONLY", {
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
                <li>
                  {t("WITHDRAW_MINIMUM_WITHDRAW", {
                    minimumWithdraw: CurrencyData.minimumWithdraw,
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
                <li>
                  {t("WITHDRAW_MAXIMUM_WITHDRAW", {
                    maximumWithdraw: CurrencyData.maximumWithdraw,
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
                <li>
                  {t("WITHDRAW_WITHDRAWAL_FEE", {
                    withdrawFee: CurrencyData.withdrawFee,
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
                <li>
                  {t("WITHDRAW_SENDING_COIN", {
                    currencySymbol: CurrencyData.currencySymbol,
                  })}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Withdraw;
