import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link } from "react-router-dom";
// AOS
import AOS from "aos";
import "aos/dist/aos.css";
import LoginForm from "../components/Auth/LoginForm.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Login(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="auth_wrapper">
        <div className="container">
          <div className="row align-items-center">
            <LoginForm/>
            <div
              className="col-lg-6 d-none d-lg-block"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <div className="banner_img">
                <img
                  src={require("../assets/images/banner_img1.png")}
                  alt="Banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
