// import lib
import isEmpty from './isEmpty';

export const toFixed = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item)
      return item.toFixed(type)
    }
    return ''
  } catch (err) {
    return ''
  }
}

export const currencyFormat = (item) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = item.toString();
      let splitValue = item.split('.')
      return splitValue[1] ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${splitValue[1]}` : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return ''
  } catch (err) {
    return ''
  }
}

export const floatNumDigit = (num) => {
  num = num.toString();
  let split = num.split('.');
  if (split.length > 1) {
    return split[1].length;
  }

  return 0;
}
export const toFixedDown = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item);
      let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
        m = item.toString().match(decReg);
      return m ? parseFloat(m[1]) : item.valueOf();
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const dynamicFind = (array, baseSymbol) => {
  for (const item of array) {
    if (item.baseSymbol === baseSymbol && item.convertSymbol == 'USD') {
      return item;
    }
  }
  return null; // If the name is not found
}

export const padSingleDigit = (number) => {
  // Convert the number to a string
  let numberStr = number.toString();

  // Check if the number is a single digit
  if (numberStr.length === 1) {
    // Pad the single digit with a leading zero
    return numberStr.padStart(2, '0');
  } else {
    // Return the original number if it's already two digits or larger
    return numberStr;
  }
}

export const formatNumber = (num) => {
  if (num % 10000000 === 0) {
    return (num / 10000000) + 'M';
  } else if (num % 1000 === 0) {
    return (num / 1000) + 'K';
  } else {
    return num;
  }
}

export const roundToNearestPointFive = (number) => {
  // Multiply the number by 2, round to the nearest integer, then divide by 2
  return Math.round(number * 2) / 2;
}
