import React from "react";
import CapGrowthBarChart from "./capGrowthBarChart.js";
import { useTranslation } from "react-i18next";

// import lib
// import { generateMonthYearArray, fourMonthsBefore } from "../../lib/datemanipulation.js";

export default function Chart(props) {
    const { activeData, /* investData, CurDate, statkeData */ } = props;
    const {t} = useTranslation()

    return (
      <div>
        <div className="row dash_box_row">
          <div className="col-xl-12">
            <div className="chart_box">
              <div className="inner_subtitle">
                <h2>{t("INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL")}</h2>
              </div>
              <CapGrowthBarChart original={activeData} />
            </div>
          </div>
        </div>
      </div>
    );
}