import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { Modal, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

//import component
import ChangeProfile from '../components/Profile/ChangeProfile'
import ChangePassword from '../components/Profile/ChangePassword'
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Profile(props) {
  const { account } = useSelector((state) => state);
  const { t, i18n } = useTranslation();

  const [profModal, setProf] = useState(false);
  const [passModal, setPass] = useState(false);
  const handleClose = () => setProf(false);
  const handleClose1 = () => setPass(false);
  const handleShow = () => setProf(true);
  const handleShow1 = () => setPass(true);

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="inner_head_title">
                  {t("PROFILE_PROFILE_TITLE")}
                </h2>
              </div>
              <div className="col-lg-12">
                <div className="dash_box_shadow profile-view mb-4">
                  <div className="user-info">
                    <div className="user-info-card">
                      <span>{t("PROFILE_USERNAME_LABEL")}</span>
                      <p>{account?.userName}</p>
                    </div>
                    <div className="user-info-card">
                      <span>{t("PROFILE_EMAIL_LABEL")}</span>
                      <p>{account?.email}</p>
                    </div>
                    <div className="user-info-card">
                      <span>{t("PROFILE_PHONE_NUMBER_LABEL")}</span>
                      <p>{`+${account?.phnCode} ${account?.phnNo}`}</p>
                    </div>
                  </div>
                </div>
                <div className="dash_box_shadow profile-view mb-4">
                  <div className="set_flx">
                    <div className="flex-profile">
                      <div className="fp-left">
                        <p>{t("PROFILE_CHANGE_USERNAME_LABEL")}</p>
                      </div>
                      <button
                        className="secondary_btn"
                        onClick={() => handleShow()}
                      >
                        {t("PROFILE_CHANGE_BUTTON")}
                      </button>
                    </div>
                    <div className="flex-profile">
                      <div className="fp-left">
                        <p>{t("PROFILE_CHANGE_PASSWORD_LABEL")}</p>
                        <p>
                          <small>{t("PROFILE_PASSWORD_DESCRIPTION")}</small>
                        </p>
                      </div>
                      <button className="secondary_btn" onClick={handleShow1}>
                        {t("PROFILE_CHANGE_BUTTON")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ChangeProfile show={profModal} handleClose={handleClose} />
      <ChangePassword show={passModal} handleClose={handleClose1} />
    </div>
  );
}
