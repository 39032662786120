import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const FAQComponent = (props) => {
  const { data } = props;
  const [count, setCount] = useState(5);
  const [FAQ, setFAQ] = useState([]);
  const { t, i18n } = useTranslation();

  const handleCount = () => {
    setCount(data.length);
  };

  useEffect(() => {
    let array = [];
    data &&
      data.length > 0 &&
      data.map((el, key) => {
        if (count > key) array.push(el);
      });
    setFAQ(array);
  }, [data, count]);

  return (
    <div>
      <section className="section faq_section">
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {t("FAQ_COMPONENT_MAIN_TITLE")}
                </h2>
                <div
                  className="accordion custom_accordian"
                  id="accordion_faq"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  {FAQ &&
                    FAQ.length > 0 &&
                    FAQ.map((item, key) => {
                      return (
                        <div className="accordion-item" key={key}>
                          <h2 className="accordion-header" id={`heading${key}`}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#faqcollapse${key}`}
                              aria-expanded="false"
                              aria-controls={`faqcollapse${key}`}
                            >
                              {key + 1}. {item.question}
                            </button>
                          </h2>
                          <div
                            id={`faqcollapse${key}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${key}`}
                            data-bs-parent="#accordion_faq"
                          >
                            <div className="accordion-body">
                              <p className="para_tag">{item.answer}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {data && data.length !== count && (
                  <div className="text-center">
                    <button className="primary_btn" onClick={handleCount}>
                      {t("FAQ_COMPONENT_VIEW_MORE_BUTTON")}
                      <span className="icon-right"></span>
                      <span className="icon-right after"></span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQComponent;
