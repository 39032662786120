import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useTranslation } from "react-i18next";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terms(props) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="inner_head_title">{t("TERMS_TITLE")}</h2>
              </div>
              <div className="col-xl-12 text-white">
                <h2>{t("TERMS_GENERAL")}</h2>
                <p>{t("TERMS_GENERAL_CONTENT_A")}</p>
                <p>{t("TERMS_GENERAL_CONTENT_B")}</p>
                <p>{t("TERMS_GENERAL_CONTENT_C")}</p>

                <h2>{t("TERMS_EXCLUSIONS_LIMITATIONS_TITLE")}</h2>
                <p>{t("TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A")}</p>
                <p>{t("TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B")}</p>

                <h2>{t("TERMS_LIMITATION_LIABILITIES_TITLE")}</h2>
                <p>{t("TERMS_LIMITATION_LIABILITIES_CONTENT_A")}</p>
                <p>{t("TERMS_LIMITATION_LIABILITIES_CONTENT_B")}</p>
                <p>{t("TERMS_LIMITATION_LIABILITIES_CONTENT_C")}</p>

                <h2>{t("TERMS_PAYOUTS_TITLE")}</h2>
                <p>{t("TERMS_PAYOUTS_CONTENT_A")}</p>
                <p>{t("TERMS_PAYOUTS_CONTENT_B")}</p>
                <p>{t("TERMS_PAYOUTS_CONTENT_C")}</p>
                <p>{t("TERMS_PAYOUTS_CONTENT_D")}</p>
                <p>{t("TERMS_PAYOUTS_CONTENT_E")}</p>
                <p>{t("TERMS_PAYOUTS_CONTENT_F")}</p>

                <h2>{t("TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE")}</h2>
                <p>{t("TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A")}</p>
                <p>{t("TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B")}</p>
                <p>{t("TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C")}</p>
                <p>{t("TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D")}</p>

                <h2>{t("TERMS_WAIVER_TITLE")}</h2>
                <p>{t("TERMS_WAIVER_CONTENT")}</p>

                <h2>{t("TERMS_FORCE_MAJEURE_TITLE")}</h2>
                <p>{t("TERMS_FORCE_MAJEURE_CONTENT")}</p>

                <h2>{t("TERMS_TERMINATION_TITLE")}</h2>
                <p>{t("TERMS_TERMINATION_CONTENT_A")}</p>

                <h2>{t("TERMS_SEVERABILITY_CLAUSE_TITLE")}</h2>
                <p>{t("TERMS_SEVERABILITY_CLAUSE_CONTENT")}</p>

                <h2>{t("TERMS_GOVERNING_LAW_TITLE")}</h2>
                <p>{t("TERMS_GOVERNING_LAW_CONTENT")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
