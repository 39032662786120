import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
//import api
import { changeProf, viewUserProfile } from "../../api/users";
//import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import { useTranslation } from "react-i18next";

const ChangeProfile = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      if (isEmpty(name)) {
        return toastAlert("error", t("NAME_FIELD_REQUIRED"), "changeName");
      }
      let data = {
        name,
      };
      let { status, message } = await changeProf(data);
      const msg = t(message);
      if (status == "success") {
        viewUserProfile(dispatch);
        toastAlert("success", msg, "changeName");
        handleClose();
      } else {
        toastAlert("error", msg, "changeName");
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        toastAlert("error", err.response.data.message, "changeName");
      }
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("CHANGE_PROFILE_CHANGE_USERNAME_TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("CHANGE_PROFILE_ENTER_NEW_USERNAME")}</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-flex-button">
          <button className="secondary_btn" onClick={handleClose}>
            {t("CHANGE_PROFILE_CLOSE_BUTTON")}
          </button>
          <button className="secondary_btn" onClick={handleClick}>
            {t("CHANGE_PROFILE_SAVE_BUTTON")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ChangeProfile;
