import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import store from "../store";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { languageChange, logout } from "../api/users";
import { useTranslation } from "react-i18next";
import isLogin from "../lib/isLogin";

export default function Navbar() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    loadScript();
  }, []);
  const { isAuth } = store.getState().auth;
  const [lan, setLan] = useState("en");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let language = localStorage.getItem("mantis_language");
  function loadScript() {
    // Navbar Sticky
    let t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      60 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }
  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setLan(languageCode);
    localStorage.setItem("mantis_language", languageCode);
    if (isLogin()) {
      languageChange({ langCode: languageCode });
    }
  };
  const langSetup = async (language) => {
    try {
      setTimeout(() => {
        i18n.changeLanguage(language);
      }, 100);
      setLan(language);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    console.log("LAN", language);
    if (language) {
      langSetup(language);
    }
  }, [language]);
  // setInterval(() => {
  //   let ln = ["en", "sp", "fr", "du", "ch"];
  //   for (let i = 0; i < ln.length;i++) {
  //     changeLanguage(ln[i]);
  //   }
  // }, 1000);
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <ul className="navbar-nav justify-content-end flex-grow-1 navbar_middle">
            {location.pathname !== "/" && (
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  {t("HOME")}
                </Link>
              </li>
            )}
            {isAuth && (
              <li className="nav-item">
                <Link className="nav-link" to="/invest">
                  {t("INVEST")}
                </Link>
              </li>
            )}
            {isAuth && location.pathname !== "/wallet" && (
              <li
                className="nav-item"
                // data-bs-dismiss="offcanvas"
              >
                <Link className="nav-link" to="/wallet">
                  {t("WALLET")}
                </Link>
              </li>
            )}

            {isAuth && location.pathname !== "/dashboard" && (
              <li
                className="nav-item"
                // data-bs-dismiss="offcanvas"
              >
                <Link className="nav-link" to="/dashboard">
                  {t("DASHBOARD")}
                </Link>
              </li>
            )}

            {isAuth && location.pathname !== "/referral" && (
              <li
                className="nav-item"
                // data-bs-dismiss="offcanvas"
              >
                <Link className="nav-link" to="/referral">
                  {t("REFERRAL")}
                </Link>
              </li>
            )}
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                Offcanvas
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 navbar_right">
                {location.pathname !== "/" && (
                  <li
                    className="nav-item mr-4 d-block d-lg-none"
                    data-bs-dismiss="offcanvas"
                  >
                    <Link className="nav-link" to="/">
                      {t("HOME")}
                    </Link>
                  </li>
                )}
                {/* <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link className="nav-link" to="/">
                    FAQ
                  </Link>
                </li> */}
                {/* {isAuth && location.pathname !== "/wallet" && (
                  <li
                    className="nav-item d-block d-lg-none"
                    data-bs-dismiss="offcanvas"
                  >
                    <Link className="nav-link" to="/wallet">
                      Wallet
                    </Link>
                  </li>
                )} */}
                {!isAuth && location.pathname !== "/login" && (
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link className="primary_btn" to="/login">
                      {t("LOGIN_LINK_LABEL")}
                      <span className="icon-right"></span>
                      <span className="icon-right after"></span>
                    </Link>
                  </li>
                )}
                {isAuth && (
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link to="/dashboard" className="primary_btn">
                      {t("DASHBOARD")}
                      <span className="icon-right"></span>
                      <span className="icon-right after"></span>
                    </Link>
                  </li>
                )}
                {!isAuth && location.pathname !== "/register" ? (
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link className="primary_btn" to="/register">
                      {t("REGISTER_LINK_LABEL")}
                      <span className="icon-right"></span>
                      <span className="icon-right after"></span>
                    </Link>
                  </li>
                ) : (
                  isAuth && (
                    <li className="nav-item" data-bs-dismiss="offcanvas">
                      <button
                        className="primary_btn"
                        onClick={() => logout(navigate, dispatch, t)}
                      >
                        {t("LOGOUT")}
                        <span className="icon-right"></span>
                        <span className="icon-right after"></span>
                      </button>
                    </li>
                  )
                )}
              </ul>
              <ul>
                <select
                  className="form-select chart_option_btn"
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={lan}
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                  <option value="nl">Dutch</option>
                  <option value="zh">Chinese</option>
                  <option value="de">German</option>
                </select>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
