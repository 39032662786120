// import constant
import { SET_USER_WALLET_LIST, RESET_ALL } from '../constant';

const initialValue = []

const wallet = (state = initialValue, action) => {
    switch (action.type) {
        case SET_USER_WALLET_LIST:
            return action.data
        case RESET_ALL: {
            return initialValue
        }
        default:
            return state;
    }
}

export default wallet;