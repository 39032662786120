import React, { useEffect, useState } from 'react'
import WalletDetails from './WalletDetails';
import TransactionHistory from './TransactionHistory';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import { getAssetData, getPriceConversion, getUserSettings } from '../../api/walletAction';
import { useDispatch } from 'react-redux'

const WalletPage = () => {
  const [Value, setValue] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    getUserSettings()
    getPriceConversion(dispatch);
  }, []);

  const refetch = async () => {
    let resp = await getAssetData(dispatch)
    if (resp)
      setValue(true)
  }
  return (
    <div className="inner_wrapper">
      <div className="container">
        <WalletDetails refetch={Value} />
        <div className="row dash_box_row">
          <Deposit />
          <Withdraw refetch={refetch} />
        </div>
        <TransactionHistory refetch={Value} setValue={setValue} />
      </div>
    </div>
  );
}

export default WalletPage