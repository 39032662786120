import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import WalletPage from "../components/Wallet/walletPage.js";
import NavbarInner from "../components/Navbar-Inner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Wallet() {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <WalletPage />
      <Footer />
    </div>
  );
}
