import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

import { useTranslation } from "react-i18next";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacy(props) {
   const { t } = useTranslation();
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="inner_head_title">
                  Privacy Policy - Mantis Network
                </h2>
              </div>
              <div className="col-xl-12 text-white">
                <section id="privacy">
                  <h2>{t("PRIVACY_SECTION_PRIVACY")}</h2>
                  <p>{t("PRIVACY_PARAGRAPH_1")}</p>

                  <h3>{t("PRIVACY_SECTION_COOKIES")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_2")}</p>

                  <h3>{t("PRIVACY_SECTION_LOG_FILES")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_3")}</p>

                  <h3>{t("PRIVACY_SECTION_SSL")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_4")}</p>
                </section>

                <section id="contactForm">
                  <h3>{t("PRIVACY_SECTION_CONTACT_FORM")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_5")}</p>
                </section>

                <section id="registration">
                  <h3>{t("PRIVACY_SECTION_REGISTRATION")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_6")}</p>
                </section>

                <section id="declaration">
                  <h3>{t("PRIVACY_SECTION_CONSENT")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_7")}</p>
                </section>

                <section id="kycCompliance">
                  <h3>{t("PRIVACY_SECTION_KYC")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_8")}</p>
                </section>

                <section id="thirdParty">
                  <h3>{t("PRIVACY_SECTION_THIRD_PARTY")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_9")}</p>
                </section>

                <section id="information">
                  <h3>{t("PRIVACY_SECTION_INFORMATION")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_10")}</p>
                </section>

                <section id="telegramPrivacy">
                  <h3>{t("PRIVACY_SECTION_TELEGRAM")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_11")}</p>
                </section>

                <section id="facebookPrivacy">
                  <h3>{t("PRIVACY_SECTION_FACEBOOK")}</h3>
                  <p>{t("PRIVACY_PARAGRAPH_12")}</p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
