import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { capitalize } from "../../lib/stringCase";
import { toFixedDown } from "../../lib/roundOf";
import { getnxHistory } from "../../api/dashboard";
import { useTranslation } from "react-i18next";

const customStyles = {
    headCells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
            fontWeight: '600', // Change font size
        },
    },
    cells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
    pagination: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
};

export default function RecentActivity() {
    // const { params } = props;
    // let recent = params && params.data ? params.data : [];
  const { t, i18n } = useTranslation();

    const [recentActivity, setRecentActivity] = useState();

    const getRecentActivity = async () => {
        try {
            const { status, result } = await getnxHistory();
            if (status == 'success') setRecentActivity(result.data)
        } catch (err) { console.log("Err on getRecentActivity", err) }
    }

    useEffect(() => {
        getRecentActivity()
    }, [])
    return (
      <div>
        <div className="row dash_box_row">
          <div className="col-lg-12">
            <div className="chart_box">
              <div className="inner_subtitle title_flex">
                <h2>{t("RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE")}</h2>
                <Link to="/wallet" className="history_link">
                  {t("RECENT_ACTIVITY_SEE_MORE_LINK")}
                </Link>
              </div>
              <div className="table-responsive primary_table_div">
                <table className="table primary_table table_bg">
                  <tbody>
                    {recentActivity &&
                      recentActivity.length > 0 &&
                      recentActivity.map((item, key) => {
                        if (key < 5)
                          return (
                            <tr key={key}>
                              <td>
                                <div className="recent_act_firstrow">
                                  {item.paymentType == "coin_deposit" ||
                                  item.paymentType == "admin_deposit" ||
                                  item.paymentType == "coin_transfer" ? (
                                    <img
                                      src={require("../../assets/images/deposit_icon.png")}
                                      alt="Icon"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={require("../../assets/images/withdraw_icon.png")}
                                      alt="Icon"
                                      className="img-fluid"
                                    />
                                  )}
                                  <div>
                                    {item.paymentType === "coin_transfer" ? (
                                      <h4>
                                        {t(
                                          "RECENT_ACTIVITY_TRANSFER_TYPE_LABEL"
                                        )}
                                      </h4>
                                    ) : item.paymentType === "coin_deposit" ||
                                      item.paymentType === "admin_deposit" ? (
                                      <h4>
                                        {t(
                                          "RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL"
                                        )}
                                      </h4>
                                    ) : (
                                      <h4>
                                        {t(
                                          "RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL"
                                        )}
                                      </h4>
                                    )}

                                    <h4>
                                      {t("RECENT_ACTIVITY_DATE_LABEL")}:{" "}
                                      {dateTimeFormat(item.createdAt)}{" "}
                                      <span
                                        className={
                                          item.status === "rejected"
                                            ? t("RECENT_ACTIVITY_RED_TEXT")
                                            : t("RECENT_ACTIVITY_GREEN_TEXT")
                                        }
                                      >
                                        {capitalize(
                                          t("RECENT_ACTIVITY_STATUS_LABEL")
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="recent_act_secondrow">
                                  <h5>
                                    {item.paymentType != "coin_transfer"
                                      ? item.paymentType == "coin_deposit" ||
                                        item.paymentType == "admin_deposit"
                                        ? "+"
                                        : "-"
                                      : ""}{" "}
                                    {item.paymentType == "coin_transfer" &&
                                    item.status == "completed"
                                      ? "+"
                                      : ""}{" "}
                                    {item.paymentType == "coin_transfer" &&
                                    item.status == "rejected"
                                      ? ""
                                      : `${toFixedDown(
                                          item.amount,
                                          4
                                        )} USD`}{" "}
                                  </h5>
                                  <h4>
                                    {item.amount > 0
                                      ? toFixedDown(item.amount, 4)
                                      : ""}{" "}
                                    {item.amount > 0 ? item.currencySymbol : ""}
                                  </h4>
                                  {item.status == "rejected" &&
                                    item.reason != "" && (
                                      <h4>{capitalize(item.reason)}</h4>
                                    )}
                                </div>
                              </td>
                            </tr>
                          );
                      })}
                  </tbody>
                </table>
              </div>

              {recentActivity && recentActivity.length == 0 && (
                <div className="table-responsive primary_table_div">
                  <DataTable
                    className="table primary_table"
                    columns={[]}
                    data={[]}
                    customStyles={customStyles}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}