
import { useState } from 'react'
import { Modal, Form, InputGroup, Button } from 'react-bootstrap';
//import api
import { resendOtp, verifyOtp, changePassword } from '../../api/users'
//import lib
import { toastAlert } from '../../lib/toastAlert'
import isEmpty from '../../lib/isEmpty'
import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

let initialFormValue = {
    otp: '',
    oldPass: '',
    newPass: '',
    confPass: ''
}

const ChangeProfile = ({ show, handleClose }) => {
    let [formValue, setFormValue] = useState(initialFormValue)
    let [isOtp, setIsOtp] = useState('open')
    let [errors, setErrors] = useState({})
    let { otp, oldPass, newPass, confPass } = formValue
  const { t, i18n } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChange = (e) => {
        let { name, value } = e.target
        setFormValue({ ...formValue, ...{ [name]: value } })
        setErrors({})
    }
    const onHide = () => {
        handleClose()
        setFormValue(initialFormValue)
        setErrors({})
        setIsOtp('open')
    }
    const sendOtp = async () => {
        try {
            let data = {
                roleType: 1,
                requestType: 'changePass'
            }
            let { status, messages } = await resendOtp(data)
            if (status == 'success') {
                setIsOtp('submit')
                toastAlert('success', messages, 'pass')
            } else {
                toastAlert('error', messages, 'pass')
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                toastAlert('error', err?.response?.data?.message, 'pass')
            }
        }
    }

    const otpVerify = async () => {
        try {
            let data = {
                type: 1,
                otp
            }
            let { status, messages, error } = await verifyOtp(data)
            if (status == 'success') {
                setIsOtp('success')
                toastAlert('success', messages, 'pass')
            } else {
                if (!isEmpty(error)) {
                    setErrors(error)
                }
                toastAlert('error', messages, 'pass')
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                toastAlert('error', err?.response?.data?.message, 'pass')
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (isEmpty(oldPass) || isEmpty(newPass) || isEmpty(confPass)) {
                return toastAlert('error', 'Field is required', 'changeName')
            }
            let data = {
                oldPassword: oldPass,
                password: newPass,
                confirmPassword: confPass
            }
            let { status, message, error } = await changePassword(data)
            if (status == 'success') {
                toastAlert('success', message, 'changeName')
                onHide()
            } else {
                if (!isEmpty(error)) {
                    setErrors(error)
                }
                toastAlert('error', message, 'changeName')
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                toastAlert('error', err.response.data.message, 'changeName')
            }
        }
    }
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {isOtp !== "success" ? (
              <>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("CHANGE_PASSWORD_ENTER_OTP")}</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="otp"
                    value={otp}
                  />
                </Form.Group>
                <p style={{ color: "red" }}>{errors?.emailOTP}</p>
              </>
            ) : (
              <>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("CHANGE_PASSWORD_ENTER_OLD_PASSWORD")}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={`${showPassword ? "text" : "password"}`}
                      onChange={handleChange}
                      name="oldPass"
                      value={oldPass}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`bi bi-eye-slash ${
                          showPassword ? "d-none" : ""
                        }`}
                      ></i>
                      <i
                        className={`bi bi-eye ${showPassword ? "" : "d-none"}`}
                      ></i>
                    </Button>
                  </InputGroup>
                </Form.Group>
                <p style={{ color: "red" }}>{errors?.oldPassword}</p>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("CHANGE_PASSWORD_ENTER_NEW_PASSWORD")}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={`${showNewPassword ? "text" : "password"}`}
                      onChange={handleChange}
                      name="newPass"
                      value={newPass}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      <i
                        className={`bi bi-eye-slash ${
                          showNewPassword ? "d-none" : ""
                        }`}
                      ></i>
                      <i
                        className={`bi bi-eye ${
                          showNewPassword ? "" : "d-none"
                        }`}
                      ></i>
                    </Button>
                  </InputGroup>
                </Form.Group>
                <p style={{ color: "red" }}>{errors?.password}</p>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD")}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={`${showConfirmPassword ? "text" : "password"}`}
                      onChange={handleChange}
                      name="confPass"
                      value={confPass}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      <i
                        className={`bi bi-eye-slash ${
                          showConfirmPassword ? "d-none" : ""
                        }`}
                      ></i>
                      <i
                        className={`bi bi-eye ${
                          showConfirmPassword ? "" : "d-none"
                        }`}
                      ></i>
                    </Button>
                  </InputGroup>
                </Form.Group>

                <p style={{ color: "red" }}>{errors?.confirmPassword}</p>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-flex-button">
            <button className="secondary_btn" onClick={onHide}>
              {t("CHANGE_PASSWORD_CLOSE_BUTTON")}
            </button>
            {isOtp === "open" ? (
              <button className="secondary_btn" onClick={sendOtp}>
                {t("CHANGE_PASSWORD_SEND_BUTTON")}
              </button>
            ) : isOtp === "submit" ? (
              <button className="secondary_btn" onClick={otpVerify}>
                {t("CHANGE_PASSWORD_VERIFY_BUTTON")}
              </button>
            ) : (
              isOtp === "success" && (
                <button className="secondary_btn" onClick={handleSubmit}>
                  {t("CHANGE_PASSWORD_SUBMIT_BUTTON")}
                </button>
              )
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
}
export default ChangeProfile