import React from "react";
import ReactApexChart from "react-apexcharts";

export default function StrategyChart({ data }) {
  console.log("DATA STRATEGY:",data)
  const chartData = {
    series: data.series,
    labels: data.strategies,
  };
  
  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: chartData.labels,
    stroke: {
      width: "1",
    },
    legend: {
      fontSize: "18px",
      fontFamily: "Craft Gothic",
      labels: {
        colors: "#FFF",
        useSeriesColors: false,
      },
      markers: {
        width: 14,
        height: 14,
        radius: 0,
        offsetX: -3,
        fillColors: ["#C242FF", "#FFC304", "#23B2F4", "#ffbead"],
      },
      position: "bottom",
      itemMargin: {
        horizontal: 10,
        vertical: 10,
      },
    },
    fill: {
      colors: ["#C242FF", "#FFC304", "#23B2F4", "#ffbead"],
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
    responsive: [
      {
        breakpoint: 575,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            show: true,
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  };
  // console.log(data, "----data89899899")
  return (
    <>
      <ReactApexChart
        options={chartOptions}
        series={chartData.series}
        type="donut"
        width={450}
        className="donut_chart"
      />
    </>
  );
}
