import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import { languageChange, logout } from "../api/users";
import store from "../store";
import { capitalize, firstLetterCase } from "../lib/stringCase";
import { useTranslation } from "react-i18next";
import isLogin from "../lib/isLogin";

export default function NavbarInner() {
  const { t, i18n } = useTranslation();
  const { account } = useSelector((state) => state);
  const [lan, setLan] = useState("en");
  let language = localStorage.getItem("mantis_language");
  const { isAuth } = store.getState().auth;
  const [userData, setUserData] = useState({
    email: account.email,
    userId: account.userId,
    emailStatus: account.emailStatus,
    userName: account.userName,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      60 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }
  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setLan(languageCode);
    localStorage.setItem("mantis_language", languageCode);
    if (isLogin()) {
      languageChange({ langCode: languageCode });
    }
  };
  const langSetup = async (language) => {
    try {
      setTimeout(() => {
        i18n.changeLanguage(language);
      }, 100);
      setLan(language);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    console.log("LAN", language);
    if (language) {
      langSetup(language);
    }
  }, [language]);
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <ul className="navbar-nav justify-content-end flex-grow-1 navbar_middle">
            {location.pathname !== "/" && (
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  {t("HOME")}
                </Link>
              </li>
            )}
            {isAuth && location.pathname !== "/invest" && (
              <li className="nav-item">
                <Link className="nav-link" to="/invest">
                  {t("INVEST")}
                </Link>
              </li>
            )}
            {isAuth && location.pathname !== "/wallet" && (
              <li className="nav-item">
                <Link className="nav-link" to="/wallet">
                  {t("WALLET")}
                </Link>
              </li>
            )}
            {isAuth && location.pathname !== "/dashboard" && (
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard">
                  {t("DASHBOARD")}
                </Link>
              </li>
            )}
            {isAuth && location.pathname !== "/referral" && (
              <li
                className="nav-item"
                // data-bs-dismiss="offcanvas"
              >
                <Link className="nav-link" to="/referral">
                  {t("REFERRAL")}
                </Link>
              </li>
            )}
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                Offcanvas
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 navbar_right">
                <li className="nav-item d-block d-lg-none afterlogin_dropdown">
                  <div className="afterlogin_dropdown_menu">
                    <span className="user_icon">
                      {firstLetterCase(userData.userName)}
                    </span>
                    <div className="d-flex flex-column">
                      <span className="user_name">
                        {capitalize(userData.userName)}
                      </span>
                      <span className="user_email">{userData.email}</span>
                    </div>
                  </div>
                </li>
                <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link className="nav-link" to="/">
                    {t("HOME")}
                  </Link>
                </li>
                <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link className="nav-link" to="/dashboard">
                    {t("DASHBOARD")}
                  </Link>
                </li>
                {isAuth && location.pathname !== "/wallet" && (
                  <li
                    className="nav-item d-block d-lg-none"
                    data-bs-dismiss="offcanvas"
                  >
                    <Link className="nav-link" to="/wallet">
                      {t("WALLET")}
                    </Link>
                  </li>
                )}
                <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link className="nav-link" to="/invest">
                    {t("INVEST")}
                  </Link>
                </li>
                <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link className="nav-link" to="/referral">
                    {t("REFERRAL")}
                  </Link>
                </li>
                {/* <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link className="nav-link" to="/">
                    FAQ
                  </Link>
                </li> */}
                <li
                  className="nav-item d-block d-lg-none"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={() => logout(navigate, dispatch, t)}
                  >
                    {t("LOGOUT")}
                  </Link>
                </li>
                <li className="nav-item dropdown afterlogin_dropdown d-none d-lg-block">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="user_icon">
                      {firstLetterCase(userData.userName)}
                    </span>
                    <div>
                      <span className="user_name">
                        {capitalize(userData.userName)}
                      </span>
                      <span className="user_email">{userData.email}</span>
                    </div>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/dashboard">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M12 22.8789C17.5228 22.8789 22 18.4018 22 12.8789C22 7.35606 17.5228 2.87891 12 2.87891C6.47715 2.87891 2 7.35606 2 12.8789C2 18.4018 6.47715 22.8789 12 22.8789Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M12 7.80859C9.93 7.80859 8.25 9.48859 8.25 11.5586C8.25 13.5886 9.84 15.2386 11.95 15.2986C11.98 15.2986 12.02 15.2986 12.04 15.2986C12.06 15.2986 12.09 15.2986 12.11 15.2986C12.12 15.2986 12.13 15.2986 12.13 15.2986C14.15 15.2286 15.74 13.5886 15.75 11.5586C15.75 9.48859 14.07 7.80859 12 7.80859Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M18.7807 20.2294C17.0007 21.8694 14.6207 22.8794 12.0007 22.8794C9.3807 22.8794 7.0007 21.8694 5.2207 20.2294C5.4607 19.3194 6.1107 18.4894 7.0607 17.8494C9.7907 16.0294 14.2307 16.0294 16.9407 17.8494C17.9007 18.4894 18.5407 19.3194 18.7807 20.2294Z"
                            fill="#23B2F4"
                          />
                        </svg>
                        {t("DASHBOARD")}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/profile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M12 22.8789C17.5228 22.8789 22 18.4018 22 12.8789C22 7.35606 17.5228 2.87891 12 2.87891C6.47715 2.87891 2 7.35606 2 12.8789C2 18.4018 6.47715 22.8789 12 22.8789Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M12 7.80859C9.93 7.80859 8.25 9.48859 8.25 11.5586C8.25 13.5886 9.84 15.2386 11.95 15.2986C11.98 15.2986 12.02 15.2986 12.04 15.2986C12.06 15.2986 12.09 15.2986 12.11 15.2986C12.12 15.2986 12.13 15.2986 12.13 15.2986C14.15 15.2286 15.74 13.5886 15.75 11.5586C15.75 9.48859 14.07 7.80859 12 7.80859Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M18.7807 20.2294C17.0007 21.8694 14.6207 22.8794 12.0007 22.8794C9.3807 22.8794 7.0007 21.8694 5.2207 20.2294C5.4607 19.3194 6.1107 18.4894 7.0607 17.8494C9.7907 16.0294 14.2307 16.0294 16.9407 17.8494C17.9007 18.4894 18.5407 19.3194 18.7807 20.2294Z"
                            fill="#23B2F4"
                          />
                        </svg>
                        {t("PROFILE_PROFILE_TITLE")}
                      </Link>
                    </li>
                    {isAuth /* && location.pathname !== "/wallet" */ && (
                      <li>
                        <Link className="dropdown-item" to="/wallet">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M15 7.0188H6C3.79 7.0188 2 8.8088 2 11.0188V7.40875C2 5.36875 3.65 3.71875 5.69 3.71875H11.31C13.35 3.71875 15 4.9788 15 7.0188Z"
                              fill="#23B2F4"
                            />
                            <path
                              opacity="0.3"
                              d="M17.48 13.0695C16.98 13.5595 16.74 14.2995 16.94 15.0495C17.19 15.9795 18.11 16.5695 19.07 16.5695H20V18.0195C20 20.2295 18.21 22.0195 16 22.0195H6C3.79 22.0195 2 20.2295 2 18.0195V11.0195C2 8.80953 3.79 7.01953 6 7.01953H16C18.2 7.01953 20 8.81953 20 11.0195V12.4695H18.92C18.36 12.4695 17.85 12.6895 17.48 13.0695Z"
                              fill="#23B2F4"
                            />
                            <path
                              d="M22.0002 13.4888V15.5488C22.0002 16.1088 21.5402 16.5688 20.9702 16.5688H19.0402C17.9602 16.5688 16.9702 15.7788 16.8802 14.6988C16.8202 14.0688 17.0602 13.4788 17.4802 13.0688C17.8502 12.6888 18.3602 12.4688 18.9202 12.4688H20.9702C21.5402 12.4688 22.0002 12.9288 22.0002 13.4888Z"
                              fill="#23B2F4"
                            />
                            <path
                              d="M13 12.7695H7C6.59 12.7695 6.25 12.4295 6.25 12.0195C6.25 11.6095 6.59 11.2695 7 11.2695H13C13.41 11.2695 13.75 11.6095 13.75 12.0195C13.75 12.4295 13.41 12.7695 13 12.7695Z"
                              fill="#23B2F4"
                            />
                          </svg>
                          {t("WALLET")}
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link className="dropdown-item" to="/invest">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M12 22.7695C17.5228 22.7695 22 18.2924 22 12.7695C22 7.24668 17.5228 2.76953 12 2.76953C6.47715 2.76953 2 7.24668 2 12.7695C2 18.2924 6.47715 22.7695 12 22.7695Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M14.2602 12.8691L12.7502 12.3391V8.94914H13.1102C13.9202 8.94914 14.5802 9.65914 14.5802 10.5291C14.5802 10.9391 14.9202 11.2791 15.3302 11.2791C15.7402 11.2791 16.0802 10.9391 16.0802 10.5291C16.0802 8.82914 14.7502 7.44914 13.1102 7.44914H12.7502V6.86914C12.7502 6.45914 12.4102 6.11914 12.0002 6.11914C11.5902 6.11914 11.2502 6.45914 11.2502 6.86914V7.44914H10.6002C9.12016 7.44914 7.91016 8.69914 7.91016 10.2291C7.91016 12.0191 8.95016 12.5891 9.74016 12.8691L11.2502 13.3991V16.7791H10.8902C10.0802 16.7791 9.42016 16.0691 9.42016 15.1991C9.42016 14.7891 9.08016 14.4491 8.67016 14.4491C8.26016 14.4491 7.92016 14.7891 7.92016 15.1991C7.92016 16.8991 9.25016 18.2791 10.8902 18.2791H11.2502V18.8691C11.2502 19.2791 11.5902 19.6191 12.0002 19.6191C12.4102 19.6191 12.7502 19.2791 12.7502 18.8691V18.2891H13.4002C14.8802 18.2891 16.0902 17.0391 16.0902 15.5091C16.0802 13.7091 15.0402 13.1391 14.2602 12.8691ZM10.2402 11.4591C9.73016 11.2791 9.42016 11.1091 9.42016 10.2391C9.42016 9.52914 9.95016 8.95914 10.6102 8.95914H11.2602V11.8191L10.2402 11.4591ZM13.4002 16.7891H12.7502V13.9291L13.7602 14.2791C14.2702 14.4591 14.5802 14.6291 14.5802 15.4991C14.5802 16.2091 14.0502 16.7891 13.4002 16.7891Z"
                            fill="#23B2F4"
                          />
                        </svg>
                        {t("INVEST")}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/referral">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M17.5291 8.63914C17.4591 8.62914 17.3891 8.62914 17.3191 8.63914C15.7691 8.58914 14.5391 7.31914 14.5391 5.75914C14.5391 4.16914 15.8291 2.86914 17.4291 2.86914C19.0191 2.86914 20.3191 4.15914 20.3191 5.75914C20.3091 7.31914 19.0791 8.58914 17.5291 8.63914Z"
                            fill="#23B2F4"
                          />
                          <path
                            opacity="0.4"
                            d="M20.7896 15.5695C19.6696 16.3195 18.0996 16.5995 16.6496 16.4095C17.0296 15.5895 17.2296 14.6795 17.2396 13.7195C17.2396 12.7195 17.0196 11.7695 16.5996 10.9395C18.0796 10.7395 19.6496 11.0195 20.7796 11.7695C22.3596 12.8095 22.3596 14.5195 20.7896 15.5695Z"
                            fill="#23B2F4"
                          />
                          <path
                            opacity="0.3"
                            d="M5.79 8.63914C5.86 8.62914 5.93 8.62914 6 8.63914C7.55 8.58914 8.78 7.31914 8.78 5.75914C8.78 4.16914 7.49 2.86914 5.89 2.86914C4.3 2.86914 3 4.15914 3 5.75914C3.01 7.31914 4.24 8.58914 5.79 8.63914Z"
                            fill="#23B2F4"
                          />
                          <path
                            opacity="0.3"
                            d="M7.555 13.7119C7.555 14.6819 7.765 15.6019 8.145 16.4319C6.735 16.5819 5.265 16.2819 4.185 15.5719C2.605 14.5219 2.605 12.8119 4.185 11.7619C5.255 11.0419 6.765 10.7519 8.185 10.9119C7.775 11.7519 7.555 12.7019 7.555 13.7119Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M12.1208 16.7391C12.0408 16.7291 11.9508 16.7291 11.8608 16.7391C10.0208 16.6791 8.55078 15.1691 8.55078 13.3091C8.55078 11.4091 10.0808 9.86914 11.9908 9.86914C13.8908 9.86914 15.4308 11.4091 15.4308 13.3091C15.4308 15.1691 13.9708 16.6791 12.1208 16.7391Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M8.87078 18.8098C7.36078 19.8198 7.36078 21.4798 8.87078 22.4798C10.5908 23.6298 13.4108 23.6298 15.1308 22.4798C16.6408 21.4698 16.6408 19.8098 15.1308 18.8098C13.4208 17.6598 10.6008 17.6598 8.87078 18.8098Z"
                            fill="#23B2F4"
                          />
                        </svg>
                        {t("REFERRAL")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => logout(navigate, dispatch, t)}
                        className="dropdown-item"
                        to="/"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M6 10.0801L6 15.6601C6 20.0801 8.35 20.0801 10.42 20.0801L12.21 20.0801C12.43 20.0801 12.63 19.9801 12.76 19.8101C12.89 19.6401 12.94 19.4101 12.88 19.2001C12.76 18.7501 12.7 18.1901 12.7 17.5201C12.7 16.8501 12.78 16.7101 13.08 16.3101L14.04 15.4001C14.74 14.7501 15.14 13.8201 15.14 12.8601C15.14 11.9001 14.74 10.9801 14.04 10.3201L13.08 9.41008C12.78 9.01008 12.7 8.87008 12.7 8.20008C12.7 7.53008 12.76 6.97008 12.88 6.52008C12.94 6.31008 12.89 6.09008 12.76 5.91008C12.63 5.74008 12.42 5.64008 12.21 5.64008L10.42 5.64008C8.35 5.66008 6 5.66008 6 10.0801Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M4.95 10.5888C5.21 10.8487 5.21 11.2788 4.95 11.5388L4.3 12.1887L9.07 12.1887C9.44 12.1887 9.75 12.4888 9.75 12.8687C9.75 13.2487 9.45 13.5488 9.07 13.5488L4.3 13.5488L4.95 14.1988C5.21 14.4588 5.21 14.8887 4.95 15.1487C4.69 15.4087 4.26 15.4087 4 15.1487L2.2 13.3487C2.19 13.3387 2.19 13.3388 2.19 13.3288C2.14 13.2688 2.09 13.2088 2.06 13.1288C2.02 13.0388 2 12.9588 2 12.8688C2 12.7788 2.02 12.6988 2.05 12.6088C2.08 12.5288 2.13 12.4488 2.2 12.3888L4 10.5888C4.26 10.3288 4.69 10.3288 4.95 10.5888Z"
                            fill="#23B2F4"
                          />
                          <path
                            d="M11.5308 6.17945C11.3808 6.74945 11.3008 7.41945 11.3008 8.21945C11.3008 9.32945 11.5708 9.73945 12.0008 10.3095C12.0208 10.3395 12.0508 10.3695 12.0808 10.3995L13.0908 11.3495C13.9308 12.1495 13.9408 13.5895 13.0808 14.3895L12.0808 15.3395C12.0508 15.3695 12.0208 15.3995 12.0008 15.4295C11.5708 15.9995 11.3008 16.4095 11.3008 17.5195C11.3008 18.3195 11.3808 18.9895 11.5308 19.5595C12.1708 21.9395 14.0608 21.9395 15.7208 21.9395L16.6508 21.9395C19.1608 21.9395 22.0008 21.9395 22.0008 16.5895L22.0008 9.14945C22.0008 5.59945 20.2008 3.79945 16.6508 3.79945L15.7208 3.79945C14.0608 3.79945 12.1708 3.79945 11.5308 6.17945ZM18.4008 10.5395L18.4008 15.1995C18.4008 15.5795 18.0908 15.8895 17.7008 15.8895C17.3108 15.8895 17.0008 15.5795 17.0008 15.1995L17.0008 10.5395C17.0008 10.1595 17.3108 9.84945 17.7008 9.84945C18.0908 9.84945 18.4008 10.1595 18.4008 10.5395Z"
                            fill="#23B2F4"
                          />
                          <path
                            opacity="0.4"
                            d="M17.7 9.84867C18.09 9.84867 18.4 10.1587 18.4 10.5387L18.4 15.1987C18.4 15.5787 18.09 15.8887 17.7 15.8887C17.31 15.8887 17 15.5787 17 15.1987L17 10.5387C17 10.1587 17.31 9.84867 17.7 9.84867Z"
                            fill="#23B2F4"
                          />
                        </svg>
                        {t("LOGOUT")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <select
                  className="form-select chart_option_btn"
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={lan}
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                  <option value="nl">Dutch</option>
                  <option value="zh">Chinese</option>
                  <option value="de">German</option>
                </select>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
