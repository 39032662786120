export default {
  PRIVACY_SECTION_PRIVACY: "Privacy",
  LOGIN_LINK_LABEL: "Login",
  REGISTER_LINK_LABEL: "Register",
  CLICK_ME_LABEL: "Click Me!",
  LOGIN_EMAIL_PLACEHOLDER: "Enter Your Email Address",
  EMPOWER_FINANCIAL_FUTURE: "Empower your financial future.",
  HOME_DESCRIPTION:
    "Dive into the world of cryptocurrency with expert guidance designed to accelerate your investment journey. Start investing in crypto today and see your portfolio expand. Mantis Network offers guidance to navigate the essentials of becoming a successful crypto investor.",
  GET_STARTED: "Get Started",
  DASHBOARD: "Dashboard",
  ALL_PARTICIPANTS: "All Participants",
  ASSETS_UNDER_MANAGEMENT: "Assets Under Management",
  WHY_INVEST_WITH_US: "Why Invest with us",
  SECURE_AND_RELIABLE: "Secure and Reliable",
  PLATFORM_SECURITY_BRIEF_EXPLANATION:
    "Our platform prioritizes security. Your assets are protected by cutting-edge technology and industry-leading security protocols.",
  EARN_REWARDS_EFFORTLESSLY: "Earn Rewards Effortlessly",
  INVESTMENT_DESCRIPTION:
    "Investment with us means you effortlessly earn rewards in the form of additional cryptocurrencies. No complex trading strategies or technical knowledge required.",
  DECENTRALIZATION_MATTERS: "Decentralization Matters",
  JOIN_THE_MOVEMENT:
    "Join the movement towards a decentralized future. By investing your crypto, you actively contribute to the strength and stability of the blockchain network.",
  USER_FRIENDLY_INTERFACE: "User-Friendly Interface",
  INTUITIVE_PLATFORM:
    "Our intuitive platform makes investment simple for both beginners and experienced investors. No hidden fees or complicated processes - just a seamless experience.",
  HOW_IT_WORKS: "How It Works",
  MANTIS_NETWORK_REGISTRATION:
    "With Mantis Network you simply need to register and create an account without any knowledge of blockchain or crypto.",
  SIMPLE_PATH_WITH_MANTIS_NETWORK: "Your Simple Path with Mantis Network",
  CREATE_YOUR_ACCOUNT: "Create Your Account",
  SIGN_UP_WITH_MANTIS_NETWORK:
    "Sign up with Mantis Network easily, no need for prior blockchain or crypto wallet experience. Our user-friendly platform is designed for everyone.",
  EXPLORE_INVESTMENT_OPTIONS: "Explore Investment Options",
  DISCOVER_INVESTMENT_OPPORTUNITIES:
    "Discover a variety of investment opportunities, including Bitcoin mining and Ethereum staking, tailored Mantis Strategies to suit your financial goals.",
  BEGIN_INVESTING: "Begin Investing",
  START_INVESTMENT_JOURNEY:
    "With our step-by-step guidance, start your investment journey in lucrative assets like Bitcoin, Ethereum, ALT coins, and diverse early-stage projects.",
  WATCH_PORTFOLIO_GROW: "Watch Your Portfolio Grow",
  TRACK_INVESTMENTS:
    "Track your investments effortlessly on Mantis Network and watch your portfolio grow as you dive deeper into the world of crypto investments.",
  ASSET_ALLOCATION: "Asset Allocation",
  FOCUS_ON_INCOME_GENERATION:
    "Focus on Income Generation by accruing BTC (Bitcoin) rewards daily in your digital wallet and ETH (Ethereum) staking rewards.",
  BTC_MINING_ALLOCATOR_ALLOCATION: "40% allocation to a BTC mining allocator",
  BTC_ALLOCATION: "30% allocation to BTC",
  ETH_ALLOCATION: "20% allocation to ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "10% allocation to Mantis Strategies seeking Alpha",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "This portfolio is suitable for investors who want to focus on building income and compounding their returns with a tilt towards BTC price exposure.",
  INVESTOR_PORTFOLIO_BLURB: "Blended focus on Income Generation and Growth",
  GROWTH_FOCUS_BLURB:
    "Focus on Generating Large Growth with a larger allocation to our Alpha seeking Mantis Strategies",
  BTC_MINING_ALLOCATOR_ALLOCATION_BALANCED:
    "40% allocation to a BTC mining allocator",
  BTC_ALLOCATION_BALANCED: "30% allocation to BTC",
  ETH_ALLOCATION_BALANCED: "20% allocation to ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_BALANCED:
    "10% allocation to Mantis Strategies seeking Alpha",
  BTC_MINING_ALLOCATOR_ALLOCATION_GROWTH:
    "25% allocation to a BTC mining allocator",
  BTC_ALLOCATION_GROWTH: "10% allocation to BTC",
  ETH_ALLOCATION_GROWTH: "15% allocation to ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_GROWTH:
    "50% allocation to Mantis Strategies seeking Alpha",
  BTC_MINING_ALLOCATOR_ALLOCATION_HIGH_YIELD:
    "30% allocation to a BTC mining allocator",
  BTC_ALLOCATION_HIGH_YIELD: "20% allocation to BTC",
  ETH_ALLOCATION_HIGH_YIELD: "20% allocation to ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION_HIGH_YIELD:
    "30% allocation to Mantis Strategies seeking Alpha",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "This portfolio is suitable for investors who want to focus on building income and compounding their returns with a tilt towards BTC price exposure whilst also seeking very high returns from the Decentralised Finance and Alternative coin market.",
  INVEST_TITLE: "Invest",
  BALANCED_TITLE: "Balanced",

  HIGH_YIELD_TITLE: "HighYield",
  GROWTH_TITLE: "Growth",

  ROI_CALCULATOR_MAIN_TITLE: "ROI Calculator",
  ROI_CALCULATOR_INVESTMENTS_LABEL: "Investments",
  ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL: "Investment Amount",
  ROI_CALCULATOR_APY_LABEL: "APY In Percentage (%)",
  ROI_CALCULATOR_MONTHS_LABEL: "Number Of Months",
  ROI_CALCULATOR_INVEST_BUTTON: "Invest",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_1: "1 Month",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_3: "3 Months",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_6: "6 Months",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_9: "9 Months",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_12: "12 Months",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_24: "24 Months",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_48: "48 Months",
  ROI_CALCULATOR_INTEREST_LABEL: "Total Interest:",
  ROI_CALCULATOR_FUTURE_VALUE_LABEL: "Future Value:",
  NEWS_COMPONENT_MAIN_TITLE: "Our news & Updates",
  NEWS_COMPONENT_VIEW_MORE_BUTTON: "View More",
  FAQ_COMPONENT_MAIN_TITLE: "Frequently Asked Questions",
  FAQ_COMPONENT_VIEW_MORE_BUTTON: "View More",
  FOOTER_INNOVATIVE_PLATFORM:
    "Mantis Network is an innovative platform that enables traditional investors to easily gain exposure to cryptocurrency investments.",
  FOOTER_FOLLOW_US: "Follow Us",
  FOOTER_COPYRIGHT: "Copyright ©",
  FOOTER_ALL_RIGHTS_RESERVED: "All Rights Reserved.",
  FOOTER_ADDRESS: "Address",
  FOOTER_CONTACT_US: "Contact Us",
  FOOTER_TERMS_OF_SERVICE: "Terms Of Service",
  FOOTER_PRIVACY_POLICY: "Privacy Policy",
  INVEST_TITLE: "Invest",
  INVEST_TABLE_HEADER_ASSETS: "Assets",
  INVEST_TABLE_HEADER_WEIGHTS: "Weights",
  INVEST_ALLOCATION_BTC: "BTC",
  INVEST_ALLOCATION_ETH: "ETH",
  INVEST_ALLOCATION_MINING_POOL: "BTC Mining Pool",
  INVEST_ALLOCATION_MANTIS_STRATEGIES: "Mantis Strategies",
  INVEST_ALLOCATION_TOTAL: "Total Allocation",
  INVEST_MINIMUM_INVESTMENT: "Minimum Investment",
  INVEST_PROJECTED_ROI: "Projected ROI (%)",
  INVEST_DETAILS: "Details",
  INVEST_INVEST_BUTTON: "Invest",
  INVEST_INVESTMENT_DETAILS: "Investment Details",
  INVEST_MONTH: "Month",
  INVEST_INTEREST_RATE: "Interest Rate",
  INVEST_MONTHS: "Months",
  INVEST_AMOUNT: "Amount",
  INVEST_INVEST_MODAL_TITLE: "Investment Order",
  INVEST_INVEST_MODAL_DESCRIPTION:
    "Please review your investment details and proceed to place the order.",
  INVEST_INVEST_MODAL_AMOUNT_LABEL: "Amount",
  INVEST_INVEST_MODAL_PLACE_ORDER: "Place Order",
  INVEST_INVEST_MODAL_CLOSE: "Close",
  INVEST_RESPONSE_MODAL_TITLE_SUCCESS: "Success",
  INVEST_RESPONSE_MODAL_TITLE_ERROR: "Error",
  INVEST_RESPONSE_MODAL_CLOSE: "Close",
  INVEST_DASHBOARD_TOTAL_LOCKED_VALUE: "Total Locked Value",
  INVEST_DASHBOARD_CAPITAL_GROWTH: "Capital Growth",
  INVEST_DASHBOARD_ROI_PERCENT: "ROI Percentage",
  INVEST_DASHBOARD_YOUR_EARNINGS: "Your Earnings",
  INVEST_DASHBOARD_AVAILABLE_BALANCE: "Available Balance",
  INVEST_DASHBOARD_WALLET_LINK: "Wallet",
  INVEST_DASHBOARD_INVEST_LINK: "Invest",

  INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE: "Investment Details",
  INVESTMENT_DETAILS_STRATEGY_LABEL: "Strategy",
  INVESTMENT_DETAILS_DURATION_LABEL: "Duration",
  INVESTMENT_DETAILS_ALL_OPTION: "All",
  INVESTMENT_DETAILS_LOW_OPTION: "Low",
  INVESTMENT_DETAILS_HIGH_OPTION: "High",
  INVESTMENT_DETAILS_ID_LABEL: "ID",
  INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL: "Total Investment Period",
  INVESTMENT_DETAILS_REWARDS_EARNED_LABEL: "Rewards Earned",
  INVESTMENT_DETAILS_REMAINING_DAYS_LABEL: "Remaining Days",
  INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL: "Capital Growth",
  INVESTMENT_DETAILS_VIEW_BUTTON_LABEL: "View",
  INVESTMENT_DETAILS_INVESTED_AT_LABEL: "Invested At",

  RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE: "Recent Activity",
  RECENT_ACTIVITY_SEE_MORE_LINK: "See more",
  RECENT_ACTIVITY_TRANSFER_TYPE_LABEL: "Transfer via USD",
  RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL: "Deposit via USD",
  RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL: "Withdraw via USD",
  RECENT_ACTIVITY_DATE_LABEL: "Date",
  RECENT_ACTIVITY_STATUS_LABEL: "Status",
  RECENT_ACTIVITY_REJECTED_STATUS: "Rejected",
  RECENT_ACTIVITY_GREEN_TEXT: "green_txt",
  RECENT_ACTIVITY_RED_TEXT: "red_txt",

  FORGOT_PASSWORD_TITLE: "Forgot Password",
  FORGOT_PASSWORD_RESET_INFO:
    "Reset Password link has been sent to your registered Email Address",
  FORGOT_PASSWORD_EMAIL_LABEL: "Email Address",
  FORGOT_PASSWORD_SUBMIT_BUTTON: "Submit",
  FORGOT_PASSWORD_GO_TO_LOGIN: "Go to Login",

  LOGIN_WELCOME_TITLE: "Welcome to",
  LOGIN_MANTIS_NETWORK: "Mantis Network",
  LOGIN_SIGN_IN: "Sign In to Continue!",
  LOGIN_EMAIL_LABEL: "Email Address",
  LOGIN_PASSWORD_LABEL: "Password",
  LOGIN_REMEMBER_ME: "Remember me",
  LOGIN_FORGOT_PASSWORD: "Forgot Password?",
  LOGIN_LOGIN_BUTTON: "Login",
  LOGIN_DONT_HAVE_ACCOUNT: "Don't have an account?",
  LOGIN_REGISTER_HERE: "Register Here",
  LOGIN_OTP_VALID_UP_TO: "OTP Valid Up To",
  LOGIN_YOUR_OTP_SENT:
    "Your One Time Passcode (OTP) is sent to your registered email",
  LOGIN_ENTER_OTP: "Enter OTP",
  LOGIN_SUBMIT_BUTTON: "Submit",
  LOGIN_RESEND_OTP: "Resend OTP",

  NEWS_EVENTS_INNER_HEAD_TITLE: "News and Updates",

  PRIVACY_INNER_HEAD_TITLE: "Privacy Policy - Mantis Network",
  PRIVACY_PARAGRAPH_1:
    "The data protection and confidentiality of your personal data is an important concern of Mantis Network. The use of our website depends on the collection of personal data, which is required in the registration and for your user access. Mantis Network strictly fulfills all legal regulations and computes your highly sensitive data conscientiously and responsibly. However, we want to point out that an absolute protection of the data during the transmission on the Internet is not possible and as a consequence, any access by third parties cannot be completely avoided at any time. Nevertheless, you may assume that we take all necessary technical and organizational security measures to protect your personal data from loss and misuse",
  PRIVACY_SECTION_COOKIES: "Cookies",
  PRIVACY_PARAGRAPH_2:
    "The website uses PHP session cookies. Cookies do not harm your computer and do not contain viruses. Cookies are a mechanism to store information to identify returning users or track their path by using the website. Cookies are used to make our website more user-friendly, comfortable, and safe. Disabling cookies may limit the functionality of this website",
  PRIVACY_SECTION_LOG_FILES: "Server LOG files",
  PRIVACY_PARAGRAPH_3:
    "Our server collects and stores automatically information in so-called server log files, which your browser automatically transmits to us. The information does not contain personal data. A correlation of this data with your personal data is not intended. We reserve the right to check this data retrospectively if we become aware of specific indications for illegal use",
  PRIVACY_SECTION_SSL: "SSL encryption",
  PRIVACY_PARAGRAPH_4:
    "For security reasons, this site uses SSL encryption for the transmission of data. As long as SSL encryption is enabled, data cannot be read by third parties during transmission",
  PRIVACY_SECTION_CONTACT_FORM: "Contact Form",
  PRIVACY_PARAGRAPH_5:
    "If you send us a message via the 'CONTACT' form, you will be asked at least for your name and your email address. These details will be transmitted and saved together with the message for further processing. The information 'telephone' and 'address' are voluntary. We will not share all this information without your consent",
  PRIVACY_SECTION_REGISTRATION: "Registration",
  PRIVACY_PARAGRAPH_6:
    "After registration we will save your password anonymously, only your e-mail address will be visible to us. Consequently, we can only reset passwords if they are forgotten. You need your email address and password to log in to our website. You are personally responsible for the effective secrecy of your passwords. Do not give the login data to anyone else",
  PRIVACY_SECTION_CONSENT: "Declaration of Consent",
  PRIVACY_PARAGRAPH_7:
    "With the participation and use of our services, you also agree on the processing of your personal data. The services cannot be provided without the processing of your personal data",
  PRIVACY_SECTION_KYC: "KYC Compliance",
  PRIVACY_PARAGRAPH_8:
    "With regard to EU and US sanctions as well as the Money Laundering Act, Mantis Network is obliged as much as possible to carry out and document a KYC (Know Your Customers) check, based on your self-disclosure. This procedure also requires proof of your identity. This data will not be disclosed to third parties without your previous consent, unless Mantis Network is legally obliged to provide. Of course, Mantis Network will immediately contact its customers if a review leads to abnormalities and they have the right to comment",
  PRIVACY_SECTION_THIRD_PARTY: "Third Party Services",
  PRIVACY_PARAGRAPH_9:
    "Mantis Network engages other companies and individuals for performances which are related to our services or to business-related administrative purposes. These third party service providers have only access as long and in the scope they absolutely need for the purpose or for what they legally obliged",
  PRIVACY_SECTION_INFORMATION: "Right off Information",
  PRIVACY_PARAGRAPH_10:
    "You have the right at any time to obtain information about your personal data as well as the purpose and type of data processing of your data. If you have any questions about personal data, you can always contact us at the address which is given at „about us“",
  PRIVACY_SECTION_TELEGRAM: "Privacy Policy for the Use of Telegram",
  PRIVACY_PARAGRAPH_11:
    "On our website are features of the service Telegram integrated. These functions are offered by Telegram Messenger LLP. 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, UK. By using the link to Telegram, you can join the 'MANTIS NETWORK info channel' with your telegram account. Further information can be found in the privacy policy of Telegram under your account: Telegram FAQ, F:, Privacy Policy",
  PRIVACY_SECTION_FACEBOOK: "Privacy Policy for the use of Facebook",
  PRIVACY_PARAGRAPH_12:
    "On our website are features of the service Facebook integrated. These functions are offered by Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. By using the link to Facebook, you can visit our account 'Mantis Network Limited'. Further information can be found in the privacy policy of Facebook; https://www.facebook.com/privacy/explanation",

  PROFILE_PROFILE_TITLE: "Profile",
  PROFILE_USERNAME_LABEL: "Username",
  PROFILE_EMAIL_LABEL: "Email",
  PROFILE_PHONE_NUMBER_LABEL: "Phone Number",
  PROFILE_CHANGE_USERNAME_LABEL: "Change Username",
  PROFILE_CHANGE_PASSWORD_LABEL: "Change Password",
  PROFILE_PASSWORD_DESCRIPTION:
    "This password is used for your login credential.",
  PROFILE_CHANGE_BUTTON: "Change",

  CHANGE_PROFILE_CHANGE_USERNAME_TITLE: "Change Username",
  CHANGE_PROFILE_ENTER_NEW_USERNAME: "Enter new username",
  CHANGE_PROFILE_CLOSE_BUTTON: "Close",
  CHANGE_PROFILE_SAVE_BUTTON: "Save",

  CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE: "Change Password",
  CHANGE_PASSWORD_ENTER_OTP: "Enter OTP",
  CHANGE_PASSWORD_ENTER_OLD_PASSWORD: "Enter Old Password",
  CHANGE_PASSWORD_ENTER_NEW_PASSWORD: "Enter New Password",
  CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD: "Enter Confirm Password",
  CHANGE_PASSWORD_CLOSE_BUTTON: "Close",
  CHANGE_PASSWORD_SEND_BUTTON: "Send",
  CHANGE_PASSWORD_VERIFY_BUTTON: "Verify",
  CHANGE_PASSWORD_SUBMIT_BUTTON: "Submit",

  WALLET_DETAILS_TITLE: "Wallet",
  WALLET_DETAILS_TOTAL_BALANCE: "Total Balance",
  WALLET_DETAILS_INVEST_BUTTON: "Invest",

  DEPOSIT_TITLE: "Deposit",
  DEPOSIT_SELECT_COIN: "Select Coin",
  DEPOSIT_SELECT_NETWORK: "Select Network",
  DEPOSIT_ADDRESS_LABEL: "Address",
  DEPOSIT_COPY_ICON_ALT: "Copy",
  DEPOSIT_GENERATE_ADDRESS_BUTTON: "Generate Address",
  DEPOSIT_QR_IMAGE_ALT: "Blur Image",
  DEPOSIT_DEPOSIT_NOTES: "Deposit Notes",
  DEPOSIT_SEND_ONLY: "Send only {currencySymbol} to this deposit address.",
  DEPOSIT_MINIMUM_DEPOSIT: "Minimum Deposit: {minimumDeposit} {currencySymbol}",
  DEPOSIT_SENDING_COIN:
    "Sending coin or token other than {currencySymbol} to this address may result in the loss of your deposit.",

  WITHDRAW_TITLE: "Withdraw",
  WITHDRAW_OTP_VALID_UP_TO: "OTP Valid Up To",
  WITHDRAW_OTP_LABEL: "OTP",
  WITHDRAW_ENTER_OTP: "Enter OTP",
  WITHDRAW_SUBMIT: "Submit",
  WITHDRAW_RESEND_OTP: "Resend OTP",
  WITHDRAW_SELECT_COIN: "Select Coin",
  WITHDRAW_SELECT: "Select",
  WITHDRAW_AMOUNT: "Amount",
  WITHDRAW_ENTER_AMOUNT: "Enter Amount",
  WITHDRAW_SELECT_NETWORK: "Select Network",
  WITHDRAW_CONVERSION_RATE: "Conversion Rate",
  WITHDRAW_ENTER_ADDRESS: "Enter Address",
  WITHDRAW_ADDRESS: "Address",
  WITHDRAW_WITHDRAW_NOW: "Withdraw Now",
  WITHDRAW_WITHDRAW_NOTES: "Withdraw Notes",
  WITHDRAW_SEND_ONLY: "Send only {{currencySymbol}} to this withdraw address.",
  WITHDRAW_MINIMUM_WITHDRAW:
    "Minimum Withdraw: {{minimumWithdraw}} {{currencySymbol}}",
  WITHDRAW_MAXIMUM_WITHDRAW:
    "Maximum Withdraw: {{maximumWithdraw}} {{currencySymbol}}",
  WITHDRAW_WITHDRAWAL_FEE: "Withdrawal fee: {{withdrawFee}} {{currencySymbol}}",
  WITHDRAW_SENDING_COIN:
    "Sending coin or token other than {{currencySymbol}} to this address may result in the loss of your Withdraw.",

  TRX_HISTORY_TITLE: "Transaction History",

  TERMS_TITLE: "Terms of Use",
  TERMS_GENERAL: "1. General",
  TERMS_GENERAL_CONTENT_A:
    "a. These Terms of Service (hereinafter „TOS“) are subject to the use of Mantis Network hosting services (hereinafter “Service”) of the website http://Mantis.Network (hereinafter “Website”). Any use of the terminology herein or other words in the singular, plural, capitalization and/or he/she or they, your/his/her shall be interpreted as interchangeable and therefore as referring to the same.",
  TERMS_GENERAL_CONTENT_B:
    "b. Any individual, entity or other legal person (hereinafter “User”) who access to the Website and will use the Services has to read, accept and confirm the TOS first and agree on to be bound by the provisions of the TOS before becoming a member. Any factual participation in the Services will constitute such acceptance. If the User does not agree on and abide by these TOS, he is not allowed to use the Services.",
  TERMS_GENERAL_CONTENT_C:
    "c. Any User of the Services confirms to be unlimited sui juris (contractual capability) and at least eighteen (18) years old by subscribing to the Services. Moreover, any User is aware of all possible risks which are related to the usage of the Services, of blockchain technology and crypto-currencies.",
  TERMS_EXCLUSIONS_LIMITATIONS_TITLE: "2. Exclusions and Limitations",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A:
    'a. The information of the Website will be provided on an "as is" basis in the maximum extent which is permitted by law. The Service excludes all representations and warranties relating to the Website and its contents or contents which will be or may be provided by any affiliates or any other third party, including any inaccuracies or omissions related to the Website and/or the Services as well as the related presentations, demonstrations, and descriptions.',
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B:
    "b. The uptime of the Service or Website is not guaranteed; maintenance and downtime may be required from time to time. The Owner is not responsible for the continuous and failure-free operation and the security of the crypto-currency networks. Nevertheless, the Owner will do its utmost to provide the Service in good conditions and to avoid any worse consequences.",
  TERMS_LIMITATION_LIABILITIES_TITLE: "3. Limitation of Liabilities",
  TERMS_LIMITATION_LIABILITIES_CONTENT_A:
    "a. Mantis Network Ltd., its legal representatives and shareholders (hereinafter collective “Owner”) will not be liable for any direct or indirect, consequential and incidental damages related to or caused by the Website and/or the Services whether the consequences were foreseeable or not and may happen in a normal case like things happen.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_B:
    "b. Especially the Owner will not be liable for damages as but not limited to damage of User's computer hardware or software, systems and programming or loss of data as well as economic loss, loss of business or loss of profits.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_C:
    "c. The User confirms the awareness of all risks including the possibility of economic loss or the loss of expected profits. The Owner will not compensate any irreversible loss.",
  TERMS_PAYOUTS_TITLE: "4. Payouts",
  TERMS_PAYOUTS_CONTENT_A:
    "a. Mantis Network will pay out rewards proportionally to the investment amount made by the user. All transactions will be processed in the currency USDT.",
  TERMS_PAYOUTS_CONTENT_B:
    "b. The payouts will be transferred ONLY to the account the User had mentioned on its investment form (dashboard). The payouts will be credited after every four (4) weeks on a regular basis.",
  TERMS_PAYOUTS_CONTENT_C:
    "c. The Owner will charge for the Services a joining fee of 2% on all deposits and a fee of 25% on all of the Mantis Network rewards.",
  TERMS_PAYOUTS_CONTENT_D:
    "d. Any rewards accumulated by a nominated crypto-currency account will be only paid to that particular address. Different wallet balances cannot be merged.",
  TERMS_PAYOUTS_CONTENT_E:
    "e. The Owner will not be liable for any changes in the crypto-currency value. The Return of Invest (ROI) will be calculated based on the actual rewards for each block for the last 24 hours. The Owner cannot guarantee that any crypto-currency will keep a constant value and therefore cannot guarantee the ROI. Total loss is possible at any time and any risk will be borne by each User.",
  TERMS_PAYOUTS_CONTENT_F:
    "f. The portfolio value can be requested once per quarter, maximum 4 weeks before the end of the holding period free of charge for calculation via the support. Each further calculation will be charged with $25 due to the high effort, which must be paid in advance by USDT. The portfolio value has a validity of 7 days in each case. For operational reasons and due to the high and recurring requests for the portfolio value, it may be that a pool value calculation is calculated only once per quarter, maximum 4 weeks before the end of the holding period and passed on.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE:
    "5. Legal Requirements and Sanctions",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A:
    "a. The User declares by his agreement to this TOS that he has not been affected by any sanction or that he has been listed by any competent supervisory authorities. He must announce changes immediately. The Owner will monitor his status regularly. A positive screening result leads to the immediate cessation of services.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B:
    "b. In addition, the User confirms with his consent to the TOS that he acts at his own expense.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C:
    "c. The Owner is entitled to require proof of the identity of the User. In the case of natural persons, this would usually be an official document of an authority (e.g. identity card), in the case of a legal entity supervisory authorities by a current extract from the commercial register.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D:
    "d. In the case of incorrect or incomplete information as well as fraud or fraudulent misrepresentation, the User shall indemnify the Owner from all legal consequences and financial damages. Additionally, the Owner will immediately suspend the User from the use of Website and Services. The Owner reserves the right to take legal actions.",
  TERMS_WAIVER_TITLE: "6. Waiver",
  TERMS_WAIVER_CONTENT:
    "No waiver of any of the provisions of the TOS will be deemed to constitute a waiver of any other provision nor shall such a waiver constitute a continuing waiver unless otherwise expressly provided in writing duly executed by the party to be bound thereby.",
  TERMS_FORCE_MAJEURE_TITLE: "7. Force Majeure",
  TERMS_FORCE_MAJEURE_CONTENT:
    "The Owner will not be liable in any event or circumstance which is beyond the control of the Owner, including but not limited to acts of Gods, riots, wars, floods, fires, explosion, strikes and other similar events, which will prevent, delay, disrupt or failure of the provision of already committed Services.",
  TERMS_TERMINATION_TITLE: "8. Termination",
  TERMS_TERMINATION_CONTENT_A:
    "The User's consent to the TOS includes an investment in the Mantis Network. The portfolio minimum lock period will be valid for a period of (180) one hundred-eighty calendar days and will be prolonged automatically if the user does not terminate the TOS by the end of the minimum locked-in period. The User can terminate its TOS and the use of Services by sending an email to support@Mantis.Network. The share of the current Mantis Network Portfolio value will be paid out. The Owner has a right of extraordinary termination if there are cogent reasons against a continuation of the business relationship with a User. In the case of a premature extraordinary termination of the contract, the share of the current Mantis Network portfolio value, minus a processing fee of 25%, will be refunded. There is no right regarding unpaid or future payouts.",
  TERMS_SEVERABILITY_CLAUSE_TITLE: "9. Severability Clause",
  TERMS_SEVERABILITY_CLAUSE_CONTENT:
    "If any provision of the TOS becomes invalid, the invalidity does not affect other provisions of the TOS that can be given effect without the invalid provision, and to this end, the provisions of the TOS are severable.",
  TERMS_GOVERNING_LAW_TITLE: "10. Governing Law",
  TERMS_GOVERNING_LAW_CONTENT:
    "The rights and obligations of the Owner and User and third parties, if any, pursuant to the TOS, the Website, and the Service are governed by, and shall be construed in accordance with the laws of the British Virgin Islands. Any dispute, controversy or claim arising out of or relating to the TOS, the Website, and the Service shall be finally settled by arbitration in accordance with the Arbitration Rules of the International Chamber of Commerce (Paris, France). The Arbitration proceedings shall be conducted in Tortola, British Virgin Islands. The language of the proceeding shall be English.",

  RESET_PASSWORD_TITLE: "Reset Password",
  RESET_PASSWORD_NEW_LABEL: "New Password",
  RESET_PASSWORD_CONFIRM_LABEL: "Confirm Password",
  RESET_PASSWORD_PASSWORD_PLACEHOLDER: "Enter Password",
  RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Enter Confirm Password",
  RESET_PASSWORD_SHOW_PASSWORD: "Show Password",
  RESET_PASSWORD_SHOW_CONFIRM_PASSWORD: "Show Confirm Password",
  RESET_PASSWORD_PASSWORD_ERROR: "Please enter a valid password",
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR: "Passwords do not match",
  RESET_PASSWORD_SUBMIT_BUTTON: "Submit",
  //
  REGISTER_USER_NAME: "User Name",
  REGISTER_ENTER_USERNAME: "Enter username",
  REGISTER_EMAIL_ADDRESS: "Email Address",
  REGISTER_ENTER_EMAIL: "Enter your Email",
  REGISTER_COUNTRY: "Country",
  REGISTER_PHONE_NUMBER: "Phone Number",
  REGISTER_ENTER_MOBILE_NUMBER: "Enter your Mobile Number",
  REGISTER_PASSWORD: "Password",
  REGISTER_ENTER_PASSWORD: "Enter Password",
  REGISTER_CONFIRM_PASSWORD: "Confirm Password",
  REGISTER_ENTER_CONFIRM_PASSWORD: "Enter Confirm Password",
  REGISTER_REFERRAL_CODE: "Referral Code (Optional)",
  REGISTER_ENTER_REFERRAL_CODE: "Enter Referral Code",
  REGISTER_OTP: "OTP",
  REGISTER_ENTER_OTP: "Enter OTP",
  REGISTER_AGREE_TERMS: "I Understand",
  REGISTER_TERMS_LINK: "Terms of Use",
  REGISTER_PRIVACY_LINK: "Privacy Policy",
  REGISTER_BUTTON: "Register",
  REGISTER_ALREADY_REGISTER: "Already Register?",
  REGISTER_SIGN_IN: "Sign In",

  REFERRAL_HEAD_TITLE: "Referral",
  REFERRAL_TOTAL_REFERRALS_LABEL: "Total Referrals",
  REFERRAL_TOTAL_INCOME_LABEL: "Total Referral Income Earned",
  REFERRAL_TOTAL_USERS_INVEST_LABEL: "Total Referral Users Invest",
  REFERRAL_LINK: "Referral Link",
  REFERRAL_LEVEL_INCOME: "Level Income",
  REFERRAL_LEVEL_1_INCOME: "Level 1 Income",
  REFERRAL_LEVEL_2_INCOME: "Level 2 Income",
  REFERRAL_LEVEL_3_INCOME: "Level 3 Income",
  REFERRAL_HISTORY_LABEL: "Referral History",

  // BACKEND

  EMAIL_EXISTS: "Email already exists",
  PHONE_EXISTS: "Phone number already exists",
  INVALID_REFERRAL_CODE: "Invalid Referral code",
  OTP_SENT: "OTP sent to your email address, Please Enter an OTP",
  EXPIRED_OTP: "Expired OTP",
  INVALID_OTP: "Invalid OTP",
  REGISTRATION_SUCCESS:
    "Registration done - Activation link sent to your Email Address",
  SOMETHING_WENT_WRONG: "Something went wrong",

  INVALID_EMAIL: "Please enter a correct email address",
  PHONE_NOT_EXISTS: "Phone Number not exists",
  IP_BLOCKED: "Your IP has been Blocked",
  ACCOUNT_LOCKED: "Your account is still locked",
  ACCOUNT_BLOCKED: "Your account was blocked. Please try again later",
  ACCOUNT_NOT_ACTIVATED: "Your account still not activated",
  LOGIN_ATTEMPTS_EXCEEDED:
    "You have had too many login attempts. Please try again after a few minutes.",
  PASSWORD_INCORRECT: "Password incorrect",
  OTP_SENT: "OTP sent to your email address, OTP is valid only for 3 minutes",
  INVALID_MOBILE_NUMBER: "Invalid Mobile Number",
  OTP_SENT_MOBILE: "OTP sent successfully, It is only valid for 10 minutes",
  OTP_EXPIRED: "OTP Expired",
  INVALID_OTP: "Invalid OTP",
  NEED_TWO_FA: "Need 2FA code",
  INVALID_TWO_FA: "Invalid 2FA code",
  EXPIRED_TWO_FA: "Expired 2FA Code",
  OTP_IS_REQUIRED: "OTP is required",
  LOGIN_SUCCESS: "Login Success!",
  ERROR_SERVER: "Error on server",
  EMAIL_NOT_EXISTS: "Email Not Exists",
  CONFIRMATION_LINK_SENT:
    "Confirmation link has been sent to your registered mail",
  INVALID_LINK: "Invalid Link!",
  OLD_PASSWORD_CANT_BE_NEW_PASSWORD: "Old password can't be a new password!",
  PASSWORD_UPDATED_SUCCESSFULLY: "Password Updated successfully",
  USER_NOT_FOUND: "User not found",
  INCORRECT_OLD_PASSWORD: "Incorrect Old Password",
  NEW_PASSWORD_CANT_BE_OLD_PASSWORD: "New password can't be an old password!",
  PASSWORD_CHANGED_SUCCESSFULLY: "Password Changed Successfully",
  SOMETHING_WENT_WRONG: "Something went wrong",

  PROFILE_DETAILS_UPDATED_SUCCESSFULLY: "Profile details updated successfully",
  PROFILE_UPDATED_SUCCESSFULLY: "Profile updated successfully",

  DEACTIVATED: "Deactivated",
  ACTIVATED: "Activated",
  USER_STATUS_CHANGED_SUCCESSFULLY: "User status changed successfully",
  SOMETHING_WENT_WRONG: "Something went wrong",
  ACTIVATE_REGISTER_USER: "activate_register_user",
  ACTIVATION_MAIL_SENT_SUCCESSFULLY: "Activation Mail Sent successfully",
  ERROR_ON_SERVER: "Error on server",
  NO_RECORD: "No record",
  UPDATED_SUCCESS: "Updated Success",
  INVALID_USER: "Invalid user",
  INVALID_MOBILE_NO: "Enter correct mobile number",
  RESEND_OTP_MOBILE:
    "Verification code sent to your mobile number, verification code is valid only for 10 minutes",
  NEXT_OTP_AFTER_3_MINUTES: "Next verification code after 3 minutes",
  RESEND_OTP_EMAIL:
    "Verification code sent to your email address, verification code is valid only for 3 minutes",
  NAME_FIELD_REQUIRED: "Name field is required",
  USERNAME_ALPHABETS_SPACES: "Username should contain alphabets and spaces",
  USER_NOT_FOUND: "User not found",
  PROFILE_UPDATED_SUCCESSFULLY: "Profile updated successfully",
  INVALID_USER_DETAILS: "Invalid user details",
  INVALID_TYPE: "Invalid type",
  CODE_VERIFIED: "Code verified",
  ERROR_ON_SERVER: "Error on server",
  INVALID_VERIFICATION_CODE: "Invalid verification code",
  VERIFICATION_CODE_EXPIRED: "Verification code expired",
  CODE_VERIFIED: "Code verified",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER: "Something went wrong, try again later",
  REQUIRED: "Required",
  USERNAME_ALPHA_SPACE: "Username should contain alphabets and spaces",
  EMAIL_REQUIRED: "Email field is required",
  MSG_FIELD_REQUIRED: "Message field is required",
  INVALID_EMAIL: "Invalid Email",
  PHONE_NO_REQUIRED: "Phone number field is required",
  COUNTRY_CODE_REQUIRED: "Country Code field is required",
  PASSWORD_REQUIRED: "Password field is required",
  INVALID_PASSWORD_FORMAT:
    "Password should contain at least one uppercase, at least one lowercase, at least one number, at least one special character and have a length between 6 and 18 characters",
  PASSWORD_LENGTH_EXCEEDED:
    "Password should contain at least one uppercase, at least one lowercase, at least one number, at least one special character and have a length between 6 and 18 characters",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password field is required",
  PASSWORD_MISMATCH: "Password and Confirm Password must match",
  TERMS_REQUIRED: "Terms & Policy field is required",
  USERID_FIELD_IS_REQUIRED: "UserId field is required",
  FIRST_NAME_FIELD_IS_REQUIRED: "First Name field is required",
  NAME_FIELD_IS_REQUIRED: "Name field is required",
  LAST_NAME_FIELD_IS_REQUIRED: "Last Name field is required",
  PHONE_CODE_FIELD_IS_REQUIRED: "Phone Code field is required",
  PHONE_NUMBER_FIELD_IS_REQUIRED: "Phone Number field is required",
  PHONE_NUMBER_IS_INVALID: "Phone number is invalid",
  ADDRESS_FIELD_IS_REQUIRED: "Address field is required",
  COUNTRY_FIELD_IS_REQUIRED: "Country field is required",
  CITY_FIELD_IS_REQUIRED: "City field is required",
  POSTAL_CODE_FIELD_IS_REQUIRED: "Postal Code field is required",

  OLD_PASSWORD_FIELD_IS_REQUIRED: "Old Password field is required",
  NEW_PASSWORD_FIELD_IS_REQUIRED: "New Password field is required",
  PASSWORD_REQUIREMENTS_NOT_MET:
    "Password should contain at least one uppercase, at least one lowercase, at least one number, at least one special character, and be between 6 and 18 characters long",
  CONFIRM_NEW_PASSWORD_FIELD_IS_REQUIRED:
    "Confirm New Password field is required",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Password and Confirm New Password must match",
  OLD_AND_NEW_PASSWORD_MUST_BE_DIFFERENT:
    "Old and New Password must be different",
  AUTH_TOKEN_FIELD_IS_REQUIRED: "Auth Token field is required",
  CONFIRM_PASSWORD_FIELD_IS_REQUIRED: "Confirm Password field is required",
  NEW_AND_CONFIRM_PASSWORD_MUST_MATCH: "New and Confirm Password must match",

  USER_ASSET_FIELD_IS_REQUIRED: "User Asset field is required",
  INVALID_TRANSACTION_ID: "Invalid transactionId",
  REQUIRED: "Required",
  AMOUNT_FIELD_IS_REQUIRED: "Amount field is required",
  INVALID_AMOUNT: "Invalid amount",
  USER_ASSET_FIELD_IS_REQUIRED: "User Asset field is required",
  INVALID_CURRENCY: "Invalid currency",
  AMOUNT_FIELD_IS_REQUIRED: "Amount field is required",
  INVALID_TRANSACTION_ID: "Invalid transactionId",
  REQUIRED: "Required",
  INVALID_CURRENCY_ID: "Invalid currency id",
  RECEIVER_ADDRESS_FIELD_IS_REQUIRED: "Receiver Address field is required",
  INVALID_ADDRESS: "Invalid address",
  AMOUNT_FIELD_IS_REQUIRED: "Amount field is required",
  PLEASE_ENTER_VALID_AMOUNT: "Please enter a valid amount",
  MIN_WITHDRAW_AMOUNT_MESSAGE:
    "Please Enter Withdraw Amount Greater than or Equal to",
  OTP_STATUS_IS_REQUIRED: "otpStatus is required",
  INVALID_OTP_STATUS: "Invalid OTP Status",
  PLEASE_ENTER_OTP: "Please enter a OTP",
  THERE_IS_NO_DATA: "There is no data",
  PLEASE_ENTER_AMOUNT_GREATER_THAN_OR_EQUAL_TO:
    "Please enter the amount greater than or equal to ",
  DEPOSIT_REQUEST_SENT_SUCCESSFULLY: "Deposit request sent successfully",
  INVALID_CURRENCY_DATA: "Invalid currency data",
  INVALID_WITHDRAW_CURRENCY_DATA: "Invalid withdraw currency data",
  MIN_WITHDRAW_AMOUNT_REQUIRED:
    "Minimum withdraw amount must be greater than or equal to ",
  MAX_WITHDRAW_AMOUNT_REQUIRED:
    "Withdraw amount must be less than or equal to ",
  INSUFFICIENT_BALANCE: "Insufficient Balance",
  WITHDRAW_AMOUNT_TOO_LOW: "Withdraw Amount too low",
  OTP_SENT_SUCCESSFULLY: "OTP sent to your email address, Please Enter a OTP",
  EXPIRED_OTP: "Expired OTP",
  INVALID_OTP: "Invalid OTP",
  WITHDRAW_REQUEST_SENT_SUCCESSFULLY: "Withdraw request sent successfully",
  ERROR_ON_SERVER: "Error on server",
  RECORD_NOT_FOUND: "Record not found!",
  GENERATED_SUCCESSFULLY: "Generated Successfully",
  STAKING_CURRENCY_NOT_EXISTS: "Staking currency does not exist",
  INVESTMENT_PLAN_ADDED_SUCCESSFULLY: "Investment plan added successfully.",
  SOMETHING_WENT_WRONG: "Something went wrong",
  STAKING_CURRENCY_NOT_EXISTS: "Staking currency does not exist",
  INVESTMENT_PLAN_UPDATED_SUCCESSFULLY: "Investment plan updated successfully",
  FETCH_SUCCESS: "Fetch successfully",
  MIN_AMOUNT_GREATER_THAN_OR_EQUAL:
    "Minimum Amount must be greater than or equal to ",
  AMOUNT_LESS_THAN_OR_EQUAL: "Amount must be less than or equal to ",
  INVALID_STAKING: "Invalid staking",
  INVALID_CURRENCY: "Invalid currency",
  DEACTIVE: "Deactive",
  NO_RECORD: "No record",
  INVALID_ASSET: "Invalid asset",
  INSUFFICIENT_BALANCE: "There is not enough asset in your balance.",
  INVESTMENT_ORDER_ADDED_SUCCESSFULLY: "Investment order added successfully.",
  KINDLY_CHOOSE_DURATION_DAYS: "Kindly choose the duration days",
  NO_RECORD: "There is no record",
  ALREADY_CANCELLED: "Already cancelled",
  INVALID_CURRENCY: "Invalid currency",
  RELEASED_SUCCESSFULLY: "Released successfully",
  ALREADY_REDEMPTION_COMPLETED: "Already redemption has been completed",
  INSUFFICIENT_AMOUNT: "Insufficient amount",
  ENTER_VALID_AMOUNT: "Enter valid amount",
  EMAIL_ALREADY_VERIFIED:
    "Your email has been already verified, you can log in",
  ACCOUNT_DEACTIVATED_BY_ADMIN:
    "Your Account Deactivated By Admin,Please Contact Admin",
  EMAIL_VERIFIED_CAN_LOGIN: "Your email has been verified, you can log in now",
  AMOUNT_ZERO: "Amount cannot be zero",
  AMOUNT_NEGATIVE: "Amount cannot be negative",
  AMOUNT_EXCEEDS_STAKED:
    // "Amount must be less than or equal to {{stakedAmount}}",
    "Minimum invest amount is {{stakedAmount}}",
  SUCCESS_COPY: "Successfully copied to clipboard",
  INTEREST_HISTORY: "Interest History",
  SEE_MORE: "See more",
  STRATEGY: "Strategy",
  PERIOD: "Period",
  MANAGEMENT_FEE: "Management Fee %",
  MINIMUM_INVESTMENT: "Minimum Investment",
  AMOUNT: "Amount",
  CLOSE: "Close",
  INVEST: "Invest",
  MONTHLY: "Monthly",
  PROCESS: "Process",
  UNLOCKED: "Unlocked",
  LOCKED: "Locked",
  DAYS: "Days",
  LOGOUT_SUCCESS: "Logout Successfully",
  HOME: "Home",
  INVEST: "Invest",
  WALLET: "Wallet",
  REFERRAL: "Referral",
  DASHBOARD: "Dashboard",
  LOGOUT: "Logout",
  THERE_ARE_NO_RECORDS_TO_DISPLAY: "There_are_no_records_to_display",
  TOTAL_REFERRAL_INCOME_EARNED: "Total Referral Income Earned",
  TOTAL_REFERRAL_USERS_INVEST: "Total Referral Users Invest",
  TYPE: "Type",
  TRX_ID: "Trx Id",
  TO_ADDRESS: "To Address",
  FROM_ADDRESS: "From Address",
  CURRENCY: "Currency",
  FEE: "Fee",
  REWARD: "Reward",
  USER_CODE: "User Code",
  REWARD_CURRENCY: "Reward Currency",
  REWARD_LEVEL: "Reward Level",
  REWARD_PERCENT: "Reward Percentage",
  CREATED_AT: "Created At",
  CUSTOM_SOLUTION: "Custom Solution",
  PROFIT: "Profit",
};
