import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { createUser } from "../../api/users";
import { toastAlert } from "../../lib/toastAlert";
import { CountrySelect } from "../../lib/countrySelect";
import isEmpty from "../../lib/isEmpty";
import { getCountryCallingCode } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
// import { getCountries } from "react-phone-number-input";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const initialFormValue = {
  username: "",
  email: "",
  phoneNo: "",
  password: "",
  confirmPassword: "",
  isTerms: "",
  otpStatus: false,
  otp: "",
  referralCode: "",
  isRefferral: false,
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [country, setCountry] = useState("AC");
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValidMobile, setIsValidMobile] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();

  const {
    email,
    password,
    confirmPassword,
    phoneNo,
    isTerms,
    otpStatus,
    otp,
    username,
    referralCode,
    isRefferral,
  } = formValue;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });
    setFormValue({
      ...formValue,
      ...{ [name]: name != "isTerms" ? value : checked },
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // if (!isValidMobile) {
      //   setLoader(false);
      //   setErrors({ ...errors, newPhoneNo: "Mobile Number is required", });
      //   return;
      // }

      let data = {
        ...formValue,
        countryCode: getCountryCallingCode(country),
        langCode: i18n.language || "en",
      };

      setLoader(true);
      const { status, message, error } = await createUser(data);
      setLoader(false);
      const nsg = t(message);
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", nsg, "signup", "TOP_CENTER");
        navigate("/login");
      } else if (status === "OTP") {
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        toastAlert("success", nsg, "signup", "TOP_CENTER");
      } else {
        if (error) {
          const translatedErrors = {};
          Object.keys(error).forEach((key) => {
            translatedErrors[key] = t(`${error[key]}`);
          });
          setErrors(translatedErrors);
          return false;
        }
        toastAlert("error", nsg, "signup", "TOP_CENTER");
      }
    } catch (err) {
      console.log("ERROR REGISTER :", err);
    }
  };
  const handleCountry = (e) => {
    try {
      setCountry(e);
      if (!isEmpty(e) && !isEmpty(phoneNo)) {
        let val = `+${getCountryCallingCode(e)}${phoneNo}`;
        let res = isValidPhoneNumber(val);
        if (res) {
          setIsValidMobile(res);
          setErrors({
            ...errors,
            phoneNo: "",
          });
        } else if (!isValidPhoneNumber(phoneNo)) {
          setErrors({
            ...errors,
            phoneNo: t("INVALID_MOBILE_NUMBER"),
          });
          return;
        }
      }
    } catch (e) {
      setErrors({
        ...errors,
        phoneNo: t("SOMETHING_WENT_WRONG"),
      });
      console.log("ERROR", e);
      return;
    }
  };

  const handleMobileNo = (e) => {
    try {
      let num = e.target.value;
      setErrors({});
      setFormValue({ ...formValue, ...{ phoneNo: num } });
      if (num === undefined) {
        setErrors({ ...errors, phoneNo: t("REQUIRED") });
        return;
      }
      if (isEmpty(num)) {
        setErrors({ ...errors, phoneNo: t("REQUIRED") });
        return;
      }
      if (!isEmpty(num)) {
        let val = `+${getCountryCallingCode(country)}${num}`;
        let res = isValidPhoneNumber(val);
        if (res) {
          setIsValidMobile(res);
        } else if (!isValidPhoneNumber(num)) {
          setErrors({ ...errors, phoneNo: t("INVALID_MOBILE_NUMBER") });
          return;
        }
      } else {
        setErrors({ ...errors, phoneNo: t("REQUIRED") });
        return;
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (!isEmpty(parsed.ref)) {
      setFormValue({
        ...formValue,
        ...{ referralCode: parsed.ref, isRefferral: true },
      });
    }
  }, []);

  return (
    <div className="auth_box">
      <h2>
        {t("LOGIN_WELCOME_TITLE")} <span>{t("LOGIN_MANTIS_NETWORK")}</span>
      </h2>
      <h2>{t("LOGIN_SIGN_IN")}</h2>
      <form className="primary_form mt-4 mt-lg-5">
        <div className="form-group">
          <label>{t("REGISTER_USER_NAME")}</label>
          <input
            value={username}
            className="form-control primary_inp"
            placeholder={t("REGISTER_ENTER_USERNAME")}
            type="text"
            name="username"
            onChange={handleChange}
          />
          <span className="text-danger">{errors?.username}</span>
        </div>

        <div className="form-group">
          <label>{t("REGISTER_EMAIL_ADDRESS")}</label>
          <input
            value={email}
            className="form-control primary_inp"
            placeholder={t("REGISTER_ENTER_EMAIL")}
            type="email"
            name="email"
            onChange={handleChange}
          />
          <span className="text-danger">{errors?.email}</span>
        </div>

        <div className="form-group">
          <label>{t("REGISTER_COUNTRY")}</label>
          <CountrySelect
            labels={en}
            value={country}
            onChange={handleCountry}
            name="country"
            className="form-select"
          />
          <label>{t("REGISTER_PHONE_NUMBER")}</label>

          <input
            className="form-control primary_inp"
            placeholder={t("REGISTER_ENTER_MOBILE_NUMBER")}
            type="number"
            name="phoneNo"
            value={phoneNo}
            onChange={handleMobileNo}
          />
          <span className="text-danger">{errors?.phoneNo}</span>
          <span className="text-danger">{errors?.phoneCode}</span>
        </div>

        <div className="form-group">
          <label>{t("REGISTER_PASSWORD")}</label>
          <div className="input-group">
            <Link
              className="input-group-text primary_inp"
              onClick={() => setShowPassword(!showPassword)}
            >
              <i
                className={`bi bi-eye-slash ${showPassword ? "d-none" : ""}`}
              ></i>
              <i className={`bi bi-eye ${showPassword ? "" : "d-none"}`}></i>
            </Link>
            <input
              id="password"
              className="form-control primary_inp"
              type={`${showPassword ? "text" : "password"}`}
              name="password"
              placeholder={t("REGISTER_ENTER_PASSWORD")}
              value={password}
              onChange={handleChange}
            />
          </div>
          <span className="text-danger">{errors?.password}</span>
        </div>

        <div className="form-group">
          <label>{t("REGISTER_CONFIRM_PASSWORD")}</label>
          <div className="input-group">
            <Link
              className="input-group-text primary_inp"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <i
                className={`bi bi-eye-slash ${
                  showConfirmPassword ? "d-none" : ""
                }`}
              ></i>
              <i
                className={`bi bi-eye ${showConfirmPassword ? "" : "d-none"}`}
              ></i>
            </Link>
            <input
              id="confirmPassword"
              className="form-control primary_inp"
              type={`${showConfirmPassword ? "text" : "password"}`}
              name="confirmPassword"
              placeholder={t("REGISTER_ENTER_CONFIRM_PASSWORD")}
              value={confirmPassword}
              onChange={handleChange}
            />
          </div>
          <span className="text-danger">{errors?.confirmPassword}</span>
        </div>

        <div className="form-group">
          <label>{t("REGISTER_REFERRAL_CODE")}</label>
          <input
            className="form-control primary_inp"
            placeholder={t("REGISTER_ENTER_REFERRAL_CODE")}
            type="text"
            name="referralCode"
            onChange={handleChange}
            value={referralCode}
            disabled={isRefferral}
          />
          <span className="text-danger">{errors?.referralCode}</span>
        </div>

        <div className="form-group">
          {otpStatus === true && (
            <div class="mb-3">
              <label for="inputPassword" class="form-label">
                {t("REGISTER_OTP")}
              </label>
              <input
                type="text"
                class="form-control"
                id="otp"
                placeholder={t("REGISTER_ENTER_OTP")}
                name="otp"
                onChange={handleChange}
                value={otp}
              />
              <span className="text-danger">{errors?.otp}</span>
            </div>
          )}
          <div className="form-check form-check-inline">
            <input
              className="form-check-input primary_checkinput "
              type="checkbox"
              id="inlineCheckbox1"
              name="isTerms"
              onChange={handleChange}
              checked={isTerms}
            />
            <label className="form-check-label" for="inlineCheckbox1">
              {t("REGISTER_AGREE_TERMS")}{" "}
              <Link to="/terms">{t("REGISTER_TERMS_LINK")}</Link> and{" "}
              <Link to="/privacy">{t("REGISTER_PRIVACY_LINK")}</Link>
            </label>
          </div>
          <span className="text-danger">{errors?.isTerms}</span>
          <br />
        </div>

        <div className="form-group">
          <button
            onClick={handleSubmit}
            className="secondary_btn"
            disabled={loader}
          >
            {t("REGISTER_BUTTON")}
          </button>
        </div>
        <label className="text-center d-block">
          {t("REGISTER_ALREADY_REGISTER")}{" "}
          <Link to="/login">{t("REGISTER_SIGN_IN")}</Link>
        </label>
      </form>
    </div>
  );
};

export default RegisterForm;
