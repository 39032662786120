export default {
  PRIVACY_SECTION_PRIVACY: "隐私",
  LOGIN_LINK_LABEL: "登录",
  REGISTER_LINK_LABEL: "注册",
  CLICK_ME_LABEL: "点击我！",
  LOGIN_EMAIL_PLACEHOLDER: "输入您的电子邮件地址",
  EMPOWER_FINANCIAL_FUTURE: "增强您的财务未来。",
  HOME_DESCRIPTION:
    "深入挖掘加密货币世界，借助专业指导加速您的投资之旅。从今天开始在加密货币中投资，看到您的投资组合扩大。Mantis Network提供指导，帮助您成功成为加密货币投资者的必备要素。",
  GET_STARTED: "开始",
  DASHBOARD: "仪表板",
  ALL_PARTICIPANTS: "所有参与者",
  ASSETS_UNDER_MANAGEMENT: "管理的资产",
  WHY_INVEST_WITH_US: "为什么选择我们投资",
  SECURE_AND_RELIABLE: "安全可靠",
  PLATFORM_SECURITY_BRIEF_EXPLANATION:
    "我们的平台重视安全。您的资产得到先进技术和行业领先的安全协议的保护。",
  EARN_REWARDS_EFFORTLESSLY: "轻松赚取奖励",
  INVESTMENT_DESCRIPTION:
    "与我们一起投资意味着您可以轻松获得额外的加密货币奖励。无需复杂的交易策略或技术知识。",
  DECENTRALIZATION_MATTERS: "去中心化很重要",
  JOIN_THE_MOVEMENT:
    "加入迈向去中心化未来的行列。通过投资您的加密货币，您积极为区块链网络的强大和稳定做出贡献。",
  USER_FRIENDLY_INTERFACE: "用户友好界面",
  INTUITIVE_PLATFORM:
    "我们直观的平台使投资对初学者和经验丰富的投资者都变得简单。没有隐藏费用或复杂的流程-只有一种无缝的体验。",
  HOW_IT_WORKS: "它是如何工作的",
  MANTIS_NETWORK_REGISTRATION:
    "使用Mantis Network，您只需注册并创建帐户，无需了解区块链或加密。",
  SIMPLE_PATH_WITH_MANTIS_NETWORK: "Mantis Network的简单路径",
  CREATE_YOUR_ACCOUNT: "创建您的帐户",
  SIGN_UP_WITH_MANTIS_NETWORK:
    "使用Mantis Network轻松注册，无需先前的区块链或加密钱包经验。我们用户友好的平台适用于所有人。",
  EXPLORE_INVESTMENT_OPTIONS: "探索投资选择",
  DISCOVER_INVESTMENT_OPPORTUNITIES:
    "发现各种投资机会，包括比特币挖矿和以太坊质押，定制的Mantis Strategies，以满足您的财务目标。",
  BEGIN_INVESTING: "开始投资",
  START_INVESTMENT_JOURNEY:
    "借助我们的逐步指导，开始您在比特币，以太坊，ALT币和各种早期项目等利润丰厚的资产中的投资之旅。",
  WATCH_PORTFOLIO_GROW: "观察您的投资组合增长",
  TRACK_INVESTMENTS:
    "在Mantis Network上轻松跟踪您的投资，并在您深入研究加密货币投资世界的过程中观察您的投资组合增长。",
  ASSET_ALLOCATION: "资产配置",
  FOCUS_ON_INCOME_GENERATION:
    "通过每天在数字钱包中累积BTC（比特币）奖励和ETH（以太坊）质押奖励，专注于收入生成。",
  BTC_MINING_ALLOCATOR_ALLOCATION: "40%分配给BTC挖矿分配器",
  BTC_ALLOCATION: "30%分配给BTC",
  ETH_ALLOCATION: "20%分配给ETH（以太坊）",
  MANTIS_STRATEGIES_ALLOCATION: "10%分配给寻找Alpha的Mantis Strategies",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "这个投资组合适合那些希望专注于收入构建和复利回报的投资者，并倾向于BTC价格暴露的人。",
  INVESTOR_PORTFOLIO_BLURB: "收入生成和增长的综合关注",
  GROWTH_FOCUS_BLURB:
    "专注于实现大规模增长，将更多资金分配给我们寻找Alpha的Mantis Strategies",
  BTC_MINING_ALLOCATOR_ALLOCATION: "30%分配给BTC挖矿分配器",
  BTC_ALLOCATION: "20%分配给BTC",
  ETH_ALLOCATION: "20%分配给ETH（以太坊）",
  MANTIS_STRATEGIES_ALLOCATION: "30%分配给寻找Alpha的Mantis Strategies",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "这个投资组合适合那些希望专注于收入构建和复利回报的投资者，并倾向于BTC价格暴露，并且还在去中心化金融和另类币市场寻求非常高回报的人。",
  INVEST_TITLE: "投资",
  BALANCED_TITLE: "均衡",
  HIGH_YIELD_TITLE: "高收益",
  GROWTH_TITLE: "增长",
  ROI_CALCULATOR_MAIN_TITLE: "ROI计算器",
  ROI_CALCULATOR_INVESTMENTS_LABEL: "投资",
  ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL: "投资金额",
  ROI_CALCULATOR_APY_LABEL: "年化收益率（APY百分比）",
  ROI_CALCULATOR_MONTHS_LABEL: "月数",
  ROI_CALCULATOR_INVEST_BUTTON: "投资",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_1: "1个月",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_3: "3个月",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_6: "6个月",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_9: "9个月",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_12: "12个月",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_24: "24个月",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_48: "48个月",
  ROI_CALCULATOR_INTEREST_LABEL: "总利息：",
  ROI_CALCULATOR_FUTURE_VALUE_LABEL: "未来价值：",
  NEWS_COMPONENT_MAIN_TITLE: "我们的新闻与更新",
  NEWS_COMPONENT_VIEW_MORE_BUTTON: "查看更多",
  FAQ_COMPONENT_MAIN_TITLE: "常见问题",
  FAQ_COMPONENT_VIEW_MORE_BUTTON: "查看更多",
  FOOTER_INNOVATIVE_PLATFORM:
    "Mantis Network是一个创新平台，使传统投资者能够轻松获得对加密货币投资的曝光。",
  FOOTER_FOLLOW_US: "关注我们",
  FOOTER_COPYRIGHT: "版权 ©",
  FOOTER_ALL_RIGHTS_RESERVED: "保留所有权利。",
  FOOTER_ADDRESS: "地址",
  FOOTER_CONTACT_US: "联系我们",
  FOOTER_TERMS_OF_SERVICE: "服务条款",
  FOOTER_PRIVACY_POLICY: "隐私政策",
  INVEST_TITLE: "投资",
  INVEST_TABLE_HEADER_ASSETS: "资产",
  INVEST_TABLE_HEADER_WEIGHTS: "权重",
  INVEST_ALLOCATION_BTC: "BTC",
  INVEST_ALLOCATION_ETH: "ETH",
  INVEST_ALLOCATION_MINING_POOL: "BTC 挖矿池",
  INVEST_ALLOCATION_MANTIS_STRATEGIES: "Mantis 策略",
  INVEST_ALLOCATION_TOTAL: "总分配",
  INVEST_MINIMUM_INVESTMENT: "最低投资",
  INVEST_PROJECTED_ROI: "预计 ROI (%)",
  INVEST_DETAILS: "详情",
  INVEST_INVEST_BUTTON: "投资",
  INVEST_INVESTMENT_DETAILS: "投资详情",
  INVEST_MONTH: "月份",
  INVEST_INTEREST_RATE: "利率",
  INVEST_MONTHS: "月",
  INVEST_AMOUNT: "金额",
  INVEST_INVEST_MODAL_TITLE: "投资订单",
  INVEST_INVEST_MODAL_DESCRIPTION: "请查看您的投资详情并继续下单。",
  INVEST_INVEST_MODAL_AMOUNT_LABEL: "金额",
  INVEST_INVEST_MODAL_PLACE_ORDER: "下单",
  INVEST_INVEST_MODAL_CLOSE: "关闭",
  INVEST_RESPONSE_MODAL_TITLE_SUCCESS: "成功",
  INVEST_RESPONSE_MODAL_TITLE_ERROR: "错误",
  INVEST_RESPONSE_MODAL_CLOSE: "关闭",
  INVEST_DASHBOARD_TOTAL_LOCKED_VALUE: "总锁定价值",
  INVEST_DASHBOARD_CAPITAL_GROWTH: "资本增长",
  INVEST_DASHBOARD_YOUR_EARNINGS: "您的收益",
  INVEST_DASHBOARD_AVAILABLE_BALANCE: "可用余额",
  INVEST_DASHBOARD_WALLET_LINK: "钱包",
  INVEST_DASHBOARD_INVEST_LINK: "投资",
  INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE: "投资详情",
  INVESTMENT_DETAILS_STRATEGY_LABEL: "策略",
  INVESTMENT_DETAILS_DURATION_LABEL: "持续时间",
  INVESTMENT_DETAILS_ALL_OPTION: "全部",
  INVESTMENT_DETAILS_LOW_OPTION: "低",
  INVESTMENT_DETAILS_HIGH_OPTION: "高",
  INVESTMENT_DETAILS_ID_LABEL: "ID",
  INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL: "总投资期",
  INVESTMENT_DETAILS_REWARDS_EARNED_LABEL: "获得的奖励",
  INVESTMENT_DETAILS_REMAINING_DAYS_LABEL: "剩余天数",
  INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL: "资本增长",
  INVESTMENT_DETAILS_VIEW_BUTTON_LABEL: "查看",
  INVESTMENT_DETAILS_INVESTED_AT_LABEL: "投资于",

  RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE: "最近活动",
  RECENT_ACTIVITY_SEE_MORE_LINK: "查看更多",
  RECENT_ACTIVITY_TRANSFER_TYPE_LABEL: "通过 USD 转账",
  RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL: "通过 USD 存款",
  RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL: "通过 USD 提现",
  RECENT_ACTIVITY_DATE_LABEL: "日期",
  RECENT_ACTIVITY_STATUS_LABEL: "状态",
  RECENT_ACTIVITY_REJECTED_STATUS: "已拒绝",
  RECENT_ACTIVITY_GREEN_TEXT: "green_txt",
  RECENT_ACTIVITY_RED_TEXT: "red_txt",
  FORGOT_PASSWORD_TITLE: "忘记密码",
  FORGOT_PASSWORD_RESET_INFO: "重设密码链接已发送到您注册的电子邮件地址",
  FORGOT_PASSWORD_EMAIL_LABEL: "电子邮件地址",
  FORGOT_PASSWORD_SUBMIT_BUTTON: "提交",
  FORGOT_PASSWORD_GO_TO_LOGIN: "前往登录",

  LOGIN_WELCOME_TITLE: "欢迎来到",
  LOGIN_MANTIS_NETWORK: "Mantis Network",
  LOGIN_SIGN_IN: "登录以继续！",
  LOGIN_EMAIL_LABEL: "电子邮件地址",
  LOGIN_PASSWORD_LABEL: "密码",
  LOGIN_REMEMBER_ME: "记住我",
  LOGIN_FORGOT_PASSWORD: "忘记密码？",
  LOGIN_LOGIN_BUTTON: "登录",
  LOGIN_DONT_HAVE_ACCOUNT: "没有账号？",
  LOGIN_REGISTER_HERE: "在这里注册",
  LOGIN_OTP_VALID_UP_TO: "OTP有效截止日期",
  LOGIN_YOUR_OTP_SENT: "您的一次性密码（OTP）已发送到您注册的电子邮件",
  LOGIN_ENTER_OTP: "输入OTP",
  LOGIN_SUBMIT_BUTTON: "提交",
  LOGIN_RESEND_OTP: "重新发送OTP",

  NEWS_EVENTS_INNER_HEAD_TITLE: "新闻和更新",

  PRIVACY_INNER_HEAD_TITLE: "隐私政策 - Mantis Network",
  PRIVACY_PARAGRAPH_1:
    "Mantis Network非常关注数据保护和个人数据的保密性。我们的网站使用个人数据进行注册和用户访问。Mantis Network严格遵守所有法律法规，认真负责地处理您的高度敏感的数据。但是，我们要指出，在互联网上传输数据时无法绝对保护数据，因此无法完全避免第三方的任何访问。尽管如此，您可以放心，我们将采取一切必要的技术和组织安全措施，以防止您的个人数据丢失和滥用",
  PRIVACY_SECTION_COOKIES: "Cookies",
  PRIVACY_PARAGRAPH_2:
    "该网站使用PHP会话cookie。 Cookies不会损害您的计算机，也不包含病毒。 Cookies是一种机制，用于存储信息以识别返回用户或跟踪其在使用网站时的路径。 Cookies用于使我们的网站更加用户友好、舒适和安全。禁用cookie可能会限制此网站的功能",
  PRIVACY_SECTION_LOG_FILES: "服务器日志文件",
  PRIVACY_PARAGRAPH_3:
    "我们的服务器自动收集并存储所谓的服务器日志文件中的信息，您的浏览器会自动传输给我们。该信息不包含个人数据。不打算将此数据与您的个人数据关联起来。如果我们发现有特定迹象表明非法使用，我们保留检查这些数据的权利",
  PRIVACY_SECTION_SSL: "SSL加密",
  PRIVACY_PARAGRAPH_4:
    "出于安全原因，此站点在数据传输时使用SSL加密。只要启用SSL加密，第三方在传输期间将无法阅读数据",
  PRIVACY_SECTION_CONTACT_FORM: "联系表格",
  PRIVACY_PARAGRAPH_5:
    "如果您通过'CONTACT'表格向我们发送消息，您至少将被要求提供您的姓名和电子邮件地址。这些详细信息将与消息一起传输和保存以供进一步处理。 '电话'和'地址'信息是自愿的。未经您的同意，我们将不会分享所有这些信息",
  PRIVACY_SECTION_REGISTRATION: "注册",
  PRIVACY_PARAGRAPH_6:
    "注册后，我们将匿名保存您的密码，只有您的电子邮件地址对我们可见。因此，只有在密码被遗忘时才能重置密码。您需要您的电子邮件地址和密码才能登录到我们的网站。您个人对于密码的保密性负有责任。不要将登录数据提供给其他人",
  PRIVACY_SECTION_CONSENT: "同意声明",
  PRIVACY_PARAGRAPH_7:
    "通过参与和使用我们的服务，您还同意处理您的个人数据。在没有处理您的个人数据的情况下，无法提供这些服务",
  PRIVACY_SECTION_KYC: "KYC合规",
  PRIVACY_PARAGRAPH_8:
    "关于欧盟和美国的制裁以及反洗钱法，Mantis Network有义务在您的自我披露的基础上尽可能进行和记录KYC（了解您的客户）检查。这个程序还需要证明您的身份。未经您事先同意，这些数据不会向第三方披露，除非Mantis Network有法律义务提供。当然，如果审核导致异常，Mantis Network将立即与其客户联系，他们有权发表评论",
  PRIVACY_SECTION_THIRD_PARTY: "第三方服务",
  PRIVACY_PARAGRAPH_9:
    "Mantis Network聘请其他公司和个人提供与我们的服务或业务相关的性能。这些第三方服务提供商只有在绝对需要的情况下才能获得访问权限",
  PRIVACY_SECTION_INFORMATION: "信息权",
  PRIVACY_PARAGRAPH_10:
    "您有权随时获取有关您的个人数据以及数据处理的目的和类型的信息。如果您对个人数据有任何疑问，可以随时通过“关于我们”中提供的地址与我们联系",
  PRIVACY_SECTION_TELEGRAM: "Telegram使用的隐私政策",
  PRIVACY_PARAGRAPH_11:
    "我们的网站集成了Telegram服务的功能。这些功能由Telegram Messenger LLP提供。 地址：71-75 Shelton Street，Covent Garden，London，WC2H 9JQ，英国。通过使用通往Telegram的链接，您可以使用Telegram帐户加入'MANTIS NETWORK信息频道'。更多信息，请查阅Telegram隐私政策：在您的帐户下的Telegram FAQ, F:, Privacy Policy",
  PRIVACY_SECTION_FACEBOOK: "Facebook使用的隐私政策",
  PRIVACY_PARAGRAPH_12:
    "我们的网站集成了Facebook服务的功能。这些功能由Facebook Ireland Limited提供，地址：4 Grand Canal Square，Dublin 2，爱尔兰。通过使用通往Facebook的链接，您可以访问我们的帐户'Mantis Network Limited'。更多信息，请查阅Facebook的隐私政策；https://www.facebook.com/privacy/explanation",
  PROFILE_PROFILE_TITLE: "个人资料",
  PROFILE_USERNAME_LABEL: "用户名",
  PROFILE_EMAIL_LABEL: "电子邮件",
  PROFILE_PHONE_NUMBER_LABEL: "电话号码",
  PROFILE_CHANGE_USERNAME_LABEL: "更改用户名",
  PROFILE_CHANGE_PASSWORD_LABEL: "更改密码",
  PROFILE_PASSWORD_DESCRIPTION: "此密码用于您的登录凭证。",
  PROFILE_CHANGE_BUTTON: "更改",

  CHANGE_PROFILE_CHANGE_USERNAME_TITLE: "更改用户名",
  CHANGE_PROFILE_ENTER_NEW_USERNAME: "输入新用户名",
  CHANGE_PROFILE_CLOSE_BUTTON: "关闭",
  CHANGE_PROFILE_SAVE_BUTTON: "保存",

  CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE: "更改密码",
  CHANGE_PASSWORD_ENTER_OTP: "输入OTP",
  CHANGE_PASSWORD_ENTER_OLD_PASSWORD: "输入旧密码",
  CHANGE_PASSWORD_ENTER_NEW_PASSWORD: "输入新密码",
  CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD: "输入确认密码",
  CHANGE_PASSWORD_CLOSE_BUTTON: "关闭",
  CHANGE_PASSWORD_SEND_BUTTON: "发送",
  CHANGE_PASSWORD_VERIFY_BUTTON: "验证",
  CHANGE_PASSWORD_SUBMIT_BUTTON: "提交",

  WALLET_DETAILS_TITLE: "钱包",
  WALLET_DETAILS_TOTAL_BALANCE: "总余额",
  WALLET_DETAILS_INVEST_BUTTON: "投资",

  DEPOSIT_TITLE: "存款",
  DEPOSIT_SELECT_COIN: "选择币种",
  DEPOSIT_SELECT_NETWORK: "选择网络",
  DEPOSIT_ADDRESS_LABEL: "地址",
  DEPOSIT_COPY_ICON_ALT: "复制",
  DEPOSIT_GENERATE_ADDRESS_BUTTON: "生成地址",
  DEPOSIT_QR_IMAGE_ALT: "模糊图像",
  DEPOSIT_DEPOSIT_NOTES: "存款说明",
  DEPOSIT_SEND_ONLY: "仅向此存款地址发送 {currencySymbol}。",
  DEPOSIT_MINIMUM_DEPOSIT: "最低存款：{minimumDeposit} {currencySymbol}",
  DEPOSIT_SENDING_COIN:
    "向此地址发送 {currencySymbol} 以外的币种或代币可能导致您的存款丢失。",
  WITHDRAW_TITLE: "提现",
  WITHDRAW_OTP_VALID_UP_TO: "OTP 有效期至",
  WITHDRAW_OTP_LABEL: "OTP",
  WITHDRAW_ENTER_OTP: "输入 OTP",
  WITHDRAW_SUBMIT: "提交",
  WITHDRAW_RESEND_OTP: "重新发送 OTP",
  WITHDRAW_SELECT_COIN: "选择币种",
  WITHDRAW_SELECT: "选择",
  WITHDRAW_AMOUNT: "金额",
  WITHDRAW_ENTER_AMOUNT: "输入金额",
  WITHDRAW_SELECT_NETWORK: "选择网络",
  WITHDRAW_CONVERSION_RATE: "汇率",
  WITHDRAW_ENTER_ADDRESS: "输入地址",
  WITHDRAW_ADDRESS: "地址",
  WITHDRAW_WITHDRAW_NOW: "立即提现",
  WITHDRAW_WITHDRAW_NOTES: "提现备注",
  WITHDRAW_SEND_ONLY: "只向此提现地址发送 {{currencySymbol}}。",
  WITHDRAW_MINIMUM_WITHDRAW:
    "最低提现额：{{minimumWithdraw}} {{currencySymbol}}",
  WITHDRAW_MAXIMUM_WITHDRAW:
    "最高提现额：{{maximumWithdraw}} {{currencySymbol}}",
  WITHDRAW_WITHDRAWAL_FEE: "提现费用：{{withdrawFee}} {{currencySymbol}}",
  WITHDRAW_SENDING_COIN:
    "将货币或代币发送到此地址以外的地址可能会导致提现损失。",

  TRX_HISTORY_TITLE: "交易历史记录",
  TERMS_TITLE: "使用条款",
  TERMS_GENERAL: "1. 通用条款",
  TERMS_GENERAL_CONTENT_A:
    "a. 这些服务条款（以下简称“TOS”）适用于使用Mantis Network托管服务（以下简称“服务”）的http://Mantis.Network网站（以下简称“网站”）。 此处的任何术语或其他单词的使用，无论是单数，复数，大写和/或他/她或他们，您/他/她都将被解释为可互换的，因此被视为指同一事物。",
  TERMS_GENERAL_CONTENT_B:
    "b. 任何访问网站并使用服务的个人、实体或其他法人主体（以下简称“用户”）在成为成员之前必须首先阅读、接受并确认TOS，并同意受TOS的约束。 对服务的任何实际参与将构成这样的接受。 如果用户不同意并遵守这些TOS，他将不被允许使用服务。",
  TERMS_GENERAL_CONTENT_C:
    "c. 服务的任何用户通过订阅服务确认具有无限制的完全民事行为能力，并且年满十八（18）岁。 此外，任何用户意识到与使用服务、区块链技术和加密货币相关的所有可能风险。",
  TERMS_EXCLUSIONS_LIMITATIONS_TITLE: "2. 排除和限制",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A:
    "a. 网站的信息将根据法律允许的最大范围提供“按原样”提供服务。服务排除与网站及其内容或可能由任何关联公司或其他第三方提供的内容相关的所有陈述和担保，包括与网站和/或服务以及相关陈述、演示和描述相关的任何不准确或遗漏。",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B:
    "b. 服务或网站的持续运行和加密货币网络的安全性不能保证；可能需要定期进行维护和停机。然而，所有者将尽最大努力提供良好条件的服务，并避免任何更糟的后果。",
  TERMS_LIMITATION_LIABILITIES_TITLE: "3. 责任限制",
  TERMS_LIMITATION_LIABILITIES_CONTENT_A:
    "a. Mantis Network Ltd.及其法定代表人和股东（以下简称“所有者”）对于与网站和/或服务相关或由此引起的任何直接或间接、后果性和偶发的损害不承担责任，无论后果是否可预见，并可能发生在正常情况下，就像事情发生一样。",
  TERMS_LIMITATION_LIABILITIES_CONTENT_B:
    "b. 特别是所有者不对用户的计算机硬件或软件、系统和编程的损害，以及数据丢失以及经济损失、业务损失或利润损失等不负责任。",
  TERMS_LIMITATION_LIABILITIES_CONTENT_C:
    "c. 用户确认了解所有风险，包括经济损失或预期利润损失的可能性。所有者将不会补偿任何不可逆转的损失。",
  TERMS_PAYOUTS_TITLE: "4. 支付",
  TERMS_PAYOUTS_CONTENT_A:
    "a. Mantis Network将按用户投资金额的比例支付奖励。所有交易将以USDT货币进行处理。",
  TERMS_PAYOUTS_CONTENT_B:
    "b. 支付仅将转移到用户在其投资表单（仪表板）上提到的帐户。支付将定期在每四（4）周进行记账。",
  TERMS_PAYOUTS_CONTENT_C:
    "c. 所有者将对所有存款收取2%的服务加入费用，并对所有Mantis Network奖励收取25%的费用。",
  TERMS_PAYOUTS_CONTENT_D:
    "d. 通过指定的加密货币帐户累积的任何奖励将仅支付到该特定地址。不同的钱包余额不能合并。",
  TERMS_PAYOUTS_CONTENT_E:
    "e. 所有者对加密货币价值的任何变化概不负责。投资回报率（ROI）将根据过去24小时每个区块的实际奖励计算。所有者不能保证任何加密货币将保持稳定的价值，因此无法保证投资回报率。在任何时候都可能发生总损失，任何风险由每个用户承担。",
  TERMS_PAYOUTS_CONTENT_F:
    "f. 每季度最多可免费通过支持请求一次投资期末前最多4周的池值计算。每一次额外的计算都将收取25美元的费用，因为这需要提前用USDT支付的高工作量。组合价值每次只有7天的有效期。由于运营原因和对组合价值的高频且经常性的请求，池值计算可能每季度只计算一次，最多在持有期结束前4周进行。",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE: "5. 法律要求和制裁",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A:
    "a. 用户同意此TOS表示他没有受到任何制裁的影响，或者他已被任何有权监管的当局列入名单。他必须立即宣布变化。所有者将定期监控他的身份。积极的筛查结果将导致立即停止服务。",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B:
    "b. 此外，用户同意TOS表示他自费行事。",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C:
    "c. 所有者有权要求用户的身份证明。对于自然人，这通常是当局的官方文件（例如身份证），对于法人，监管机构则需要商业注册簿的最新摘录。",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D:
    "d. 如果信息不正确或不完整，以及欺诈或欺诈行为，用户应赔偿所有者的所有法律后果和财务损失。此外，所有者将立即暂停用户对网站和服务的使用。所有者保留采取法律行动的权利。",
  TERMS_WAIVER_TITLE: "6. 弃权",
  TERMS_WAIVER_CONTENT:
    "不放弃TOS的任何规定均不被视为放弃任何其他规定，也不构成持续放弃，除非另有以书面形式经当事方签署的明示规定。",
  TERMS_FORCE_MAJEURE_TITLE: "7. 不可抗力",
  TERMS_FORCE_MAJEURE_CONTENT:
    "在所有权人无法控制的任何事件或情况下，包括但不限于上帝的行为、暴动、战争、洪水、火灾、爆炸、罢工和其他类似事件，这将阻止、延迟、干扰或导致已经承诺的服务失败的情况下，所有权人将不承担责任。",
  TERMS_TERMINATION_TITLE: "8. 终止",
  TERMS_TERMINATION_CONTENT_A:
    "用户对TOS的同意包括对Mantis Network的投资。投资组合最低锁定期将在（180）一百八十个日历天的期限内有效，并且如果用户在最低锁定期结束时没有终止TOS，则将自动延长。用户可以通过发送电子邮件至support@Mantis.Network终止其TOS和使用服务。将支付当前Mantis Network投资组合价值的份额。在存在充分理由反对与用户业务关系继续的情况下，所有权人有权进行特别终止。在合同的提前特别终止的情况下，将退还当前Mantis Network投资组合价值的份额，减去25%的处理费。没有关于未支付或未来支付的权利。",
  TERMS_SEVERABILITY_CLAUSE_TITLE: "9. 可分割性条款",
  TERMS_SEVERABILITY_CLAUSE_CONTENT:
    "如果TOS的任何规定变为无效，则无效性不影响可以在没有无效规定的情况下产生效果的TOS的其他规定，为此，TOS的规定是可分割的。",
  TERMS_GOVERNING_LAW_TITLE: "10. 管辖法律",
  TERMS_GOVERNING_LAW_CONTENT:
    "根据TOS、网站和服务，所有权人、用户和第三方（如有）的权利和义务受到，将按照英属维尔京群岛法律解释和执行。任何因TOS、网站和服务而产生或与之相关的争议、纠纷或索赔将最终根据国际商会仲裁规则（法国巴黎）进行仲裁。仲裁程序将在英属维尔京群岛的托尔托拉进行。程序的语言将是英语。",

  RESET_PASSWORD_TITLE: "重设密码",
  RESET_PASSWORD_NEW_LABEL: "新密码",
  RESET_PASSWORD_CONFIRM_LABEL: "确认密码",
  RESET_PASSWORD_PASSWORD_PLACEHOLDER: "输入密码",
  RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "输入确认密码",
  RESET_PASSWORD_SHOW_PASSWORD: "显示密码",
  RESET_PASSWORD_SHOW_CONFIRM_PASSWORD: "显示确认密码",
  RESET_PASSWORD_PASSWORD_ERROR: "请输入有效密码",
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR: "密码不匹配",
  RESET_PASSWORD_SUBMIT_BUTTON: "提交",

  REGISTER_USER_NAME: "用户名",
  REGISTER_ENTER_USERNAME: "输入用户名",
  REGISTER_EMAIL_ADDRESS: "电子邮件地址",
  REGISTER_ENTER_EMAIL: "输入您的电子邮件地址",
  REGISTER_COUNTRY: "国家",
  REGISTER_PHONE_NUMBER: "电话号码",
  REGISTER_ENTER_MOBILE_NUMBER: "输入您的手机号码",
  REGISTER_PASSWORD: "密码",
  REGISTER_ENTER_PASSWORD: "输入密码",
  REGISTER_CONFIRM_PASSWORD: "确认密码",
  REGISTER_ENTER_CONFIRM_PASSWORD: "输入确认密码",
  REGISTER_REFERRAL_CODE: "推荐代码（可选）",
  REGISTER_ENTER_REFERRAL_CODE: "输入推荐代码",
  REGISTER_OTP: "OTP",
  REGISTER_ENTER_OTP: "输入OTP",
  REGISTER_AGREE_TERMS: "我明白",
  REGISTER_TERMS_LINK: "使用条款",
  REGISTER_PRIVACY_LINK: "隐私政策",
  REGISTER_BUTTON: "注册",
  REGISTER_ALREADY_REGISTER: "已经注册？",
  REGISTER_SIGN_IN: "登录",

  REFERRAL_HEAD_TITLE: "推荐",
  REFERRAL_TOTAL_REFERRALS_LABEL: "总推荐数",
  REFERRAL_TOTAL_INCOME_LABEL: "总推荐收入",
  REFERRAL_TOTAL_USERS_INVEST_LABEL: "总推荐用户投资",
  REFERRAL_LINK: "推荐链接",
  REFERRAL_LEVEL_INCOME: "级别收入",
  REFERRAL_LEVEL_1_INCOME: "一级收入",
  REFERRAL_LEVEL_2_INCOME: "二级收入",
  REFERRAL_LEVEL_3_INCOME: "三级收入",
  REFERRAL_HISTORY_LABEL: "推荐历史",

  // BACKEND

  EMAIL_EXISTS: "电子邮件已存在",
  PHONE_EXISTS: "电话号码已存在",
  INVALID_REFERRAL_CODE: "无效的推荐代码",
  OTP_SENT: "OTP已发送到您的电子邮件地址，请输入OTP",
  EXPIRED_OTP: "过期的OTP",
  INVALID_OTP: "无效的OTP",
  REGISTRATION_SUCCESS: "注册完成 - 激活链接已发送到您的电子邮件地址",
  SOMETHING_WENT_WRONG: "出了点问题",

  INVALID_EMAIL: "请输入正确的电子邮件地址",
  PHONE_NOT_EXISTS: "电话号码不存在",
  IP_BLOCKED: "您的IP已被阻止",
  ACCOUNT_LOCKED: "您的帐户仍处于锁定状态",
  ACCOUNT_BLOCKED: "您的帐户已被封锁。 请稍后重试",
  ACCOUNT_NOT_ACTIVATED: "您的帐户尚未激活",
  LOGIN_ATTEMPTS_EXCEEDED: "您已尝试登录次数过多。 请稍后再试。",
  PASSWORD_INCORRECT: "密码不正确",
  OTP_SENT: "OTP已发送到您的电子邮件地址，OTP仅在3分钟内有效",
  INVALID_MOBILE_NUMBER: "无效的手机号码",
  OTP_SENT_MOBILE: "OTP已成功发送，仅在10分钟内有效",
  OTP_EXPIRED: "OTP已过期",
  INVALID_OTP: "无效的OTP",
  NEED_TWO_FA: "需要2FA代码",
  INVALID_TWO_FA: "无效的2FA代码",
  EXPIRED_TWO_FA: "过期的2FA代码",
  OTP_IS_REQUIRED: "需要OTP",
  LOGIN_SUCCESS: "登录成功！",
  ERROR_SERVER: "服务器错误",

  EMAIL_NOT_EXISTS: "邮箱不存在",
  CONFIRMATION_LINK_SENT: "确认链接已发送到您注册的邮箱",
  SOMETHING_WENT_WRONG: "出现了一些问题",

  INVALID_LINK: "链接无效!",
  OLD_PASSWORD_CANT_BE_NEW_PASSWORD: "旧密码不能是新密码!",
  PASSWORD_UPDATED_SUCCESSFULLY: "密码已成功更新",
  SOMETHING_WENT_WRONG: "出了点问题",

  USER_NOT_FOUND: "用户未找到",
  INCORRECT_OLD_PASSWORD: "旧密码不正确",
  NEW_PASSWORD_CANT_BE_OLD_PASSWORD: "新密码不能是旧密码！",
  PASSWORD_CHANGED_SUCCESSFULLY: "密码成功更改",

  PROFILE_DETAILS_UPDATED_SUCCESSFULLY: "个人资料更新成功",
  PROFILE_UPDATED_SUCCESSFULLY: "个人资料更新成功",

  DEACTIVATED: "已停用",
  ACTIVATED: "已激活",
  USER_STATUS_CHANGED_SUCCESSFULLY: "用户状态已成功更改",
  SOMETHING_WENT_WRONG: "出了点问题",

  ACTIVATE_REGISTER_USER: "activate_register_user",
  ACTIVATION_MAIL_SENT_SUCCESSFULLY: "激活邮件发送成功",
  ERROR_ON_SERVER: "服务器错误",
  NO_RECORD: "无记录",
  UPDATED_SUCCESS: "更新成功",
  INVALID_USER: "无效用户",
  INVALID_MOBILE_NO: "输入正确的手机号码",
  RESEND_OTP_MOBILE: "验证码已发送到您的手机号码，验证码仅在10分钟内有效",
  NEXT_OTP_AFTER_3_MINUTES: "3分钟后的下一个验证码",
  RESEND_OTP_EMAIL: "验证码已发送到您的电子邮件地址，验证码仅在3分钟内有效",
  NAME_FIELD_REQUIRED: "姓名字段为必填项",
  USERNAME_ALPHABETS_SPACES: "用户名应包含字母和空格",
  USER_NOT_FOUND: "找不到用户",
  PROFILE_UPDATED_SUCCESSFULLY: "个人资料更新成功",
  INVALID_USER_DETAILS: "无效的用户详情",
  INVALID_TYPE: "无效的类型",
  CODE_VERIFIED: "验证码已验证",
  ERROR_ON_SERVER: "服务器错误",
  INVALID_VERIFICATION_CODE: "无效的验证码",
  VERIFICATION_CODE_EXPIRED: "验证码已过期",
  CODE_VERIFIED: "验证码已验证",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER: "出了点问题，请稍后再试",
  REQUIRED: "必需",
  USERNAME_ALPHA_SPACE: "用户名只能包含字母和空格",
  EMAIL_REQUIRED: "电子邮件字段是必需的",
  INVALID_EMAIL: "无效的电子邮件",
  PHONE_NO_REQUIRED: "电话号码字段是必需的",
  COUNTRY_CODE_REQUIRED: "国家代码字段是必需的",
  PASSWORD_REQUIRED: "密码字段是必需的",
  INVALID_PASSWORD_FORMAT:
    "密码必须至少包含一个大写字母、一个小写字母、一个数字、一个特殊字符，并且长度在6到18个字符之间",
  PASSWORD_LENGTH_EXCEEDED:
    "密码必须至少包含一个大写字母、一个小写字母、一个数字、一个特殊字符，并且长度在6到18个字符之间",
  CONFIRM_PASSWORD_REQUIRED: "确认密码字段是必需的",
  PASSWORD_MISMATCH: "密码和确认密码必须匹配",
  TERMS_REQUIRED: "条款和政策字段是必需的",
  USERID_FIELD_IS_REQUIRED: "必须填写 UserId 字段",
  FIRST_NAME_FIELD_IS_REQUIRED: "必须填写名字字段",
  LAST_NAME_FIELD_IS_REQUIRED: "必须填写姓氏字段",
  PHONE_CODE_FIELD_IS_REQUIRED: "必须填写电话代码字段",
  PHONE_NUMBER_FIELD_IS_REQUIRED: "必须填写电话号码字段",
  PHONE_NUMBER_IS_INVALID: "电话号码无效",
  ADDRESS_FIELD_IS_REQUIRED: "必须填写地址字段",
  COUNTRY_FIELD_IS_REQUIRED: "必须填写国家字段",
  CITY_FIELD_IS_REQUIRED: "必须填写城市字段",
  POSTAL_CODE_FIELD_IS_REQUIRED: "必须填写邮政编码字段",
  OLD_PASSWORD_FIELD_IS_REQUIRED: "旧密码字段是必需的",
  NEW_PASSWORD_FIELD_IS_REQUIRED: "新密码字段是必需的",
  PASSWORD_REQUIREMENTS_NOT_MET:
    "密码必须包含至少一个大写字母，至少一个小写字母，至少一个数字，至少一个特殊字符，并且长度在6到18个字符之间",
  CONFIRM_NEW_PASSWORD_FIELD_IS_REQUIRED: "确认新密码字段是必需的",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH: "新密码和确认密码必须匹配",
  OLD_AND_NEW_PASSWORD_MUST_BE_DIFFERENT: "旧密码和新密码必须不同",
  AUTH_TOKEN_FIELD_IS_REQUIRED: "身份验证令牌字段是必需的",
  CONFIRM_PASSWORD_FIELD_IS_REQUIRED: "确认密码字段是必需的",
  NEW_AND_CONFIRM_PASSWORD_MUST_MATCH: "新密码和确认密码必须匹配",
  USER_ASSET_FIELD_IS_REQUIRED: "用户资产字段是必需的",
  INVALID_TRANSACTION_ID: "无效的交易ID",
  REQUIRED: "必填",
  AMOUNT_FIELD_IS_REQUIRED: "金额字段是必需的",
  INVALID_AMOUNT: "无效的金额",
  USER_ASSET_FIELD_IS_REQUIRED: "用户资产字段是必需的",
  INVALID_CURRENCY: "无效的货币",
  AMOUNT_FIELD_IS_REQUIRED: "金额字段是必需的",
  INVALID_TRANSACTION_ID: "无效的交易ID",
  REQUIRED: "必填",
  INVALID_CURRENCY_ID: "无效的货币 ID",
  RECEIVER_ADDRESS_FIELD_IS_REQUIRED: "接收地址字段是必需的",
  INVALID_ADDRESS: "无效的地址",
  AMOUNT_FIELD_IS_REQUIRED: "金额字段是必需的",
  PLEASE_ENTER_VALID_AMOUNT: "请输入有效金额",
  MIN_WITHDRAW_AMOUNT_MESSAGE: "请填写大于或等于最小提款金额",
  OTP_STATUS_IS_REQUIRED: "OTP 状态是必需的",
  INVALID_OTP_STATUS: "无效的 OTP 状态",
  PLEASE_ENTER_OTP: "请输入 OTP",
  THERE_IS_NO_DATA: "没有数据",
  PLEASE_ENTER_AMOUNT_GREATER_THAN_OR_EQUAL_TO: "请输入大于或等于的金额 ",
  DEPOSIT_REQUEST_SENT_SUCCESSFULLY: "存款请求已成功发送",
  INVALID_CURRENCY_DATA: "无效的货币数据",
  INVALID_WITHDRAW_CURRENCY_DATA: "无效的提款货币数据",
  MIN_WITHDRAW_AMOUNT_REQUIRED: "最低提款金额必须大于或等于 ",
  MAX_WITHDRAW_AMOUNT_REQUIRED: "提款金额必须小于或等于 ",
  INSUFFICIENT_BALANCE: "余额不足",
  WITHDRAW_AMOUNT_TOO_LOW: "提款金额过低",
  OTP_SENT_SUCCESSFULLY: "OTP已发送至您的电子邮件地址，请输入OTP",
  EXPIRED_OTP: "OTP已过期",
  INVALID_OTP: "无效的OTP",
  WITHDRAW_REQUEST_SENT_SUCCESSFULLY: "提款请求已成功发送",
  ERROR_ON_SERVER: "服务器出错",
  RECORD_NOT_FOUND: "记录未找到！",
  GENERATED_SUCCESSFULLY: "生成成功",
  STAKING_CURRENCY_NOT_EXISTS: "质押货币不存在",
  INVESTMENT_PLAN_ADDED_SUCCESSFULLY: "成功添加投资计划。",
  STAKING_CURRENCY_NOT_EXISTS: "质押货币不存在",
  INVESTMENT_PLAN_UPDATED_SUCCESSFULLY: "投资计划成功更新",
  FETCH_SUCCESS: "成功获取",
  MIN_AMOUNT_GREATER_THAN_OR_EQUAL: "最小金额必须大于或等于 ",
  AMOUNT_LESS_THAN_OR_EQUAL: "金额必须小于或等于 ",
  INVALID_STAKING: "无效的质押",
  INVALID_CURRENCY: "无效的货币",
  DEACTIVE: "停用",
  NO_RECORD: "无记录",
  INVALID_ASSET: "无效的资产",
  INSUFFICIENT_BALANCE: "您的余额中没有足够的资产。",
  INVESTMENT_ORDER_ADDED_SUCCESSFULLY: "投资订单已成功添加。",
  KINDLY_CHOOSE_DURATION_DAYS: "请选择持续时间天数",
  NO_RECORD: "没有记录",
  ALREADY_CANCELLED: "已取消",
  INVALID_CURRENCY: "无效货币",
  RELEASED_SUCCESSFULLY: "成功释放",
  ALREADY_REDEMPTION_COMPLETED: "已经完成赎回",
  INSUFFICIENT_AMOUNT: "金额不足",
  ENTER_VALID_AMOUNT: "请输入有效金额",
  EMAIL_ALREADY_VERIFIED: "您的电子邮件已经验证过，您现在可以登录",
  ACCOUNT_DEACTIVATED_BY_ADMIN: "您的帐户已被管理员停用，请联系管理员",
  EMAIL_VERIFIED_CAN_LOGIN: "您的电子邮件已经验证过，您现在可以登录",
  AMOUNT_ZERO: "金额不能为零",
  AMOUNT_NEGATIVE: "金额不能为负数",
  AMOUNT_EXCEEDS_STAKED: "金额必须小于或等于 {{stakedAmount}}",
  SUCCESS_COPY: "成功复制到剪贴板",
  INTEREST_HISTORY: "利息历史",
  SEE_MORE: "查看更多",
  STRATEGY: "策略",
  PERIOD: "周期",
  MANAGEMENT_FEE: "管理费 %",
  MINIMUM_INVESTMENT: "最低投资",
  AMOUNT: "数量",
  CLOSE: "关闭",
  INVEST: "投资",
  MONTHLY: "每月",
  PROCESS: "处理",
  UNLOCKED: "已解锁",
  LOCKED: "已锁定",
  DAYS: "天",
  LOGOUT_SUCCESS: "成功登出",
  INVEST_DASHBOARD_ROI_PERCENT: "投资回报率百分比",
  HOME: "主页",
  INVEST: "投资",
  WALLET: "钱包",
  REFERRAL: "推荐",
  DASHBOARD: "仪表板",
  LOGOUT: "登出",
  THERE_ARE_NO_RECORDS_TO_DISPLAY: "没有要显示的记录",
  TOTAL_REFERRAL_INCOME_EARNED: "总推荐收入",
  TOTAL_REFERRAL_USERS_INVEST: "总推荐用户投资",
  TYPE: "类型",
  TRX_ID: "交易 ID",
  TO_ADDRESS: "目标地址",
  FROM_ADDRESS: "来源地址",
  CURRENCY: "货币",
  FEE: "费用",
  REWARD: "奖励",
  USER_CODE: "用户代码",
  REWARD_CURRENCY: "奖励货币",
  REWARD_LEVEL: "奖励级别",
  REWARD_PERCENT: "奖励百分比",
  CREATED_AT: "创建于",
  CUSTOM_SOLUTION: "定制解决方案",
  PROFIT: "利润",
  BTC_MINING_ALLOCATOR_ALLOCATION_BALANCED: "40％分配给BTC挖矿分配器",
  BTC_ALLOCATION_BALANCED: "30％分配给BTC",
  ETH_ALLOCATION_BALANCED: "20％分配给ETH（以太坊）",
  MANTIS_STRATEGIES_ALLOCATION_BALANCED:
    "10％分配给寻求Alpha的Mantis Strategies",
  BTC_MINING_ALLOCATOR_ALLOCATION_GROWTH: "25％分配给BTC挖矿分配器",
  BTC_ALLOCATION_GROWTH: "10％分配给BTC",
  ETH_ALLOCATION_GROWTH: "15％分配给ETH（以太坊）",
  MANTIS_STRATEGIES_ALLOCATION_GROWTH: "50％分配给寻求Alpha的Mantis Strategies",
  BTC_MINING_ALLOCATOR_ALLOCATION_HIGH_YIELD: "30％分配给BTC挖矿分配器",
  BTC_ALLOCATION_HIGH_YIELD: "20％分配给BTC",
  ETH_ALLOCATION_HIGH_YIELD: "20％分配给ETH（以太坊）",
  MANTIS_STRATEGIES_ALLOCATION_HIGH_YIELD:
    "30％分配给寻求Alpha的Mantis Strategies",
};
